import React, { Component } from 'react';
import { Route, Routes } from 'react-router';
import NotFound from '../Common/NotFound';
import IsrIndex from './IsrIndex';
import Impersonate from './Impersonate';
import TicketReport from './TicketReport';
import TicketDetails from './TicketDetails';
import SpreadPuller from './SpreadPuller';
import JobAssociations from './JobAssociations';

export class IsrMain extends Component {
    displayName = IsrMain.name

    render() {
        let availableRoutes = this.props.userInfo.isClientAdminAndHasPublishersAssigned ?
            (<Routes>
                <Route path="/" element={<IsrIndex userInfo={this.props.userInfo} />} />
                <Route path="/ticketReport" element={<TicketReport {...this.props} userInfo={this.props.userInfo} />} />
                <Route path="/ticketDetails/*" element={<TicketDetails {...this.props} userInfo={this.props.userInfo} />} />
                <Route element={<NotFound/>} />
            </Routes>)
            :
            (<Routes>
                <Route exact path="/" element={<IsrIndex userInfo={this.props.userInfo} />} />
                <Route path="/ticketReport" element={<TicketReport {...this.props} userInfo={this.props.userInfo} />} />
                <Route path="/spreadPuller" element={<SpreadPuller/>} />
                <Route path="/ticketDetails/*" element={<TicketDetails {...this.props} userInfo={this.props.userInfo} />} />
                <Route path="/jobAssociations" element={<JobAssociations/>} />
                <Route path="/impersonate" element={<Impersonate {...this.props} userInfo={this.props.userInfo} updateUserInfo={this.props.updateUserInfo} updateSubmission={this.props.updateSubmission} />} />
                <Route element={<NotFound/>} />
            </Routes>);
        return availableRoutes;
    }
}