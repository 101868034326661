import React, { Component } from 'react';
import { lang } from '../../Language';
import { withStyles } from 'tss-react/mui';
import ImageDisplay from '../Common/ImageDisplay';
import Typography from '@mui/material/Typography';

const styles = theme => ({
    sizeGroupCell: {
        display: 'table-cell',
        verticalAlign: 'top',
    },
    sizeGroupInfoCell: {
        display: 'table-cell',
        verticalAlign: 'top',
        paddingLeft: '1em',
        minWidth: '120px',
    },
    sizeGroupPages: {
        display: 'flex',
    },
    panels: {
        textAlign: 'center',
    },
});

class SizePreviewRow extends Component {
    displayName = SizePreviewRow.name
    
    render() {
        const { classes } = this.props;
        let { size } = this.props;
        
        var dispBleed = (size.displayBleed.length > 0) ? <Typography variant="body2">{lang("bleed")} - {size.displayBleed}</Typography> : '';
        var dispSafety = (size.displaySafety.length > 0) ? <Typography variant="body2">{lang("safety")} - {size.displaySafety}</Typography> : '';
        var dispWidth = lang("seePanels");
        if (size.sizeComponents.length === 1) {
            dispWidth = size.sizeComponents[0].displayWidth;
        }

        return (
            <div key={size.id} className={this.props.className} onClick={this.props.onClick} >
                <div className={classes.sizeGroupCell}>
                    <div className={classes.sizeGroupPages}>
                        {size.sizeComponents.map(sizeComponent => {
                            let pageWidth = '';
                            if (size.sizeComponents.length > 1) {
                                pageWidth = <Typography variant="body2" style={{ marginLeft: '1em' }} >{sizeComponent.displayWidth}</Typography>;
                            }
                            return (
                                <div key={sizeComponent.id} className={classes.panels} >
                                    <ImageDisplay componentSize={sizeComponent.viewSize} sizePanels={sizeComponent.panels} marginLeft='1em' />
                                    {pageWidth}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={classes.sizeGroupInfoCell}>
                    <Typography variant="body2">{lang("width")} - {dispWidth}</Typography>
                    <Typography variant="body2">{lang("height")} - {size.displayHeight}</Typography>
                    <br />
                    {dispBleed}
                    {dispSafety}
                </div>
            </div>
        );
    }
}

export default withStyles(SizePreviewRow, styles);