import React from 'react';
import Header from './Header';
import Footer from './Footer';
import NavMenu from './NavMenu';
import TrackDeliveries from './Tracking/TrackDeliveries';
import { TicketRouter } from './Submission/TicketRouter';
import EditProfile from './EditProfile';
import Help from './Help';
import { AdminMain } from './Admin/AdminMain';
import { IsrMain } from './Isr/IsrMain';
import { Routes, Route } from 'react-router';
import { withStyles } from 'tss-react/mui';
import { overrideComponentThemeStyle } from '../StyleCustomization';
import { Navigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { lang } from '../Language';
import { getComponentThemeProperty } from '../StyleCustomization';
import { withLocation } from '../withLocation';
import SalesReport from './Sales/SalesReport';

const styles = theme => {
    var result = {
        root: {
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
        },
        header: {
            flexShrink: '0',
        },
        main: {
            display: 'flex',
            flexFlow: 'row',
            flex: '1 0 auto',
        },
        content: {
            flex: '2 0px',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            paddingBottom: theme.spacing(2),
        },
        body: {
            flex: '1 0 auto',
        },
        footer: {
            flexShrink: '0',
            paddingTop: '1em',
        },
    };

    result = overrideComponentThemeStyle(theme, result, "mainLayout");
    //Login page can override this:
    document.body.style.background = getComponentThemeProperty("mainLayout", "background"); 
    return result;
};

class MainLayout extends React.Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        let { currentSubmission } = prevState;
        if (currentSubmission.redirectLocation === nextProps.location.pathname) {
            currentSubmission.redirectLocation = '';
            return { currentSubmission: currentSubmission };
        }
        return null;
    }
    displayName = MainLayout.name

    constructor(props) {
        super(props);
        this.state = {
            currentSubmission: { backup: [] },
            showingMessage: false,
            lastMessage: '',
        };
    }

    updateSubmission = (ticketState) => {
        this.setState({ currentSubmission: ticketState });
    }

    showBackgroundDialog = (message) => {
        this.setState({ showingMessage: true, lastMessage: message });
    }

    closeDialog = () => {
        this.setState({ showingMessage: false });
    };

    getDialogContent = () => {
        return <Dialog
            open={this.state.showingMessage}
            onClose={this.closeDialog}
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {this.state.lastMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button key="btnOK" onClick={() => this.closeDialog()} variant="contained" color="secondary" autoFocus>
                    {lang("ok")}
                </Button>
            </DialogActions>
        </Dialog>;
    }

    ticketRouting = (classes, currentSubmission) => {
        //A 'redirectLocation' variable can be added to the currentSubmission from anywhere in the app.  
        //If it is set to a Route shown below, such as '/ticket', it will redirect to that url location, upon 
        //calling 'updateSubmission' and saving the state of the currentSubmission.
        if (currentSubmission && currentSubmission.redirectLocation && currentSubmission.redirectLocation.length > 0) {
            return <Navigate to={currentSubmission.redirectLocation} replace />;
        }
        else {
            let route = <></>;
            const loc = this.props.location.pathname.toLowerCase();
            if (loc.startsWith('/ticket') && !this.props.userInfo.cannotSubmit) {
                route = <TicketRouter {...this.props} currentSubmission={this.state.currentSubmission} updateSubmission={this.updateSubmission} showBackgroundDialog={this.showBackgroundDialog} />;
            }
            else if (loc.startsWith('/track') && !this.props.userInfo.cannotSubmit) {
                route = <TrackDeliveries {...this.props} currentSubmission={this.state.currentSubmission} updateSubmission={this.updateSubmission} />;
            }
            else if (loc.startsWith('/profile')) {
                route = <EditProfile {...this.props} updateUserInfo={this.props.updateUserInfo} />;
            }
            else if (loc.startsWith('/help')) {
                route = <Help />;
            }
            else if (loc.startsWith('/sales')) {
                route = <SalesReport {...this.props} userInfo={this.props.userInfo} />
            }
            else if (loc.startsWith('/isr')) {
                route = <IsrMain {...this.props} userInfo={this.props.userInfo} updateUserInfo={this.props.updateUserInfo} updateSubmission={this.updateSubmission} />;
            }
            else if (loc.startsWith('/admin')) {
                route = <AdminMain />;
            }

            return (
                <div className={classes.body}>
                    {route}
                </div>
            );
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <Header userInfo={this.props.userInfo} updateUserInfo={this.props.updateUserInfo} updateSubmission={this.updateSubmission} showBackgroundDialog={this.showBackgroundDialog} />
                </div>
                <div className={classes.main}>
                    <NavMenu userInfo={this.props.userInfo} />
                    <div id="pageBodyArea" className={classes.content}>
                        {this.ticketRouting(classes, this.state.currentSubmission)}
                        <div className={classes.footer}>
                            <Footer />
                        </div>
                    </div>
                </div>
                {this.getDialogContent()}
            </div>
        );
    }
}
export default withLocation(withStyles(MainLayout, styles, { withTheme: true }));