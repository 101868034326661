import React, { useEffect } from 'react';
import { createMakeStyles } from "tss-react";
import { useTheme } from "@mui/material/styles";
import LinearProgress from '@mui/material/LinearProgress';
const { makeStyles } = createMakeStyles({ useTheme });

const useStyles = makeStyles()({
    progressBarContainer: {
        maxWidth: 500
    },
    progressBar: {
        marginTop: 50,
        marginBottom: 50,
        marginLeft: 50,
        marginRight: 50,
        height: 20,
        borderRadius: 10
    }
});

//variant (Required): The Material UI variant that can be applied to a progress bar (e.g. 'indeterminate').
//value (Optional): The value of the progress bar [0-100].
//onUpdate (Optional): A method that is called after each length of time specified by the 'interval'.
//interval (Optional): The interval in milliseconds that the 'onUpdate' method should be called.
export default function ProgressBar({ value, variant, onUpdate, interval }) {
    const { classes } = useStyles();

    useEffect(() => {
        let intervalPointer = undefined;

        //if a callback method and a time interval are provided we can setup a way of periodically checking for changes in an indeterminate progress bar.
        if (onUpdate && interval) {
            intervalPointer = setInterval(onUpdate, interval);
        }

        return () => {
            if (intervalPointer) {
                clearInterval(intervalPointer);
            }
        };
    }, []);


    return (
        <div style={{ width: 500 }}>
            <div className={classes.progressBarContainer}>
                <LinearProgress variant={variant} value={value} className={classes.progressBar} />
            </div>
        </div>
    );
}
