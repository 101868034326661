import React, { Component } from 'react';
import { lang, langFormat } from '../../Language';
import { withStyles } from 'tss-react/mui';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PrintUpload from './PrintUpload';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ProgressBar from '../Common/ProgressBar';
import PrecheckPreflightResults from './PrecheckPreflightResults';
import EditDetails, { FileStatus } from './EditDetails';
import { TicketComponentStatus } from '../../TicketComponentStatus';
import TicketDetails from './TicketDetails';
import ReadyToSubmit from './ReadyToSubmit';
import CreateAd from './CreateAd';
import CyberSourceDialog from '../CyberSourceDialog';
import { getComponentThemeProperty } from '../../StyleCustomization';
import { overrideComponentThemeStyle } from '../../StyleCustomization';
import FixedFilePreview from './FixedFilePreview';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchValueFromQueryString } from '../../HelperMethods';
import MediaUpload from './MediaUpload';
import MediaFileRow from './MediaFileRow';
import ResetMediaStatus from './ResetMediaStatus';
import PositionApproval from './PositionApproval';
import ResetPrintStatus from './ResetPrintStatus';
import Positioner from './Positioner';
import { MediaFileType } from './TicketDetailsCustom';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Map from '@mui/icons-material/Map';
import TicketClosedDialog, { TicketState } from './TicketClosedDialog';
import GateDialog from "../Gatefold/GateDialog";
import CustomTooltip from '../Common/CustomTooltip';
import { ProofIncluded } from '../../ProofIncluded';

const styles = theme => {
    var panelBg = getComponentThemeProperty("panelHeaders", "backgroundColor");
    if (!panelBg || panelBg.length === 0) {
        panelBg = theme.palette.primary.main;
    }
    var panelPalette = theme.palette.augmentColor({ color: { main: panelBg } });
    var result = {
        '@keyframes flashPanelHeader': {
            '0%': { opacity: 1 },
            '33%': { opacity: 0.5 },
            '66%': { opacity: 1 }
        },
        flashPanelHeader: {
            animationName: '$flashPanelHeader',
            animationDuration: getComponentThemeProperty("mainView", "flashAnimationDuration"),
            animationIterationCount: 3
        },
        heading: {
            fontWeight: 'bold',
            marginTop: theme.spacing(1),
        },
        horizontalBar: {
            borderColor: 'black'
        },
        outsideWrapper: {
            display: 'flex',
            flexFlow: 'row',
            '&> div:not(:first-of-type)': {
                marginLeft: theme.spacing(1),
            },
            '&> div:not(:last-of-type)': {
                marginRight: theme.spacing(1),
            }
        },
        mainBody: {
            flex: '1 0',
        },
        componentDetails: {
            display: 'flex',
            flexWrap: 'wrap-reverse',
        },
        componentDetailsMedia: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        detailsPanelCollapsed: {
            writingMode: 'vertical-rl',
            msWritingMode: 'tb-lr',
            width: '36px',
            flexShrink: '0',
            background: '#FFF',
            /* The following two lines, and flexGrow on first-of-type at the bottom only exist so the close animation looks better. */
            display: 'flex',
            alignItems: 'flex-start',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            '& p:first-of-type': {
                flexGrow: '1',
                width: '36px',
            },
            '& svg,& span': {
                verticalAlign: 'middle', /*Fixes centering in Safari */
            },
        },
        detailsPanelExpanded: {
            flexShrink: '0',
            background: theme.palette.background.paper,
            border: '1px solid rgba(0, 0, 0, 0.12)',
            boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            overflow: 'hidden',
            width: '350px',
            '& $detailsPanelBody': {
                width: '348px', //350 minus the border size
            },
            '& $detailsPanelHeader': {
                width: '348px',
            },
        },
        detailsPanelHeader: {
            cursor: 'pointer',
            userSelect: 'none',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: '#BBB',
            borderTopStyle: 'none',
            backgroundColor: panelPalette.main,
            color: panelPalette.contrastText,
            display: 'flex',
            padding: '5px',
            '&> p': {
                flexGrow: 1,
            },
            '&:hover': {
                backgroundColor: panelPalette.dark,
            },
            'transition': 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        },
        detailsPanelBody: {
            padding: theme.spacing(1),
            paddingTop: '0',
            overflow: 'hidden',
        },
        gridLeft: {
            minWidth: 500
        },
        panelHeader: {
            backgroundColor: panelPalette.main,
            color: panelPalette.contrastText,
            '& div>p': {
                color: panelPalette.contrastText,
            },
            margin: 0,
            minHeight: '36px !important',
            height: 36,
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            '&:hover': {
                backgroundColor: panelPalette.dark,
            },
            '& div': {
                justifyContent: 'space-between'
            }
        },
        fileUpload: {
            position: 'absolute',
            top: 12,
            left: 200
        },
        colors: {
            marginLeft: 60
        },
        pantoneColors: {
            paddingLeft: 10
        },
        repurposeDropdown: {
            position: 'absolute',
            top: 200,
            left: 250,
            width: 400
        },
        imagePanel: {
            marginLeft: 10
        },
        buttonDiv: {
            float: 'right',
            marginTop: 10
        },
        button: {
            marginLeft: 10
        },
        expansionPanelDetails: {
            padding: '24px 24px 24px',
            display: 'flex'
        },
        loadingPopup: {
            padding: '24px',
            minWidth: '100px',
            overflow: 'hidden',
            textAlign: 'center'
        },
        dialogComponent: {
            maxHeight: 'calc(100% - 20px)'
        },
        '@keyframes pulseButton': {
            '70%': { border: '1px solid rgba(255,255,255,0)' },
            '80%': { border: '1px solid rgba(255,255,255,0.6)' },
            '100%': { border: '1px solid rgba(255,255,255,0)' },
        },
        gateButton: {
            animationName: '$pulseButton',
            animationDuration: '10s',
            animationIterationCount: 'infinite',
            border: '1px solid rgba(255,255,255,0)',
            borderRadius: '4px',
            transform: 'scale(1.2)'
        }
    };
    result = overrideComponentThemeStyle(theme, result, "mainView");
    return result;
};

const DialogType = Object.freeze({
    None: 0,
    DeleteConfirmation: 1,
    Error: 2,
    BuyProofs: 3,
    ProofPaymentResult: 4,
    Component: 5,
    DeliveryConfirmation: 6,
    AgreementConfirmation: 7
});

var _timeoutDelay = null;

class MainView extends Component {
    displayName = MainView.name;

    constructor(props) {
        super(props);

        let defaultDialogType = DialogType.None;
        let defaultShowDialog = false;
        let defaultDialogMessage = '';

        //Check for CyberSource info to show to user
        let decision = fetchValueFromQueryString(props.location.search, 'decision').toLowerCase();
        let reason = fetchValueFromQueryString(props.location.search, 'reason');

        if (decision !== '') {
            let addAdditionalInfoMessage = false;
            if (decision === 'decline') {
                decision = 'reject';
                addAdditionalInfoMessage = true;
            }
            decision = lang(`cyberSource_${decision}Message`);
            if (reason.length > 0) {
                reason = `${lang('reason')}: ${lang(`cyberSource_${reason}`)}`;
            }
            defaultShowDialog = true;
            defaultDialogType = DialogType.ProofPaymentResult;
            defaultDialogMessage = <div><p>{decision}</p><p>{reason}</p>{addAdditionalInfoMessage ? (<p>{lang('cyberSource_rejectMessageDetails')}</p>) : ''}</div>;
        }

        this.state = {
            message: defaultDialogMessage,
            showDialog: defaultShowDialog,
            dialogType: defaultDialogType,
            isSubmitting: false,
            dialogComponent: undefined,
            ticketState: TicketState.None,
            showingGatefold: false
        };
        window.scroll(0, 0); //Scroll to top of page when this component is created - Loading editor for a new or existing ticket
    }

    handleChange = (index) => {
        this.props.currentSubmission.expandedPanel = index;
        this.props.currentSubmission.flashPanel = -1;
        this.props.updateSubmission(this.props.currentSubmission);
    };

    collapseExpandArrow = (classes, expanded) => {
        if (expanded) {
            return <ArrowDropUp style={{ verticalAlign: 'middle' }} />;
        }
        else {
            return <ArrowDropDown style={{ verticalAlign: 'middle' }} />;
        }
    }

    toggleDetailsPane = () => {
        let { currentSubmission } = this.props;
        currentSubmission.detailsCollapsed = !currentSubmission.detailsCollapsed;
        this.props.updateSubmission(currentSubmission);
    }

    setAgreementConfirmed = () => {
        fetch('SvrTicket/SetAgreementConfirmed?tid=' + this.props.currentSubmission.ticket.id, { method: 'POST', credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    let { currentSubmission } = this.props;
                    if (currentSubmission.isCyberSourceEnabled && currentSubmission.ticket.proofIncluded === ProofIncluded.Request && this.allComponentsComplete(currentSubmission.ticket.components)){
                        this.showCyberSourceProofPayment(false);
                    }
                    else {
                        this.setState({ showDialog: false, dialogType: DialogType.None });
                        this.submitTicket(false);
                    }
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        this.setState({ showDialog: true, message: lang(data.error), dialogType: DialogType.Error });
                    }
                }
            });
    }

    showDialog = (classes, popOutComponent, popOutIndex) => {
        let dialogTitle = '';
        let dialogContent = '';
        let cancelButton = '';
        let okButton = (<Button variant='contained' autoFocus onClick={() => this.closeDialog(this.state.dialogType === DialogType.DeleteConfirmation)} color="secondary">
            {lang('ok')}
        </Button>);
        if (this.state.dialogType === DialogType.DeleteConfirmation) {
            dialogTitle = lang('deleteTicketDialogHeader');
            dialogContent = lang('deleteTicketDialogText');
            cancelButton = (<Button variant='contained' onClick={() => this.closeDialog(false)} color="primary">{lang('cancel')}</Button>);
        }
        else if (this.state.dialogType === DialogType.Error) {
            dialogTitle = lang('error');
            dialogContent = this.state.message;
        }
        else if (this.state.dialogType === DialogType.ProofPaymentResult) {
            dialogTitle = lang('proofPaymentResult');
            dialogContent = this.state.message;
        }
        else if (this.state.dialogType === DialogType.DeliveryConfirmation) {
            dialogTitle = lang('readyForSubmission');
            dialogContent = lang('deliveryConfirmationDialogMessage');
            cancelButton = (<Button variant='contained' onClick={() => this.closeDialog(false)} color="primary">{lang('continueEditing')}</Button>);
            if (this.props.currentSubmission.isCyberSourceEnabled && this.props.currentSubmission.ticket.proofIncluded === ProofIncluded.Request) {
                okButton = (<Button variant='contained' autoFocus onClick={() => this.showCyberSourceProofPayment(true)} color="secondary">
                    {langFormat('buyProofsAndDeliver', lang('submitTicket'))}
                </Button>);
            }
            else {
                okButton = (<Button variant='contained' autoFocus onClick={() => this.closeDeliveryConfirmationDialog(this.state.dialogType === DialogType.DeliveryConfirmation)} color="secondary">
                    {lang('submitTicket')}
                </Button>);
            }
        }
        else if (this.state.dialogType === DialogType.AgreementConfirmation) {
            dialogTitle = lang('agreementConfirmationHeader');
            dialogContent = <div dangerouslySetInnerHTML={{ __html: lang('agreementConfirmationMessage') }} />
            cancelButton = (<Button variant='contained' onClick={() => this.setState({ dialogType: DialogType.None, showDialog: false })} color='primary'>{lang('cancel')}</Button>);
            okButton = (<Button variant='contained' autofocus onClick={this.setAgreementConfirmed} color='secondary'>{lang('agreementConfirmationButtonText')}</Button>);
        }

        if (popOutComponent) {
            let component = this.props.currentSubmission.ticket.components[popOutIndex];

            return (
                <Dialog maxWidth={false} open={this.state.showDialog} classes={{ paperScrollPaper: classes.dialogComponent }}>
                    <DialogContent style={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2, paddingBottom: 2 }}>
                        <Accordion expanded>
                            <AccordionSummary className={classes.panelHeader}>
                                <Typography variant="body2">
                                    {this.collapseExpandArrow(classes, this.props.currentSubmission.expandedPanel === popOutIndex)}
                                    <span style={{ verticalAlign: 'middle' }}>{this.getPanelHeaderText(component, popOutIndex)}</span>
                                </Typography>
                                <OpenInNew onClick={() => this.expandComponentIntoDialog(undefined, true)} style={{ transform: 'rotate(180deg)', width: 50, paddingLeft: 7, paddingRight: 7 }} />
                            </AccordionSummary>
                            <AccordionDetails className={classes.expansionPanelDetails}>
                                {popOutComponent}
                            </AccordionDetails>
                        </Accordion>
                    </DialogContent>
                </Dialog>
            )
        }

        if (this.state.dialogType === DialogType.BuyProofs) {
            return <CyberSourceDialog cancelProofRequest={this.cancelProofRequest} open={this.state.showDialog} close={this.closeDialog} proofPaymentData={this.state.proofPaymentData} />;
        }
        else {
            return (
                <Dialog maxWidth={this.state.dialogType === DialogType.AgreementConfirmation ? 'md' : 'sm'} open={this.state.showDialog} onClose={() => this.closeDialog(false)} >
                    <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body2">{dialogContent}</Typography>
                    </DialogContent>
                    <DialogActions>
                        {cancelButton}
                        {okButton}
                    </DialogActions>
                </Dialog>
            );
        }
    }

    //switch proof included to 'none' and update ticket.
    cancelProofRequest = () => {
        let { updateSubmission, currentSubmission } = this.props;
        fetch(`SvrTicket/ClearProofRequest?tid=${currentSubmission.ticket.id}`,
            {
                method: 'POST',
                credentials: "same-origin"
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    currentSubmission.ticket = null;
                    updateSubmission(currentSubmission);
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(data.error) });
                    }
                }
            });
        this.closeDialog();
    }

    closeDialog = (deleteTicket) => {
        this.setState({ showDialog: false });
        if (!deleteTicket && this.state.dialogType === DialogType.ProofPaymentResult && this.props.currentSubmission.ticket.proofPaid && this.props.currentSubmission.ticket.proofIncluded === ProofIncluded.Request &&
            this.allComponentsComplete(this.props.currentSubmission.ticket.components)) {
            this.submitTicket(false);
        }
        if (deleteTicket) {
            this.deleteTicket();
        }
    }

    closeGatePreview = () => {
        this.setState({ showingGatefold: false });
    }

    showDeliveryConfirmationDialog = () => {
        this.props.currentSubmission.wasDeliveryConfirmationDialogShown = true;
        this.props.updateSubmission(this.props.currentSubmission);
        this.setState({ showDialog: true, dialogType: DialogType.DeliveryConfirmation });
    }

    closeDeliveryConfirmationDialog = (submitTicket) => {
        this.setState({ showDialog: false });
        if (submitTicket) {
            this.submitTicket(true);
        }
    }

    closeTicketCheck = () => {
        const { currentSubmission } = this.props;
        if (currentSubmission.refDocs && currentSubmission.refDocs.find(item => item.status === FileStatus.Uploading)) {
            this.setState({ message: lang("uploadInProgress"), showDialog: true, dialogType: DialogType.Error });
            return false;
        }
        if (currentSubmission.fileUploads && currentSubmission.fileUploads.length > 0) {
            this.setState({ message: lang('uploadInProgress'), showDialog: true, dialogType: DialogType.Error });
            return false;
        }
        return true;
    }

    showDeleteTicketDialog = () => {
        if (this.closeTicketCheck()) {
            this.setState({ showDialog: true, dialogType: DialogType.DeleteConfirmation });
        }
    }

    showAgreementConfirmationDialog = () => {
        this.setState({ showDialog: true, dialogType: DialogType.AgreementConfirmation });
    }

    deleteTicket = () => {
        fetch('SvrTicket/DeleteTicket?tid=' + this.props.currentSubmission.ticket.id, { method: 'POST', credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.props.currentSubmission.ticket = null;
                    this.props.currentSubmission.editorInfo = null;
                    this.props.currentSubmission.redirectLocation = '/ticket';
                    this.props.updateSubmission(this.props.currentSubmission);
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        this.setState({ showDialog: true, message: lang(data.error), dialogType: DialogType.Error });
                    }
                }
            });
    }

    saveTicketAndRedirect = () => {
        if (this.closeTicketCheck()) {
            fetch('SvrTicket/UpdateTicket', {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.props.currentSubmission.ticket)
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        let { currentSubmission } = this.props;
                        currentSubmission.ticket = null;
                        currentSubmission.editorInfo = null;
                        currentSubmission.redirectLocation = '/ticket';
                        this.props.updateSubmission(currentSubmission);
                    }
                    else {
                        if (data.error === 'isDeleted') {
                            this.setState({ ticketState: TicketState.Deleted });
                        }
                        else if (data.error === 'isSubmitted') {
                            this.setState({ ticketState: TicketState.Submitted });
                        }
                        else {
                            this.setState({ showErrorDialog: true, message: lang(data.error) });
                        }
                    }
                });
        }
    }

    renderPanelContent = (currentSubmission, updateSubmission, index, closeDialog) => {
        let ticket = currentSubmission.ticket;
        let component = ticket.components[index];

        if (ticket.isPrint) {

            if (component.status === TicketComponentStatus.PreflightPassed && !component.hasPreflightWarnings && !component.hasPreflightErrors) {
                //update ticket to ready to approve position
                component.status = TicketComponentStatus.ReadyToApprovePosition;
                updateSubmission(currentSubmission);
            }

            if (component.status < TicketComponentStatus.ReadyToPrecheck || component.status === TicketComponentStatus.PublisherSupplied) {
                return <PrintUpload showBackgroundDialog={this.props.showBackgroundDialog} updateSubmission={updateSubmission} currentSubmission={currentSubmission} componentIndex={index} reusableComponents={this.props.currentSubmission.reusableComponents} />;
            }
            else if (component.status === TicketComponentStatus.PrecheckPassed ||
                component.status === TicketComponentStatus.PrecheckFailed ||
                component.status === TicketComponentStatus.ReadyToApprovePrecheck) {
                return <PrecheckPreflightResults updateSubmission={updateSubmission} currentSubmission={currentSubmission} componentIndex={index} component={this.props.currentSubmission.ticket.components[index]} />;
            }
            else if (component.status <= TicketComponentStatus.Prechecking || component.status === TicketComponentStatus.ReadyToPreCrop
                || component.status === TicketComponentStatus.Precropping || component.status === TicketComponentStatus.ReadyToCopyFiles ||
                component.status === TicketComponentStatus.CopyingExtraFiles) {
                this.checkTicketComponentStatus(component.id);
                return <ProgressBar interval={3000} onUpdate={() => this.checkTicketComponentStatus(component.id)} variant='indeterminate' />;
            }
            else if (component.canAutoPosition && (component.status === TicketComponentStatus.ReadyToPosition || component.status === TicketComponentStatus.ReadyToSoftCrop ||
                component.status === TicketComponentStatus.SoftCropping || component.status === TicketComponentStatus.ReadyToHardCrop ||
                component.status === TicketComponentStatus.HardCropping || component.status === TicketComponentStatus.GeneratingTemplates)) {
                this.checkPreflightStatus(component.id);
                return <ProgressBar interval={3000} onUpdate={() => { this.checkPreflightStatus(component.id); }} variant='indeterminate' />;
            }
            else if (component.status === TicketComponentStatus.ReadyToPosition) {
                return <Positioner isExpanded={closeDialog !== undefined} closeDialog={closeDialog} currentSubmission={currentSubmission} componentIndex={index} updateSubmission={updateSubmission} />;
            }
            else if (component.status === TicketComponentStatus.ReadyToPreflight || component.status === TicketComponentStatus.Preflighting ||
                component.status === TicketComponentStatus.ReadyToSoftCrop || component.status === TicketComponentStatus.SoftCropping ||
                component.status === TicketComponentStatus.ReadyToHardCrop || component.status === TicketComponentStatus.HardCropping ||
                component.status === TicketComponentStatus.GeneratingTemplates) {
                this.checkPreflightStatus(component.id);
                return <ProgressBar interval={3000} onUpdate={() => this.checkPreflightStatus(component.id)} variant='indeterminate' />;
            }
            else if (component.status === TicketComponentStatus.PreflightFailed || (component.status === TicketComponentStatus.PreflightPassed && (component.hasPreflightWarnings || component.hasPreflightErrors))) {
                return <PrecheckPreflightResults isPreflight updateSubmission={updateSubmission} currentSubmission={currentSubmission} componentIndex={index} component={this.props.currentSubmission.ticket.components[index]} />;
            }
            else if (component.status === TicketComponentStatus.ReadyToFixFile || component.status === TicketComponentStatus.FixingFile) {
                return <ProgressBar interval={3000} onUpdate={() => this.checkFixMyFileStatus(component.id)} variant='indeterminate' />;
            }
            else if (component.status === TicketComponentStatus.ReadyToGenerateTemplate) {
                return <ProgressBar interval={3000} onUpdate={() => this.checkFixMyFileStatus(component.id)} variant='indeterminate' />;
            }
            else if (component.status === TicketComponentStatus.ReadyToReviewFix || component.status === TicketComponentStatus.FixFilePassed) {
                return <FixedFilePreview updateSubmission={updateSubmission} currentSubmission={currentSubmission} componentIndex={index} />;
            }
            else if (component.status === TicketComponentStatus.ReadyToApprovePosition) {
                return <PositionApproval isExpanded={closeDialog !== undefined} closeDialog={closeDialog} updateSubmission={updateSubmission} currentSubmission={currentSubmission} componentIndex={index} />;
            }
            else if (component.status === TicketComponentStatus.ReadyToTransmit) {
                return <ReadyToSubmit updateSubmission={updateSubmission} currentSubmission={currentSubmission} componentIndex={index} />;
            }
            else if (component.status === TicketComponentStatus.PrecheckCrashed || component.status === TicketComponentStatus.PreCropCrashed ||
                component.status === TicketComponentStatus.GenerateTemplatesFailed || component.status === TicketComponentStatus.FailedToCopyFiles ||
                component.status === TicketComponentStatus.PreCropFailed || component.status === TicketComponentStatus.HardCropFailed ||
                component.status === TicketComponentStatus.HardCropCrashed || component.status === TicketComponentStatus.PreflightCrashed ||
                component.status === TicketComponentStatus.FixFileFailed || component.status === TicketComponentStatus.FixFileCrashed ||
                component.status === TicketComponentStatus.SoftCropFailed || component.status === TicketComponentStatus.SoftCropCrashed) {
                return <ResetPrintStatus currentSubmission={currentSubmission} updateSubmission={updateSubmission} componentIndex={index} status={component.status} />
            }
        }
        else {
            if (this.anyComponentHasStatus(ticket, TicketComponentStatus.ReadyToPrecheck, true)) {
                return <MediaUpload showBackgroundDialog={this.props.showBackgroundDialog} updateSubmission={updateSubmission} currentSubmission={currentSubmission} componentIndex={index} reusableComponents={this.props.currentSubmission.reusableComponents} />;
            }
            else if (this.anyComponentHasStatus(ticket, TicketComponentStatus.PrecheckCrashed, false) || this.anyComponentHasStatus(ticket, TicketComponentStatus.PreCropCrashed)) {
                return <ResetMediaStatus currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission} />;
            }
            else if (this.anyComponentHasStatus(ticket, TicketComponentStatus.ReadyToPrecheck) || this.anyComponentHasStatus(ticket, TicketComponentStatus.Prechecking) || this.anyComponentHasStatus(ticket, TicketComponentStatus.ReadyToPreCrop) || this.anyComponentHasStatus(ticket, TicketComponentStatus.Precropping)) {
                return <ProgressBar interval={3000} onUpdate={() => this.checkAllTicketComponentStatuses(ticket)} variant='indeterminate' />;
            }
            else if (this.anyComponentHasStatus(ticket, TicketComponentStatus.PrecheckPassed) || this.anyComponentHasStatus(ticket, TicketComponentStatus.PrecheckFailed) || this.anyComponentHasStatus(ticket, TicketComponentStatus.ReadyToApprovePrecheck)) {
                return <PrecheckPreflightResults updateSubmission={updateSubmission} currentSubmission={currentSubmission} componentIndex={index} component={this.props.currentSubmission.ticket.components[index]} />;
            }
            else if (this.allComponentsHaveStatus(ticket, TicketComponentStatus.ReadyToTransmit)) {
                return <PrecheckPreflightResults updateSubmission={updateSubmission} currentSubmission={currentSubmission} componentIndex={index} component={this.props.currentSubmission.ticket.components[index]} />;
            }
        }
        return '';
    }

    checkFixMyFileStatus = (ticketComponentId) => {
        fetch(`SvrTicket/GetTicketComponentStatus?tcid=${ticketComponentId}`,
            {
                method: 'POST',
                credentials: "same-origin"
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    if (data.status !== TicketComponentStatus.ReadyToFixFile && data.status !== TicketComponentStatus.FixingFile
                        && data.status !== TicketComponentStatus.FixFilePassed && data.status !== TicketComponentStatus.ReadyToGenerateTemplate
                        && data.status !== TicketComponentStatus.GeneratingTemplates) {
                        fetch('SvrTicket/GetTicketComponent?tcid=' + ticketComponentId, { method: 'POST', credentials: 'same-origin' })
                            .then(response => response.json())
                            .then(data => {
                                if (data.success) {
                                    let components = this.props.currentSubmission.ticket.components;
                                    components[this.findIndexOfComponentId(components, +ticketComponentId)] = data.ticketComponent;
                                    this.props.updateSubmission(this.props.currentSubmission);
                                }
                            });
                    }
                }
            });
    }

    checkPreflightStatus = (ticketComponentId) => {
        fetch('SvrTicket/GetTicketComponentStatus?tcid=' + ticketComponentId,
            {
                method: 'POST',
                credentials: "same-origin"
            })
            .then(response => response.json())
            .then(data => {
                if (data.success && (data.status === TicketComponentStatus.PreflightPassed || data.status === TicketComponentStatus.PreflightFailed
                    || data.status === TicketComponentStatus.PreflightCrashed || data.status === TicketComponentStatus.HardCropCrashed
                    || data.status === TicketComponentStatus.HardCropFailed || data.status === TicketComponentStatus.SoftCropFailed
                    || data.status === TicketComponentStatus.SoftCropCrashed)) {
                    fetch('SvrTicket/GetTicketComponent?tcid=' + ticketComponentId, { method: 'POST', credentials: 'same-origin' })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success) {
                                let components = this.props.currentSubmission.ticket.components;
                                components[this.findIndexOfComponentId(components, +ticketComponentId)] = data.ticketComponent;
                                this.props.updateSubmission(this.props.currentSubmission);
                            }
                        });
                }
            });
    }

    checkTicketComponentStatus = (ticketComponentId) => {
        fetch('SvrTicket/GetTicketComponentStatus?tcid=' + ticketComponentId,
            {
                method: 'POST',
                credentials: 'same-origin'
            })
            .then(response => response.json())
            .then(data => {
                if (data.success && ((data.status >= TicketComponentStatus.ReadyToApprovePrecheck && data.status !== TicketComponentStatus.ReadyToCopyFiles && data.status !== TicketComponentStatus.CopyingExtraFiles) || data.status === TicketComponentStatus.PrecheckFailed
                    || data.Status === TicketComponentStatus.PreCropFailed || data.Status === TicketComponentStatus.FailedToCopyFiles)) {
                    fetch('SvrTicket/GetTicketComponent?tcid=' + ticketComponentId, { method: 'POST', credentials: 'same-origin' })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success) {
                                let components = this.props.currentSubmission.ticket.components;
                                components[this.findIndexOfComponentId(components, +ticketComponentId)] = data.ticketComponent;
                                this.props.updateSubmission(this.props.currentSubmission);
                            }
                        });
                }
            });
    }

    checkAllTicketComponentStatuses = (ticket) => {
        fetch(`SvrTicket/GetAllTicketComponentStatuses?ticketId=${ticket.id}`, { method: 'POST', credentials: "same-origin" })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    let success = true;
                    data.componentStatuses.forEach(status => {
                        if (status > TicketComponentStatus.Precropping || status === TicketComponentStatus.PrecheckCrashed ||
                            status === TicketComponentStatus.PrecheckFailed || status === TicketComponentStatus.PreCropCrashed ||
                            status === TicketComponentStatus.PreCropFailed) {
                            success &= true;
                        }
                        else {
                            success = false;
                        }
                    });

                    if (success) {
                        //All are out of prechecking
                        fetch(`SvrTicket/GetTicket?ticketId=${ticket.id}`, { method: 'POST', credentials: "same-origin" })
                            .then(response => response.json())
                            .then(data => {
                                if (data.success) {
                                    this.props.currentSubmission.ticket = data.ticket;
                                    this.props.updateSubmission(this.props.currentSubmission);
                                }
                            });
                    }
                }
            });
    }

    findIndexOfComponentId = (components, tcid) => {
        let i;
        for (i = 0; i < components.length; i++) {
            if (components[i].id === tcid) {
                return i;
            }
        }
        return -1;
    }

    truncateFileName = (filename) => {
        const maxLength = 20;
        return filename.length <= maxLength ? filename : `${filename.substring(0, maxLength)}...`;
    }

    submitTicket = (checkForAgreementConfirmation = false) => {
        if (this.props.currentSubmission.showAgreementConfirmation && checkForAgreementConfirmation) {
            this.showAgreementConfirmationDialog();
            return;
        }
        let ticketId = this.props.currentSubmission.ticket.id;
        this.setState({ isSubmitting: true });
        fetch(`SvrTicket/SubmitTicket?ticketId=${ticketId}`,
            {
                method: 'POST',
                credentials: "same-origin"
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    //ticket and components have changes on them, refetch updated ticket
                    this.props.currentSubmission.ticket = null;
                    this.props.currentSubmission.editorInfo = null;
                    this.props.updateSubmission(this.props.currentSubmission);
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(data.error), isSubmitting: false });
                    }
                }
            });
    }

    showCyberSourceProofPayment = (checkForAgreementConfirmation = false) => {
        if (this.props.currentSubmission.showAgreementConfirmation && checkForAgreementConfirmation) {
            this.showAgreementConfirmationDialog();
            return;
        }
        let ticketId = this.props.currentSubmission.ticket.id;
        fetch(`SvrTicket/ProofRequestData?tid=${ticketId}`,
            {
                method: 'POST',
                credentials: "same-origin"
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.setState({ showDialog: true, dialogType: DialogType.BuyProofs, proofPaymentData: { ticketId, shippingConfigs: data.shippingConfigs, email: data.email, phoneNumber: data.phoneNumber, proofPrice: data.proofPrice } });
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                }
            });
    }

    getPanelHeaderText = (component, index) => {
        let { currentSubmission } = this.props;
        let panelText = '';
        let ticket = currentSubmission.ticket;

        if (currentSubmission.ticket.isPrint) {

            let isDisplayingFixMyFile = component.isDisplayingFixMyFile !== undefined && component.isDisplayingFixMyFile;
            if (!component.panelPositions || component.panelPositions.length === 1) {
                if (!component.panelPositions || component.panelPositions[0].name === null) {
                    panelText = `${lang('page')} ${index + 1}`;
                }
                else {
                    panelText = component.panelPositions[0].name;
                }
            }
            else if (component.panelPositions.length === 2) {
                panelText = component.panelPositions[0].name + '/' + component.panelPositions[component.panelPositions.length - 1].name;
            }
            else if (component.panelPositions.length > 2) {
                panelText = component.panelPositions[0].name + '-' + component.panelPositions[component.panelPositions.length - 1].name;
            }

            const filename = component.filename === null ? null : this.truncateFileName(component.filename);

            if (component.status <= TicketComponentStatus.UploadFailed) {
                if (currentSubmission.fileUploads === undefined) {
                    currentSubmission.fileUploads = [];
                }
                let upload = currentSubmission.fileUploads.find(x => x.componentIndex === index);
                if (upload !== undefined) {
                    let filename = this.truncateFileName(upload.filename);
                    return `${panelText} - ${filename} - ${lang('fileUploading')}...`;
                }
                return `${panelText} - ${lang('fileSelection')}`;
            }
            else if (component.status === TicketComponentStatus.PrecheckPassed ||
                component.status === TicketComponentStatus.PrecheckFailed ||
                component.status === TicketComponentStatus.ReadyToApprovePrecheck) {
                return `${panelText} - ${filename} - ${lang('precheckResults')}`;
            }
            else if (component.status <= TicketComponentStatus.Prechecking || component.status === TicketComponentStatus.ReadyToPreCrop
                || component.status === TicketComponentStatus.Precropping || component.status === TicketComponentStatus.ReadyToCopyFiles ||
                component.status === TicketComponentStatus.CopyingExtraFiles) {
                return `${panelText} - ${filename} - ${lang('prechecking')}...`;
            }
            else if (component.status === TicketComponentStatus.ReadyToPosition) {
                return `${panelText} - ${filename} - ${lang('positionSelection')}`;
            }
            else if (component.status === TicketComponentStatus.PublisherSupplied) {
                return `${panelText} - ${lang('publisherSupplied')} - ${lang('readyForSubmission')}`;
            }
            else if (component.canAutoPosition && (component.status === component.ReadyToPosition || component.status === TicketComponentStatus.ReadyToSoftCrop || component.status === TicketComponentStatus.SoftCropping ||
                component.status === TicketComponentStatus.ReadyToHardCrop || component.status === TicketComponentStatus.HardCropping ||
                component.status === TicketComponentStatus.GeneratingTemplates)) {
                return `${panelText} - ${filename} - ${lang('autoPositioning')}...`;
            }
            else if (component.status === TicketComponentStatus.ReadyToSoftCrop || component.status === TicketComponentStatus.SoftCropping ||
                component.status === TicketComponentStatus.ReadyToHardCrop || component.status === TicketComponentStatus.HardCropping ||
                component.status === TicketComponentStatus.GeneratingTemplates) {
                return `${panelText} - ${filename} - ${lang('positioning')}...`;
            }
            else if (component.status === TicketComponentStatus.ReadyToPreflight || component.status === TicketComponentStatus.Preflighting) {
                return `${panelText} - ${filename} - ${lang('preflighting')}`;
            }
            else if ((component.status === TicketComponentStatus.PreflightPassed || component.status === TicketComponentStatus.PreflightFailed)
                && !isDisplayingFixMyFile) {
                return `${panelText} - ${filename} - ${lang('preflightingResults')}`;
            }
            else if ((component.status === TicketComponentStatus.PreflightPassed || component.status === TicketComponentStatus.PreflightFailed)
                && isDisplayingFixMyFile) {
                return `${panelText} - ${filename} - ${lang('fixMyFile')}`;
            }
            else if (component.status === TicketComponentStatus.ReadyToFixFile || component.status === TicketComponentStatus.FixingFile) {
                return `${panelText} - ${filename} - ${lang('adShuttleIsAttempting')}`;
            }
            else if (component.status === TicketComponentStatus.ReadyToGenerateTemplate) {
                return `${panelText} - ${filename} - ${lang('generatingTemplate')}`;
            }
            else if (component.status === TicketComponentStatus.ReadyToReviewFix || component.status === TicketComponentStatus.FixFilePassed) {
                return `${panelText} - ${filename} - ${lang('fixedFilePreview')}`;
            }
            else if (component.status === TicketComponentStatus.ReadyToApprovePosition) {
                return `${panelText} - ${filename} - ${lang('approvePosition')}`;
            }
            else if (component.status === TicketComponentStatus.ReadyToTransmit) {
                return `${panelText} - ${filename} - ${lang('readyForSubmission')}`;
            }
            else if (component.status === TicketComponentStatus.PrecheckCrashed || component.status === TicketComponentStatus.PreCropCrashed ||
                component.status === TicketComponentStatus.PreflightCrashed || component.status === TicketComponentStatus.FailedToCopyFiles ||
                component.status === TicketComponentStatus.PreCropFailed || component.status === TicketComponentStatus.GenerateTemplatesFailed) {
                return `${panelText} - ${filename} - ${lang('verificationFailed')}`;
            }
            else if (component.status === TicketComponentStatus.SoftCropCrashed || component.status === TicketComponentStatus.HardCropCrashed ||
                component.status === TicketComponentStatus.SoftCropFailed || component.status === TicketComponentStatus.HardCropFailed) {
                return `${panelText} - ${filename} - ${lang('positioningFailed')}`;
            }
            else if (component.status === TicketComponentStatus.PreflightCrashed) {
                return `${panelText} - ${filename} - ${lang('preflightFailed')}`;
            }
            else if (component.status === TicketComponentStatus.FixFileCrashed || component.status === TicketComponentStatus.FixFileFailed) {
                return `${panelText} - ${filename} - ${lang('fixMyFileFailed')}`;
            }
        }
        else {
            if (this.anyComponentHasStatus(ticket, TicketComponentStatus.ReadyToUpload)) {
                return lang('fileSelection');
            }
            else if (this.anyComponentHasStatus(ticket, TicketComponentStatus.PrecheckCrashed, false) || this.anyComponentHasStatus(ticket, TicketComponentStatus.PreCropCrashed)) {
                return lang('verificationFailed');
            }
            else if (this.anyComponentHasStatus(ticket, TicketComponentStatus.ReadyToPrecheck) || this.anyComponentHasStatus(ticket, TicketComponentStatus.Prechecking) ||
                this.anyComponentHasStatus(ticket, TicketComponentStatus.ReadyToPreCrop) || this.anyComponentHasStatus(ticket, TicketComponentStatus.Precropping)) {
                return `${lang('prechecking')}...`;
            }
            else if (this.anyComponentHasStatus(ticket, TicketComponentStatus.PrecheckPassed) || this.anyComponentHasStatus(ticket, TicketComponentStatus.PrecheckFailed) || this.anyComponentHasStatus(ticket, TicketComponentStatus.ReadyToApprovePrecheck)) {
                let badFileNamesTruncated = this.truncateFileName(this.getCommaDelimitedFilenamesOfFailedMediaFiles());
                return `${badFileNamesTruncated} - ${lang('precheckResults')}`;
            }
            else if (this.allComponentsHaveStatus(ticket, TicketComponentStatus.ReadyToTransmit)) {
                return lang('precheckResults');
            }
        }
        return '';
    }


    getCommaDelimitedFilenamesOfFailedMediaFiles = () => {
        let result = '';
        let components = this.props.currentSubmission.ticket.components;
        for (let i = 0; i < components.length; i++) {
            if (components[i].status === TicketComponentStatus.PrecheckFailed || components[i].status === TicketComponentStatus.PreCropFailed) {
                if (result.length > 0) {
                    result += ', ';
                }
                result += components[i].filename;
            }
        }
        return result;
    }

    anyComponentHasStatus = (ticket, status, lessThan) => {
        for (let i = 0; i < ticket.components.length; i++) {
            if (lessThan === undefined) {
                lessThan = false;
            }
            if (lessThan) {
                if (ticket.components[i].status < status) {
                    return true;
                }
            }
            else if (ticket.components[i].status === status) {
                return true;
            }
        }
        return false;
    }

    allComponentsHaveStatus = (ticket, status) => {
        for (let i = 0; i < ticket.components.length; i++) {
            if (ticket.components[i].status !== status) {
                return false;
            }
        }
        return true;
    }

    allComponentsComplete = (components) => {
        for (let i = 0; i < components.length; i++) {
            if (components[i].status !== TicketComponentStatus.PublisherSupplied && components[i].status !== TicketComponentStatus.ReadyToTransmit) {
                if (this.props.currentSubmission.wasDeliveryConfirmationDialogShown) {
                    //allows DeliveryConfirmation dialog to pop up again when all components are complete
                    if (_timeoutDelay === null) {
                        //This is called during a render that was invoked as a result of a ticket update, 
                        //but we are not allowed to update the state during a render. Pop up the dialog 500ms from now.
                        _timeoutDelay = setTimeout(() => {
                            this.props.currentSubmission.wasDeliveryConfirmationDialogShown = false;
                            this.props.updateSubmission(this.props.currentSubmission);
                            _timeoutDelay = null;
                        }, 500);
                    }
                }
                return false;
            }
        }
        if (this.props.currentSubmission.showDeliveryConfirmationDialog && !this.props.currentSubmission.wasDeliveryConfirmationDialogShown && !this.state.showDialog) {
            if (_timeoutDelay === null) {
                //This is called during a render that was invoked as a result of a ticket update, 
                //but we are not allowed to update the state during a render. Pop up the dialog 500ms from now.
                _timeoutDelay = setTimeout(() => {
                    this.showDeliveryConfirmationDialog();
                    _timeoutDelay = null;
                }, 500);
            }
        }

        return true;
    }

    returnToTrackDeliveries = () => {
        let { currentSubmission, updateSubmission } = this.props;
        currentSubmission.ticket = null;
        currentSubmission.editorInfo = null;
        currentSubmission.redirectLocation = '/TrackDeliveries';
        updateSubmission(currentSubmission);
    }

    expandComponentIntoDialog = (component, close) => {
        if (close) {
            this.setState({ dialogComponent: undefined, showDialog: false, dialogType: DialogType.None });
        }
        else {
            this.setState({ dialogComponent: component, showDialog: true, dialogType: DialogType.Component });
        }
    }

    render() {
        const { classes, currentSubmission, updateSubmission, showBackgroundDialog } = this.props;
        let bodyContent = '';
        let popOutComponent = '';
        let popOutIndex = -1;
        if (currentSubmission.showEditDetails) {
            bodyContent = <TicketDetails currentSubmission={currentSubmission} updateSubmission={updateSubmission} />;
        }
        else {
            let detailsPane = '';
            let deleteAndCloseButtons = <React.Fragment>
                <Button onClick={this.showDeleteTicketDialog} variant='contained' color='primary'>{lang('deleteTicket')}</Button>
                {!(currentSubmission.ticket.isCreateAnAd && currentSubmission.ticket.components && currentSubmission.ticket.components[0].status === TicketComponentStatus.ReadyToUpload) ?
                    <Button onClick={this.saveTicketAndRedirect} className={classes.button} variant='contained' color='primary'>{lang('saveAndContinue')}</Button>
                    : ''}
            </React.Fragment>;
            if (currentSubmission.detailsCollapsed) {
                detailsPane = <div className={classes.detailsPanelCollapsed}>
                    <Typography variant="body2" className={classes.detailsPanelHeader} onClick={() => this.toggleDetailsPane()}>
                        <ChevronRightIcon />
                        <span>{lang("ticketInfo")}</span>
                    </Typography>
                </div>;
            }
            else {
                let gatePreviewButton = '';
                if (currentSubmission.ticket.size !== null && currentSubmission.ticket.size.impositionId !== null) {
                    gatePreviewButton = <CustomTooltip placement="top-start"
                        title={
                            <React.Fragment>
                                <Typography color="inherit">{lang("gatePreview")}</Typography>
                            </React.Fragment>
                        }
                    >
                        <Map className={classes.gateButton} onClick={(e) => { e.stopPropagation(); this.setState({ showingGatefold: true }); }} />
                    </CustomTooltip>;
                }
                detailsPane = <div className={classes.detailsPanelExpanded}>
                    <div className={classes.detailsPanelHeader} onClick={() => this.toggleDetailsPane()}>
                        <Typography variant="body2">
                            <ChevronLeftIcon style={{ verticalAlign: 'middle' }} /><span style={{ verticalAlign: 'middle' }}>{lang("ticketInfo")}</span>
                        </Typography>
                        {gatePreviewButton}
                    </div>
                    <div className={classes.detailsPanelBody}>
                        <EditDetails currentSubmission={currentSubmission} updateSubmission={updateSubmission} showBackgroundDialog={showBackgroundDialog} />
                    </div>
                </div>;
            }

            let expandCollapseContentPanels = [];
            if (currentSubmission.ticket.isPrint) {
                //pop out the component
                expandCollapseContentPanels = currentSubmission.ticket.components.map((component, index) => {
                    let flashPanel = this.props.currentSubmission.flashPanel;
                    let panelclassNames = flashPanel === index ? `${classes.panelHeader} ${classes.flashPanelHeader}` : classes.panelHeader;
                    let expansionPanel = (
                        <Accordion id={`expansionPanel-${index}`} key={`tcid_${component.id}`} expanded={currentSubmission.expandedPanel === index} onChange={() => this.handleChange(index)}>
                            <AccordionSummary id={`summary-${component.id}`} className={panelclassNames}>
                                <Typography variant="body2">
                                    {this.collapseExpandArrow(classes, currentSubmission.expandedPanel === index)}
                                    <span style={{ verticalAlign: 'middle' }}>{this.getPanelHeaderText(component, index)}</span>
                                </Typography>
                                {component.status === TicketComponentStatus.ReadyToPosition || component.status === TicketComponentStatus.ReadyToApprovePosition
                                    ? <OpenInNew style={{ width: 50, paddingLeft: 7, paddingRight: 7 }} onClick={() => this.expandComponentIntoDialog(component)} /> : ''}
                            </AccordionSummary>
                            <AccordionDetails className={classes.expansionPanelDetails}>
                                {this.renderPanelContent(currentSubmission, updateSubmission, index, this.state.dialogComponent && component.id === this.state.dialogComponent.id ? () => this.expandComponentIntoDialog(undefined, true) : undefined)}
                            </AccordionDetails>
                        </Accordion>
                    );
                    if (this.state.dialogComponent && component.id === this.state.dialogComponent.id) {
                        popOutComponent = this.renderPanelContent(currentSubmission, updateSubmission, index, this.state.dialogComponent && component.id === this.state.dialogComponent.id ? () => this.expandComponentIntoDialog(undefined, true) : undefined);
                        popOutIndex = index;
                        expansionPanel = '';
                    }
                    return expansionPanel;
                });
            }
            else if (Number(currentSubmission.ticket.fileTypeId) !== MediaFileType.DETAILS_ONLY) {
                expandCollapseContentPanels = (
                    <Accordion key='panelHeader' expanded>
                        <AccordionSummary className={classes.panelHeader}>
                            <Typography variant="body2">
                                <span style={{ verticalAlign: 'middle' }}>{this.getPanelHeaderText(undefined, 0)}</span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {this.renderPanelContent(currentSubmission, updateSubmission, 0, false)}
                        </AccordionDetails>
                    </Accordion>
                );
            }

            let mediaFileRows = '';
            if (!currentSubmission.ticket.isPrint && currentSubmission.ticket.components.length > 0 &&
                Number(currentSubmission.ticket.fileTypeId) !== MediaFileType.DETAILS_ONLY) {
                mediaFileRows = (
                    <div style={{ marginLeft: 50, minWidth: 440 }}>
                        <div style={{ paddingBottom: 30 }}><Typography variant="body2"><span style={{ marginLeft: 50 }}>{lang('filenames')}</span><span style={{ marginLeft: 155 }}>{lang('previews')}</span></Typography></div>
                        {currentSubmission.ticket.components.map((component, index) => {
                            if (component.status > TicketComponentStatus.Precropping || component.status === TicketComponentStatus.PrecheckFailed || component.status === TicketComponentStatus.PreCropFailed) {
                                let filePath = `File/StreamFile?fileType=${component.fileType}&id=${component.id}&hash=${component.fileHash}`;
                                return <MediaFileRow currentSubmission={currentSubmission} updateSubmission={updateSubmission} key={`media_${component.id}`} component={component} filePath={filePath} templateSize={component.templateSize} componentIndex={index} />;
                            }
                            return '';
                        })}
                    </div>
                );
            }

            if (currentSubmission.ticket.isCreateAnAd && currentSubmission.ticket.components && currentSubmission.ticket.components[0].status === TicketComponentStatus.ReadyToUpload) {
                bodyContent = (<div className={classes.outsideWrapper}>
                    {detailsPane}
                    <div className={classes.mainBody}>
                        <CreateAd currentSubmission={currentSubmission} updateSubmission={updateSubmission} showBackgroundDialog={showBackgroundDialog} bottomButtons={deleteAndCloseButtons} />
                    </div>
                    {this.showDialog(classes, popOutComponent, popOutIndex)}
                </div>);
            }
            else {
                let componentDetailsClass = currentSubmission.ticket.isPrint ? classes.componentDetails : classes.componentDetailsMedia;
                if (this.state.isSubmitting) {
                    bodyContent = <Dialog open><DialogContent className={classes.loadingPopup}><CircularProgress /></DialogContent></Dialog>;
                }
                else {
                    bodyContent = (<div className={classes.outsideWrapper}>
                        {detailsPane}
                        <div className={classes.mainBody}>
                            <div className={componentDetailsClass}>
                                <div style={{ paddingBottom: 50 }}>
                                    {expandCollapseContentPanels}
                                    <div className={classes.buttonDiv}>
                                        {deleteAndCloseButtons}
                                        {currentSubmission.isCyberSourceEnabled && currentSubmission.ticket.proofIncluded === ProofIncluded.Request && this.allComponentsComplete(currentSubmission.ticket.components) ? (
                                            <Button onClick={() => this.showCyberSourceProofPayment(true)} className={classes.button} variant='contained' color='secondary'>{langFormat('buyProofsAndDeliver', lang('submitTicket'))}</Button>
                                        ) : this.allComponentsComplete(currentSubmission.ticket.components) ? (
                                            <Button onClick={() => this.submitTicket(true)} className={classes.button} variant='contained' color='secondary'>{lang('submitTicket')}</Button>
                                        ) : ''}
                                    </div>
                                </div>
                                {mediaFileRows}
                            </div>
                        </div>
                        {this.showDialog(classes, popOutComponent, popOutIndex)}
                    </div>);
                }
            }
        }

        return (
            <React.Fragment>
                <Typography className={classes.heading} variant='h5'>{langFormat("ticketNumber", currentSubmission.ticket.id)}</Typography>
                <hr className={classes.horizontalBar} />
                {bodyContent}
                <GateDialog open={this.state.showingGatefold} closeDialog={this.closeGatePreview} ticket={currentSubmission.ticket} />
                <TicketClosedDialog open={this.state.ticketState !== TicketState.None} isDeleted={this.state.ticketState === TicketState.Deleted} currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission} />
            </React.Fragment>
        );
    }
}

export default withStyles(MainView, styles);