import React, { useEffect, useState } from 'react';
import { useTheme } from "@mui/material/styles";
import { createMakeStyles } from "tss-react";
import Button from '@mui/material/Button';
import { lang } from '../../Language';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';
import TicketClosedDialog, { TicketState } from './TicketClosedDialog';
import { PublisherInterface } from "@chili-publish/publisher-interface";
const { makeStyles } = createMakeStyles({ useTheme });

const useStyles = makeStyles()(theme => ({
    bottomButtons: {
        width: '1020px',
        textAlign: 'right',
        marginTop: theme.spacing(1),
        '&> button': {
            marginLeft: '5px',
            marginRight: '5px',
        },
    },
    adCreationFrame: {
        width: '1020px',
        height: '700px',
        border: '0',
        paddingLeft: '5px',
    },
    previewDialog: {
        display: 'flex',
    },
    previewImage: {
        maxHeight: '70vh',
        maxWidth: '50vw',
        objectFit: 'contain',
        marginRight: theme.spacing(1),
    },
    neutralColor: {
        color: theme.palette.neutral.contrastText,
        backgroundColor: theme.palette.neutral.main,
        '&:hover': {
            backgroundColor: theme.palette.neutral.dark,
        },
    },
    loadingPopup: {
        padding: '24px',
        minWidth: '100px',
        overflow: 'hidden',
        textAlign: 'center'
    },
}));

const DialogButtons = Object.freeze({
    OK: 1,
    Cancel: 2,
    Yes: 4,
    No: 8,
});

var _editor = null;
var _lastXml = null;

export default function CreateAd({ currentSubmission, updateSubmission, showBackgroundDialog, bottomButtons }) {
    const { classes } = useStyles();
    const [loading, setLoading] = useState(false);
    const [showingMessage, setShowingMessage] = useState(false);
    const [showingPreview, setShowingPreview] = useState(false);
    const [lastMessage, setLastMessage] = useState('');
    const [dialogButtons, setDialogButtons] = useState(1);
    const [dialogAction, setDialogAction] = useState(null);
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [ticketState, setTicketState] = useState(TicketState.None);

    useEffect(() => {
        if (!currentSubmission.editorInfo) {
            fetch(`SvrTicket/GetAdCreationSettings?ticketId=${currentSubmission.ticket.id}`, { credentials: 'same-origin' })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        currentSubmission.editorInfo =
                        {
                            editorUrl: data.editorUrl,
                            pdfSettings: data.pdfSettings,
                            imageProfileId: data.imageProfileId,
                            workspaceId: data.workspaceId
                        };
                        _lastXml = null;
                        updateSubmission(currentSubmission);
                        const chiliIframe = document.getElementById("ad-creation-iframe");
                        chiliIframe.src = currentSubmission.editorInfo.editorUrl;
                        chiliEditorLoad(chiliIframe);
                    }
                    else if (data.error) {
                        setLastMessage(lang(data.error));
                        setShowingMessage(true);
                        setDialogButtons(DialogButtons.OK);
                    }
                });
        }
        else {
            const chiliIframe = document.getElementById("ad-creation-iframe");
            chiliIframe.src = currentSubmission.editorInfo.editorUrl;
            chiliEditorLoad(chiliIframe);
        }
        return () => {
            if (_editor) {
                if (currentSubmission.editorInfo && _lastXml) {
                    currentSubmission.editorInfo.tempXml = _lastXml;
                    updateSubmission(currentSubmission);
                }
                _editor = null;
            }
            window.OnEditorEvent = undefined;
        };
    }, []);

    const chiliEditorLoad = function (iframe) {
        (async () => {
            const publisher = await PublisherInterface.build(iframe, {
                events: ["DocumentFullyLoaded", "UndoStackChanged"]
            });
            window.OnEditorEvent = (eventName, targetId) => {
                switch (eventName) {
                    case 'DocumentFullyLoaded':
                        (async () => {
                            if (!_editor) {
                                //Only handle this event if we don't have an editor instance already.
                                //Otherwise we'll get stuck in an infinite loading loop trying to restore previous changes.
                                _editor = publisher;
                                if (currentSubmission.editorInfo && currentSubmission.editorInfo.tempXml) {
                                    await _editor.executeFunction("document", "OpenDocumentFromXml", currentSubmission.editorInfo.tempXml, currentSubmission.editorInfo.workspaceId);
                                }
                            }
                            setEditorLoaded(true);
                        })();
                        break;
                    case 'UndoStackChanged':
                        //Backup any changes to the document here because it's an async call so we can't do it when they navigate away.
                        (async () => {
                            _lastXml = await _editor.executeFunction("document", "GetTempXML");
                        })();
                        break;
                }
            }
        })();
    };

    const applyComponentUpdate = (component) => {
        currentSubmission.ticket.components[0] = component;
        updateSubmission(currentSubmission);
    };

    const useCreatedAd = () => {
        let ticketId = currentSubmission.ticket.id;
        fetch(`SvrTicket/UseCreatedAd?ticketId=${ticketId}`, { method: "POST", credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setShowingPreview(false);
                    applyComponentUpdate(data.component);
                }
                else {
                    if (data.error === 'isDeleted') {
                        setTicketState(TicketState.Deleted);
                    }
                    else if (data.error === 'isSubmitted') {
                        setTicketState(TicketState.Submitted);
                    }
                    else {
                        let error = data.error;
                        if (!error) {
                            error = 'unknownError';
                        }
                        setShowingPreview(false);
                        setLastMessage(lang(error));
                        setShowingMessage(true);
                        setDialogButtons(DialogButtons.OK);
                    }
                }
            });
    };

    const waitForTask = (ticketId, pdfTaskId, imageTaskId) => {
        var retries = 18;
        var isPdf = true;
        var checkPreview = function () {
            retries--;
            if (retries === 0) {
                //Preview took over 36 seconds to generate, something is probably wrong so we should just give up.
                setLoading(false);
                setLastMessage(lang("generateTimeout"));
                setShowingMessage(true);
                setDialogButtons(DialogButtons.OK);
                return;
            }
            var taskId = isPdf ? pdfTaskId : imageTaskId;
            fetch(`SvrTicket/CreatePreviewStatus?ticketId=${ticketId}&taskId=${taskId}&isPdf=${isPdf}`, { credentials: 'same-origin' })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        if (data.isCompleted === true) {
                            if (isPdf) {
                                isPdf = false;
                                setTimeout(checkPreview, 500);
                            }
                            else {
                                setLoading(false);
                                setShowingPreview({ ticketComponentId: data.ticketComponentId, fileName: data.fileName });
                            }
                        }
                        else if (data.isCompleted === false) {
                            //Check again 2 seconds later
                            setTimeout(checkPreview, 2000);
                        }
                        else {
                            //Unexpected response or failed
                            setLoading(false);
                            setLastMessage(lang(data.error));
                            setShowingMessage(true);
                            setDialogButtons(DialogButtons.OK);
                        }
                    }
                    else {
                        let error = data.error;
                        if (!error) {
                            error = 'unknownError';
                        }
                        setLoading(false);
                        setLastMessage(lang(error));
                        setShowingMessage(true);
                        setDialogButtons(DialogButtons.OK);
                    }
                });
        };
        setTimeout(checkPreview, 1500);
    };

    const generatePreview = async () => {
        let tempXml = await _editor.executeFunction("document", "GetTempXML");
        let ticketId = currentSubmission.ticket.id;
        setLoading(true);
        fetch("SvrTicket/CreateAdPreview", {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin',
            body: JSON.stringify({ ticketId: ticketId, documentXml: tempXml })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    waitForTask(ticketId, data.pdfTaskId, data.imageTaskId);
                }
                else {
                    if (data.error === 'isDeleted') {
                        setTicketState(TicketState.Deleted);
                    }
                    else if (data.error === 'isSubmitted') {
                        setTicketState(TicketState.Submitted);
                    }
                    else {
                        showBackgroundDialog(lang(data.error));
                        setLoading(false);
                    }
                }
            });
    };

    const previewAdClicked = async () => {
        var pfResults = await _editor.getObject("document.preflightResults");
        if (pfResults.numErrors > 0) {
            setLastMessage(lang("documentHasErrors"));
            setShowingMessage(true);
            setDialogButtons(DialogButtons.OK);
        }
        else if (pfResults.numWarnings > 0) {
            setLastMessage(lang("documentHasWarnings"));
            setShowingMessage(true);
            setDialogButtons(DialogButtons.OK | DialogButtons.Cancel);
            setDialogAction(() => { generatePreview(); });
        }
        else {
            generatePreview();
        }
    };

    const closeMessage = (dialogResult) => {
        let doAction = null;
        if (dialogAction && (dialogResult === DialogButtons.OK || dialogResult === DialogButtons.Yes)) {
            doAction = dialogAction;
        }
        setShowingMessage(false);
        setDialogAction(null);
        //We save the action until after the dialog is cleared, in case the action wants to show a dialog.
        if (doAction) {
            doAction();
        }
    };

    const getDialogContent = () => {
        let buttons = [];
        let activeButtons = dialogButtons;
        if ((activeButtons & DialogButtons.No) === DialogButtons.No) {
            buttons.push(<Button key="btnNo" onClick={() => closeMessage(DialogButtons.No)} variant="contained" color="primary">
                {lang("no")}
            </Button>);
        }
        if ((activeButtons & DialogButtons.Yes) === DialogButtons.Yes) {
            buttons.push(<Button key="btnYes" onClick={() => closeMessage(DialogButtons.Yes)} variant="contained" color="secondary" autoFocus>
                {lang("yes")}
            </Button>);
        }
        if ((activeButtons & DialogButtons.Cancel) === DialogButtons.Cancel) {
            buttons.push(<Button key="btnCancel" onClick={() => closeMessage(DialogButtons.Cancel)} variant="contained" color="primary">
                {lang("cancel")}
            </Button>);
        }
        if (buttons.length === 0 || (activeButtons & DialogButtons.OK) === DialogButtons.OK) {
            buttons.push(<Button key="btnOK" onClick={() => closeMessage(DialogButtons.OK)} variant="contained" color="secondary" autoFocus>
                {lang("ok")}
            </Button>);
        }

        return <Dialog
            open={showingMessage}
            onClose={closeMessage}
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {lastMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {buttons}
            </DialogActions>
        </Dialog>;
    }

    const getPreviewDialog = () => {
        if (showingPreview) {
            let ticketCompId = showingPreview.ticketComponentId;
            let fileName = showingPreview.fileName;
            const disclaimer = React.createElement("span", { dangerouslySetInnerHTML: { __html: lang("creationDisclaimer") } });

            return <Dialog open maxWidth="md">
                <DialogContent>
                    <div className={classes.previewDialog}>
                        <div>
                            <img className={classes.previewImage} src={`./File/StreamFile?fileType=OriginalThumbnail&id=${ticketCompId}`} alt="Ad Preview" />
                        </div>
                        <div>
                            <Typography variant="body2">{disclaimer}</Typography>
                            <a id="createdDownloadLink" style={{ display: 'none' }} href={`./File/StreamFile?fileType=OriginalPdf&id=${ticketCompId}&noinline=true`} download={fileName} target="_blank" rel="noopener noreferrer">{fileName}</a>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowingPreview(false)} variant="contained" color="primary">
                        {lang("returnToFileCreation")}
                    </Button>
                    <Button onClick={() => document.getElementById('createdDownloadLink').click()} variant="contained" className={classes.neutralColor}>
                        {lang("downloadACopy")}
                    </Button>
                    <Button onClick={() => useCreatedAd()} variant="contained" color="secondary">
                        {lang("proceedWithCreatedFile")}
                    </Button>
                </DialogActions>
            </Dialog>;
        }
        return '';
    };

    let editorPlaceholder = <CircularProgress />;
    if (currentSubmission.editorInfo) {
        editorPlaceholder = '';
    }
    let loadingTag = '';
    if (loading) {
        loadingTag = <Dialog open><DialogContent className={classes.loadingPopup}><CircularProgress /></DialogContent></Dialog>;
    }

    return (
        <React.Fragment>
            <div>
                <iframe id="ad-creation-iframe" title="Ad Editor" className={classes.adCreationFrame}
                    style={{ display: currentSubmission.editorInfo ? 'inherit' : 'none' }} />
                {editorPlaceholder}
            </div>
            <div className={classes.bottomButtons}>
                {bottomButtons}
                <Button variant="contained" color="secondary" disabled={!editorLoaded} onClick={()=>previewAdClicked()} > { lang("previewContinue") }</Button>
            </div>
            {getDialogContent()}
            {getPreviewDialog()}
            {loadingTag}
            <TicketClosedDialog open={ticketState !== TicketState.None} isDeleted={ticketState === TicketState.Deleted} currentSubmission={currentSubmission} updateSubmission={updateSubmission} />
        </React.Fragment>
    );
}