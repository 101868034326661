import React, { Component } from 'react';
import { lang } from '../../Language';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TicketComponentStatus } from '../../TicketComponentStatus';
import Typography from '@mui/material/Typography';
import SupportRequest from '../SupportRequest';
import TicketClosedDialog, { TicketState } from './TicketClosedDialog';

const styles = theme => ({
    message: {
        display: 'inline-block',
        width: 350
    },
    button: {
        marginLeft: 10
    },
    container: {
        minWidth: 750
    }
});

const DialogType = Object.freeze({
    None: 0,
    Error: 1
});

class ResetMediaStatus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            dialogType: DialogType.None,
            message: '',
            ticketState: TicketState.None
        };
    }

    resetStatusOnComponents = () => {
        fetch(`SvrTicket/ReturnToPreviousMediaFileReadyStatus?tid=${this.props.currentSubmission.ticket.id}`, {
            method: 'POST',
            credentials: "same-origin",
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.props.currentSubmission.ticket = data.ticket;  //Upate Ticket, components have changed
                    this.props.updateSubmission(this.props.currentSubmission);
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(data.error) });
                    }
                }
            });
    }

    removeAllFiles = () => {
        let prevStatus = TicketComponentStatus.ReadyToUpload;
        let tcid = this.props.currentSubmission.ticket.components[0].id;
        fetch(`SvrTicket/ReturnToStatus?tcid=${tcid}&statusId=${prevStatus}&autoPositionFile=false`, { method: "POST", credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.props.currentSubmission.ticket.components = this.props.currentSubmission.ticket.components.slice(1, 1)
                    this.props.currentSubmission.ticket.components[0] = data.updatedComponent;
                    this.props.updateSubmission(this.props.currentSubmission);
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        let error = (data.error) ? data.error : 'unknownError';
                        this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(error) });
                    }
                }
            });
    }

    closeDialog = () => {
        this.setState({showDialog: false, message: ''});
    }

    render() {
        const { classes } = this.props; 
        return (
            <div className={classes.container}>
                <Typography variant="body2" component='div' className={classes.message}>{lang('systemError')}</Typography>
                <div style={{ display: 'inline-block' }}><SupportRequest showButton ticketId={this.props.currentSubmission.ticket.id} requestType={3} /></div>
                <Button className={classes.button} onClick={this.removeAllFiles} variant='contained' color='primary'>{lang('removeAllFiles')}</Button>
                <Button className={classes.button} onClick={this.resetStatusOnComponents} variant='contained' color='primary'>{lang('retry')}</Button>               
                <Dialog open={this.state.showDialog} onClose={this.closeDialog} >
                    <DialogTitle id="form-dialog-title">{lang('error')}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body2">{this.state.message}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' autoFocus onClick={this.closeDialog} color="secondary">
                            {lang('ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <TicketClosedDialog open={this.state.ticketState !== TicketState.None} isDeleted={this.state.ticketState === TicketState.Deleted} currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission} />
            </div>
            );
    }
}

export default withStyles(ResetMediaStatus, styles);