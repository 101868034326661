import React from 'react';
import { lang } from '../Language';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
    layout: {
        width: '88%',
        display: 'block', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(5),
        marginLeft: 'auto',
        marginRight: 'auto',
        '& ol': {
            listStyleType: 'none',
            counterReset: 'item',
            padding: '0',
        },
        '& ol > li': {
            display: 'table',
            counterIncrement: 'item',
        },
        '& ol > li:before': {
            content: 'counters(item, ".") ". "',
            display: 'table-cell',
            paddingRight: '1.0em',
        },
        '& li ol > li': {
            margin: '0',
        },
        '& li ol > li:before': {
            content: 'counters(item, ".") " "',
        },
        '& p': {
            marginTop: '0.5em',
            marginBottom: '0.5em',
        }
    },
    logo: {
        textAlign: 'left',
    },
    printPage: {
        textAlign: 'right',
        paddingTop: '1em',
    },
    denseText: {
        lineHeight: '1.2',
    }
});

class Terms extends React.Component {
    displayName = Terms.name

    printMe = () => {
        window.print();
    };

    render() {
        let { classes } = this.props;

        return (
            <div className={classes.layout} >
                <Grid container>
                    <Grid item xs={6} className={classes.logo}>
                        <img src="/AdShuttleLogo.png" alt="AdShuttle" />
                    </Grid>
                    <Grid item xs={6} className={classes.printPage}>
                        <Typography variant="body2"><a href="/terms#" onClick={() => this.printMe()}>{lang("printThisPage")}</a></Typography>
                    </Grid>
                </Grid>
                <Typography variant="h6" align='center'>
                    <u>{lang("termsOfUse")}</u>
                </Typography>
                <Typography variant="body2" className={classes.denseText}>
                    {lang("termsP1")}
                </Typography>
                <Typography variant="body2" className={classes.denseText}>
                    {lang("termsP2")}
                </Typography>
                <Typography variant="body2" className={classes.denseText}>
                    {lang("termsP3")}
                </Typography>
                <ol>
                    <li>
                        <Typography variant="body2" className={classes.denseText}>
                            {lang("terms10")}
                        </Typography>
                        <ol>
                            <li><Typography variant="body2" className={classes.denseText}>{lang("terms11")}</Typography></li>
                            <li>
                                <Typography variant="body2" className={classes.denseText}>{lang("terms12P1")}</Typography>
                                <ul>
                                    <li><Typography variant="body2" className={classes.denseText}>{lang("terms12a")}</Typography></li>
                                    <li><Typography variant="body2" className={classes.denseText}>{lang("terms12b")}</Typography></li>
                                    <li><Typography variant="body2" className={classes.denseText}>{lang("terms12c")}</Typography></li>
                                    <li><Typography variant="body2" className={classes.denseText}>{lang("terms12d")}</Typography></li>
                                    <li><Typography variant="body2" className={classes.denseText}>{lang("terms12e")}</Typography></li>
                                </ul>
                                <Typography variant="body2" className={classes.denseText}>{lang("terms12P2")}</Typography>
                            </li>
                            <li><Typography variant="body2" className={classes.denseText}>{lang("terms13")}</Typography></li>
                            <li><Typography variant="body2" className={classes.denseText}>{lang("terms14")}</Typography></li>
                            <li><Typography variant="body2" className={classes.denseText}>{lang("terms15")}</Typography></li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant="body2" className={classes.denseText}>
                            {lang("terms20")}
                        </Typography>
                        <ol>
                            <li><Typography variant="body2" className={classes.denseText}>{lang("terms21")}</Typography></li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant="body2" className={classes.denseText}>
                            {lang("terms30")}
                        </Typography>
                        <ol>
                            <li><Typography variant="body2" className={classes.denseText}>{lang("terms31")}</Typography></li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant="body2" className={classes.denseText}>
                            {lang("terms40")}
                        </Typography>
                        <ol>
                            <li><Typography variant="body2" className={classes.denseText}>{lang("terms41")}</Typography></li>
                            <li><Typography variant="body2" className={classes.denseText}>{lang("terms42")}</Typography></li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant="body2" className={classes.denseText}>
                            {lang("terms50")}
                        </Typography>
                        <ol>
                            <li><Typography variant="body2" className={classes.denseText}>{lang("terms51")}</Typography></li>
                        </ol>
                    </li>
                </ol>
            </div>
        );
    }
}

export default withStyles(Terms, styles);