import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import { lang, langFormat } from '../../Language';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowRight from '@mui/icons-material/ArrowRight';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { PdfPresets } from '../PdfPresets';
import SupportRequest from '../SupportRequest';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TicketComponentStatus } from '../../TicketComponentStatus';
import MediaUpload from './MediaUpload';
import TicketClosedDialog, { TicketState } from './TicketClosedDialog';
import { getComponentThemeProperty } from '../../StyleCustomization';

const styles = theme => ({
    largeText: {
        fontSize: '14pt',
        fontWeight: 'bold'
    },
    panelArrow: {
        color: 'black',
        paddingRight: '0 !important',
        paddingBottom: 3
    },
    clickForDetails: {
        marginLeft: 10
    },
    panelHeader: {
        backgroundColor: '#F6F6F6',
        margin: 0,
        height: 30,
        borderRadius: 8,
        padding: 0,
        wordWrap: 'no-wrap'
    },
    expanded: {
        minHeight: '30px !important',
        marginTop: '4px !important',
        marginBottom: '4px !important'
    },
    panel: {
        margin: 0
    },
    link: {
        marginTop: 20,
        marginBottom: 20,
    },
    buttonArea: {
        float: 'right'
    },
    buttonContainer: {
        textAlign: 'center',
        display: 'inline-flex'
    },
    button: {
        marginLeft: 10
    },
    controlContainer: {
        width: 170
    },
    panelDetails: {
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 8,
        overflowWrap: 'anywhere'
    },
    content: {
        marginTop: 8,
        marginBottom: 8
    }
});

const DialogType = Object.freeze({
    None: 0,
    PresetsDialog: 1,
    Error: 2,
    UploadMediaFileDialog: 3,
    Position: 4
});

class PrecheckPreflightResults extends Component {
    displayName = PrecheckPreflightResults.name

    constructor(props) {
        super(props);
        this.state = {
            expanded: '',
            dialogType: DialogType.None,
            results: undefined,
            isLoading: true,
            showDialog: false,
            message: '',
            displayFixMyFileDisclaimer: false,
            overrideClicked: false,
            showMediaUploadDialog: false,
            mediaUploadButtonEnabled: false,
            mediaUploadButtonClicked: false,
            mediaUploadCancelEnabled: true,
            ticketState: TicketState.None
        };
    }

    componentDidMount() {
        this.loadResults();
    }

    loadResults = () => {
        let resultsType = this.props.isPreflight ? 'PreflightResults' : 'PrecheckResults';
        fetch(`SvrTicket/${resultsType}?tcid=${this.props.component.id}`, { method: 'POST', credentials: "same-origin" })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.setState({ results: data.results, isLoading: false });
                }
                else {
                    this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(data.error) });
                }
            });
    }

    updateMediaButtonState = (enable, enableCancel) => {
        this.setState({ mediaUploadButtonEnabled: enable, mediaUploadCancelEnabled: enableCancel });
    }

    closeDialog = (result) => {
        if (result === 1) {
            this.returnToPreviousStatus(TicketComponentStatus.ReadyToPosition);
        }
        else if (result === 2) {
            let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
            fetch(`SvrTicket/AutoPosition?tcid=${component.id}`, { method: 'POST', credentials: "same-origin" })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        this.props.currentSubmission.ticket.components[this.props.componentIndex] = data.ticketComponent;
                        this.props.updateSubmission(this.props.currentSubmission);
                    }
                    else {
                        if (data.error === 'isDeleted') {
                            this.setState({ ticketState: TicketState.Deleted });
                        }
                        else if (data.error === 'isSubmitted') {
                            this.setState({ ticketState: TicketState.Submitted });
                        }
                    }
                });
        }
        this.setState({ showDialog: false });
    }

    showArrow = (classes, open) => {
        if (open) {
            return <ArrowDropUp className={classes.panelArrow} />;
        }
        else {
            return <ArrowRight className={classes.panelArrow} />;
        }
    }

    handleChange = panel => (expanded) => {
        this.setState({
            expanded: expanded && this.state.expanded !== panel ? panel : false,
        });
    };

    fixMyFile = () => {
        let { updateSubmission, componentIndex } = this.props;
        this.setState({ displayFixMyFileDisclaimer: true });
        this.props.currentSubmission.ticket.components[componentIndex].isDisplayingFixMyFile = true;
        updateSubmission(this.props.currentSubmission);
    }

    returnToPreflightResults = () => {
        let { updateSubmission, componentIndex } = this.props;
        this.setState({ displayFixMyFileDisclaimer: false });
        this.props.currentSubmission.ticket.components[componentIndex].isDisplayingFixMyFile = false;
        updateSubmission(this.props.currentSubmission);
    }

    updateStatus = (nextStatus, entireTicket) => {
        let { updateSubmission, componentIndex } = this.props;
        if (!entireTicket) {
            let tcid = this.props.currentSubmission.ticket.components[componentIndex].id;
            fetch(`SvrTicket/UpdateComponentStatus?tcid=${tcid}&statusId=${nextStatus}`, { method: "POST", credentials: 'same-origin' })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        this.props.currentSubmission.ticket.components[componentIndex].status = nextStatus;
                        updateSubmission(this.props.currentSubmission);
                    }
                    else {
                        if (data.error === 'isDeleted') {
                            this.setState({ ticketState: TicketState.Deleted });
                        }
                        else if (data.error === 'isSubmitted') {
                            this.setState({ ticketState: TicketState.Submitted });
                        }
                        else {
                            let error = (data.error) ? data.error : 'unknownError';
                            this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(error) });
                        }
                    }
                });
        }
        else {
            fetch(`SvrTicket/UpdateAllIncompleteComponentsToStatus?tid=${this.props.currentSubmission.ticket.id}&statusId=${nextStatus}`, { method: "POST", credentials: 'same-origin' })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        this.props.currentSubmission.ticket = data.ticket;
                        updateSubmission(this.props.currentSubmission);
                    }
                    else {
                        if (data.error === 'isDeleted') {
                            this.setState({ ticketState: TicketState.Deleted });
                        }
                        else if (data.error === 'isSubmitted') {
                            this.setState({ ticketState: TicketState.Submitted });
                        }
                    }
                });
            this.setState({ overrideClicked: true });
        }
    }

    returnToPreviousStatus = (prevStatus, autoPosition) => {
        let tcid = this.props.currentSubmission.ticket.components[this.props.componentIndex].id;
        fetch(`SvrTicket/ReturnToStatus?tcid=${tcid}&statusId=${prevStatus}&autoPositionFile=${autoPosition}`, { method: "POST", credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.props.currentSubmission.ticket.components[this.props.componentIndex] = data.updatedComponent;
                    this.props.updateSubmission(this.props.currentSubmission);
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        let error = (data.error) ? data.error : 'unknownError';
                        this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(error) });
                    }
                }
            });
    }

    reposition = () => {
        let canAutoPosition = this.props.currentSubmission.ticket.components[this.props.componentIndex].canAutoPosition;
        if (canAutoPosition) {
            this.setState({ showDialog: true, dialogType: DialogType.Position });
        }
        else {
            this.returnToPreviousStatus(TicketComponentStatus.ReadyToPosition, false);
        }
    }

    selectNewFile = () => {
        this.returnToPreviousStatus(TicketComponentStatus.ReadyToUpload, false);
    }

    approve = (entireTicket, isOverride) => {
        if (entireTicket) {
            this.updateStatus(TicketComponentStatus.ReadyToTransmit, entireTicket);
        }
        else if (this.props.isPreflight) {
            this.updateStatus(TicketComponentStatus.ReadyToApprovePosition, entireTicket);
        }
        else {
            let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
            let tcid = component.id;
            fetch(`SvrTicket/ApprovePrecheck?tcid=${tcid}&isOverride=${isOverride}`, { method: 'POST', credentials: "same-origin" })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        this.props.currentSubmission.ticket.components[this.props.componentIndex].status = data.newStatus
                        this.props.updateSubmission(this.props.currentSubmission);
                    }
                    else {
                        if (data.error === 'isDeleted') {
                            this.setState({ ticketState: TicketState.Deleted });
                        }
                        else if (data.error === 'isSubmitted') {
                            this.setState({ ticketState: TicketState.Submitted });
                        }
                        else {
                            this.setState({ dialogType: DialogType.Error, message: lang(data.error), showDialog: true });
                        }
                    }
            });
        }
    }

    showPDFPresetsDialog = (showPresetsDialog) => {
        if (showPresetsDialog) {
            return <PdfPresets closeDialog={this.closeDialog} />;
        }
        else {
            return '';
        }
    }

    openPresetsDialog = () => {
        this.setState({ showDialog: true, dialogType: DialogType.PresetsDialog });
    }

    showLinks = (classes, isPreflight) => {
        let isPrint = this.props.currentSubmission.ticket.isPrint;
        let isCreateAnAd = this.props.currentSubmission.ticket.isCreateAnAd;
        let showAdCreationGuideLink = getComponentThemeProperty("help", "showAdCreationGuideLink") === 'true';
        let hidePrecheckReportLink = false;
        if (this.state.results) {
            let errorMessages = this.state.results.messages.filter(message => message.isError);
            if (errorMessages.length === 1 && errorMessages[0].isCorruptMessage) {
                hidePrecheckReportLink = true;
            }
        }
        if (isPreflight) {
            return (
                <div>
                    <div className={classes.link}><Typography variant="body2"><a target='_blank' rel='noopener noreferrer' href={'./File/StreamFile?fileType=PreflightReport&id=' + this.props.component.id}>{langFormat('viewPreflightReport', '')}</a></Typography></div>
                    {isCreateAnAd ? (
                        <div className={classes.link}><Typography variant="body2"><a target='_blank' rel='noopener noreferrer' href='File/HelpResource?fileType=CreateAnAdGuide'>{lang('viewCreateAnAdGuide')}</a></Typography></div>
                    ) : (
                        <React.Fragment>
                            {showAdCreationGuideLink ? (
                                <div className={classes.link}><Typography variant="body2"><a target='_blank' rel='noopener noreferrer' href={'./File/HelpResource?filetype=AdCreationGuide'}>{lang('viewAdCreationGuide')}</a></Typography></div>
                            ) : null}
                            <div className={classes.link}><Typography variant="body2"><a target='_blank' rel='noopener noreferrer' href={'./File/HelpResource?filetype=PrintSubmissionGuide'}>{lang('viewPrintSubmissionGuide')}</a></Typography></div>
                        </React.Fragment>

                    )}
                    <div className={classes.link}>
                        <Typography variant="body2">
                            {lang('presetsForPDF') !== undefined && lang('presetsForPDF').trim() !== '' ? (
                                <React.Fragment>
                                    <Link to='#' onClick={this.openPresetsDialog}>{lang('download')}</Link>
                                    {` ${lang('presetsForPDF')}`}
                                </React.Fragment>
                            ) : ''}
                        </Typography>
                    </div>
                    {React.createElement("div", { dangerouslySetInnerHTML: { __html: lang('preflightCustomMessage') } })}
                </div>
                );
        }
        else {
            let precheckReportLink = isPrint && !hidePrecheckReportLink ? <div className={classes.link}><Typography variant="body2"><a target='_blank' rel='noopener noreferrer' href={'./File/StreamFile?fileType=PrecheckReport&id=' + this.props.component.id}>{langFormat('viewPrecheckReport', '')}</a></Typography></div> : '';
            return (
                <div>
                    {precheckReportLink}
                    {isPrint ? (
                        <React.Fragment>
                            {isCreateAnAd ? (
                                <div className={classes.link}><Typography variant="body2"><a target='_blank' rel='noopener noreferrer' href='File/HelpResource?fileType=CreateAnAdGuide'>{lang('viewCreateAnAdGuide')}</a></Typography></div>
                            ) : (
                                <React.Fragment>
                                    {showAdCreationGuideLink ? (
                                        <div className={classes.link}><Typography variant="body2"><a target='_blank' rel='noopener noreferrer' href={'./File/HelpResource?filetype=AdCreationGuide'}>{lang('viewAdCreationGuide')}</a></Typography></div>
                                    ): null}
                                    <div className={classes.link}><Typography variant="body2"><a target='_blank' rel='noopener noreferrer' href={'./File/HelpResource?filetype=PrintSubmissionGuide'}>{lang('viewPrintSubmissionGuide')}</a></Typography></div>
                                </React.Fragment>
                            )}
                            <div className={classes.link}>
                                <Typography variant="body2">
                                    {lang('presetsForPDF') !== undefined && lang('presetsForPDF').trim() !== '' ? (
                                        <React.Fragment>
                                            <Link to='#' onClick={this.openPresetsDialog}>{lang('download')}</Link>
                                            {` ${lang('presetsForPDF')}`}
                                        </React.Fragment>
                                    ) : ''}
                                </Typography>
                            </div>
                            {React.createElement("div", { dangerouslySetInnerHTML: { __html: lang('precheckCustomMessage') } })}
                        </React.Fragment>
                    ) : (
                        <div className={classes.link}><Typography variant="body2"><a target='_blank' rel='noopener noreferrer' href={'./File/HelpResource?filetype=DigitalSubmissionGuide'}>{lang('viewDigitalSubmissionGuide')}</a></Typography></div>
                    )}
                </div>
                );
        }
    }

    removeAllFiles = () => {
        let { updateSubmission } = this.props;
        let prevStatus = TicketComponentStatus.ReadyToUpload;
        let tcid = this.props.currentSubmission.ticket.components[0].id;
        fetch(`SvrTicket/ReturnToStatus?tcid=${tcid}&statusId=${prevStatus}&autoPositionFile=false`, { method: "POST", credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.props.currentSubmission.ticket.components = this.props.currentSubmission.ticket.components.slice(1, 1)
                    this.props.currentSubmission.ticket.components[0] = data.updatedComponent;
                    updateSubmission(this.props.currentSubmission);
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        let error = (data.error) ? data.error : 'unknownError';
                        this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(error) });
                    }
                }
            });
    }

    closeUploadAdditionalFilesDialog = () => {
        if (this.state.mediaUploadCancelEnabled) {
            this.setState({ showMediaUploadDialog: false });
        }
    }

    uploadAdditionalFiles = () => {
        this.setState({ showMediaUploadDialog: true });
    }

    showButtons = (classes, isPreflight) => {
        let isMediaPrecheck = !this.props.currentSubmission.ticket.isPrint;
        let canOverrideErrors = this.state.results.userCanOverrideErrors;
        let hasErrors = this.state.results.messages.filter(message => message.isError).length > 0;
        let hasWarnings = this.state.results.messages.filter(message => !message.isError).length > 0;
        let selectFileText = this.props.currentSubmission.ticket.isCreateAnAd ? lang('createNewFile') : lang('selectNewFile');
        let component = this.props.currentSubmission.ticket.components[this.props.componentIndex]
        if (!isMediaPrecheck) {
            if (isPreflight) {
                const showFixMyfile = component.status === TicketComponentStatus.PreflightFailed &&
                    this.props.currentSubmission.fixMyFileEnabled && component.areAllPreflightErrorsFixable;
                return (
                    <div className={classes.buttonContainer}>
                        <SupportRequest showButton ticketId={this.props.currentSubmission.ticket.id} requestType={3} />
                        <Button className={classes.button} onClick={this.selectNewFile} variant='contained' color='primary'>{selectFileText}</Button>
                        <Button className={classes.button} onClick={this.reposition} variant='contained' color='primary'>{lang('reposition')}</Button>
                        {showFixMyfile ? (
                            <Button className={classes.button} onClick={this.fixMyFile} variant='contained' color='secondary' autoFocus>{lang('fixMyFile')}</Button>
                        ) : ''}
                        {(component.status !== TicketComponentStatus.PreflightCrashed && !hasErrors) ? (
                            <Button className={classes.button} onClick={() => this.approve(false, false)} variant='contained' color='secondary' autoFocus>{lang('approve')}</Button>
                        ) : hasErrors && canOverrideErrors ? (
                            <Button className={classes.button} onClick={() => this.approve(false, true)} variant='contained' color='secondary' autoFocus>{lang('overrideErrors')}</Button>
                        ) : ''}
                    </div>
                );
            }
            else {
                return (
                    <div className={classes.buttonContainer}>
                        <SupportRequest showButton ticketId={this.props.currentSubmission.ticket.id} requestType={3} />
                        <Button className={classes.button} onClick={this.selectNewFile} variant='contained' color='primary'>{selectFileText}</Button>
                        {(component.status !== TicketComponentStatus.PrecheckCrashed && !hasErrors) ? (
                            <Button className={classes.button} onClick={() => this.approve(false, false)} variant='contained' color='secondary' autoFocus>{lang('approve')}</Button>
                        ) : hasErrors && canOverrideErrors ? (
                            <Button className={classes.button} onClick={() => this.approve(false, true)} variant='contained' color='secondary' autoFocus>{lang('overrideErrors')}</Button>
                        ) : ''}
                    </div>
                );
            }
        }
        else {
            return (
                <div className={classes.buttonContainer}>
                    {hasErrors || hasWarnings ? (<SupportRequest showButton ticketId={this.props.currentSubmission.ticket.id} requestType={3} />) : ''}
                    <Button className={classes.button} onClick={this.uploadAdditionalFiles} variant='contained' color='primary'>{lang('uploadAdditionalFiles')}</Button>
                    <Button className={classes.button} onClick={this.removeAllFiles} variant='contained' color='primary'>{lang('removeAllFiles')}</Button>
                    {hasErrors && canOverrideErrors && !this.state.overrideClicked ? (
                        <Button className={classes.button} onClick={() => this.approve(true, true)} variant='contained' color='secondary' autoFocus>{lang('overrideErrors')}</Button>
                    ) : ''}
                </div>
            );
        }
    }

    getSolutionDiv = (array) => {
        return (
            <div>
                {array.map((pair, index) => {
                    return <p key={index}>{pair[0] + ': ' + pair[1]}</p>
                })}
            </div>
            );
    }

    showAccordions = (classes, isError) => {
        if (isError && this.state.results) {
            let errorMessages = this.state.results.messages.filter(message => message.isError);
            if (errorMessages.length > 0) {
                return (
                    <div>
                        {errorMessages.map((message, index) => {
                            let solution = message.solution === undefined ? '' : message.solution;
                            let description = message.description;
                            if (message.isCorruptMessage) {
                                description = lang('pdfIsCorrupt');
                            }
                            if (message.multiSolution) {
                                let array = Object.entries(message.multiSolution);
                                solution = this.getSolutionDiv(array);
                            }
                            return (
                                <Accordion key={`pferror_${index}`} onChange={this.handleChange(`error-panel-${index}`)} expanded={this.state.expanded === `error-panel-${index}`}>
                                    <AccordionSummary className={classes.panelHeader}>
                                        {this.showArrow(classes, this.state.expanded === `error-panel-${index}`)}
                                        <Typography variant="body2">{description}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.panelDetails}>
                                        <Typography component='div' variant="body2">{solution}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                    </div>
                );
            }
            else {
                return (
                    <Accordion disabled className={classes.panel}>
                        <AccordionSummary style={{ backgroundColor: '#F6F6F6' }} classes={{ expanded: classes.expanded }}>
                            <Typography variant="body2">{lang('noErrors')}</Typography>
                        </AccordionSummary>
                    </Accordion>
                );
            }
        }
        else if (this.state.results) {
            let warningMessages = this.state.results.messages.filter(message => !message.isError);
            if (warningMessages.length > 0) {
                return (
                    <div>
                        {warningMessages.map((message, index) => {
                            return (
                                <Accordion key={`pfwarn_${index}`} onChange={this.handleChange(`warning-panel-${index}`)} expanded={this.state.expanded === `warning-panel-${index}`}>
                                    <AccordionSummary className={classes.panelHeader}>
                                        {this.showArrow(classes, this.state.expanded === `warning-panel-${index}`)}
                                        <Typography variant="body2">{message.description}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.panelDetails}>
                                        <Typography component='div' variant="body2">{message.solution}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                        {React.createElement("div", { dangerouslySetInnerHTML: { __html: this.state.results.inkDensityMessage } })}
                    </div>
                );
            }
            else {
                return (
                    <Accordion disabled className={classes.panel}>
                        <AccordionSummary style={{ backgroundColor: '#F6F6F6' }} classes={{ expanded: classes.expanded }} >
                            <Typography variant="body2">{lang('noWarnings')}</Typography>
                        </AccordionSummary>
                    </Accordion>
                );
            }
        }
        else {
            return '';
        }
    }

    startFixMyFile = () => {
        this.updateStatus(TicketComponentStatus.ReadyToFixFile, false);
    }

    disableMediaUploadButtonClicked = () => {
        this.setState({ mediaUploadButtonClicked: false });
    }

    render() {
        const { classes, isPreflight } = this.props;
        if (this.props.currentSubmission.reloadPrecheckPreflightResults) {
            //reload results once if needed.
            this.props.currentSubmission.reloadPrecheckPreflightResults = false;
            this.loadResults();
        }

        let dialogTitle = this.state.dialogType !== DialogType.Position ? lang('error') : lang('confirmAutoPosition');
        let dialogButtons = this.state.dialogType !== DialogType.Position ? (
            <Button onClick={this.closeDialog} autoFocus variant='contained' color='secondary'>{lang('ok')}</Button>
        ) : (
                <div>
                    <Button onClick={() => this.closeDialog(1)} variant='contained' color='primary'>{lang('manuallyPosition')}</Button>
                    <Button style={{ marginLeft: 10 }} onClick={() => this.closeDialog(2)} variant='contained' color='primary'>{lang('autoPosition')}</Button>
                </div>
            );
        let dialogContent = this.state.dialogType !== DialogType.Position ? this.state.message : (
            <Typography variant="body2" component='div'>{lang('thisFileMayBeAutoPositioned')}</Typography>
        );


        if (this.state.displayFixMyFileDisclaimer) {
            let errorMessages = this.state.results.messages.filter(message => message.isError);
            return (
                <div>
                    <Typography variant="body2">
                        {React.createElement("span", { dangerouslySetInnerHTML: { __html: lang("fixMyFileDisclaimer1") } })}
                    </Typography>
                    <ul>
                        {errorMessages.map((message, index) => {
                            return <Typography variant="body2" key={index}><li>{message.description}</li></Typography>;
                        })}
                    </ul>
                    <Typography variant="body2">
                        {React.createElement("span", { dangerouslySetInnerHTML: { __html: lang("fixMyFileDisclaimer2") } })}
                    </Typography>
                    <div className={classes.buttonArea}>
                        <Button onClick={this.returnToPreflightResults} className={classes.button} variant='contained' color='primary'>{lang('returnToPreflight')}</Button>
                        <Button onClick={this.startFixMyFile} className={classes.button} variant='contained' color='secondary'>{lang('iUnderstand')}</Button>
                    </div>
                    <TicketClosedDialog open={this.state.ticketState !== TicketState.None} isDeleted={this.state.ticketState === TicketState.Deleted} currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission} />
                </div>
                );
        }
        else {
            return (
                <div style={{ minWidth: 550, maxWidth: 700, width: '100%' }}>
                    {this.state.isLoading ? (<CircularProgress />) :
                        (<div>
                            <Grid container justifyContent='space-between' spacing={5}>
                                <Grid item xs={6}>
                                    <div>
                                        <Typography variant="body2">
                                            <span className={classes.largeText}>{lang('warnings')}</span>
                                            <span className={classes.clickForDetails}>({lang('clickForDetails')})</span>
                                        </Typography>
                                    </div>
                                    {this.showAccordions(classes, false)}
                                </Grid>
                                <Grid item xs={6}>
                                    <div>
                                        <Typography variant="body2">
                                            <span className={classes.largeText}>{lang('errors')}</span>
                                            <span className={classes.clickForDetails}>({lang('clickForDetails')})</span>
                                        </Typography>
                                    </div>
                                    {this.showAccordions(classes, true)}
                                </Grid>
                            </Grid>
                            {this.showLinks(classes, isPreflight)}
                            <div className={classes.buttonArea}>
                                {this.showButtons(classes, isPreflight)}
                            </div>
                        </div>)}

                    {this.state.showDialog ? this.state.dialogType === DialogType.PresetsDialog ? (
                        <PdfPresets closeDialog={this.closeDialog} />
                    ) : (
                            <Dialog open={this.state.showDialog} onClose={this.closeDialog} >
                                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                                <DialogContent>
                                    <Typography variant="body2">{dialogContent}</Typography>
                                </DialogContent>
                                <DialogActions>
                                    {dialogButtons}
                                </DialogActions>
                            </Dialog>
                        ) : ''}
                    <Dialog open={this.state.showMediaUploadDialog} onClose={this.closeUploadAdditionalFilesDialog} >
                        <DialogTitle>{lang('fileSelection')}</DialogTitle>
                        <DialogContent>
                            <MediaUpload inDialog disableMediaUploadButtonClicked={this.disableMediaUploadButtonClicked} mediaUploadButtonClicked={this.state.mediaUploadButtonClicked} updateMediaButtonState={this.updateMediaButtonState} showBackgroundDialog={this.props.showBackgroundDialog} updateSubmission={this.props.updateSubmission} componentIndex={0} currentSubmission={this.props.currentSubmission} reusableComponents={this.props.currentSubmission.reusableComponents} />
                        </DialogContent>
                        <DialogActions>
                            <Button style={{ display: this.state.mediaUploadCancelEnabled ? 'inline' : 'none'}} variant='contained' onClick={this.closeUploadAdditionalFilesDialog} color="primary">
                                {lang('cancel')}
                            </Button>
                            <Button style={{ display: this.state.mediaUploadButtonEnabled && this.state.mediaUploadCancelEnabled ? 'inline' : 'none' }} onClick={() => { this.setState({ mediaUploadButtonClicked: true }) }} disabled={!this.state.mediaUploadButtonEnabled} color='secondary' variant="contained">{lang('proceedToPrecheck')}</Button>
                        </DialogActions>
                    </Dialog>
                    <TicketClosedDialog open={this.state.ticketState !== TicketState.None} isDeleted={this.state.ticketState === TicketState.Deleted} currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission} />
                </div>
            );
        }
    }
}

export default withStyles(PrecheckPreflightResults, styles, { withTheme: true });