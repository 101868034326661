import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { lang } from '../Language';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import green from '@mui/material/colors/green';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { isValidPasswordComplexity } from '../HelperMethods';

const styles = theme => ({
    title: {
        padding: '12px 24px 8px',
    },
    centerText: {
        textAlign: 'center',
    },
    formFix: {
        width: '100%', //Only needed for IE11
    },
    denseGridParent: {
        marginTop: '0px',  //Spacing causes margin to go negative, which is usually fine except 
        marginBottom: '0px',
    },
    denseGridItem: {
        paddingTop: '2px !important',
        paddingBottom: '0px !important',
    },
    denseCheckbox: {
        paddingTop: '6px',
        paddingBottom: '6px',
    },
    forIE11: {
        display: 'none',
        //IE11 does not support submit buttons outside of a form via the form property.
        //This is needed to use built in HTML5 form validation.
        //The create button in the dialog calls an invisible button inside the form instead.
    },
    wrapper: {
        textAlign: 'center',
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: 'calc(100% - 40px)',
        left: 'calc(100% - 90px)',
        overflow: 'hidden', //Needed for IE11 to stop showing scroll bars
    },
});

class NewAccount extends Component {
    displayName = NewAccount.name

    constructor(props) {
        super(props);
        this.state = {
            profile: [],
            loading: true,
            stillLoading: false,
            submitting: false,
            showingMessage: false,
            accountCreated: false,
            lastMessage: '',
            lastQuizCache: '',
            quiz1: '',
            quiz2: '',
            quizLoading: true
        };
    }

    componentDidMount() {
        setTimeout(() => {
            //Wait until 1 second has passed before displaying the loading spinner.
            //The reply for NewUser usually comes back under 100ms, and it actually takes longer
            //for the dialog to appear if we need to show a loading indicator.
            if (this.state.loading) {
                this.setState({ stillLoading: true });
            }
        }, 1000);

        fetch('SvrUser/NewUser', { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                let cacheTime = new Date().getTime().toString();
                this.setState({
                    profile: data,
                    loading: false,
                    stillLoading: false,
                    lastQuizCache: cacheTime,
                    quiz1: '',
                    quiz2: '',
                    quizLoading: true
                });
            });
    }

    handleSubmit = (e) => {
        if (!this.state.submitting) {
            var form = document.getElementById("newAccountForm");
            var password1 = document.getElementById("password1");
            var password2 = document.getElementById("password2");
            var agreePrivacy = document.getElementById("agreePrivacy");
            var agreeTerms = document.getElementById("agreeTerms");

            if (password1.value === '' || password2.value === '') {
                return;
            }
            if (!isValidPasswordComplexity(password1)) {
                return;
            }
            if (password1.value !== password2.value) {
                password2.setCustomValidity(lang('passwordMismatch') + '\n');
            }
            else {
                password2.setCustomValidity('');
            }
            if (!agreePrivacy.checked) {
                agreePrivacy.setCustomValidity(lang('pleaseAgreePrivacy'));
            }
            else {
                agreePrivacy.setCustomValidity('');
            }
            if (!agreeTerms.checked) {
                agreeTerms.setCustomValidity(lang('pleaseAgreeTerms'));
            }
            else {
                agreeTerms.setCustomValidity('');
            }
            if (!form.checkValidity()) {
                return; //Regular submit will happen, causing built in form validation to work.
            }
            e.preventDefault();
            this.setState(
                {
                    submitting: true
                },
                () => {
                    require('formdata-polyfill'); //IE11 needs this for FormData POST to work correctly.
                    fetch(`SvrUser/CreateUser`, {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/json'
                        },
                        credentials: 'same-origin',
                        body: JSON.stringify(this.state.profile.user)
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success === true) {
                                this.setState({ submitting: false, accountCreated: true });
                            }
                            else {
                                if (data.error === 'quizFailed') {
                                    //Force the CAPTCHA to reload, we'll give them a new one
                                    var myProfile = this.state.profile;
                                    myProfile.quizType = data.quizType;
                                    myProfile.quizPrompt = data.quizPrompt;
                                    myProfile.user['quiz'] = '';
                                    let cacheTime = new Date().getTime().toString();
                                    this.setState({
                                        submitting: false,
                                        lastMessage: lang(data.error),
                                        showingMessage: true,
                                        profile: myProfile,
                                        lastQuizCache: cacheTime,
                                        quiz1: '',
                                        quiz2: '',
                                        quizLoading: true
                                    });
                                }
                                else {
                                    this.setState({
                                        submitting: false,
                                        lastMessage: lang(data.error),
                                        showingMessage: true
                                    });
                                }
                            }
                        });
                },
            );
        }
        return false;
    };

    handleChange = event => {
        var currentProfile = this.state.profile;
        var { quiz1, quiz2 } = this.state;

        if (event.target.type === 'checkbox') {
            currentProfile.user[event.target.value] = event.target.checked;
        }
        else if (event.target.name === 'quiz1' || event.target.name === 'quiz2') {
            if (event.target.name === 'quiz1') {
                quiz1 = event.target.value.trim();
                if (event.target.selectionStart == event.target.selectionEnd && event.target.selectionStart > 0 &&
                    event.target.value[event.target.selectionStart - 1] === ' ') {
                    //User just typed a space. Jump them into the next box
                    document.getElementById('quiz2').focus();
                }
            }
            else {
                quiz2 = event.target.value.trim();
            }
            let combo = quiz1 + ' ' + quiz2;
            let split = (combo + ' ').split(' '); //Added space to the end to ensure we always have 2 entries
            currentProfile.user['quiz'] = combo;
            quiz1 = split[0];
            quiz2 = split[1];
        } 
        else {
            currentProfile.user[event.target.name] = event.target.value;
        }
        this.setState({ profile: currentProfile, quiz1: quiz1, quiz2: quiz2 });
    };

    handlePasswordChange = event => {
        this.handleChange(event)
        this.validatePassword(event);
    }

    closeMessage = () => {
        this.setState({ showingMessage: false });
    };

    onCreationCompleted = () => {
        this.props.closeDialog();
    };

    onQuizLoaded = () => {
        this.setState({ quizLoading: false });
    }

    refreshQuiz = () => {
        this.setState({ submitting: true},
            () => {
                fetch('SvrUser/NewQuiz', { method: 'POST', credentials: 'same-origin' })
                    .then(response => response.json())
                    .then(data => {
                        var currentProfile = this.state.profile;
                        currentProfile.quizType = data.quizType;
                        currentProfile.quizPrompt = data.quizPrompt;
                        currentProfile.user['quiz'] = '';
                        let cacheTime = new Date().getTime().toString();
                        this.setState({
                            submitting: false,
                            profile: currentProfile,
                            lastQuizCache: cacheTime,
                            quiz1: '',
                            quiz2: '',
                            quizLoading: true
                        });
                    });
            });
    }

    renderQuiz = () => {
        const { classes } = this.props;
        const { profile, lastQuizCache, quiz1, quiz2, submitting, quizLoading } = this.state;
        let refreshButton = (<Button onClick={this.refreshQuiz} size="small"
            color="secondary" variant="contained"
            disabled={submitting}
            startIcon={<RefreshIcon />}>
            {lang("refresh")}
        </Button>);

        if (profile.quizType == 1) {
            return (
                <Paper>
                    <Grid container spacing={1} className={classes.denseGridParent} alignItems="center" justifyContent="center">
                        <Grid item xs={6}>
                            <Typography variant="caption">{profile.quizPrompt}</Typography>
                            <FormControl required margin="none" fullWidth>
                                <Input id="quiz" name="quiz" value={profile.user.quiz} onChange={this.handleChange} inputProps={{ maxLength: "6" }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs="auto">
                            {quizLoading && <div style={{ width: 70, height: 62 }}><HourglassEmptyIcon fontSize='large' style={{ display: 'block', margin: 'auto' }} /></div>}
                            <img key={"quiz" + lastQuizCache} src={"SvrUser/ForHumans?refresh=" + lastQuizCache}
                                style={{ width: 70, height: 62, display: (quizLoading) ? 'none' : 'block' }}
                                onLoad={this.onQuizLoaded} />
                        </Grid>
                        <Grid item xs="auto">
                            {refreshButton}
                        </Grid>
                    </Grid>
                </Paper>
            );
        }
        else if (profile.quizType == 2) {
            return (
                <Paper>
                    <Grid container spacing={1} className={classes.denseGridParent} alignItems="center" justifyContent="center">
                        <Grid item xs={6}>
                            <Typography variant="caption">{profile.quizPrompt}</Typography>
                            <Grid container spacing={1} className={classes.denseGridParent} alignItems="center" justifyContent="center">
                                <Grid item xs={6}>
                                    <FormControl required margin="none" fullWidth>
                                    <Input id="quiz1" name="quiz1" value={quiz1} onChange={this.handleChange} inputProps={{ maxLength: "20" }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl required margin="none" fullWidth>
                                    <Input id="quiz2" name="quiz2" value={quiz2} onChange={this.handleChange} inputProps={{ maxLength: "20" }} />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style={{ textAlign: 'right' }}>
                            {quizLoading && <div style={{ width: 250, height: 62 }}><HourglassEmptyIcon fontSize='large' style={{ display: 'block', margin: 'auto' }} /></div>}
                            <img key={"quiz" + lastQuizCache} src={"SvrUser/ForHumans?refresh=" + lastQuizCache}
                                style={{ width: 250, display: (quizLoading) ? 'none' : 'block' }}
                                onLoad={this.onQuizLoaded} />
                            <br/>
                            {refreshButton}
                        </Grid>
                    </Grid>
                </Paper>
            );
        }
        return (
            <Paper>
                <Typography>{lang("quizFailed")}</Typography>
            </Paper>
        );
    }

    renderProfileGrid = () => {
        const { classes } = this.props;
        const { submitting } = this.state;
        const { profile } = this.state;
        let cbPrivacyText = (
            <span>{lang("iAgreeTo")} <Link to="/privacy" target="_blank">{lang("privacyStatement")}</Link></span>
        );
        let cbTermsText = (
            <span>{lang("iAgreeTo")} <Link to="/terms" target="_blank">{lang("termsAndConditions")}</Link></span>
        );
        let quizContent = this.renderQuiz();

        return (
            <div>
                <Dialog open scroll='paper' aria-labelledby='new-user-title'>
                    <DialogTitle id="new-user-title" className={classes.title} > {lang("newUser")}</DialogTitle>
                    <DialogContent>
                        <form id="newAccountForm" className={classes.formFix} >
                            <FormControl margin="none" required fullWidth variant="standard">
                                <InputLabel htmlFor="email">{lang("emailAddress")}</InputLabel>
                                <Input id="email" name="email" type="email" autoComplete="email" value={profile.user.email} onChange={this.handleChange} inputProps={{ maxLength: "100" }} />
                            </FormControl>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <FormControl margin="none" required fullWidth variant="standard">
                                        <InputLabel htmlFor="firstName">{lang("firstName")}</InputLabel>
                                        <Input id="firstName" name="firstName" autoComplete="firstName" value={profile.user.firstName} onChange={this.handleChange} inputProps={{ maxLength: "30" }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl margin="none" required fullWidth variant="standard">
                                        <InputLabel htmlFor="lastName">{lang("lastName")}</InputLabel>
                                        <Input id="lastName" name="lastName" autoComplete="lastName" value={profile.user.lastName} onChange={this.handleChange} inputProps={{ maxLength: "30" }} />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl margin="none" required fullWidth variant="standard">
                                <InputLabel htmlFor="companyName">{lang("company")}</InputLabel>
                                <Input id="companyName" name="companyName" autoComplete="companyName" value={profile.user.companyName} onChange={this.handleChange} inputProps={{ maxLength: "30" }} />
                            </FormControl>
                            <FormControl margin="none" fullWidth variant="standard">
                                <InputLabel htmlFor="phone">{lang("phone")}</InputLabel>
                                <Input id="phone" name="phone" autoComplete="phone" value={profile.user.phone} onChange={this.handleChange} inputProps={{ maxLength: "15" }} />
                            </FormControl>
                            <FormControl margin="none" fullWidth variant="standard">
                                <InputLabel htmlFor="address1">{lang("address1")}</InputLabel>
                                <Input id="address1" name="address1" autoComplete="address1" value={profile.user.address1} onChange={this.handleChange} inputProps={{ maxLength: "40" }} />
                            </FormControl>
                            <FormControl margin="none" fullWidth variant="standard">
                                <InputLabel htmlFor="address2">{lang("address2")}</InputLabel>
                                <Input id="address2" name="address2" autoComplete="address2" value={profile.user.address2} onChange={this.handleChange} inputProps={{ maxLength: "40" }} />
                            </FormControl>
                            <Grid container spacing={1} className={classes.denseGridParent}>
                                <Grid item xs={6} className={classes.denseGridItem}>
                                    <FormControl margin="none" fullWidth variant="standard">
                                        <InputLabel htmlFor="city">{lang("city")}</InputLabel>
                                        <Input id="city" name="city" autoComplete="city" value={profile.user.city} onChange={this.handleChange} inputProps={{ maxLength: "30" }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.denseGridItem}>
                                    <FormControl margin="none" required fullWidth variant="standard">
                                        <InputLabel htmlFor="state">{lang("state")}</InputLabel>
                                        <Select id="state" name="state" native value={profile.user.state} onChange={this.handleChange}>
                                            <option value="" />
                                            {profile.states.map(state => (
                                                <option key={state.value} value={state.id}>
                                                    {state.value}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.denseGridItem}>
                                    <FormControl margin="none" fullWidth variant="standard">
                                        <InputLabel htmlFor="postalCode">{lang("postalCode")}</InputLabel>
                                        <Input id="postalCode" name="postalCode" autoComplete="postalCode" value={profile.user.postalCode} onChange={this.handleChange} inputProps={{ maxLength: "10" }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.denseGridItem}>
                                    <FormControl margin="none" fullWidth variant="standard">
                                        <InputLabel htmlFor="country">{lang("country")}</InputLabel>
                                        <Input id="country" name="country" autoComplete="country" value={profile.user.country} onChange={this.handleChange} inputProps={{ maxLength: "30" }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.denseGridItem}>
                                    <FormControl margin="none" required fullWidth variant="standard">
                                        <InputLabel htmlFor="password1">{lang("Password")}</InputLabel>
                                        <Input id="password1" name="password1" type="password" onChange={this.handlePasswordChange} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.denseGridItem}>
                                    <FormControl margin="none" required fullWidth variant="standard">
                                        <InputLabel htmlFor="password2">{lang("confirmPassword")}</InputLabel>
                                        <Input id="password2" name="password2" type="password" onChange={this.handlePasswordChange} />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl margin="none" required fullWidth variant="standard">
                                <InputLabel htmlFor="units">{lang("unitPreference")}</InputLabel>
                                <Select id="units" name="units" native value={profile.user.units} onChange={this.handleChange}>
                                    <option value="" />
                                    {profile.unitsOfMeasure.map(uom => (
                                        <option key={uom.value} value={uom.id}>
                                            {uom.value}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                            {quizContent}
                            <Typography variant="body2">
                                <FormControlLabel margin="none"
                                    control={<Checkbox id="agreePrivacy" value="agreePrivacy" color="primary" className={classes.denseCheckbox} onChange={this.handleChange} />}
                                    label={cbPrivacyText}
                                />
                            </Typography>
                            <Typography variant="body2">
                                <FormControlLabel margin="none"
                                    control={<Checkbox id="agreeTerms" value="agreeTerms" color="primary" className={classes.denseCheckbox} onChange={this.handleChange} />}
                                    label={cbTermsText}
                                />
                            </Typography>
                            <div className={classes.forIE11}>
                                <button id="btnCreateAccount" type="submit" onClick={this.handleSubmit} />
                            </div>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => this.props.closeDialog()}
                        >{lang("cancel")}
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            disabled={submitting}
                            onClick={() => document.getElementById("btnCreateAccount").click()}
                        >
                            {lang("createAccount")}
                        </Button>
                        {submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </DialogActions>
                </Dialog>
                <div>
                    <Dialog
                        open={this.state.showingMessage}
                        onClose={this.closeMessage}
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {this.state.lastMessage}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeMessage} color="secondary" variant="contained" autoFocus>
                                {lang("ok")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.accountCreated}
                        onClose={this.onCreationCompleted}
                        aria-labelledby="created-dialog-title"
                        aria-describedby="created-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="created-dialog-description">
                                <span>{lang("activateThroughEmail")}</span>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.onCreationCompleted} color="secondary" variant="contained" autoFocus>
                                {lang("ok")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }

    validatePassword = (event) => {
        event.target.setCustomValidity('text');
        if (event.target == document.getElementById('password2')) {
            this.validatePasswordMatch(event);
            return;
        }

        if (isValidPasswordComplexity(event.target)) {
            event.target.setCustomValidity('');
        }
    };

    validatePasswordMatch = (event) => {
        var password = document.getElementById('password1');
        if (password.value !== event.target.value) {
            event.target.setCustomValidity(lang('passwordMismatch') + '\n');
        }
        else {
            event.target.setCustomValidity('');
        }
    };

    render() {
        let contents = '';
        if (this.state.stillLoading) {
            contents = <Dialog transitionDuration={0} open={this.state.loading}><DialogContent><CircularProgress /></DialogContent></Dialog>;
        }
        else if (this.state.loading) {
            contents = '';
        }
        else {
            contents = this.renderProfileGrid();
        }

        return (
            <React.Fragment>
                {contents}
            </React.Fragment>
        );
    }
}

export default withStyles(NewAccount, styles);