//All TicketComponent statuses and values

export const TicketComponentStatus = Object.freeze({
    ReadyToUpload: 4000,
    UploadStarting: 4005,
    Uploading: 4010,
    UploadFailed: 4020,
    RepurposeFailed: 4021,

    ReadyToPrecheck: 4030,
    Prechecking: 4040,
    PrecheckPassed: 4050,
    PrecheckFailed: 4060,
    PrecheckCrashed: 4070,

    ReadyToPreCrop: 4080,
    Precropping: 4090,
    PreCropFailed: 4100,
    PreCropCrashed: 4110,

    ReadyToApprovePrecheck: 4120,

    SoftCropCrashed: 4150,

    ReadyToPosition: 4200,

    ReadyToSoftCrop: 4210,
    SoftCropping: 4220,
    SoftCropFailed: 4230,

    ReadyToHardCrop: 4240,
    HardCropping: 4250,
    HardCropFailed: 4260,
    HardCropCrashed: 4270,

    ReadyToGenerateTemplate: 4280,
    GeneratingTemplates: 4290,
    GenerateTemplatesFailed :4300,

    ReadyToPreflight: 4400,
    Preflighting: 4410,
    PreflightPassed: 4420,
    PreflightFailed: 4430,
    PreflightCrashed: 4440,

    ReadyToFixFile: 4500,
    FixingFile: 4510,
    FixFilePassed: 4520,
    FixFileFailed: 4530,
    FixFileCrashed: 4540,
    ReadyToReviewFix: 4550,
    ReadyToApprovePosition: 4560,

    ReadyToCopyFiles: 4590,
    CopyingExtraFiles: 4591,
    FailedToCopyFiles: 4592,

    ReadyToTransmit: 4600,

    ReadyToAddCropMarks: 4610,
    AddingCropMarks: 4611,
    AddCropMarksFailed: 4612,
    AddCropMarksCrashed: 4613,

    ReadyToCreatePlateReadyFile: 4615,
    CreatingPlateReadyFile: 4616,
    CreatingPlateReadyFileFailed: 4617,
    CreatingPlateReadyFileCrashed: 4618,

    ReadyToAlterGeometry: 4621,
    AlteringGeometry: 4622,
    AlteringGeometryCrashed: 4623,

    PostProcessingComplete: 4630,

    PublisherSupplied: 4700,

    AutoSubmissionFailedPrePos: 4800,
    AutoSubmissionFailedPos: 4801,
    AutoSubmissionFailedInsp: 4802,
    AutoSubmissionFailedPostPrc: 4803
});


