import React, { Component } from 'react';
import { lang, langFormat } from '../../Language';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TicketComponentStatus } from '../../TicketComponentStatus';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions';
import OpenInNew from '@mui/icons-material/OpenInNew';
import TicketClosedDialog, { TicketState } from './TicketClosedDialog';

const styles = ({
    filePreview: {
        display: 'inline-block'
    },
    toolsAndInfo: {
        width: 220,
        marginLeft: 30
    },
    zoomTool: {
        width: 200,
        height: 200
    },
    zoomToolText: {
        width: 200,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    bleedImagePreview: {
        width: 80,
        height: 80
    },
    buttons: {
        textAlign: 'right',
        paddingTop: 20,
        '&> button': {
            marginLeft: 5,
            marginRight: 5
        }
    },
    infoArea: {
        paddingTop: 10,
        paddingBottom: 10
    },
    cornerBleeds: {
        borderSpacing: 10
    },
    cornerBleedText: {
        textAlign: 'center',
        fontWeight: 'bold'
    },
    filePreviewText: {
        textAlign: 'center',
        fontWeight: 'bold'
    },
    expandText: {
        marginTop: 10,
        width: 200
    }
});

const DialogType = Object.freeze({
    None: 0,
    Error: 1,
    Position: 2
});

const halfOfZoomToolWidth = 100;

class PositionApproval extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            dialogType: DialogType.None,
            message: '',
            zoomToollocX: 0,
            zoomToollocY: 0,
            bleedText: '',
            ticketState: TicketState.None
        };
        this.imageInfo = undefined;
    }

    componentDidMount() {
        fetch(`SvrTicket/GetMaxBleedFormatted?tcid=${this.props.currentSubmission.ticket.components[this.props.componentIndex].id}`, { method: 'POST', credentials: "same-origin" })
            .then(response => response.json())
            .then(data => {
                this.setState({ bleedText: data.bleedText });
            });
    }

    updateZoomView = (event) => {
        //Same calc values every time, so save off values for quick lookup.
        if (!this.imageInfo) {
            this.imageInfo = {
                horiEdges: halfOfZoomToolWidth * (event.target.clientWidth / event.target.naturalWidth),
                vertEdges: halfOfZoomToolWidth * (event.target.clientHeight / event.target.naturalHeight),
                targetWidth: event.target.clientWidth,
                targetHeight: event.target.clientHeight,
                widthRatio: event.target.naturalWidth / event.target.clientWidth,
                heightRatio: event.target.naturalHeight / event.target.clientHeight
            }
        }
        let offsetX = event.nativeEvent.offsetX;
        let offsetY = event.nativeEvent.offsetY;
        let locChange = false;
        let newXOffset = this.state.zoomToollocX; //current locX
        let newYOffset = this.state.zoomToollocY; //current LocY

        if (offsetX >= this.imageInfo.horiEdges && offsetX <= event.target.clientWidth - this.imageInfo.horiEdges) {
            locChange = true;
            newXOffset = (-offsetX * this.imageInfo.widthRatio) + halfOfZoomToolWidth;
        }
        if (offsetY >= this.imageInfo.vertEdges && offsetY <= event.target.clientHeight - this.imageInfo.vertEdges) {
            locChange = true;
            newYOffset = (-offsetY * this.imageInfo.heightRatio) + halfOfZoomToolWidth;
        }
        if (locChange) {
            this.setState({ zoomToollocX: newXOffset, zoomToollocY: newYOffset });
        }
    }

    componentDidMount = () => {
        this.adjustSizeofPreviewImage();
    }

    returnToPreviousStatus = (prevStatus, autoPosition) => {
        let tcid = this.props.currentSubmission.ticket.components[this.props.componentIndex].id;
        fetch(`SvrTicket/ReturnToStatus?tcid=${tcid}&statusId=${prevStatus}&autoPositionFile=${autoPosition}`, { method: "POST", credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.props.currentSubmission.ticket.components[this.props.componentIndex] = data.updatedComponent;
                    this.props.updateSubmission(this.props.currentSubmission);
                    this.closeDialogIfInsideDialog();
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        let error = (data.error) ? data.error : 'unknownError';
                        this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(error) });
                    }
                }
            });
    }

    updateStatus = (nextStatus) => {
        let { updateSubmission, componentIndex } = this.props;
        let tcid = this.props.currentSubmission.ticket.components[componentIndex].id;
        fetch(`SvrTicket/UpdateComponentStatus?tcid=${tcid}&statusId=${nextStatus}`, { method: "POST", credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.props.currentSubmission.ticket.components[componentIndex].status = nextStatus;
                    updateSubmission(this.props.currentSubmission);
                    this.closeDialogIfInsideDialog();
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        let error = (data.error) ? data.error : 'unknownError';
                        this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(error) });
                    }
                }
            });
       
    }

    selectNewFile = () => {
        this.returnToPreviousStatus(TicketComponentStatus.ReadyToUpload, false);
    }

    reposition = () => {
        let canAutoPosition = this.props.currentSubmission.ticket.components[this.props.componentIndex].canAutoPosition;
        if (canAutoPosition) {
            this.setState({ showDialog: true, dialogType: DialogType.Position });
        }
        else {
            this.returnToPreviousStatus(TicketComponentStatus.ReadyToPosition, false);
        }
    }

    returnToPreflight = () => {
        this.returnToPreviousStatus(TicketComponentStatus.ReadyToPreflight, false);
    }

    approvePosition = () => {
        this.updateStatus(TicketComponentStatus.ReadyToTransmit);
    }

    closeDialogIfInsideDialog = () => {
        if (this.props.closeDialog) {
            this.props.closeDialog();
        }
    }

    closeDialog = (result) => {
        if (result === 1) {
            this.returnToPreviousStatus(TicketComponentStatus.ReadyToPosition);
        }
        else if (result === 2) {
            let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
            fetch(`SvrTicket/AutoPosition?tcid=${component.id}`, { method: 'POST', credentials: "same-origin" })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        this.props.currentSubmission.ticket.components[this.props.componentIndex] = data.ticketComponent;
                        this.props.updateSubmission(this.props.currentSubmission);
                        this.closeDialogIfInsideDialog();
                    }
                    else {
                        if (data.error === 'isDeleted') {
                            this.setState({ ticketState: TicketState.Deleted });
                        }
                        else if (data.error === 'isSubmitted') {
                            this.setState({ ticketState: TicketState.Submitted });
                        }
                    }
                });
        }
        this.setState({ showDialog: false });
    }

    adjustSizeofPreviewImage = () => {
        let index = this.props.componentIndex;
        let image = document.getElementById(`preview-image-${index}`);
        let props = this.props;
        let getOffsetFromTopOfDocument = this.getOffsetFromTopOfDocument;
        let getOffsetFromLeftOfDocument = this.getOffsetFromLeftOfDocument;
        image.onload = function () {
            let isInDialog = props.isExpanded;
            let minImageHeight = 400;  //size of text area and tools.
            let offsetFromTopOfWindow = getOffsetFromTopOfDocument(image);
            let offsetFromLeftOfWindow = getOffsetFromLeftOfDocument(image);
            let numOfComponents = props.currentSubmission.ticket.components.length;
            let component = props.currentSubmission.ticket.components[props.componentIndex];
            let availableVerticalSpace = isInDialog ? window.innerHeight - 200 : window.innerHeight - offsetFromTopOfWindow - (numOfComponents * 30) - 140;
            let availableHorizontalSpace = isInDialog ? window.innerWidth - 800 : window.innerWidth - offsetFromLeftOfWindow - 800;
            let ratio = image.clientWidth / image.clientHeight;
            if (!isInDialog && ratio >= 1) {
                //wider than tall
                let newAvailableVerticalSpace = image.clientHeight * availableHorizontalSpace / image.clientWidth;
                if (newAvailableVerticalSpace < availableVerticalSpace) {
                    availableVerticalSpace = newAvailableVerticalSpace;
                }
            }
            if (component.isSmallerThanIssueTrim && image.naturalHeight < minImageHeight) {
                availableVerticalSpace = 0;  //If image is smaller than height of issue than don't try expanding it, it would distort the image.
            }
            if (availableVerticalSpace > minImageHeight) {
                let heightMulitplier = availableVerticalSpace / minImageHeight;
                image.style.maxHeight = isInDialog ? window.innerHeight - 200 + 'px' : (heightMulitplier * image.clientHeight) + 'px';
                image.style.height = (heightMulitplier * image.clientHeight) + 'px';
            }
        };
    }

    getOffsetFromTopOfDocument = (element) => {
        let offsetTop = 0;
        while (element) {
            offsetTop += element.offsetTop;
            element = element.offsetParent;
        }
        return offsetTop;
    }

    getOffsetFromLeftOfDocument = (element) => {
        let offsetLeft = 0;
        while (element) {
            offsetLeft += element.offsetLeft;
            element = element.offsetParent;
        }
        return offsetLeft;
    }

    render() {
        const { classes, componentIndex, currentSubmission, isExpanded } = this.props;
        let tcid = currentSubmission.ticket.components[componentIndex].id;
        let newFileText = currentSubmission.ticket.isCreateAnAd ? lang('createNewFile') : lang('selectNewFile');
        let dialogTitle = this.state.dialogType !== DialogType.Position ? lang('error') : lang('confirmAutoPosition');
        let dialogButtons = this.state.dialogType !== DialogType.Position ? (
            <Button onClick={this.closeDialog} autoFocus variant='contained' color='secondary'>{lang('ok')}</Button>
        ): (
            <div>
                <Button onClick={() => this.closeDialog(1)} variant='contained' color='primary'>{lang('manuallyPosition')}</Button>
                <Button style={{marginLeft: 10}} onClick={() => this.closeDialog(2)} variant='contained' color='primary'>{lang('autoPosition')}</Button>
            </div>
        )
        let dialogContent = this.state.dialogType !== DialogType.Position ? this.state.message : (
            <Typography variant="body2" component='div'>{lang('thisFileMayBeAutoPositioned')}</Typography>
        );

        let expandText = isExpanded
            ? <div className={classes.expandText}>{`${lang('clickThe')} `}<OpenInNew style={{ transform: 'rotate(180deg)', fontSize: '1em', verticalAlign: 'sub' }} />{` ${lang('positionApprovalMinimizeText')}`}</div>
            : <div className={classes.expandText}>{`${lang('clickThe')} `}<OpenInNew style={{ fontSize: '1em', verticalAlign: 'sub' }} />{` ${lang('positionApprovalExpandText')}`}</div>

        return (
            <div>
                <div style={{display: 'flex'}}>
                    <div className={classes.filePreview}>
                        <Typography variant="body2" className={classes.filePreviewText} component='div'>{lang('verifyAd')}</Typography>
                        <div style={{ textAlign: 'center'}}>
                            <img id={`preview-image-${componentIndex}`} style={{ maxHeight: 400 }} onMouseMove={this.updateZoomView} className={classes.image} src={`./File/StreamFile?fileType=HardCropFullWithTemplate&id=${tcid}&lastModified=${currentSubmission.ticket.components[componentIndex].lastChangedTimestamp}`} alt='' />
                        </div>
                    </div>
                    <div style={{ display: 'inline-block', verticalAlign: 'top', width: 425, marginLeft: 10 }}>
                        <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                            <Typography variant="body2" className={classes.zoomToolText} component='div'>{lang('zoomTool')}</Typography>
                            <div style={{ backgroundImage: `url(./File/StreamFile?fileType=HardCropFullWithTemplate&id=${tcid}&lastModified=${currentSubmission.ticket.components[componentIndex].lastChangedTimestamp})`, backgroundPosition: `${this.state.zoomToollocX}px ${this.state.zoomToollocY}px`, zIndex: 0 }} className={classes.zoomTool}></div>
                        </div>
                        <div style={{ display: 'inline-block', verticalAlign: 'top', marginLeft: 10 }}>
                            <Typography variant="body2" component='div' className={classes.cornerBleedText}>{lang('cornerBleedPreviews')}</Typography>
                            <table className={classes.cornerBleeds}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className={classes.bleedImagePreview} style={{ backgroundImage: `url(./File/StreamFile?fileType=HardCropFullWithTemplate&id=${tcid})` }}></div>
                                        </td>
                                        <td>
                                            <div className={classes.bleedImagePreview} style={{ backgroundImage: `url(./File/StreamFile?fileType=HardCropFullWithTemplate&id=${tcid})`, backgroundPosition: 'top right' }}></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={classes.bleedImagePreview} style={{ backgroundImage: `url(./File/StreamFile?fileType=HardCropFullWithTemplate&id=${tcid})`, backgroundPosition: 'bottom left' }}></div>
                                        </td>
                                        <td>
                                            <div className={classes.bleedImagePreview} style={{ backgroundImage: `url(./File/StreamFile?fileType=HardCropFullWithTemplate&id=${tcid})`, backgroundPosition: 'bottom right' }}></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{ display: 'block' }}>
                            <div className={classes.infoArea}>
                                <Typography variant="body2" component='div'>{lang('pleaseConfirm')}</Typography>
                                <div>
                                    <Typography variant="body2" component='ul'>
                                        <li>{lang('safetyTrimAndBleed')}</li>
                                        <li>{lang('contentIsComplete')}</li>
                                        <li>{lang('importantOversized')}</li>
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="body2" component='div'>{langFormat('redLines', this.state.bleedText)}</Typography>
                                    <Typography variant="body2" component='div'>{lang('blueLines')}</Typography>
                                    <Typography variant="body2" component='div'>{lang('greenLines')}</Typography>
                                </div>
                                <Typography variant='body2' component='div'>{expandText}</Typography>
                                {React.createElement("div", { dangerouslySetInnerHTML: { __html: lang('positionApprovalCustomDisclaimer') } })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.buttons}>
                    <Button onClick={this.selectNewFile} variant='contained' color='primary'>{newFileText}</Button>
                    <Button onClick={this.reposition} variant='contained' color='primary'>{lang('reposition')}</Button>
                    <Button onClick={this.returnToPreflight} variant='contained' color='primary'>{lang('returnToPreflight')}</Button>
                    <Button onClick={this.approvePosition} autoFocus variant='contained' color='secondary'>{lang('approve')}</Button>
                </div>
                <Dialog open={this.state.showDialog} onClose={this.closeDialog}>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>{dialogContent}</DialogContent>
                    <DialogActions>
                        {dialogButtons}
                    </DialogActions>
                </Dialog>
                <TicketClosedDialog open={this.state.ticketState !== TicketState.None} isDeleted={this.state.ticketState === TicketState.Deleted} currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission} />
            </div>
            );
    }
}

export default withStyles(PositionApproval, styles);