import React, { Component } from 'react';
import { lang } from '../../Language';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import GateDisplay from './GateDisplay';
import { Typography } from '@mui/material';

const styles = theme => ({
    dialogContent: {
        height: '500px',
        overflow: 'hidden' //For Chrome, which displays a vertical scroll bar even though everything fits
    },
    disclaimer: {
        flexGrow: 1,
        textAlign: 'center',
        marginBottom: 'auto'
    }
});

class GateDialog extends Component {
    displayName = GateDialog.name

    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            showingMessage: false,
            lastMessage: '',
        };
    }

    closeGateDialog = () => {
        this.props.closeDialog();
    };

    render() {
        const { classes, open, ticket } = this.props;

        return (
            <Dialog aria-labelledby='gate-preview-title' open={open} fullWidth scroll='body' maxWidth='lg' >
                <DialogTitle id="gate-preview-title">{lang("gatePreview")}</DialogTitle>
                <DialogContent className={classes.dialogContent} >
                    <GateDisplay ticket={ticket} />
                </DialogContent>
                <DialogActions>
                    <Typography className={classes.disclaimer}>{lang("gateDisclaimer")}</Typography>
                    <Button name='ok' variant='contained' autoFocus onClick={this.closeGateDialog} color="secondary">
                        {lang('ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(GateDialog, styles);