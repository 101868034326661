import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { lang } from '../../Language';
import { Navigate } from 'react-router';

export const TicketState = Object.freeze({
    None: 0,
    Deleted: 1,
    Submitted: 2
});


class TicketClosedDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: ''
        };
    }


    returnToTrackDeliveries = () => {
        const { currentSubmission, updateSubmission } = this.props;
        if (currentSubmission && updateSubmission) {
            currentSubmission.ticket = null;
            currentSubmission.redirectLocation = '/track';
            updateSubmission(currentSubmission);
        }
        else {
            this.setState({ redirect: <Navigate to={{ pathname: '/track' }} replace />})
        }
    }

    startNewSubmission = () => {
        const { currentSubmission, updateSubmission } = this.props;
        if (currentSubmission && updateSubmission) {
            currentSubmission.ticket = null;
            currentSubmission.redirectLocation = '/ticket/new';
            updateSubmission(currentSubmission);
        }
        else {
            this.setState({ redirect: <Navigate to={{ pathname: '/ticket/new' }} replace /> });
        }
    }

    render() {
        const { isDeleted, open } = this.props;
        let dialogTitle = lang('ticketSubmitted');
        let dialogText = lang('ticketSubmittedText');
        if (isDeleted) {
            dialogTitle = lang('ticketDeleted')
            dialogText = lang('ticketDeletedText');
        } 

        return (
            <React.Fragment>
                <Dialog open={open}>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>{dialogText}</DialogContent>
                    <DialogActions>
                        <Button onClick={this.returnToTrackDeliveries} variant='contained' color='primary'>{lang('trackDeliveries')}</Button>
                        <Button onClick={this.startNewSubmission} variant='contained' color='primary'>{lang('startNewTicket')}</Button>
                    </DialogActions>
                </Dialog>
                {this.state.redirect}
            </React.Fragment>
            );
    }
}

export default TicketClosedDialog;