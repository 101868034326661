import React from 'react';
import { lang } from '../../Language';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import { overrideComponentThemeStyle } from '../../StyleCustomization';
import TicketReportDetails from './TicketReportDetails';
import { dateParser } from '../../HelperMethods';
import { withLocation } from '../../withLocation';

const styles = theme => {
    var result = {
        heading: {
            fontWeight: 'bold',
            marginTop: theme.spacing(1)
        },
        horizontalBar: {
            borderColor: 'black'
        },
        headerSection: {
            height: '100%',
        }
    };
    result = overrideComponentThemeStyle(theme, result, "TicketDetails");
    return result;
};


class TicketDetails extends React.Component {
    static getChildPath(props) {
        let currentPath = props.location.pathname;
        let pieces = currentPath.split('/', 4);
        if (pieces.length === 4) {
            return pieces[3];
        }
        return null;
    }
    static urlTicketId(props) {
        let cp = TicketDetails.getChildPath(props);
        if (cp) {
            let ticketId = Number(cp);
            if (ticketId > 0) {
                return ticketId;
            }
        }
        return null;
    }

    displayName = TicketDetails.name

    constructor(props) {
        super(props);
        this.state = {
            showError: false,
            error: '',
            currentReport: {
                ticketDetailsLoaded: false,
                ticket: null,
                ticketComponentStatuses: null
            }
        };
    }

    componentDidMount() {
        let { currentReport } = this.state;
        if (!currentReport.ticket) {
            this.loadTicketDetails();
        }
    }

    loadTicketDetails = () => {
        let ticketId = TicketDetails.urlTicketId(this.props);
        fetch(`SvrIsr/GetTicketDetail?tid=${ticketId}`, { method: 'post', credentials: 'same-origin' })
            .then(response => response.text())
            .then(data => {
                if (data) {
                    data = JSON.parse(data, dateParser);
                    let { currentReport } = this.state;
                    if (data.success) {
                        currentReport.ticket = data.ticket;
                        currentReport.ticketComponentStatuses = data.ticketComponentStatuses;
                        currentReport.ticketDetailsLoaded = true;
                        this.updateReport(currentReport);
                    }
                    else {
                        this.setState({ showError: true, error: data.error });
                    }
                }
            });
    }

    updateReport = (state) => {
        this.setState({ currentReport: state });
    }

    render() {
        const { classes } = this.props;
        const { currentReport } = this.state;
        let body = '';
        if (this.state.showError) {
            body = lang(this.state.error);
        }
        else if (!currentReport.ticket) {
            this.loadTicketDetails();
            body = <CircularProgress />;
        }
        else {
            body = <TicketReportDetails userInfo={this.props.userInfo} currentReport={currentReport} updateReport={this.updateReport} />;
        }
        return (
            <div>
                <div className={classes.headerSection}>
                    <Typography className={classes.heading} variant='h5'>{lang("ticketDetails")}</Typography>
                    <hr className={classes.horizontalBar} />
                </div>
                {body}
            </div>
        );
    }
}

export default withLocation(withStyles(TicketDetails, styles, { withTheme: true }));