import React, { Component } from 'react';
import { lang } from '../../Language';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TicketComponentStatus } from '../../TicketComponentStatus';
import TicketClosedDialog, { TicketState } from './TicketClosedDialog';

const styles = theme => ({
    button: {
        marginLeft: 10
    },
    buttons: {
        width: '100%',
        textAlign: 'right'
    }
});

const DialogType = Object.freeze({
    None: 0,
    Error: 1,
    Position: 2
});

class ReadyToSubmit extends Component {
    displayName = ReadyToSubmit.name

    constructor(props) {
        super(props);
        this.state = {
            dialogType: DialogType.None,
            showDialog: false,
            message: '',
            ticketState: TicketState.None
        };
        let unfinishedPanel = this.findNextUnfinishedComponentAndFlashPanel();
        props.currentSubmission.flashPanel = unfinishedPanel;
        props.updateSubmission(props.currentSubmission);
    }

    findNextUnfinishedComponentAndFlashPanel() {
        let components = this.props.currentSubmission.ticket.components;
        if (components) {
            for (let i = 0; i < components.length; i++) {
                if (components[i].status !== TicketComponentStatus.PublisherSupplied && components[i].status !== TicketComponentStatus.ReadyToTransmit) {
                    return i;
                }
            }
        }
        return -1;
    }

    returnToPreviousStatus = (prevStatus, autoPosition) => {
        let { currentSubmission, updateSubmission, componentIndex } = this.props;
        let tcid = currentSubmission.ticket.components[componentIndex].id;
        fetch(`SvrTicket/ReturnToStatus?tcid=${tcid}&statusId=${prevStatus}&autoPositionFile=${autoPosition}`, { method: "POST", credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    currentSubmission.ticket.components[componentIndex] = data.updatedComponent;
                    updateSubmission(currentSubmission);
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        let error = (data.error) ? data.error : 'unknownError';
                        this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(error) });
                    }
                }
            });
    }

    closeDialog = (result) => {
        if (result === 1) {
            this.returnToPreviousStatus(TicketComponentStatus.ReadyToPosition);
        }
        else if (result === 2) {
            let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
            fetch(`SvrTicket/AutoPosition?tcid=${component.id}`, { method: 'POST', credentials: "same-origin" })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        this.props.currentSubmission.ticket.components[this.props.componentIndex] = data.ticketComponent;
                        this.props.updateSubmission(this.props.currentSubmission);
                    }
                    else {
                        if (data.error === 'isDeleted') {
                            this.setState({ ticketState: TicketState.Deleted });
                        }
                        else if (data.error === 'isSubmitted') {
                            this.setState({ ticketState: TicketState.Submitted });
                        }
                    }
                });
        }
        this.setState({ showDialog: false });
    }

    uploadNewfile = () => {
        this.returnToPreviousStatus(TicketComponentStatus.ReadyToUpload);
    }

    reposition = () => {
        let canAutoPosition = this.props.currentSubmission.ticket.components[this.props.componentIndex].canAutoPosition;
        if (canAutoPosition) {
            this.setState({ showDialog: true, dialogType: DialogType.Position });
        }
        else {
            this.returnToPreviousStatus(TicketComponentStatus.ReadyToPosition, false);
        }
    }

    render() {
        const { classes, currentSubmission } = this.props;
        let newFileText = currentSubmission.ticket.isCreateAnAd ? lang('createNewFile') : lang('uploadNewFile');
        let isIsrUpload = currentSubmission.ticket.isrUploadPageCount;

        let dialogTitle = this.state.dialogType !== DialogType.Position ? lang('error') : lang('confirmAutoPosition');
        let dialogButtons = this.state.dialogType !== DialogType.Position ? (
            <Button onClick={this.closeDialog} autoFocus variant='contained' color='secondary'>{lang('ok')}</Button>
        ) : (
                <div>
                    <Button onClick={() => this.closeDialog(1)} variant='contained' color='primary'>{lang('manuallyPosition')}</Button>
                    <Button style={{ marginLeft: 10 }} onClick={() => this.closeDialog(2)} variant='contained' color='primary'>{lang('autoPosition')}</Button>
                </div>
            )
        let dialogContent = this.state.dialogType !== DialogType.Position ? this.state.message : (
            <Typography variant="body2" component='div'>{lang('thisFileMayBeAutoPositioned')}</Typography>
        );

        return (
            <div className={classes.buttons}>
                <Button className={classes.button} color='primary' variant='contained' onClick={this.uploadNewfile}>{newFileText}</Button>
                {!isIsrUpload ? (
                    <Button className={classes.button} color='primary' variant='contained' onClick={this.reposition}>{lang('reposition')}</Button>
                ): ''}
                <Dialog open={this.state.showDialog} onClose={this.closeDialog} >
                    <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body2">{dialogContent}</Typography>
                    </DialogContent>
                    <DialogActions>
                        {dialogButtons}
                    </DialogActions>
                </Dialog>
                <TicketClosedDialog open={this.state.ticketState !== TicketState.None} isDeleted={this.state.ticketState === TicketState.Deleted} currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission} />
            </div>
        );
    }
}

export default withStyles(ReadyToSubmit, styles);