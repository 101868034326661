import React, { Component } from 'react';
import { lang } from '../../Language';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import InfiniteScroll from 'react-infinite-scroll-component';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ComponentDetails from '../Tracking/ComponentDetails';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import ResubmissionDialog from './ResubmissionDialog';
import ResubmissionNotesDialog from '../Common/ResubmissionNotesDialog';

const styles = theme => ({
    header: {
        fontWeight: 'bold',
        width: 400,
        paddingTop: theme.spacing(1)
    },
    searchMargin: {
        marginRight: 5,
        marginLeft: 5
    },
    searchField: {
        width: 180
    },
    searchAlign: {
        paddingTop: theme.spacing(1),
        textAlign: 'right'
    },
    searchError: {
        color: 'red'
    },
    horizontalBar: {
        borderColor: 'black'
    },
    newContinueButton: {
        marginTop: theme.spacing(1),
        minWidth: 160
    },
    gridMinWidth: {
        minWidth: 300
    },
    neutralColor: {
        color: theme.palette.neutral.contrastText,
        backgroundColor: theme.palette.neutral.main,
        '&:hover': {
            backgroundColor: theme.palette.neutral.dark,
        },
    },
    inlineFlex: {
        display: 'inline-flex'
    }
});

class NewContinue extends Component {
    displayName = NewContinue.name

    constructor(props) {
        super(props);
        this.state = {
            tickets: [],
            isSearch: false,
            searchText: '',
            showLastError: false,
            lastErrorMessage: '',
            resubmissionTicketId: -1
        };
    }

    setResubmissionTicketId = (ticketId) => {
        this.setState({ resubmissionTicketId: ticketId });
    }

    componentDidMount() {
        this.getTickets();
    }

    showErrorDialog = () => {
        return (
            <Dialog open={this.state.showLastError} onClose={this.closeDialog} >
                <DialogTitle id="form-dialog-title">{lang('error')}</DialogTitle>
                <DialogContent>
                    {this.state.lastErrorMessage}
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' autoFocus onClick={this.closeDialog} color="secondary">
                        {lang('ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    closeDialog = () => {
        this.setState({ showLastError: false });
    }

    onTicketDeleted = () => {
        this.getTickets();
    }

    getTickets = (searchText, numberOfTickets) => {
            fetch('SvrTracking/GetTicketsForUser',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    credentials: 'same-origin',
                    body: JSON.stringify({ numberOfTickets, completed: false, searchText })
                })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        this.setState({
                            tickets: data.tickets, totalTickets: data.totalTickets, searchText: searchText, isSearch: searchText && searchText.length > 0
                        });
                    }
                    else {
                        this.setState({ showLastError: true, lastErrorMessage: lang(data.error) });
                    }
                });
    }

    submitSearch = (event) => {
        let form = document.getElementById('searchForm');
        let searchText = document.getElementById('searchText');
        if (searchText.value.length < 3) {
            searchText.setCustomValidity(lang('ticketSearchError'));
        }
        else {
            searchText.setCustomValidity('');
        }
        if (!form.checkValidity()) {
            return;
        }
        event.preventDefault();
        this.getTickets(searchText.value);
    }

    onTextChange = (event) => {
        this.setState({ searchText: event.target.value });
    }

    render() {
        const { classes } = this.props;
        let showSearchReset = this.state.isSearch ? <Button size='small' onClick={(e) => { this.getTickets() }} className={classes.searchMargin} variant='contained' color='secondary' >{lang('reset')}</Button> : '';

        return (
            <div>
                <Grid container columnSpacing={20}>
                    <Grid item xs={2} className={classes.gridMinWidth}>
                        <Typography className={classes.header} variant="h5">{lang("newContinue")}</Typography>
                    </Grid>
                    <Grid className={classes.inlineFlex} item xs={4}>
                        <Button size='small' className={classes.newContinueButton} color="secondary" variant="contained" component={Link} to='/ticket/new'>
                            {lang("startNewTicket")}
                        </Button>
                        {this.props.allowAdResubmission && <ResubmissionDialog currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission} onticketSelectedHandler={(ticketId) => this.setResubmissionTicketId(ticketId)} />}
                        {this.props.allowAdResubmission && <ResubmissionNotesDialog ticketId={this.state.resubmissionTicketId} showDialog={this.state.resubmissionTicketId !== -1} onCloseHandler={() => this.setState({ resubmissionTicketId: -1 })} />}
                    </Grid>
                    <Grid item xs={true}>
                        <div className={classes.searchAlign}>
                            <form id='searchForm'>
                                <FormControl style={{ display: 'inline' }} variant="standard">
                                    <Input name='searchText' id='searchText' type='text' onChange={this.onTextChange} className={classes.searchField} />
                                </FormControl>
                                <Button size='small' name='search' type='submit' onClick={this.submitSearch} className={`${classes.searchMargin} ${classes.neutralColor}`} variant='contained' color='primary'>{lang('search')}</Button>
                                {this.state.isSearch ? '|' : ''}{showSearchReset}
                            </form>
                        </div>
                    </Grid>
                </Grid>
                <hr className={classes.horizontalBar} />
                <InfiniteScroll
                    dataLength={this.state.tickets.length}
                    next={() => this.getTickets(this.state.searchText, this.state.tickets.length + 5)}
                    hasMore={this.state.tickets.length < this.state.totalTickets}
                    loading={<div>{lang('ticketListLoading')}</div>}
                    size={50}>
                    {
                        this.state.tickets.map(ticket => {
                            return <ComponentDetails key={ticket.id} data={ticket} deleteTicketHandler={this.onTicketDeleted} />;
                        })
                    }
                </InfiniteScroll>
            </div>
        );
    }
}

export default withStyles(NewContinue, styles);
