import React, { Component } from 'react';
import { lang } from '../Language';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import green from '@mui/material/colors/green';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const styles = theme => ({
    formFix: {
        width: '100%', //Only needed for IE11
    },
    forIE11: {
        display: 'none',
        //IE11 does not support submit buttons outside of a form via the form property.
        //This is needed to use built in HTML5 form validation.
        //The create button in the dialog calls an invisible button inside the form instead.
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: 'calc(100% - 40px)',
        left: 'calc(100% - 55px)',
        overflow: 'hidden', //Needed for IE11 to stop showing scroll bars
    },
});

class ForgotPassword extends Component {
    displayName = ForgotPassword.name

    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            showingMessage: false,
            lastMessage: '',
        };
    }

    handleSubmit = (e) => {
        if (!this.state.submitting) {
            var form = document.getElementById("forgotPasswordForm");
            if (!form.checkValidity()) {
                return; //Regular submit will happen, causing built in form validation to work.
            }
            e.preventDefault();
            this.setState(
                {
                    submitting: true
                },
                () => {
                    require('formdata-polyfill'); //IE11 needs this for FormData POST to work correctly.
                    var formData = new FormData(form);
                    fetch(`SvrUser/ForgotPassword`, {
                        method: "POST",
                        credentials: 'same-origin',
                        body: formData
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success === true) {
                                this.setState({
                                    submitting: false,
                                    showingMessage: true,
                                    lastMessage: lang('passwordEmailSent')
                                });
                            }
                            else {
                                this.setState({
                                    submitting: false,
                                    lastMessage: lang(data.error),
                                    showingMessage: true
                                });
                            }
                        });
                },
            );
        }
        return false;
    };

    closeMessage = () => {
        this.props.closeDialog();
    };

    render() {
        const { classes } = this.props;
        const { submitting } = this.state;
        const defaultEmailValue = (this.props.defaultEmail) ? this.props.defaultEmail.value : '';

        return (
            <div>
                <Dialog open
                    aria-labelledby='forgot-password-title'
                >
                    <DialogTitle id="forgot-password-title">{lang("forgotPassword")}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body2">{lang("forgotPasswordEnterEmail")}</Typography>
                        <form id="forgotPasswordForm" className={classes.formFix} >
                            <FormControl margin="none" required fullWidth variant="standard">
                                <InputLabel htmlFor="email">{lang("emailAddress")}</InputLabel>
                                <Input autoFocus name="email" type="email" autoComplete="email" inputProps={{ maxLength: "100" }} defaultValue={defaultEmailValue} />
                            </FormControl>
                            <div className={classes.forIE11}>
                                <button id="btnForgotOk" type="submit" onClick={this.handleSubmit} />
                            </div>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => this.props.closeDialog()}
                        >{lang("cancel")}
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            disabled={submitting}
                            onClick={() => document.getElementById("btnForgotOk").click()}
                        >
                            {lang("ok")}
                        </Button>
                        {submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </DialogActions>
                </Dialog>
                <div>
                    <Dialog
                        open={this.state.showingMessage}
                        onClose={this.closeMessage}
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {this.state.lastMessage}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeMessage} color="primary" variant="contained" autoFocus>
                                {lang("ok")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }
}

export default withStyles(ForgotPassword, styles);