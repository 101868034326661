import { lang } from './Language';

export function fetchValueFromQueryString(queryString, value) {
    if (queryString.indexOf(value) < 0) {
        return '';
    }
    else {
        var index = queryString.indexOf(value);
        index = index + value.length;
        let indexOfEndOfValue = queryString.indexOf('&', index);
        if (indexOfEndOfValue === -1) {
            indexOfEndOfValue = queryString.length;
        }
        return queryString.substring(index + 1, indexOfEndOfValue);
    }
}

export function endsWithIgnoreCase(text, searchFor) {
    text = text.toLowerCase();
    searchFor = searchFor.toLowerCase();
    return text.indexOf(searchFor, text.length - searchFor.length) >= 0;
}

export function dateParser(key, value) {
    if (typeof value === 'string') {
        var isDateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(?:Z|(\+|-)([\d|:]*))?$/;
        if (isDateRegex.exec(value)) {
            return new Date(value);
        }
    }
    return value;
}

export function isValidPasswordComplexity(passwordInput) {
    var message = '';
    const specialCharCheck = new RegExp("(?=.*[^A-Za-z0-9])");
    const digitCheck = new RegExp("(?=.*[0-9])"); 
    const upperCaseCheck = new RegExp("(?=.*[A-Z])"); 
    const lowerCaseCheck = new RegExp("(?=.*[a-z])");

    if(!specialCharCheck.test(passwordInput.value)){
        message = lang('passwordSpecialChar') + '\n';
    } 
    if(!digitCheck.test(passwordInput.value)){
        message = message + lang('passwordDigit') + '\n';
    } 
    if(!upperCaseCheck.test(passwordInput.value)){
        message = message + lang('passwordUpperLetter') + '\n';
    } 
    if(!lowerCaseCheck.test(passwordInput.value)){
        message = message + lang('passwordSmallLetter') + '\n';
    }  
    if (passwordInput.value.length > 0 && passwordInput.value.length < 12) {
        message = message + lang('passwordMustBeLength');
    }
    if(message !== ''){
        passwordInput.setCustomValidity(message);
        return false;
    }
    return true;           
}