import React from 'react';
import { lang, langFormat } from '../../Language';
import UserPicker from './UserPicker';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
    heading: {
        fontWeight: 'bold',
        marginTop: theme.spacing(1)
    },
    horizontalBar: {
        borderColor: 'black'
    },
});

class Impersonate extends React.Component {
    displayName = Impersonate.name

    onUserSelected = (userId) => {
        fetch(`SvrISR/Impersonate?userId=${userId}`, { credentials: 'same-origin', method: 'post' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.props.updateUserInfo(data.userInfo);
                    //Intentionally wipe out any tickets that are open and redirect to the ticket page
                    this.props.updateSubmission({ redirectLocation: '/ticket' });
                }
                else {
                    alert(lang(data.error)); //Not wasting a MUI dialog on this since it should never happen and is internal functionality.
                }
            });
    };

    unimpersonate = () => {
        //Clicks the button in the page header so that we don't have duplicate code.
        var unimpButton = document.getElementById("btnUnimpersonate");
        if (unimpButton) {
            unimpButton.click();
        }
    };

    render() {
        const { classes, userInfo } = this.props;
        let impersonator = <UserPicker selectButtonText={lang("impersonateUser")} onUserSelected={this.onUserSelected} />;
        if (userInfo.isImpersonating) {
            impersonator = <Button variant="contained" color="secondary" onClick={() => this.unimpersonate()}>{langFormat("stopImpersonating", userInfo.impersonator)}</Button>;
        }

        return (
            <div>
                <Typography className={classes.heading} variant='h5'>{lang("impersonateUser")}</Typography>
                <hr className={classes.horizontalBar} />
                {impersonator}
            </div>
        );
    }
}

export default withStyles(Impersonate, styles);