import React, { Component } from 'react';
import { lang } from '../Language';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import green from '@mui/material/colors/green';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HelpIconTwoTone from '@mui/icons-material/HelpTwoTone';
import { overrideComponentThemeStyle } from '../StyleCustomization';
import { Link } from 'react-router-dom';

const styles = theme => {
    var result = {
        formFix: {
            width: '100%', //Only needed for IE11
        },
        forIE11: {
            display: 'none',
            //IE11 does not support submit buttons outside of a form via the form property.
            //This is needed to use built in HTML5 form validation.
            //The create button in the dialog calls an invisible button inside the form instead.
        },
        fakeLabel: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            color: 'rgb(0,0,0,0.54)',
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: 'calc(100% - 40px)',
            left: 'calc(100% - 55px)',
            overflow: 'hidden', //Needed for IE11 to stop showing scroll bars
        },
    };
    result = overrideComponentThemeStyle(theme, result, "supportRequest");
    return result;
};

class SupportRequest extends Component {
    displayName = SupportRequest.name

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showDialog: false,
            submitting: false,
            showingMessage: false,
            lastMessage: '',
            requestInfo: null,
        };

        if (props.showDialog) {
            this.showRequestDialog();
        }
    }

    showRequestDialog = () => {
        this.setState({
            loading: true,
        });
        fetch(`SvrHelp/BeginSupportRequest?ticketId=${this.props.ticketId}&requestType=${this.props.requestType}`, { credentials: 'same-origin' } )
            .then(response => response.json())
            .then(data => {
                this.setState({ loading: false, showDialog: true, requestInfo: data });
            });
    }

    closeRequestDialog = () => {
        this.setState({
            showDialog: false
        });
    }

    closeMessage = () => {
        this.setState({
            showingMessage: false
        });
    }

    handleChange = event => {
        var currentRequest = this.state.requestInfo;
        currentRequest.request[event.target.name] = event.target.value;
        this.setState({ requestInfo: currentRequest });
    };

    handleSubmit = (e) => {
        if (!this.state.submitting) {
            var form = document.getElementById("supportRequestForm");
            if (!form.checkValidity()) {
                return; //Regular submit will happen, causing built in form validation to work.
            }
            e.preventDefault();
            this.setState(
                {
                    submitting: true
                },
                () => {
                    require('formdata-polyfill'); //IE11 needs this for FormData POST to work correctly.
                    fetch("SvrHelp/SendSupportRequest", {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/json'
                        },
                        credentials: 'same-origin',
                        body: JSON.stringify(this.state.requestInfo.request)
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success === true) {
                                this.setState({
                                    showDialog: false,
                                    submitting: false,
                                    showingMessage: true,
                                    lastMessage: lang('supportRequestSent')
                                });
                            }
                            else {
                                this.setState({
                                    submitting: false,
                                    lastMessage: lang(data.error),
                                    showingMessage: true
                                });
                            }
                        });
                },
            );
        }
        return false;
    };

    render() {
        const { classes } = this.props;
        const { submitting } = this.state;
        const { requestInfo } = this.state;

        let formContent = '';
        let activationControl = '';
        //It's either going to be showButton or showLink, if you have neither you can't launch the dialog!
        if (this.props.showButton) {
            activationControl = (
                <Button variant="contained" color="primary" data-component="supportRequest" data-classname="helpButton" disabled={this.state.loading} onClick={this.showRequestDialog} >
                  <HelpIconTwoTone />&nbsp;{lang("needHelp")}
                </Button>
            );
        }
        else if (this.props.showLink)
        {
            activationControl = (
                <Link to="#" onClick={this.showRequestDialog}>{lang("haveSpecialistContactMe")}</Link>
            );
        }
        if (requestInfo) {
            let ticketField = '';
            if (requestInfo.request.ticketId) {
                ticketField = <Typography variant="body2" className={classes.fakeLabel}>{lang("ticketId")}: {requestInfo.request.ticketId}</Typography>;
            }
            formContent = (
                <form id="supportRequestForm" className={classes.formFix} >
                    <FormControl margin="none" required fullWidth variant="standard">
                        <InputLabel htmlFor="email">{lang("contactEmail")}</InputLabel>
                        <Input name="email" type="email" value={requestInfo.request.email} onChange={this.handleChange} inputProps={{ maxLength: "100" }} />
                    </FormControl>
                    <FormControl margin="none" fullWidth variant="standard">
                        <InputLabel htmlFor="phone">{lang("contactPhone")}</InputLabel>
                        <Input name="phone" value={requestInfo.request.phone} onChange={this.handleChange} inputProps={{ maxLength: "15" }} />
                    </FormControl>
                    <FormControl margin="none" required fullWidth variant="standard">
                        <InputLabel shrink htmlFor="requestType">{lang("type")}</InputLabel>
                        <Select id="requestType" name="requestType" native value={requestInfo.request.requestType} onChange={this.handleChange}>
                            <option value="">{lang("selectType")}</option>
                            {requestInfo.requestTypes.map(uom => (
                                <option key={uom.value} value={uom.id}>
                                    {uom.value}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    {ticketField}
                    <FormControl required fullWidth variant="filled">
                        <InputLabel htmlFor="description">{lang("description")}</InputLabel>
                        <FilledInput
                            name="description"
                            multiline
                            rows={4}
                            value={requestInfo.request.description}
                            onChange={this.handleChange}
                        />
                    </FormControl>
                    <div className={classes.forIE11}>
                        <button id="btnSubmitSupport" type="submit" onClick={this.handleSubmit} />
                    </div>
                </form>
            );
        }

        return (
            <div>
                {activationControl}
                <Dialog open={this.state.showDialog}
                    aria-labelledby='support-request-title'
                >
                    <DialogTitle id="support-request-title">{lang("supportRequest")}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body2">
                            {lang("forUrgentRequests")} {lang("supportPhoneUsa")}<br />
                            {lang("outsideUScall")} {lang("supportPhoneOutsideUs")}
                        </Typography>
                        {formContent}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.closeRequestDialog}
                        >{lang("cancel")}
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            disabled={submitting}
                            onClick={() => document.getElementById("btnSubmitSupport").click()}
                        >
                            {lang("send")}
                        </Button>
                        {submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </DialogActions>
                </Dialog>
                <div>
                    <Dialog
                        open={this.state.showingMessage}
                        onClose={this.closeMessage}
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {this.state.lastMessage}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeMessage} color="secondary" variant="contained" autoFocus>
                                {lang("ok")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }
}

export default withStyles(SupportRequest, styles);