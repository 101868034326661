import React from 'react';
import { lang, langFormat } from '../Language';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import { getComponentThemeProperty } from '../StyleCustomization';
import { overrideComponentThemeStyle } from '../StyleCustomization';
import classNames from 'classnames'
import FormControlLabel from '@mui/material/FormControlLabel';
import InfoIcon from '@mui/icons-material/Info';
import CustomTooltip from './Common/CustomTooltip';

const styles = theme => {
    var result = {
        header: {
            flex: '1 100%',
        },
        logo: {
            float: 'left',
        },
        password: {
            color: 'red',
            fontWeight: "bold",
        },
        separated: {
            paddingLeft: '8px',
            paddingRight: '8px',
        },
        linkButton: {
            verticalAlign: 'baseline',
            marginLeft: '3px',
            padding: '1px 8px',
            textDecoration: 'underline',
            color: getComponentThemeProperty("mainLayout", "headerLinkColor"),
        },
        userActions: {
            float: 'right',
            padding: '5px',
            '& p': {
                color: getComponentThemeProperty("mainLayout", "headerForegroundColor"),
            },
            '& p a': {
                color: getComponentThemeProperty("mainLayout", "headerLinkColor"),
            },
            '& p a:visited': {
                color: getComponentThemeProperty("mainLayout", "headerLinkColor"),
            },
        },
    };

    result = overrideComponentThemeStyle(theme, result, "header");
    return result;
};

class Header extends React.Component {
    displayName = Header.name

    unimpersonate = () => {
        const { userInfo } = this.props;
        if (userInfo.isImpersonating) {
            fetch('SvrISR/EndImpersonation', { credentials: 'same-origin' })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        this.props.updateUserInfo(data.userInfo);
                        //Intentionally wipe out any tickets that are open and redirect to the ISR page
                        //You can't keep working on someone else's ticket if you're not impersonating them
                        this.props.updateSubmission({ redirectLocation: '/isr' });
                    }
                    else {
                        this.props.showBackgroundDialog(lang(data.error));
                    }
                });
        }
    };

    render() {
        const { classes, userInfo } = this.props;
        var userBlock = <span className={classes.separated}>{userInfo.fullName}</span>;
        var passwordChange = <span className={classNames(classes.separated, classes.password)}>{langFormat("passwordExpiredSoon", userInfo.passwordExpirationDays)}</span>;
        let toolTipText =
            <ul>
                <div>{lang("passwordPolicyChanged")}</div>
                <li>{lang("passwordMustBeLength")} </li>
                <li>{lang("passwordDigit")} </li>
                <li>{lang("passwordSpecialChar")}</li>
                <li>{lang("passwordUpperLetter")}</li>
                <li>{lang("passwordExpiration")}</li>
            </ul>

        if (userInfo.isImpersonating) {
            userBlock = <React.Fragment>
                <span className={classes.separated}>{userInfo.fullName} {lang("isImpersonating")}</span> |
                <Button id="btnUnimpersonate" className={classes.linkButton} variant="text" size="small" onClick={() => this.unimpersonate()}>{langFormat("stopImpersonating", userInfo.impersonator)}</Button>
            </React.Fragment>;
        }
        return (
            <div className={classes.header}>
                <img src="Branding/GetImage?fileType=logo" alt="AdShuttle" className={classes.logo} />
                <div className={classes.userActions} >
                    <Typography variant="body2">

                        {(userInfo.passwordExpirationDays) > 0 &&
                            <React.Fragment>
                                {passwordChange}
                                < CustomTooltip
                                    placement="top-start"
                                    title={
                                        <React.Fragment>
                                            <Typography color="inherit">{toolTipText}</Typography>
                                        </React.Fragment>
                                    }
                                >
                                    <FormControlLabel control={<InfoIcon value="passwordInfo" style={{ marginRight: "-15px", marginLeft: "10px", fill: "#315778" }}></InfoIcon>} label={<Typography style={{ fontWeight: "bold" }}></Typography>} />
                                </CustomTooltip >
                            </React.Fragment>
                        }
                        {userBlock} |
                        <span className={classes.separated}><Link to="/logout">{lang("logout")}</Link></span>
                    </Typography>
                </div>
            </div >
        );
    }
}
export default withStyles(Header, styles, { withTheme: true });