import React, { Component } from 'react';
import { lang, langFormat } from '../../Language';
import { ProofIncluded } from '../../ProofIncluded';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FilledInput from '@mui/material/FilledInput';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TicketDetailsCustom from './TicketDetailsCustom';
import SizeGroupPicker from './SizeGroupPicker';
import SizePreviewRow from './SizePreviewRow';
import { Link } from 'react-router-dom';
import Map from '@mui/icons-material/Map';
import CustomTooltip from '../Common/CustomTooltip';

const styles = theme => ({
    flexibleRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        '& div:not(:first-of-type)': {
            paddingLeft: theme.spacing(1),
        },
        '& div:not(:last-of-type)': {
            paddingRight: theme.spacing(1),
        }
    },
    widthLimited: {
        maxWidth: '700px',
    },
    autoFitColumn: {
        flexShrink: '0',
        marginTop: 'auto',
    },
    fillRemainderColumn: {
        flex: '1 0 auto',
    },
    checkboxPad: {
        paddingTop: '8px',
        paddingBottom: '8px',
    },
    adSizePreivew: {
        padding: theme.spacing(1),
    },
    fakeLabel: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontSize: '0.75rem',
        width: '100%',
        color: 'rgba(0, 0, 0, 0.54)',
        paddingTop: theme.spacing(1),
    },
    fakeInput: {
        fontSize: '1rem',
        width: '100%',
    },
    adSpecsUrlSection: {
        maxWidth: 700
    },
    adSpecs: {
        float: 'right'
    },
    proofDeliveryDetailsSection: {
        maxWidth: 700
    },
    proofDeliveryDetails: {
        float: 'right'
    },
    italicized: {
        fontStyle: 'italic'
    },
    animationMessage: {
        fontWeight: 'bold',
        paddingTop: 10,
        paddingBottom: 10
    }
});

const DialogType = Object.freeze({
    NONE: 0,
    SEND_PROOF: 1,
    REQUEST_PROOF: 2,
    WAIVE_PROOF: 3,
    ERROR: 4,
    PROOF_DELIVERY_DETAILS: 5
});

const NOTES_CHAR_LIMIT = 255;

export const ISR_UPLOAD = 'isrUpload';

class TicketDetailsPrint extends Component {
    displayName = TicketDetailsPrint.name

    constructor(props) {
        super(props);
        this.state = {
            showingDialog: false,
            dialogType: DialogType.NONE,
            dialogMessage: '',
        };
    }

    getSelectedSize = () => {
        let { currentSubmission } = this.props;
        if (currentSubmission.ticket.sizeGroupId || currentSubmission.ticket.sizeId) {
            for (var size in currentSubmission.adSizes) {
                let sizeGroupId = Number(currentSubmission.ticket.sizeGroupId);
                let sizeId = Number(currentSubmission.ticket.sizeId);
                if ((sizeGroupId > 0 && currentSubmission.adSizes[size].id === sizeGroupId) ||
                    (sizeId > 0 && currentSubmission.adSizes[size].sizeId === sizeId)) {
                    return currentSubmission.adSizes[size];
                }
            }
        }
        return null;
    };

    handleChange = (event) => {
        let { currentSubmission } = this.props;

        if (event.target.type === 'checkbox') {
            currentSubmission.ticket[event.target.value] = event.target.checked;
            if (event.target.value === 'isCreateAnAd' || event.target.value === 'isInsertWorkflow') {
                currentSubmission.sizeGroup = '';
                currentSubmission.ticket.sizeId = null;
                currentSubmission.ticket.sizeGroupId = null;
                currentSubmission.ticket.isrUploadPageCount = null;
                currentSubmission.groupSizes = null;
            }
            if (event.target.value === 'isInsertWorkflow') {
                this.props.loadAdSizes(currentSubmission);
            }
        }
        else if (event.target.name === 'sizeGroup') {
            currentSubmission.sizeGroup = event.target.value;
            if (currentSubmission.sizeGroup === ISR_UPLOAD) {
                currentSubmission.ticket.sizeId = null;
                currentSubmission.ticket.sizeGroupId = null;
                currentSubmission.ticket.isrUploadPageCount = "1";
            }
            else {
                var results = currentSubmission.sizeGroup.split('_');
                currentSubmission.ticket.sizeGroupId = results[0];
                currentSubmission.ticket.sizeId = results[1];
                if (currentSubmission.ticket.sizeId === 'null') {
                    currentSubmission.ticket.sizeId = null;
                }
                currentSubmission.ticket.isrUploadPageCount = null;
                currentSubmission.editorInfo = null; //On size change, clear any cached Ad Creation editor settings.
            }
            currentSubmission.groupSizes = null; //If it's a group, we need to fetch this again. If not, it should be null.
            if (currentSubmission.ticket.sizeId > 0 || currentSubmission.ticket.isrUploadPageCount > 0) {
                currentSubmission.multipleSizes = false;
            }
            else {
                currentSubmission.multipleSizes = true;
                this.loadGroupSizes(currentSubmission);
            }
        }
        else {
            currentSubmission.ticket[event.target.name] = event.target.value;
        }
        if (event.target.name === 'proofIncluded') {
            //Only 3 of the options have custom logic
            if (currentSubmission.ticket.proofIncluded && currentSubmission.ticket.proofIncluded.length > 0) {
                currentSubmission.ticket.proofIncluded = Number(currentSubmission.ticket.proofIncluded);
            }
            if (currentSubmission.ticket.proofIncluded === 3000) {
                //Send Proof
                this.setState({
                    showingDialog: true,
                    dialogType: DialogType.SEND_PROOF,
                });
            }
            else if (currentSubmission.ticket.proofIncluded === 3001) {
                //Request Proof - Display a dialog if CyberSource is not enabled. (If it is, they get prompted later)
                if (!currentSubmission.canOrderProofs) {
                    this.setState({
                        showingDialog: true,
                        dialogType: DialogType.REQUEST_PROOF,
                    });
                }
            }
            else if (currentSubmission.ticket.proofIncluded === 3002) {
                //Waive Proof dialog
                this.setState({
                    showingDialog: true,
                    dialogType: DialogType.WAIVE_PROOF,
                });
            }
        }

        this.props.updateSubmission(currentSubmission);
    };

    loadGroupSizes = (currentSubmission) => {
        let publisherId = currentSubmission.ticket.publisherId;
        let publicationId = currentSubmission.ticket.publicationId;
        let issueId = currentSubmission.ticket.issueId;
        if ((currentSubmission.allowInsertsWorkflow && currentSubmission.ticket.isInsertWorkflow && !currentSubmission.selectedPublication.hasAssociations) ||
            (currentSubmission.allowInsertsWorkflow && !currentSubmission.ticket.isInsertWorkflow && currentSubmission.selectedPublication.hasAssociations)) {
            publicationId = currentSubmission.selectedPublication.associatedPublicationId;
            issueId = currentSubmission.selectedIssue.associatedIssueId;
        }
        let groupId = currentSubmission.ticket.sizeGroupId;
        fetch(`SvrTicket/SizeGroupList?publisherId=${publisherId}&publicationId=${publicationId}&issueId=${issueId}&groupId=${groupId}`, { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    let { currentSubmission } = this.props;
                    currentSubmission.groupSizes = data.groupSizes;
                    currentSubmission.sameFoldingDummy = data.sameFoldingDummy;
                    this.props.updateSubmission(currentSubmission);
                }
                else {
                    this.setState({
                        showingDialog: true,
                        dialogType: DialogType.ERROR,
                        dialogMessage: lang(data.error)
                    });
                }
            });
    };

    okDialog = () => {
        this.setState({
            showingDialog: false,
            dialogType: DialogType.NONE,
        });
    };

    cancelDialog = () => {
        if (this.state.dialogType === DialogType.SEND_PROOF ||
            this.state.dialogType === DialogType.REQUEST_PROOF ||
            this.state.dialogType === DialogType.WAIVE_PROOF) {
            let { currentSubmission } = this.props;
            //Revert to default
            if (currentSubmission.proofDefault > 0) {
                currentSubmission.ticket.proofIncluded = currentSubmission.proofDefault;
            }
            else {
                currentSubmission.ticket.proofIncluded = '';
            }
            this.props.updateSubmission(currentSubmission);
        }
        this.setState({
            showingDialog: false,
            dialogType: DialogType.NONE,
        });
    };

    buildDetailsBody = () => {
        let { currentSubmission } = this.props;
        const { classes } = this.props;
        let detailsBody = '';
        if (!currentSubmission.adSizes) {
            //AdSizes is loaded in the parent component because it needs to change when you switch issues
            detailsBody = <LinearProgress variant="query" />;
        }
        else {
            detailsBody = [];
            let isrUploadOption = '';
            if (currentSubmission.allowIsrUpload && !currentSubmission.ticket.isCreateAnAd) {
                isrUploadOption = <option value={ISR_UPLOAD}>{lang("isrUpload")}</option>;
            }
            let adSize = <FormControl key="adSize" className={classes.widthLimited} margin="dense" required fullWidth variant="standard">
                <InputLabel shrink htmlFor="sizeGroup">{lang("adSize")}</InputLabel>
                <Select native id="sizeGroup" name="sizeGroup" displayEmpty value={currentSubmission.sizeGroup} onChange={this.handleChange} >
                    <option value="">{lang("selectAdSize")}</option>
                    {isrUploadOption}
                    {currentSubmission.adSizes.map(adSize => {
                        if (!currentSubmission.ticket.isCreateAnAd) {
                            return <option key={`${adSize.id}_${adSize.sizeId}`} value={`${adSize.id}_${adSize.sizeId}`}>
                                {adSize.name}
                            </option>;
                        }
                        return ''; //Just here to prevent a warning about no return value
                    })}
                </Select>
            </FormControl>;
            let insertsCheckbox =
                <CustomTooltip
                    placement="top-start"
                    title={
                        <React.Fragment>
                            <Typography color="inherit">{lang("insertsTooltip")}</Typography>
                        </React.Fragment>
                    }
                >
                    <FormControlLabel control={<Checkbox checked={currentSubmission.ticket.isInsertWorkflow} value="isInsertWorkflow" className={classes.checkboxPad} onChange={this.handleChange} />} label={<Typography style={{ fontWeight: "bold" }}>{lang("inserts")}</Typography>} />
                </CustomTooltip>;
            if (currentSubmission.hasCreateSizes && !currentSubmission.ticket.isInsertWorkflow) {
                detailsBody.push(<div key="adSizeRow" className={`${classes.flexibleRow} ${classes.widthLimited}`}>
                    <div className={classes.fillRemainderColumn}>{adSize}</div>
                    <div className={classes.autoFitColumn}>
                        <CustomTooltip
                            placement="top-start"
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">{lang("createAnAdTooltip")}</Typography>
                                </React.Fragment>
                            }
                        >
                            <FormControlLabel control={<Checkbox checked={currentSubmission.ticket.isCreateAnAd} value="isCreateAnAd" className={classes.checkboxPad} onChange={this.handleChange} />} label={<Typography style={{ fontWeight: "bold" }}>{lang("createAnAd")}</Typography>} />
                        </CustomTooltip>
                        {currentSubmission.allowInsertsWorkflow && !currentSubmission.ticket.isCreateAnAd &&
                            (currentSubmission.selectedPublication.hasAssociations ||
                                (currentSubmission.selectedPublication.associatedPublicationId && currentSubmission.selectedIssue.associatedIssueId)) &&
                            insertsCheckbox
                        }
                    </div>
                </div>);
            }
            else if (currentSubmission.allowInsertsWorkflow && !currentSubmission.ticket.isCreateAnAd &&
                (currentSubmission.selectedPublication.hasAssociations ||
                    (currentSubmission.selectedPublication.associatedPublicationId && currentSubmission.selectedIssue.associatedIssueId))) {
                detailsBody.push(<div key="adSizeRow" className={`${classes.flexibleRow} ${classes.widthLimited}`}>
                    <div className={classes.fillRemainderColumn}>{adSize}</div>
                    <div className={classes.autoFitColumn}>
                        {insertsCheckbox}
                    </div>
                </div>);
            }
            else {
                detailsBody.push(adSize);
            }
            if (currentSubmission.selectedPublication && currentSubmission.selectedPublication.adSpecsUrl && currentSubmission.selectedPublication.adSpecsUrl.trim() !== '') {
                detailsBody.push(<div key='adSpecUrl' className={classes.adSpecsUrlSection}><a className={classes.adSpecs} target='_blank' rel='noopener noreferrer' href={currentSubmission.selectedPublication.adSpecsUrl}>{lang('adSpecs')}</a></div>)
            }
            let selectedSize = this.getSelectedSize();
            if (currentSubmission.ticket.sizeGroupId) {
                if (currentSubmission.multipleSizes) {
                    if (currentSubmission.groupSizes) {
                        detailsBody.push(
                            <React.Fragment key="groupSizes">
                                <div className={classes.fakeLabel}>{lang("adFilePreview")}</div>
                                <Typography variant="body2" className={classes.fakeInput}>{langFormat("possibleAdSections", selectedSize.name)}</Typography>
                                <SizeGroupPicker currentSubmission={currentSubmission} updateSubmission={this.props.updateSubmission} />
                            </React.Fragment>
                        );
                    }
                    else {
                        detailsBody.push(<LinearProgress key="groupSizes" variant="query" />);
                    }
                }
            }
            if (selectedSize && selectedSize.details) {
                detailsBody.push(<SizePreviewRow key={`sizePreview${selectedSize.details.id}`} size={selectedSize.details} className={classes.adSizePreivew} />);
            }
            let hasAnimation = false;
            if (currentSubmission.groupSizes && currentSubmission.groupSizes !== null) {
                for (let i = 0; i < currentSubmission.groupSizes.length; i++) {
                    if (currentSubmission.groupSizes[i].id === currentSubmission.ticket.sizeId) {
                        hasAnimation = currentSubmission.groupSizes[i].impositionId !== null;
                        break;
                    }
                }
            }
            else if (selectedSize !== null && currentSubmission.ticket.sizeId !== null) {
                hasAnimation = selectedSize.hasAnimation;
            }
            if (hasAnimation) {
                detailsBody.push(<Typography key='animationMessage' className={classes.animationMessage}>
                    {lang('animationMessagePart1')}<Map color='primary' />{lang('animationMessagePart2')}
                    <br />
                    {lang('animationMessagePart3')}<span className={classes.italicized}>{lang('animationMessagePart4')}</span>
                </Typography>);
            }
            if (currentSubmission.ticket.isrUploadPageCount > 0) {
                let pageOptions = [];
                for (var i = 1; i <= 8; i++) {
                    pageOptions.push(<option key={i} value={i}>{i.toString()}</option>);
                }
                detailsBody.push(<FormControl key="isrUploadPageCount" className={classes.widthLimited} margin="dense" required fullWidth variant="standard">
                    <InputLabel shrink htmlFor="isrUploadPageCount">{lang("numberOfPages")}</InputLabel>
                    <Select native id="isrUploadPageCount" name="isrUploadPageCount" value={currentSubmission.ticket.isrUploadPageCount} onChange={this.handleChange} >
                        {pageOptions}
                    </Select>
                </FormControl>);
            }
            if (currentSubmission.ticket.sizeId || currentSubmission.ticket.isrUploadPageCount > 0) {

                detailsBody.push(<FormControl key="proofOptions" className={classes.widthLimited} style={{ display: currentSubmission.showProofOptions ? 'inline-flex' : 'none' }} margin="dense" required={currentSubmission.showProofOptions} fullWidth variant="standard">
                    <InputLabel shrink htmlFor="proofIncluded">{lang("proofOptions")}</InputLabel>
                    <Select native id="proofIncluded" name="proofIncluded" value={currentSubmission.ticket.proofIncluded} onChange={this.handleChange} >
                        <option value="">{lang("selectProofOption")}</option>
                        {currentSubmission.proofOptions.map(proof => (
                            <option key={proof.name} value={proof.id}>
                                {lang(proof.name)}
                            </option>
                        ))}
                    </Select>
                </FormControl>);

                let showProofDeliveryDetails = true;
                for (let i = 0; i < currentSubmission.proofOptions.length; i++) {
                    if (currentSubmission.proofOptions[i].id === ProofIncluded.Virtual) {
                        showProofDeliveryDetails = false;
                        break;
                    }
                }

                if (showProofDeliveryDetails && currentSubmission.selectedPublication.isrName && currentSubmission.selectedPublication.isrName.trim() !== '' && currentSubmission.selectedPublication.isrEmailAddress && currentSubmission.selectedPublication.isrEmailAddress.trim() !== '' &&
                    currentSubmission.selectedPublication.proofDeliveryAddress && currentSubmission.selectedPublication.proofDeliveryAddress.trim() !== '') {
                    detailsBody.push(<div key='proofDeliveryDetails' className={classes.proofDeliveryDetailsSection}><Link to='#' onClick={() => this.setState({ showingDialog: true, dialogType: DialogType.PROOF_DELIVERY_DETAILS })} className={classes.proofDeliveryDetails}>{lang('proofDeliveryDetails')}</Link></div>)
                }

                if (currentSubmission.inlineProofMessage && currentSubmission.inlineProofMessage.length > 0) {
                    detailsBody.push(<Typography key="inlineProofMessage">{currentSubmission.inlineProofMessage}</Typography>);
                }
                if (currentSubmission.ticket.proofIncluded === 3004 && lang("redirectToPublisherDesc").length > 1) {
                    //Request proof from Publisher selected
                    detailsBody.push(<Typography key="requestPublisherMessage">
                        {React.createElement("span", { dangerouslySetInnerHTML: { __html: lang("redirectToPublisherDesc") } })}
                    </Typography>);
                }
                if (currentSubmission.canSetBillable) {
                    detailsBody.push(<FormControlLabel key="setIsBillable"
                        control={
                            <Checkbox
                                checked={currentSubmission.ticket.isBillable}
                                value="isBillable"
                                onChange={this.handleChange}
                            />
                        }
                        label={lang("isBillable")}
                    />);
                }
                detailsBody.push(<TicketDetailsCustom key="customDetails" currentSubmission={currentSubmission} updateSubmission={this.props.updateSubmission} />);

                let numOfNewLineChars = 0;
                for (let i = 0; i < currentSubmission.ticket.notes.length; i++) {
                    if (currentSubmission.ticket.notes[i] === '\n') {
                        numOfNewLineChars += 2;
                    }
                }

                detailsBody.push(<FormControl key="notes" className={classes.widthLimited} fullWidth variant="filled">
                    <InputLabel htmlFor="detailsNotes">{lang("notes")}</InputLabel>
                    <FilledInput
                        id="detailsNotes"
                        name="notes"
                        multiline
                        rows={3}
                        value={currentSubmission.ticket.notes}
                        onChange={this.handleChange}
                        inputProps={{ maxLength: (NOTES_CHAR_LIMIT - numOfNewLineChars).toString() }}
                    />
                </FormControl>);
            }
        }
        return detailsBody;
    };

    getDialogContent = () => {
        let dialogContent = '';
        let { currentSubmission } = this.props;
        if (this.state.showingDialog) {
            if (this.state.dialogType === DialogType.REQUEST_PROOF) {
                dialogContent = <Typography variant="body2">{lang("requestProofWarning")}</Typography>;
            }
            else if (this.state.dialogType === DialogType.SEND_PROOF) {
                let top = '';
                let middle = '';
                let bottom = '';
                if (currentSubmission.proofCertType === "SWOP") {
                    top = <React.Fragment>
                        <Typography variant="body2"><b>{lang("sendProofMessageOne")}</b><br /></Typography>
                        <Typography variant="body2">{lang("sendProofClickLinkToSeeList")}<br /></Typography>
                    </React.Fragment>;
                    if (currentSubmission.proofCertUrl && currentSubmission.proofCertUrl.length > 0) {
                        middle = <Typography variant="body2"><br /><a href={currentSubmission.proofCertUrl} target="_blank" rel="noopener noreferrer">{currentSubmission.proofCertUrl}</a></Typography>;
                    }
                }
                else if (currentSubmission.proofCertType === "SNAP") {
                    top = <Typography variant="body2">{lang("sendProofSnapMessage")}</Typography>;
                }
                if (currentSubmission.sendProofAddress && currentSubmission.sendProofAddress.length > 0) {
                    bottom = <Typography variant="body2">{lang("sendProofsTo")} {currentSubmission.sendProofAddress}</Typography>;
                }
                dialogContent = <React.Fragment>{top}{middle}{bottom}</React.Fragment>;
            }
            else if (this.state.dialogType === DialogType.WAIVE_PROOF) {
                dialogContent = <Typography variant="body2">{lang("proofWaiverMessage")}</Typography>;
            }
            else if (this.state.dialogType === DialogType.ERROR) {
                dialogContent = <Typography variant="body2">{lang(this.state.dialogMessage)}</Typography>;
            }
            else if (this.state.dialogType === DialogType.PROOF_DELIVERY_DETAILS) {
                dialogContent = (
                    <div>
                        {currentSubmission.selectedPublication.proofDeliveryRecipient.trim() !== '' ? <Typography style={{ textAlign: 'center' }} component='div'>{currentSubmission.selectedPublication.proofDeliveryRecipient}</Typography> : ''}
                        {currentSubmission.selectedPublication.isProofEmailVisible && currentSubmission.selectedPublication.isrName.trim() !== '' && currentSubmission.selectedPublication.isrEmailAddress.trim() !== '' ? <Typography style={{ textAlign: 'center' }} component='div'>{`${lang('contact')} `}<a href={`mailto:${currentSubmission.selectedPublication.isrEmailAddress}`}>{currentSubmission.selectedPublication.isrName}</a></Typography> : ''}
                        <Typography style={{ textAlign: 'center' }} component='div'>{this.generateProofDeliveryAddress(currentSubmission.selectedPublication.proofDeliveryAddress)}</Typography>
                    </div>);
            }
        }
        return dialogContent;
    };

    getDialogTitle = () => {
        if (this.state.dialogType === DialogType.PROOF_DELIVERY_DETAILS) {
            return <DialogTitle>{lang('proofDeliveryDetails')}</DialogTitle>;
        }
        return '';
    }

    generateProofDeliveryAddress = (proofDeliveryAddress) => {
        let addressArray = proofDeliveryAddress.split('<br/>');
        return addressArray.map((x, index) => <React.Fragment key={index}><div style={{ whiteSpace: 'nowrap' }}>{x}</div></React.Fragment>);
    }

    render() {
        let detailsBody = this.buildDetailsBody();
        let dialogText = this.getDialogContent();
        let dialogTitle = this.getDialogTitle();
        let cancelButton = '';
        if (this.state.dialogType !== DialogType.ERROR && this.state.dialogType !== DialogType.PROOF_DELIVERY_DETAILS) {
            cancelButton = <Button onClick={this.cancelDialog} variant="contained" color="primary">
                {lang("cancel")}
            </Button>;
        }

        return (
            <React.Fragment>
                {detailsBody}
                <Dialog
                    open={this.state.showingDialog}
                    aria-describedby="message-dialog-description"
                >
                    {dialogTitle}
                    <DialogContent id="message-dialog-description">
                        {dialogText}
                    </DialogContent>
                    <DialogActions>
                        {cancelButton}
                        <Button onClick={this.okDialog} variant="contained" color="secondary" autoFocus>
                            {lang("ok")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(TicketDetailsPrint, styles);