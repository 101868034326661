import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import green from '@mui/material/colors/green';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import { lang } from '../Language';
import SupportRequest from './SupportRequest';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { overrideComponentThemeStyle } from '../StyleCustomization';
import { getComponentThemeProperty } from '../StyleCustomization';
import { isValidPasswordComplexity } from '../HelperMethods';
import { withLocation } from '../withLocation';

const styles = theme => {
    var result = {
        contentWrapper: {
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
        },
        header: {
            background: '#FFF',
        },
        loginBody: {
            flex: '1 0 auto',
            background: '#ABC',
        },
        logo: {
            textAlign: 'center',
            marginTop: theme.spacing(3),
            '& img': { height: 'inherit' },
        },
        layout: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
        paper: {
            minWidth: '350px',
            maxWidth: '400px',
            marginTop: theme.spacing(4),
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(1)}`,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            paddingTop: '6px',
        },
        submitWrapper: {
            margin: theme.spacing(1),
            textAlign: 'center',
            display: 'block',
        },
        submit: {
            paddingLeft: '64px',
            paddingRight: '64px',
        },
        buttonProgress: {
            color: green[500],
            position: 'relative',
            top: '8px',
            left: '-95px',
            marginTop: 0,
            marginRight: 0,
        },
        helpBelowLogin: {
            textAlign: 'center',
            paddingTop: theme.spacing(6),
        },
        bottomBar: {
            flexShrink: '0',
            textAlign: 'center',
            padding: '1em',
            '& p': {
                color: getComponentThemeProperty("login", "footerForegroundColor"),
            },
            '& p a': {
                color: getComponentThemeProperty("login", "footerLinkColor"),
            },
            '& p a:visited': {
                color: getComponentThemeProperty("login", "footerLinkColor"),
            },
        },
        bottomSegment: {
            paddingLeft: '0.5em',
            paddingRight: '0.5em',
        }
    };
    
    result = overrideComponentThemeStyle(theme, result, "login");
    result.logo.paddingRight = 0;
    let loginBg = getComponentThemeProperty("login", "background");
    if (loginBg && loginBg.length > 0) {
        document.body.style.background = loginBg;
    }
    else {
        document.body.style.background = result.loginBody.background;
    }
    return result;
};

const DialogType = Object.freeze({
    None: 0,
    Error: 1
});

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            dialogType: DialogType.None,
            showDialog: false,
            message: '',
            passwordReset: false,
            redirectToLogin: false
        };

        fetch('SvrUser/IsPasswordResetLinkValid' + props.location.search, { method: 'POST', credentials: "same-origin" })
            .then(response => response.json())
            .then(data => {
                if (!data.isValid) {
                    this.setState({ redirectToLogin: true });
                }
            });
    }

    buildLoginBox = () => {
        const { classes } = this.props;
        const { loading } = this.state;
        const { message } = this.props.location.state ?? "";

        return <Paper className={classes.paper}>
            <Typography variant="subtitle2">
                <strong>{message} {lang("enterNewPassword")}</strong>
            </Typography>
            <form id="newPasswordForm" className={classes.form}>
                <FormControl margin="dense" required fullWidth variant="standard">
                    <InputLabel htmlFor="password">{lang("password")}</InputLabel>
                    <Input
                        id="password"
                        name="password"
                        type="password"
                        autoFocus
                        onChange={this.validatePassword} />
                </FormControl>

                <FormControl margin="dense" required fullWidth variant="standard">
                    <InputLabel htmlFor="confirmPassword">{lang("newPasswordConfirmPassword")}</InputLabel>
                    <Input
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        onChange={this.validatePasswordMatch}
                    />
                </FormControl>

                <span className={classes.submitWrapper}>
                    {loading && <CircularProgress size={24} style={{ visibility: 'hidden' }} /> /* Extra spinner keeps submit button centered. */}
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                        data-component="login"
                        data-classname="submit"
                        className={classes.submit}
                        onClick={this.submitNewPassword}
                    >
                        {lang("reset")}
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </span>
            </form>
            <p />
            <div>
                <Link to='/logout'>{lang('returnToLoginPage')}</Link>
            </div>
        </Paper>;
    };

    validatePassword = (event) => {
        if (isValidPasswordComplexity(event.target)) {
            event.target.setCustomValidity('');
        }
    };

    validatePasswordMatch = (event) => {
        var password = document.getElementById('password');
        if (password.value !== event.target.value) {
            event.target.setCustomValidity(lang('passwordMismatch') + '\n'); ;
        }
        else {
            event.target.setCustomValidity('');
        }      
    };

    submitNewPassword = (e) => {
        var form = document.getElementById("newPasswordForm");
        var password = document.getElementById('password');
        var confirmPassword = document.getElementById('confirmPassword');

        if (password.value === '' || confirmPassword.value === '') {
            return;
        }
        if (!isValidPasswordComplexity(password)) {
            return;
        }
        if (password.value !== confirmPassword.value) {
            confirmPassword.setCustomValidity(lang('passwordMismatch') + '\n'); ;
        }
        else {           
            confirmPassword.setCustomValidity('');
        }
        if (!form.checkValidity()) {
            return; //Regular submit will happen, causing built in form validation to work.
        }
        e.preventDefault();
        if (password.value !== confirmPassword.value) {
            this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang('confirmPasswordNoMatch') });
            return;
        }
        fetch('SvrUser/UpdatePassword' + this.props.location.search, {
            method: 'POST',
            credentials: "same-origin",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ password1: password.value, password2: confirmPassword.value })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.setState({ passwordReset: true });
                }
                else {
                    this.setState({ showDialog: true, message: lang(data.error), dialogType: DialogType.Error });
                }
            });
    }

    closeDialog = () => {
        this.setState({ showDialog: false });
    }

    render() {
        const { classes } = this.props;

        let header = '';
        let logo = '';
        let loginBox = '';
        if (getComponentThemeProperty("login", "hasHeader") === "True") {
            header = <div className={classes.header}>
                <img className={classes.headerLogo} data-component="login" data-classname="headerLogo" src="branding/GetImage?fileType=loginLogo" alt="AdShuttle" />
            </div>;
        }
        else {
            logo = <div className={classes.logo} data-component="login" data-classname="logo">
                <img data-component="login" data-classname="logo" src="branding/GetImage?fileType=loginLogo" alt="AdShuttle" />
            </div>;
        }

        loginBox = <React.Fragment>
            <div className={classes.layout}>{this.buildLoginBox()}</div>
            <div className={classes.helpBelowLogin}><SupportRequest showButton /></div>
        </React.Fragment>;

        return (
            <div className={classes.contentWrapper}>
                {this.state.passwordReset ? (
                    <Navigate to='/ticket' replace />
                ) : ''}
                {this.state.redirectToLogin ? (
                    <Navigate to={{ pathname: '/', state: {showError: true, lastError: 'resetPasswordLinkInvalid'} }} replace />
                ): ''}
                {header}
                <div className={classes.loginBody}>
                    <div className={classes.spaceAboveLogo} />
                    {logo}
                    {loginBox}
                </div>

                <div className={classes.bottomBar}>
                    <Typography variant="body2">
                        <span className={classes.bottomSegment}>{lang("copyright")} {lang("workflowSolutions")}</span>
                        <span className={classes.bottomSegment}><Link to="/terms" target="_blank">{lang("terms")}</Link></span>
                        <span className={classes.bottomSegment}><Link to="/privacy" target="_blank">{lang("privacyPolicy")}</Link></span>
                    </Typography>
                </div>
                <Dialog open={this.state.showDialog} onClose={this.closeDialog}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} variant="contained" color="secondary" autoFocus>
                            {lang("ok")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withLocation(withStyles(ResetPassword, styles, { withTheme: true }));