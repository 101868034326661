import React, { Component } from 'react';
import { lang } from '../Language';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import SupportRequest from './SupportRequest';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getComponentThemeProperty } from '../StyleCustomization';
import { withLocation } from '../withLocation';

const styles = theme => ({
    table: { borderBottomWidth: 0},
    heading: { fontWeight: 'bold', marginTop: theme.spacing(1) },
    head: { color: 'black', fontSize: 16, fontWeight: 'bold', height: 25, padding: 0 },
    horizontalBar: { borderColor: 'black' },
    row: { border: 0 },
    fakeRow: { minHeight: 30 },
    body: { paddingTop: 10, padding: 0, border: 0, verticalAlign: 'top' }
});

class Help extends Component {
    displayName = Help.name

    render() {
        const { classes } = this.props;
        let showCreateAnAdGuideLink = getComponentThemeProperty("help", "showCreateAnAdGuideLink") === 'true';
        let showDigitalSubmissionGuideLink = getComponentThemeProperty("help", "showDigitalSubmissionGuideLink") === 'true';
        let showProofRequestGuideLink = getComponentThemeProperty("help", "showProofRequestGuideLink") === 'true';
        let showAdCreationGuideLink = getComponentThemeProperty("help", "showAdCreationGuideLink") === 'true';
        return (
            <div>
                <Typography className={classes.heading} variant='h5'>{lang("help")}</Typography>
                <hr className={classes.horizontalBar} />
                <Table>
                    <TableHead>
                        <TableRow className={classes.row}>
                            <TableCell className={classes.head}>{lang('guides')}</TableCell>
                            <TableCell className={classes.head}>{lang('pdfPresetFiles')}</TableCell>
                            <TableCell className={classes.head}>{lang('supportRequest')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className={classes.row}>
                            <TableCell className={classes.body}>
                                {showAdCreationGuideLink ? (
                                    <div className={classes.fakeRow}>
                                        <a target='_blank' href='File/HelpResource?fileType=AdCreationGuide'>{lang('viewAdCreationGuide')}</a>
                                    </div>
                                ): null}
                                <div className={classes.fakeRow}>
                                    <a target='_blank' href='File/HelpResource?fileType=PrintSubmissionGuide'>{lang('viewPrintSubmissionGuide')}</a>
                                </div>
                                {showProofRequestGuideLink ? (
                                    <div className={classes.fakeRow}>
                                        <a target='_blank' href='File/HelpResource?fileType=ProofRequestGuide'>{lang('viewProofRequestGuide')}</a>
                                    </div>
                                ): null}
                                {showDigitalSubmissionGuideLink ? (
                                    <div className={classes.fakeRow}>
                                        <a target='_blank' href='File/HelpResource?fileType=DigitalSubmissionGuide'>{lang('viewDigitalSubmissionGuide')}</a>
                                    </div>
                                ) : null}
                                {showCreateAnAdGuideLink ? (
                                    <div className={classes.fakeRow}>
                                        <a target='_blank' href='File/HelpResource?fileType=CreateAnAdGuide'>{lang('viewCreateAnAdGuide')}</a>
                                    </div>
                                ) : null}
                            </TableCell>
                            <TableCell className={classes.body}>
                                <div className={classes.fakeRow}>
                                    <a href='File/HelpResource?fileType=AdobePdfPresets'>{lang('downloadAdobePdfPresets')}</a>
                                </div>
                                <div className={classes.fakeRow}>
                                    <a href='File/HelpResource?fileType=QuarkXPressExport'>{lang('downloadQuarkXpressExport')}</a>
                                </div>
                                <div className={classes.fakeRow}>
                                    <a href='File/HelpResource?fileType=QuarkXPressPrintStyle'>{lang('downloadQuarkXpressPrintStyle')}</a>
                                </div>
                            </TableCell>
                            <TableCell className={classes.body}>
                                <div className={classes.fakeRow}>
                                    {lang('forUrgentRequests')} {lang('supportPhoneUsa')}
                                </div>
                                <div className={classes.fakeRow}>
                                    {lang('outsideUScall')} {lang('supportPhoneOutsideUs')}
                                </div>
                                <div className={classes.fakeRow}>
                                    <SupportRequest showButton showDialog={this.props.location.pathname === '/help/support'} />
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        );
    }
}

export default withLocation(withStyles(Help, styles, { withTheme: true }));