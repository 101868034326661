import React from 'react';
import JobPicker from './JobPicker';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import { lang, langFormat } from '../../Language';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { withStyles } from 'tss-react/mui';
import TableSortLabel from '@mui/material/TableSortLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { dateParser } from '../../HelperMethods';
import Sync from '@mui/icons-material/Sync'
import CustomTooltip from '../Common/CustomTooltip';

const styles = theme => ({
    heading: {
        fontWeight: 'bold',
        marginTop: theme.spacing(1)
    },
    horizontalBar: {
        borderColor: 'black'
    },
    widthLimited: {
        maxWidth: '400px',
    },
    table: {
        borderCollapse: 'separate',
        borderSpacing: '3px'
    },
    headerCell: {
        fontSize: 14,
        height: 40,
        paddingLeft: 3,
        paddingRight: 3,
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    sortLabel: {
        color: `${theme.palette.primary.contrastText} !important`,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold'
        },
        '&:focus': {
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold'
        }
    },
    tableRow: {
        minHeight: '30px',
        height: 'inherit',
    },
    linkButton: {
        verticalAlign: 'baseline',
        marginLeft: '3px',
        padding: '1px 8px',
        textDecoration: 'underline',
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: 'inherit',
            textDecoration: 'underline',
        },
        '&:active': {
            color: theme.palette.primary.light,
        },
    },
    dialogContents: {
        display: 'inline-flex'
    },
    bold: {
        fontWeight: 'bold'
    }
});

const DialogType = Object.freeze({
    None: 0,
    Error: 1,
    JobInfo: 2,
    ResetIssueImports: 3
});

class JobAssociations extends React.Component {
    displayName = JobAssociations.name

    constructor(props) {
        super(props);
        this.state = {
            publishers: null,
            publisherId: '',
            publications: null,
            publicationId: '',
            issueList: null,
            sortCol: 'psIssueDate',
            sortDesc: true,
            jobPickerIssue: null,
            showDialog: false,
            dialogType: DialogType.None,
            jobInfo: undefined,
            message: ''
        };
        this.columns = [
            { id: 'psIssueName', label: langFormat('pubStudio',lang('issueName')) },
            { id: 'psIssueDate', label: langFormat('pubStudio', lang('issueDate')) },
            { id: 'insightTitleName', label: langFormat('inSight', lang('title')) },
            { id: 'insightTitleCode', label: langFormat('inSight', lang('titleCode')) },
            { id: 'insightJobName', label: langFormat('inSight', lang('jobName')) },
            { id: 'insightJobNumber', label: langFormat('inSight', lang('jobNumber')) },
            { id: 'jobSelection', label: lang('jobSelection') },
            { id: 'resetImports', label: lang('resetIssueImportsTableHeader') },
        ];
    }

    componentDidMount() {
        if (!this.state.publishers) {
            fetch('SvrISR/FullPublisherList', { credentials: 'same-origin' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ publishers: data });
                });
        }
    };

    loadPublications = (publisherId) => {
        if (Number(publisherId) > 0) {
            fetch(`SvrISR/PublicationList?publisherId=${publisherId}`, { credentials: 'same-origin' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ publications: data });
                });
        }
    };

    loadIssues = (publicationId) => {
        let { publisherId } = this.state;
        if (Number(publisherId) > 0 && Number(publicationId) > 0) {
            fetch(`SvrISR/JobAssociationIssues?publisherId=${publisherId}&publicationId=${publicationId}`, { credentials: 'same-origin' })
                .then(response => response.text())
                .then(data => {
                    if (data) {
                        data = JSON.parse(data, dateParser);
                        if (data.success) {
                            this.setState({ issueList: data.issues });
                        }
                    }
                });
        }
    };

    loadJobAndFacilityInfo = (jobNumber) => {
        fetch(`SvrISR/InsightJobInfo?jobNumber=${jobNumber}`, { method: 'POST', credentials: "same-origin" })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.setState({ jobInfo: data.jobInfo, showDialog: true, dialogType: DialogType.JobInfo, message: '' });
                }
                else {
                    this.setState({ jobInfo: undefined, showDialog: true, dialogType: DialogType.Error, message: langFormat(data.error, jobNumber) });
                }
            });
    }

    handleChange = event => {
        let poke = {};
        poke[event.target.name] = event.target.value;
        this.setState(poke);
        if (poke.publisherId) {
            this.loadPublications(poke.publisherId);
        }
        else if (poke.publicationId) {
            this.loadIssues(poke.publicationId);
        }
    };

    getSortedIssues = () => {
        var compare = function (a, b) {
            if (b < a) return -1;
            if (b > a) return 1;
            return 0;
        };

        const { sortCol, sortDesc, issueList } = this.state;
        const sortedIndexes = issueList.map((el, index) => [el, index]);
        sortedIndexes.sort((a, b) => {
            const order = compare(a[0][sortCol], b[0][sortCol]);
            if (order !== 0) return (sortDesc) ? order : -order;
            return (sortDesc) ? a[1] - b[1] : b[1] - a[1];
        });
        return sortedIndexes.map(el => el[0]);
    };

    changeSort = (colName) => {
        if (this.state.sortCol === colName) {
            const { sortDesc } = this.state;
            this.setState({ sortDesc: !sortDesc });
        }
        else {
            this.setState({ sortCol: colName });
        }
    };

    onJobUpdated = () => {
        //We don't actually need to push an update to issueList if we modified a row inside of it because
        //the change will be used when the table renders its rows again.
        //This violates React's immutable state principle, but since we're closing the dialog the entire component
        //needs to be re-rendered anyway so this saves us from having to copy the array just to modify one row.
        this.setState({ jobPickerIssue: null });
    }

    getTableHeader = () => {
        const { classes } = this.props;
        return (
            <TableHead>
                <TableRow style={{ height: '40px' }}>
                    {this.columns.map(
                        col => (
                            <TableCell key={col.id} className={classes.headerCell}
                                onClick={()=>this.changeSort(col.id)}
                                sortDirection={this.state.sortCol === col.id ? (this.state.sortDesc ? 'desc' : 'asc') : false}
                            >
                                <TableSortLabel
                                    classes={{ root: classes.sortLabel, active: classes.sortLabel, icon: classes.sortLabel }}
                                    active={this.state.sortCol === col.id}
                                    direction={this.state.sortDesc ? 'desc' : 'asc'}
                                >
                                    {col.label}
                                </TableSortLabel>
                            </TableCell>
                        ),
                        this,
                    )}
                </TableRow>
            </TableHead>
        );
    };

    closeDialog = () => {
        this.setState({ showDialog: false });
    }

    resetIssueImports = (psIssueId) => {
        fetch(`SvrISR/ResetIssueImports?publisherId=${this.state.publisherId}&publicationId=${this.state.publicationId}&issueId=${psIssueId}`, { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.setState({ showDialog: true, dialogType: DialogType.ResetIssueImports, message: lang('resetIssueImportsDialogMessage') });
                }
                else {
                    this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(data.error) });
                }
            });
    }

    render() {
        const { classes } = this.props;
        let publishers = <CircularProgress />;
        let publications = <React.Fragment />;
        let issues = <React.Fragment />;

        let dialogTitle = this.state.dialogType !== DialogType.ResetIssueImports ? lang('insightJobInfo') : lang('resetIssueImportsDialogTitle');

        if (this.state.publishers) {
            publishers =
                <FormControl className={classes.widthLimited} margin="dense" required fullWidth variant="standard">
                    <InputLabel shrink htmlFor="publisherId">{lang("publisher")}</InputLabel>
                    <Select native name="publisherId" displayEmpty value={this.state.publisherId} onChange={this.handleChange} >
                        <option value="">{lang("selectPublisher")}</option>
                        {this.state.publishers.map(publisher => (
                            <option key={publisher.name} value={publisher.id}>
                                {publisher.name}
                            </option>
                        ))}
                    </Select>
                </FormControl>;
        }
        if (this.state.publisherId && Number(this.state.publisherId) > 0 && this.state.publications) {
            publications = <FormControl className={classes.widthLimited} margin="dense" required fullWidth variant="standard">
                <InputLabel shrink htmlFor="publications">{lang("publication")}</InputLabel>
                <Select native name="publicationId" displayEmpty value={this.state.publicationId} onChange={this.handleChange} >
                    <option value="">{lang("selectPublication")}</option>
                    {this.state.publications.map(publication => 
                        <option key={publication.name} value={publication.id}>
                            {publication.name}
                        </option>
                    )}
                </Select>
            </FormControl>;
        }
        if (this.state.publicationId && Number(this.state.publicationId) > 0 && this.state.issueList) {
            issues = <Table className={classes.table} aria-labelledby="tableTitle">
                {this.getTableHeader()}
                <TableBody>
                    {this.getSortedIssues().map(i =>
                        <TableRow key={i.psIssueId} className={classes.tableRow}>
                            <TableCell>{i.psIssueName}</TableCell>
                            <TableCell>{i.psIssueDate.toLocaleDateString()}</TableCell>
                            <TableCell>{i.insightTitleName}</TableCell>
                            <TableCell>{i.insightTitleCode}</TableCell>
                            <TableCell>{i.insightJobName}</TableCell>
                            <TableCell>
                                {i.insightJobNumber ? (
                                    <Button className={classes.linkButton} variant='text' size='small' disableRipple
                                        onClick={() => this.loadJobAndFacilityInfo(i.insightJobNumber)}>
                                        {i.insightJobNumber}
                                    </Button>
                                ): ''}
                            </TableCell>
                            <TableCell>
                                <Button className={classes.linkButton} variant="text" size="small" disableRipple
                                    onClick={() => this.setState({ jobPickerIssue: i })}
                                >
                                    {(i.insightJobNumber && i.insightJobNumber.length > 0) ? lang('changeJob') : lang('selectJob')}
                                </Button>
                            </TableCell>
                            {i.insightJobName ? (
                                <TableCell>
                                    <CustomTooltip placement="top-start" title={
                                        <React.Fragment>
                                            <Typography color="inherit">{lang("resetIssueImportsTooltip")}</Typography>
                                        </React.Fragment>
                                    }>
                                        <Button color='primary' variant='contained' onClick={() => this.resetIssueImports(i.psIssueId)}>
                                            <Sync />
                                        </Button>
                                    </CustomTooltip>
                                </TableCell>
                            ): ''}
                        </TableRow>)}
                </TableBody>
            </Table>;
        }

        let jobInfo = this.state.jobInfo;
        let dialogContents = jobInfo === undefined ? this.state.message : (
            <div className={classes.dialogContents}>
                <Typography style={{width: 450}} component='div'>
                    <div><span className={classes.bold}>{`${lang('title')}: `}</span><span>{jobInfo.title}</span></div>
                    <div><span className={classes.bold}>{`${lang('titleCode')}: `}</span><span>{jobInfo.titleCode}</span></div>
                    <div><span className={classes.bold}>{`${lang('issueName')}: `}</span><span>{jobInfo.jobName}</span></div>
                    <div><span className={classes.bold}>{`${lang('jobNumber')}: `}</span><span>{jobInfo.jobNumber}</span></div>
                    <div><span className={classes.bold}>{`${lang('imagingJobId')}: `}</span><span>{jobInfo.imagingJobId}</span></div>
                </Typography>
                <Typography component='div'>
                    <div><span className={classes.bold}>{`${lang('imagingPodFolder')}: `}</span><span>{jobInfo.imagingPodFolder}</span></div>
                    <div><span className={classes.bold}>{`${lang('serverLocation')}: `}</span><span>{jobInfo.serverLocation}</span></div>
                    <div><span className={classes.bold}>{`${lang('podServer')}: `}</span><span>{jobInfo.podServer}</span></div>
                    <div><span className={classes.bold}>{`${lang('jobFolderName')}: `}</span><span>{jobInfo.jobFolderName}</span></div>
                    <div><span className={classes.bold}>{`${lang('imagingFacilityId')}: `}</span><span>{jobInfo.imagingFacilityId}</span></div>
                    <div><span className={classes.bold}>{`${lang('imagingFacilityType')}: `}</span><span>{jobInfo.imagingFacilityType}</span></div>
                    <div><span className={classes.bold}>{`${lang('imagingFacilityName')}: `}</span><span>{jobInfo.imagingFacilityName}</span></div>
                    <div><span className={classes.bold}>{`${lang('rmsLocationNum')}: `}</span><span>{jobInfo.rmsLocationNum}</span></div>
                    <div><span className={classes.bold}>{`${lang('locationNum')}: `}</span><span>{jobInfo.locationNum}</span></div>
                </Typography>
            </div>
            )

        return (
            <div>
                <Typography className={classes.heading} variant='h5'>{lang("jobAssociations")}</Typography>
                <hr className={classes.horizontalBar} />
                {publishers} {publications}
                <p />
                {issues}
                <JobPicker
                    publisherId={this.state.publisherId}
                    publicationId={this.state.publicationId}
                    job={this.state.jobPickerIssue}
                    onJobUpdated={() => this.onJobUpdated()}
                />
                <Dialog open={this.state.showDialog} fullWidth maxWidth={this.state.dialogType === DialogType.JobInfo ? 'lg' : 'sm'} >
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>
                        {dialogContents}
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' color='secondary' autoFocus onClick={this.closeDialog}>{lang('ok')}</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(JobAssociations, styles);