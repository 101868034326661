import React from 'react';
import { langFormat } from '../../Language';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';

export const styles = theme => ({
    /* Styles applied to the root element. */
    root: {
        display: 'inline-flex',
        alignItems: 'center',
        cursor: 'pointer',
        // For correct alignment with the text.
        verticalAlign: 'middle',
        // Remove grey highlight
        WebkitTapHighlightColor: 'transparent',
        marginLeft: -14,
        marginRight: 16, // used for row presentation of radio/checkbox
        '&$disabled': {
            cursor: 'default',
        },
    },
    picturePlacement: {
        flexDirection: 'column',
        marginLeft: 16,
    },
    image: {
        height: '50px',
    },
    disabledCaption: {
        paddingTop: '12px', //Matches the default checkbox padding
        textAlign: 'center',
        maxWidth: '150px',
        fontSize: 'smaller',
    },
    /* Styles applied to the root element if `disabled={true}`. */
    disabled: {},
    /* Styles applied to the label's Typography component. */
    label: {
        '&$disabled': {
            color: theme.palette.text.disabled,
        },
    },
});

/**
 * Copied from Material-UI's FormControlLabel and customized for media types.
 * This allows us to have both a label and an image. By default, MUI only allows a single label on one side.
 */
function MediaPickerButton(props) {
    const {
        checked,
        classes,
        className: classNameProp,
        disabled: disabledProp,
        inputRef,
        label,
        muiFormControl,
        name,
        onChange,
        value,
        publisherId,
        mediaTypeId,
        canSelect,
        ...other
    } = props;
    let control = <Radio color="primary" />;

    let disabled = disabledProp;
    if (typeof disabled === 'undefined' && typeof control.props.disabled !== 'undefined') {
        disabled = control.props.disabled;
    }
    if (typeof disabled === 'undefined' && muiFormControl) {
        disabled = muiFormControl.disabled;
    }
    if (!canSelect) {
        disabled = true;
    }

    const controlProps = {
        disabled,
    };
    ['checked', 'name', 'onChange', 'value', 'inputRef'].forEach(key => {
        if (typeof control.props[key] === 'undefined' && typeof props[key] !== 'undefined') {
            controlProps[key] = props[key];
        }
    });

    let selectionControl = '';
    if (canSelect) {
        selectionControl = <div className={classNames(
            classes.root,
            {
                [classes['labelPlacementEnd']]: false,
                [classes.disabled]: disabled,
            },
            classNameProp,
        )}
        >{React.cloneElement(control, controlProps)}
            <Typography variant="body2"
                component="span"
                className={classNames(classes.label, { [classes.disabled]: disabled })}
            >
                {label}
            </Typography>
        </div>;
    }
    else {
        selectionControl = <Typography variant="body2"
            component="span"
            className={classNames(classes.label, classes.disabledCaption, { [classes.disabled]: disabled })}
        >
            {langFormat("submissionNotOpen",label)}
        </Typography>;
    }

    return (
        <label
            className={classNames(
                classes.root,
                {
                    [classes['picturePlacement']]: true,
                    [classes.disabled]: disabled,
                },
                classNameProp,
            )}
            {...other}
        >
            <img src={`File/StreamFileCached?fileType=MediaTypeThumbnail&id=${publisherId},${mediaTypeId}`} alt={label} className={classes.image} />
            {selectionControl}
        </label>
    );
}

MediaPickerButton.propTypes = {
    /**
     * If `true`, the component appears selected.
     */
    checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css-api) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * If `true`, the control will be disabled.
     */
    disabled: PropTypes.bool,
    /**
     * Use that property to pass a ref callback to the native input component.
     */
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    /**
     * The text to be used in an enclosing label element.
     */
    label: PropTypes.node,
    /**
     * The position of the label.
     */
    labelPlacement: PropTypes.oneOf(['end', 'start', 'top', 'bottom']),
    /**
     * @ignore
     */
    muiFormControl: PropTypes.object,
    /*
     * @ignore
     */
    name: PropTypes.string,
    /**
     * Callback fired when the state is changed.
     *
     * @param {object} event The event source of the callback.
     * You can pull out the new value by accessing `event.target.checked`.
     * @param {boolean} checked The `checked` value of the switch
     */
    onChange: PropTypes.func,
    /**
     * The value of the component.
     */
    value: PropTypes.string,
};

export default withStyles(MediaPickerButton, styles, { name: 'MediaPickerButton' });