import React from 'react';
import { lang } from '../Language';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
    footer: {
        flex: '1 100%',
        textAlign: 'center',
    },
    spaced: {
        paddingLeft: '0.5em',
        paddingRight: '0.5em',
    }
});

class Footer extends React.Component {
    displayName = Footer.name

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.footer}>
                <Typography variant="body2">
                    <span className={classes.spaced}>{lang("copyright")} {lang("workflowSolutions")}</span>
                    <span className={classes.spaced}><Link to="/terms" target="_blank">{lang("terms")}</Link></span>
                    <span className={classes.spaced}><Link to="/privacy" target="_blank">{lang("privacyPolicy")}</Link></span>
                </Typography>
            </div>
        );
    }
}
export default withStyles(Footer, styles);