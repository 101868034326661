import React, { Component } from 'react';
import { lang } from '../../Language';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import LinearProgress from '@mui/material/LinearProgress';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import TicketDetailsUrls from './TicketDetailsUrls';

const styles = theme => ({
    spacer: {
        paddingTop: theme.spacing(2),
    },
    widthLimited: {
        maxWidth: '700px',
    },
    fakeLabel: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontSize: '0.75rem',
        width: '100%',
        color: 'rgba(0, 0, 0, 0.54)',
        paddingTop: theme.spacing(1),
    },
    fakeInput: {
        fontSize: '1rem',
        width: '100%',
    },
    fakeDisabled: {
        color: '#bbb',
    },
});

export const MediaFileType = Object.freeze({
    DYNAMIC: 1,
    STATIC: 2,
    PREMIUM: 3,
    STATIC_LINK: 4,
    DETAILS_ONLY: 5,
    ENHANCED: 6,
});

class TicketDetailsCustom extends Component {
    displayName = TicketDetailsCustom.name
    
    handleChange = (event) => {
        let { currentSubmission } = this.props;

        currentSubmission.ticket[event.target.name] = event.target.value;

        this.props.updateSubmission(currentSubmission);
    };

    render() {
        let { currentSubmission } = this.props;
        const { classes } = this.props;
        let detailsBody = '';
        let urlsBody = '';
        if (currentSubmission.allowsUrls && (Number(currentSubmission.ticket.fileTypeId) === MediaFileType.STATIC_LINK ||
            Number(currentSubmission.ticket.fileTypeId) === MediaFileType.DETAILS_ONLY)) {
            urlsBody = <TicketDetailsUrls currentSubmission={currentSubmission} updateSubmission={this.props.updateSubmission} readOnly={this.props.readOnly} />;
        }
        if (!currentSubmission.mediaTypeFields) {
            //Note: Media Type custom fields are loaded by TicketDetails to reduce complexity and bundle requests.
            detailsBody = <LinearProgress variant="query" />;
        }
        else if (this.props.readOnly) {
            detailsBody = currentSubmission.mediaTypeFields.map(field => {
                let textValue = <Typography variant="body2" className={classes.fakeInput}>{currentSubmission.ticket[field.name]}</Typography>;
                let fieldName = lang("field_" + field.name);
                if (field.required) {
                    fieldName = fieldName + " *";
                }
                if (!currentSubmission.ticket[field.name] || currentSubmission.ticket[field.name].length === 0) {
                    textValue = <Typography variant="body2" className={`${classes.fakeInput} ${classes.fakeDisabled}`}>{fieldName}</Typography>;
                }
                return (<div key={field.name}>
                    <div className={classes.fakeLabel}>{fieldName}</div>
                    {textValue}
                </div>);
            });
        }
        else {
            detailsBody = currentSubmission.mediaTypeFields.map(field => {
                if (field.options) {
                    return (<FormControl key={field.name} className={classes.widthLimited} margin="dense" required={field.required} fullWidth variant="standard">
                        <InputLabel shrink htmlFor={field.name}>{lang("field_" + field.name)}</InputLabel>
                        <Select native name={field.name} displayEmpty value={currentSubmission.ticket[field.name]} onChange={this.handleChange} >
                            {field.options.map(option => (
                                <option key={option.name} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>);
                }
                else {
                    return (<FormControl key={field.name} className={classes.widthLimited} margin="dense" required={field.required} fullWidth variant="standard">
                        <InputLabel htmlFor={field.name}>{lang("field_" + field.name)}</InputLabel>
                        {(field.maxLength) ?
                            (<Input name={field.name} value={currentSubmission.ticket[field.name]} onChange={this.handleChange} inputProps={{ maxLength: field.maxLength }} />) :
                            (<Input name={field.name} value={currentSubmission.ticket[field.name]} onChange={this.handleChange} />)}
                    </FormControl>);
                }
            });
        }

        return (
            <React.Fragment>
                {detailsBody}
                {urlsBody}
            </React.Fragment>
        );
    }
}

export default withStyles(TicketDetailsCustom, styles);