import Tooltip from '@mui/material/Tooltip';
import { withStyles } from 'tss-react/mui';

const CustomTooltip = withStyles(Tooltip, theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #929292',
        marginBottom: -5,
        marginLeft: 5
    },
}));

export default CustomTooltip;