import React from 'react';
import { lang } from '../Language';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
    layout: {
        width: '88%',
        display: 'block', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(5),
        marginLeft: 'auto',
        marginRight: 'auto',
        '& p': {
            marginTop: '1.0em',
            marginBottom: '1.0em',
        }
    },
    logo: {
        textAlign: 'left',
    },
    printPage: {
        textAlign: 'right',
        paddingTop: '0.5em',
    },
    denseText: {
        lineHeight: '1.2',
    }
});

class Privacy extends React.Component {
    displayName = Privacy.name

    printMe = () => {
        window.print();
    };

    render() {
        let { classes } = this.props;

        return (
            <div className={classes.layout} >
                <Grid container>
                    <Grid item xs={6} className={classes.logo}>
                        <img src="/AdShuttleLogo.png" alt="AdShuttle" />
                    </Grid>
                    <Grid item xs={6} className={classes.printPage}>
                        <Typography variant="body2"><a href="/privacy#" onClick={() => this.printMe()}>{lang("printThisPage")}</a></Typography>
                    </Grid>
                </Grid>
                <Typography variant="h6" align='center'>
                    <u>{lang("asPrivacyPolicy")}</u>
                </Typography>
                <Typography variant="body2" className={classes.denseText}>
                    {lang("privacyP1")}
                </Typography>
                <Typography variant="body2" className={classes.denseText}>
                    {/*Section 1 Header*/}
                    <u>{lang("privacyS1H")}</u>
                </Typography>
                <Typography variant="body2" className={classes.denseText}>
                    {/*Section 1 Paragraph 1*/}
                    {lang("privacyS1P1")}
                </Typography>
                <Typography variant="body2" className={classes.denseText}>
                    {lang("privacyS1P2")}
                </Typography>
                <Typography variant="body2" className={classes.denseText}>
                    {lang("privacyS1P3")}
                </Typography>
                <Typography variant="body2" className={classes.denseText}>
                    <u>{lang("privacyS2H")}</u>
                </Typography>
                <Typography variant="body2" className={classes.denseText}>
                    {lang("privacyS2P1")}
                </Typography>
                <Typography variant="body2" className={classes.denseText}>
                    {lang("privacyS2P2")}
                </Typography>
                <Typography variant="body2" className={classes.denseText}>
                    <u>{lang("privacyS3H")}</u>
                </Typography>
                <Typography variant="body2" className={classes.denseText}>
                    {lang("privacyS3P1")}
                </Typography>
                <Typography variant="body2" className={classes.denseText}>
                    <u>{lang("privacyS4H")}</u>
                </Typography>
                <Typography variant="body2" className={classes.denseText}>
                    {lang("privacyS4P1")} <a href={"mailto:" + lang("supportEmail")}>{lang("supportEmail")}</a>
                </Typography>
            </div>
        );
    }
}

export default withStyles(Privacy, styles);