export function overrideComponentThemeStyle(theme, styles, componentName) {
    var themeComponent = theme.adShuttle.filter(obj => {
        return obj.componentName === componentName;
    });
    if (themeComponent && themeComponent.length > 0) {
        themeComponent = themeComponent[0];
        for (var i = 0; i < themeComponent.classes.length; i++) {
            var classObj = themeComponent.classes[i];
            if (!styles[classObj.className]) {
                styles[classObj.className] = {};
            }
            for (var j = 0; j < classObj.properties.length; j++) {
                var property = classObj.properties[j];
                if (property.propertyValue === "") {
                    delete styles[classObj.className][property.property];
                }
                else {
                    styles[classObj.className][property.property] = property.propertyValue;
                }
            }
            if (classObj.hover.properties.length > 0) {
                styles[classObj.className]['&:hover'] = {};
                for (var k = 0; k < classObj.hover.properties.length; k++) {
                    var prop = classObj.hover.properties[k];
                    if (prop.propertyValue === "") {
                        delete styles[classObj.className]['&:hover'][prop.property];
                    } else {
                        styles[classObj.className]['&:hover'][prop.property] = prop.propertyValue;
                    }
                }
            }
        }
    }
    return styles;
}

export function getComponentThemeProperty(componentName, propertyName) {
    const theme = window.AdShuttleGlobal.getTheme();
    var themeComponent = theme?.adShuttle?.filter(obj => {
        return obj.componentName.toUpperCase() === componentName.toUpperCase();
    });
    if (themeComponent && themeComponent.length > 0) {
        var property = themeComponent[0].properties.filter(obj => {
            return obj.property === propertyName;
        });
        if (property && property.length > 0) {
            return property[0].propertyValue;
        }
    }
    return "";
}