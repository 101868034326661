import React from 'react';
import { withStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { lang } from '../../Language';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

const styles = theme => ({
    tableRow: {
        height: 40,
    },
    button: {
        marginLeft: 10,
        marginRight: 10,
    },
    tableFooter: {
        textAlign: 'center',
    },
    iconLink: {
        cursor: 'pointer',
    },
    filterLink: {
        marginLeft: 10,
        marginRight: 10
    },
    tableFooterMargin: {
        marginTop: 20,
        marginBottom: 20
    },
    MuiButton: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 3,
        paddingRight: 3
    },
    header: {
        fontWeight: 'bold',
        marginTop: theme.spacing(1)
    },
    horizontalBar: {
        borderColor: 'black'
    },
    tableCell: {
        paddingLeft: 2,
        paddingRight: 2
    },
    select: {
        maxWidth: '400px',
        minWidth: '180px'
    }
});

var maxRow = 0;

class UserTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            publishers: [],
            lastMessage: '',
            dialogOpen: false,
            currentTopRow: 0,
            filterText: '',
            saveEnabled: true
        }
    }

    componentDidMount() {
        fetch('SvrAdmin/InitAdmin', { method: 'post', credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                maxRow = data.maxRow;
                this.setState({ publishers: data.publishers, rows: data.rows });
            });
    }

    saveUsers = () => {
        fetch('SvrAdmin/SaveUsers',
            {
                method: 'post',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                credentials: "same-origin",
                body: JSON.stringify(this.state.rows.filter(row => row.rowActivated))
            }).then(response => response.json())
            .then(data => {
                if (!data.success) {
                    this.setState({ lastMessage: lang(data.error) });
                }
            });
    }

    activateRow = (id) => {
        var newRows = this.state.rows.slice();
        var index = 0;
        while (index < newRows.length)
        {
            if (newRows[index].id === id) {
                newRows[index].rowActivated = !newRows[index].rowActivated;
                break;
            }
            index++;
        }
        this.setState({rows: newRows});
    }

    fetchFirst = () => {
        fetch('SvrAdmin/GetUsers?startingRow=0&filter=' + this.state.filterText, { method: 'POST', credentials: 'same-origin' })
        .then(response => response.json())
        .then(data => {
            this.setState({ rows: data, currentTopRow: 0, lastMessage: ''});
        });
    }

    fetchPrev = () => {
        fetch('SvrAdmin/GetUsers?startingRow=' + (this.state.currentTopRow - this.state.rows.length) + '&filter=' + this.state.filterText, { method: 'POST', credentials: 'same-origin' })
        .then(response => response.json())
            .then(data => {
                this.setState({ rows: data, currentTopRow: this.state.currentTopRow - this.state.rows.length, lastMessage: ''});
        });
    }

    fetchNext = () => {
        fetch('SvrAdmin/GetUsers?startingRow=' + (this.state.currentTopRow + this.state.rows.length) + '&filter=' + this.state.filterText, { method: 'post', credentials: 'same-origin'})
        .then(response => response.json())
        .then(data => {
            this.setState({ rows: data, currentTopRow: this.state.currentTopRow + this.state.rows.length, lastMessage: ''});
        });
    }
    fetchLast = () => {
        fetch('SvrAdmin/GetUsers?startingRow=' + (maxRow - this.state.rows.length) + '&filter=' + this.state.filterText, { method: 'post', credentials: 'same-origin' })
        .then(response => response.json())
        .then(data => {
            this.setState({ rows: data, currentTopRow: maxRow - this.state.rows.length, lastMessage: ''});
        });
    }

    areUsersValid = () => {
        let isValid = true;
        let rows = this.state.rows.slice();
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].isSales && rows[i].assignedPublisherIds.length !== 1) {
                isValid = false;
                break;
            }
        }
        return isValid;
    }

    checkChanged = (event, id) => {
        var newRows = this.state.rows.slice();
        var index = 0;
        while (index < newRows.length)
        {
            if (newRows[index].id === id) {
                newRows[index][event.target.name] = event.target.checked;
                break;
            }
            index++;
        }
        this.setState({ rows: newRows, saveEnabled: this.areUsersValid() });
    }

    resetPassword = email => {
        fetch('SvrAdmin/ResetPassword?username=' + email, { method: 'POST', credentials: 'same-origin'})
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    var msg = lang(data.msg) + ' ' + lang(data.msg2) + ' ' + data.newPassword;
                    this.setState({ lastMessage: msg });
                }
                else {
                    this.setState({ lastMessage: lang(data.error) });
                }
            });
    }

    publisherChanged = (event, id) => {
        var newRows = this.state.rows.slice();
        var index = 0;
        while (index < newRows.length)
        {
            if (newRows[index].id === id) {
                newRows[index].assignedPublisherIds = [];
                for (let i = 0; i < event.target.value.length; i++) {
                    newRows[index].assignedPublisherIds.push(event.target.value[i]);
                }
                break;
            }
            index++;
        }
        this.setState({ rows: newRows, saveEnabled: this.areUsersValid() });
    }

    displayResetPasswordMessage = classes => {
        if (this.state.lastMessage !== '') {
            return <Typography variant="body2" component='div' className={`${classes.tableFooter} ${classes.tableFooterMargin}`}>{this.state.lastMessage}</Typography>;
        }
        else {
            return '';
        }
    }

    closeFilterDialog = text => {
        this.setState({dialogOpen: false});
        if (text && text !== '') {

            fetch('SvrAdmin/GetMaxUserRow', { credentials: 'same-origin' })
                .then(response => response.json())
                .then(data => {
                    maxRow = data.maxRow;
                });

            fetch('SvrAdmin/GetUsers?startingRow=0&filter=' + text, { method: 'POST', credentials: 'same-origin' })
            .then(response => response.json())
                .then(data => {
                    this.setState({rows: data, currentTopRow: 0, lastMessage: '', filterText: text});
                });
        }
    }

    resetFilter = () => {
        fetch('SvrAdmin/GetUsers?startingRow=0', { method: 'post', credentials: 'same-origin'})
        .then(response => response.json())
            .then(data => {this.setState({ rows: data, currentTopRow: 0, lastMessage: '', filterText: '' });
        });
    }

    getPublisherNameFromId = (id) => {
        for (let i = 0; i < this.state.publishers.length; i++) {
            if (this.state.publishers[i].id === id) {
                return this.state.publishers[i].name;
            }
        }
        return '';
    }

    openFilterDialog = () => {
        this.setState({dialogOpen: true});
    }

    selectOpened = () => {
        this.setState({ saveEnabled: false });
    }

    selectClosed = () => {
        this.setState({ saveEnabled: this.areUsersValid() });
    }

    render() {
        const { classes } = this.props;
          return (
          <div>
              <Typography className={classes.header} variant='h5'>{lang('users')}</Typography>
              <hr className={classes.horizontalBar} />
              <Table size='small'>
                <TableHead>
                <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCell}></TableCell>
                    <TableCell className={classes.tableCell}>{lang('id')}</TableCell>
                    <TableCell className={classes.tableCell}>{lang('email')}</TableCell>
                    <TableCell className={classes.tableCell}>{lang('isClientAdmin')}</TableCell>
                    <TableCell className={classes.tableCell}>{lang('publisher')}</TableCell>
                    <TableCell className={classes.tableCell}>{lang('isActive')}</TableCell>
                    <TableCell className={classes.tableCell}>{lang('isAdmin')}</TableCell>
                    <TableCell className={classes.tableCell}>{lang('isISR')}</TableCell>
                    <TableCell className={classes.tableCell}>{lang('isSales')}</TableCell>
                    <TableCell className={classes.tableCell}>{lang('cannotSubmit')}</TableCell>
                    <TableCell className={classes.tableCell}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.rows.map(row => {
                    return (
                        <TableRow className={classes.tableRow} key={row.id}>
                            <TableCell className={classes.tableCell}><Checkbox color="primary" className={classes.MuiButton} checked={row.rowActivated} onChange={() => { this.activateRow(row.id) }} /></TableCell>
                            <TableCell className={classes.tableCell}>{row.id}</TableCell>
                            <TableCell className={classes.tableCell}>{row.email}</TableCell>
                            <TableCell className={classes.tableCell}><Checkbox color="primary" className={classes.MuiButton} name='isClientAdmin' disabled={!row.rowActivated} checked={row.isClientAdmin} onChange={(e) => {this.checkChanged(e, row.id)}} /></TableCell>
                            <TableCell className={classes.tableCell}>
                                <Select onOpen={this.selectOpened} onClose={this.selectClosed} className={classes.select} multiple variant="standard"
                                    value={row.assignedPublisherIds}
                                    renderValue={(data) => <div>{data.map(id => this.getPublisherNameFromId(id)).join(', ')}</div>}
                                    disabled={!row.rowActivated || !row.isClientAdmin && !row.isSales} onChange={(e) => { this.publisherChanged(e, row.id) }}>
                                    {
                                        this.state.publishers.map((publisher) => (
                                            <MenuItem key={publisher.id} value={publisher.id}>
                                                <Checkbox checked={row.assignedPublisherIds.indexOf(publisher.id) > -1} />
                                                <ListItemText primary={publisher.name} />
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </TableCell>
                            <TableCell className={classes.tableCell}><Checkbox color="primary" className={classes.MuiButton} name='isActive' disabled={!row.rowActivated} checked={row.isActive} onChange={(e) => {this.checkChanged(e, row.id)}} /></TableCell>
                            <TableCell className={classes.tableCell}><Checkbox color="primary" className={classes.MuiButton} name='isAdmin' disabled={!row.rowActivated} checked={row.isAdmin} onChange={(e) => {this.checkChanged(e, row.id)}} /></TableCell>
                            <TableCell className={classes.tableCell}><Checkbox color="primary" className={classes.MuiButton} name='isISR' disabled={!row.rowActivated} checked={row.isISR} onChange={(e) => {this.checkChanged(e, row.id)}} /></TableCell>
                            <TableCell className={classes.tableCell}><Checkbox color="primary" className={classes.MuiButton} name='isSales' disabled={!row.rowActivated} checked={row.isSales} onChange={(e) => { this.checkChanged(e, row.id) }} /></TableCell>
                            <TableCell className={classes.tableCell}><Checkbox color="primary" className={classes.MuiButton} name='cannotSubmit' disabled={!row.rowActivated} checked={row.cannotSubmit} onChange={(e) => { this.checkChanged(e, row.id) }} /></TableCell>
                            <TableCell className={classes.tableCell}><Link to='#' onClick={() => { this.resetPassword(row.email) }}>{lang('resetPassword')}</Link></TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <div className={classes.tableFooter}>
                  <div>
                    <p></p>
                    <div>
                        <Typography variant="body2">
                            <Link className={classes.filterLink} to='#' onClick={this.openFilterDialog}>{lang('filter')}</Link>|<Link to='#' className={classes.filterLink} onClick={this.resetFilter}>{lang('reset')}</Link>
                        </Typography>
                    </div>
                    <p></p>
                    <div>
                        <FirstPage color={this.state.currentTopRow < this.state.rows.length ? 'disabled' : 'inherit'} className={this.state.currentTopRow < this.state.rows.length ? '' : classes.iconLink} onClick={this.state.currentTopRow < this.state.rows.length ? () => { } : this.fetchFirst} />
                        <ChevronLeftIcon color={this.state.currentTopRow < this.state.rows.length ? 'disabled' : 'inherit'} className={this.state.currentTopRow < this.state.rows.length ? '' : classes.iconLink} onClick={this.state.currentTopRow < this.state.rows.length ? () => { } : this.fetchPrev} />
                        <ChevronRightIcon color={this.state.currentTopRow >= (maxRow - this.state.rows.length) ? 'disabled' : 'inherit'} className={this.state.currentTopRow >= (maxRow - this.state.rows.length) ? '' : classes.iconLink} onClick={this.state.currentTopRow >= (maxRow - this.state.rows.length) ? () => { } : this.fetchNext} />
                        <LastPage color={this.state.currentTopRow >= (maxRow - this.state.rows.length) ? 'disabled' : 'inherit'} className={this.state.currentTopRow >= (maxRow - this.state.rows.length) ? '' : classes.iconLink} onClick={this.state.currentTopRow >= (maxRow - this.state.rows.length) ? () => { } : this.fetchLast} />
                    </div>
                    <p></p>
                    <div>
                              <Button className={classes.button} component={Link} to="/admin" variant='contained' color='primary'>{lang('back')}</Button>
                              <Button className={classes.button} disabled={!this.state.saveEnabled} onClick={this.saveUsers} variant='contained' color='secondary'>{lang('save')}</Button>
                    </div>
                  </div>
              </div>
              {
                this.displayResetPasswordMessage(classes)
              }
              <Dialog open={this.state.dialogOpen} onClose={this.closeFilterDialog} >
                  <DialogTitle id="form-dialog-title">{lang('filter')}</DialogTitle>
                  <DialogContent>
                    <TextField autoFocus margin="dense" id="filterText" fullWidth />
                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' onClick={() => { this.closeFilterDialog('') }} color="primary">
                        {lang('close')}
                          </Button>
                    <Button variant='contained' onClick={() => { this.closeFilterDialog(document.getElementById('filterText').value); }} color="secondary">
                        {lang('filter')}
                    </Button>
                  </DialogActions>
              </Dialog>
          </div>
      );
    }
}

export default withStyles(UserTable, styles);