import React from 'react';
import Button from '@mui/material/Button';
import { lang } from '../../Language';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
    heading: {
        fontWeight: 'bold',
        marginTop: theme.spacing(1)
    },
    horizontalBar: {
        borderColor: 'black'
    },
    isrButton: {
        marginRight: '10px'
    }
});

class IsrIndex extends React.Component {
    displayName = IsrIndex.name

    render() {
        const { classes, userInfo } = this.props;
        let buttons = userInfo.isClientAdminAndHasPublishersAssigned ?
            <Button color="primary" variant="contained" component={Link} to="/isr/ticketReport" className={classes.isrButton}>{lang("ticketReport")}</Button>
            :
            <React.Fragment>
                <Button color="primary" variant="contained" component={Link} to="/isr/ticketReport" className={classes.isrButton}>{lang("ticketReport")}</Button>
                <Button color="primary" variant="contained" component={Link} to="/isr/spreadPuller" className={classes.isrButton}>{lang("spreadPuller")}</Button>
                <Button color="primary" variant="contained" component={Link} to="/isr/jobAssociations" className={classes.isrButton}>{lang("jobAssociations")}</Button>
                <Button color="primary" variant="contained" component={Link} to="/isr/impersonate" className={classes.isrButton}>{lang("impersonateUser")}</Button>
            </React.Fragment>;
        return (
            <div>
                <Typography className={classes.heading} variant='h5'>{lang("isr")}</Typography>
                <hr className={classes.horizontalBar} />
                <p>
                    { buttons }
                </p>
            </div>
        );
    }
}

export default withStyles(IsrIndex, styles, { withTheme: true });