var _languageTable = { };

export function setLanguageLookup(lookup) {
    _languageTable = lookup;
}

export function lang(name) {
    var result = _languageTable[name];
    if (result) {
        return result;
    }
    return name;
}

export function langFormat(name) {
    var unformatted = lang(name);
    for (let i = 1; i < arguments.length; i++) {
        unformatted = unformatted.replace("{" + (i - 1).toString() + "}", arguments[i]);
    }
    return unformatted;
}