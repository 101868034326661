import React from 'react';
import { withStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { lang } from '../../Language';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const styles = theme => ({
    layout: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    center: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(1),
    }
});

function PaperSheet(props) {
    const { classes } = props;

    return (
        <main className={classes.layout}>
            <div>
                <Paper className={classes.root} elevation={1}>
                    <Typography align="center" variant="h5" component="h3">
                        {lang("404")}
                    </Typography>
                    <Typography align="center" component="p">
                        {lang("notFoundDesc")}
                    </Typography>
                    <div className={classes.center}>
                        <Button color="primary" variant="contained" component={Link} to='/'>
                            {lang("home")}
                        </Button>
                    </div>
                </Paper>
            </div>
        </main>
    );
}

export default withStyles(PaperSheet, styles);