import React from 'react';
import { lang } from '../../Language';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import green from '@mui/material/colors/green';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TicketReportList from './TicketReportList';
import TicketReportDetails from './TicketReportDetails';
import NativeSelect from '@mui/material/NativeSelect';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { overrideComponentThemeStyle } from '../../StyleCustomization';
import { dateParser } from '../../HelperMethods';

const styles = theme => {
    var result = {
        heading: {
            fontWeight: 'bold',
            marginTop: theme.spacing(1)
        },
        horizontalBar: {
            borderColor: 'black'
        },
        formFix: {
            width: '100%', //Only needed for IE11
        },
        searchSection: {
            width: 900,
        },
        searchType: {
            width: 48,
            marginTop: 15,
            alignItems: 'flex-start',
            display: 'inline-table'
        },
        searchTypeSection: {
            display: 'flex',
        },
        dropDownContainer: {
            width: 274,
            marginRight: 15
        },
        submit: {
            margin: '15px 0px 25px 45px',
        },
        filterSection: {
            '& div:not(:last-child)': {
                marginBottom: 2
            },
            marginLeft: 2
        },
        filterSectionLabel: {
            display: 'inline-block',
            marginRight: 25,
            verticalAlign: 'middle',
            whiteSpace: 'nowrap'
        },
        showLateProofCheckbox: {
            marginLeft: -20,
            padding: 5,
        },
        filterControl: {
            padding: 5,
        },
        filterLabel: {
            marginRight: 25,
        },
        groupHeader: {
            marginTop: theme.spacing(1),
            cursor: 'pointer',
            userSelect: 'none',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: '#CCC',
            background: "#EEE",
            '&:hover': {
                background: '#D8D8D8'
            },
            'transition': 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        },
        reportSection: {
            overflow: 'auto'
        },
        headerSection: {
            height: '100%',
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: 'calc(100% - 55px)',
            left: 'calc(100% - 65px)',
            overflow: 'hidden', //Needed for IE11 to stop showing scroll bars
        }
    };
    result = overrideComponentThemeStyle(theme, result, "ticketReport");
    return result;
};

const SearchType = Object.freeze({
    PubIssue: 1,
    Submission: 2,
    Ticket: 3,
    TicketComponent: 4
});

class TicketReport extends React.Component {
    displayName = TicketReport.name

    constructor(props) {
        super(props);
        this.state = {
            showError: false,
            error: '',
            errorMessage: '',
            currentReport: {
                publishers: null,
                publications: null,
                issues: null,
                mediaTypes: null,
                selectedMediaTypes: [],
                ticketStatuses: null,
                selectedTicketStatuses: [],
                proofTypes: null,
                selectedProofTypes: [],
                billableOptions: null,
                selectedBillableOption: '',
                proofReceivedOptions: null,
                selectedProofReceivedOption: '',
                anyColor: true,
                colorC: false,
                colorM: false,
                colorY: false,
                colorK: false,
                tickets: [],
                publisherId: '',
                publicationId: '',
                issueId: '',
                ticketId: '',
                ticketComponentId: '',
                showLateProofSubmissionsOnly: false,
                searchTypeId: SearchType.PubIssue,
                multiSearchTypeId: SearchType.Ticket,
                multiSearchTypeValue: '',
                ticketReportIsLoading: false,
                ticketReportLoaded: false,
                ticketDetailsLoaded: false,
                additionalFiltersCollapsed: false,
                ticket: null,
                ticketComponentStatuses: null
            }
        };
    }

    componentDidMount() {
        let { currentReport } = this.state;
        if (!currentReport.publishers) {
            this.loadPublishers();
        }
    }

    updateReport = (state) => {
        this.setState({ currentReport: state });
    }

    closeErrorDialog = () => {
        this.setState({ error: '', errorMessage: '', showError: false });
    };

    loadPublishers = () => {
        fetch('SvrIsr/PublisherList', { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                let { currentReport } = this.state;
                currentReport.publishers = data;
                this.updateReport(currentReport);
            });
    }

    loadPublications = () => {
        let { currentReport } = this.state;
        fetch(`SvrIsr/PublicationList?publisherId=${currentReport.publisherId}`, { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                let { currentReport } = this.state;
                currentReport.publications = data;
                this.updateReport(currentReport);
            });
    }

    loadIssues = () => {
        let { currentReport } = this.state;
        fetch(`SvrIsr/IssueList?publisherId=${currentReport.publisherId}&publicationId=${currentReport.publicationId}`, { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                let { currentReport } = this.state;
                currentReport.issues = data;
                this.updateReport(currentReport);
            });
    }

    loadAdditionalFilterSelections = () => {
        let { currentReport } = this.state;
        fetch(`SvrIsr/AdditionalFilterSelection?publisherId=${currentReport.publisherId}&publicationId=${currentReport.publicationId}`, { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                let { currentReport } = this.state;
                currentReport.mediaTypes = data.mediaTypes;
                currentReport.ticketStatuses = data.ticketStatuses;
                currentReport.proofTypes = data.proofTypes;
                currentReport.billableOptions = data.billableOptions;
                currentReport.proofReceivedOptions = data.proofReceivedOptions;
                currentReport.selectedMediaTypes = data.selectedMediaTypes;
                currentReport.selectedTicketStatuses = data.selectedTicketStatuses;
                currentReport.selectedProofTypes = data.selectedProofTypes;
                currentReport.selectedBillableOption = data.selectedBillableOption.toString();
                currentReport.selectedProofReceivedOption = data.selectedProofReceivedOption.toString();
                this.updateReport(currentReport);
            });
    }

    toggleGroup = (groupName) => {
        let { currentReport } = this.state;
        currentReport[groupName] = !currentReport[groupName];
        this.updateReport(currentReport);
    }

    getSearchTypeDescription = (id) => {
        let { currentReport } = this.state;
        let result = "";
        if (currentReport.multiSearchTypeId === SearchType.Submission) {
            result = lang("submissionId");
        }
        else if (currentReport.multiSearchTypeId === SearchType.Ticket) {
            result = lang("ticketId");
        }
        else if (currentReport.multiSearchTypeId === SearchType.TicketComponent) {
            result = lang("ticketComponentId");
        }
        return result;
    }

    handleClick = (event) => {
        let { currentReport } = this.state;
        if (event.target.name === 'publisherId' || event.target.name === 'publicationId' || event.target.name === 'issueId') {
            currentReport.searchTypeId = SearchType.PubIssue;
        }
        if (event.target.name === 'multiSearchType' || event.target.name === 'multiSearchTypeValue') {
            currentReport.searchTypeId = currentReport.multiSearchTypeId;
        }
        this.updateReport(currentReport);
    };

    handleChange = (event) => {
        let { currentReport } = this.state;
        if (event.target.name === 'publisherId') {
            currentReport.publisherId = event.target.value;
            this.loadPublications();
        }
        else if (event.target.name === 'publicationId') {
            currentReport.publicationId = event.target.value;
            this.loadIssues();
            this.loadAdditionalFilterSelections();
        }
        else if (event.target.name === 'issueId') {
            currentReport.issueId = event.target.value;
            this.handleSubmit(event);
        }
        else if (event.target.name === 'searchType') {
            currentReport.searchTypeId = Number(event.target.value);
            var publisherId = document.getElementById('publisherId');
            var publicationId = document.getElementById('publicationId');
            var multiSearchTypeValue = document.getElementById('multiSearchTypeValue');
            publisherId.setCustomValidity('');
            if (publicationId) {
                publicationId.setCustomValidity('');
            }
            multiSearchTypeValue.setCustomValidity('');
        }
        else if (event.target.name === 'multiSearchType') {
            currentReport.multiSearchTypeId = Number(event.target.value);
            currentReport.searchTypeId = Number(event.target.value);
        }
        else if (event.target.name === 'ticketId') {
            currentReport.ticketId = event.target.value.replace(/[^0-9]/g, '');
        }
        else if (event.target.name === 'ticketComponentId') {
            currentReport.ticketComponentId = event.target.value.replace(/[^0-9]/g, '');
        }
        else if (event.target.name === 'multiSearchTypeValue') {
            currentReport.multiSearchTypeValue = event.target.value.replace(/[^0-9]/g, '');
        }
        else if (event.target.name === 'mediaType') {
            var mediaTypeIndex = currentReport.selectedMediaTypes.indexOf(Number(event.target.value));
            if (mediaTypeIndex > -1) {
                currentReport.selectedMediaTypes.splice(mediaTypeIndex, 1);
            } else {
                currentReport.selectedMediaTypes.push(Number(event.target.value));
            }
        }
        else if (event.target.name === 'showLateProofSubmissionsOnly') {
            currentReport.showLateProofSubmissionsOnly = !currentReport.showLateProofSubmissionsOnly;
        }
        else if (event.target.name === 'ticketStatus') {
            var statusIndex = currentReport.selectedTicketStatuses.indexOf(Number(event.target.value));
            if (statusIndex > -1) {
                currentReport.selectedTicketStatuses.splice(statusIndex, 1);
            } else {
                currentReport.selectedTicketStatuses.push(Number(event.target.value));
            }
        }
        else if (event.target.name === 'proofType') {
            var proofTypeIndex = currentReport.selectedProofTypes.indexOf(Number(event.target.value));
            if (proofTypeIndex > -1) {
                currentReport.selectedProofTypes.splice(proofTypeIndex, 1);
            } else {
                currentReport.selectedProofTypes.push(Number(event.target.value));
            }
        }
        else if (event.target.name === 'ticketColor') {
            currentReport[event.target.value] = !currentReport[event.target.value];
        }
        else if (event.target.name === 'proofReceived') {
            currentReport.selectedProofReceivedOption = event.target.value;
        }
        else if (event.target.name === 'billable') {
            currentReport.selectedBillableOption = event.target.value;
        }

        this.updateReport(currentReport);
    };

    handleSubmit = (e) => {
        if (!this.state.submitting) {
            let { currentReport } = this.state;
            var form = document.getElementById("searchForm");
            var publisherId = document.getElementById('publisherId');
            var publicationId = document.getElementById('publicationId');
            var multiSearchTypeValue = document.getElementById('multiSearchTypeValue');
            publisherId.setCustomValidity('');
            if (publicationId) {
                publicationId.setCustomValidity('');
            }
            multiSearchTypeValue.setCustomValidity('');

            if (currentReport.searchTypeId === SearchType.PubIssue) {    
                if (publisherId.value === '') {
                    publisherId.setCustomValidity(lang('publisherRequired'));
                } else {
                    if (publicationId.value === '') {
                        publicationId.setCustomValidity(lang('publicationRequired'));
                    }
                }
            }
            else if (currentReport.searchTypeId !== SearchType.PubIssue && multiSearchTypeValue.value === '') {
                multiSearchTypeValue.setCustomValidity(lang('requiredField'));
            }

            if (!form.checkValidity()) {
                return; //Regular submit will happen, causing built in form validation to work.
            }
            e.preventDefault();

            currentReport.ticketReportLoaded = false;
            currentReport.ticketDetailsLoaded = false;
            this.updateReport(currentReport);

            this.setState({ submitting: true });
            if (currentReport.searchTypeId === SearchType.PubIssue || currentReport.searchTypeId === SearchType.Submission) {
                require('formdata-polyfill'); //IE11 needs this for FormData POST to work correctly.
                var formData = new FormData();
                if (currentReport.searchTypeId === SearchType.PubIssue) {
                    formData.append("publisherId", currentReport.publisherId);
                    formData.append("publicationId", currentReport.publicationId);
                    formData.append("issueId", currentReport.issueId);
                    formData.append("mediaTypes", currentReport.selectedMediaTypes);
                    formData.append("showLateProofSubmissionsOnly", currentReport.showLateProofSubmissionsOnly);
                    formData.append("statuses", currentReport.selectedTicketStatuses);
                    formData.append("billingFilter", currentReport.selectedBillableOption);
                    formData.append("proofTypes", currentReport.selectedProofTypes);
                    formData.append("filterColors", !currentReport.anyColor);
                    formData.append("colorC", currentReport.colorC);
                    formData.append("colorM", currentReport.colorM);
                    formData.append("colorY", currentReport.colorY);
                    formData.append("colorK", currentReport.colorK);
                    formData.append("proofReceivedFilter", currentReport.selectedProofReceivedOption);
                }
                else {
                    formData.append("submissionId", currentReport.multiSearchTypeValue);
                }
                fetch("SvrIsr/TicketList", {
                    method: "POST",
                    body: formData,
                    credentials: "same-origin"
                })
                .then(response => response.text())
                .then(data => {
                    if (data) {
                        data = JSON.parse(data, dateParser);
                        let { currentReport } = this.state;
                        currentReport.tickets = data;
                        currentReport.ticketReportLoaded = true;
                        this.updateReport(currentReport);
                        this.setState({ submitting: false });
                    }
                });
            }
            else if (currentReport.searchTypeId === SearchType.Ticket || currentReport.searchTypeId === SearchType.TicketComponent) {
                var ticketId = currentReport.searchTypeId === SearchType.Ticket ? document.getElementById('multiSearchTypeValue').value : null;
                var ticketComponentId = currentReport.searchTypeId === SearchType.TicketComponent ? document.getElementById('multiSearchTypeValue').value : null;

                fetch(`SvrIsr/GetTicketDetail?tid=${ticketId}&tcid=${ticketComponentId}`, { method: 'post', credentials: 'same-origin' })
                    .then(response => response.text())
                    .then(data => {
                        if (data) {
                            data = JSON.parse(data, dateParser);
                            let { currentReport } = this.state;
                            if (data.success) {
                                currentReport.ticket = data.ticket;
                                currentReport.ticketComponentStatuses = data.ticketComponentStatuses;
                                currentReport.ticketDetailsLoaded = true;
                                this.updateReport(currentReport);
                                this.setState({ submitting: false });
                            }
                            else {
                                this.setState({ submitting: false, showError: true, error: data.error });
                            }
                        }
                    });
            }
        }
        return false;
    };

    render() {
        const { classes } = this.props;
        const { currentReport } = this.state;
        let issueSelector = "";
        let issueSelectorFilters = "";
        let multiTypeSelector = "";
        let report = '';

        issueSelectorFilters =
            <React.Fragment>
                {currentReport.publicationId !== "" &&
                    [<Collapse key="additionalFilters" in={!currentReport.additionalFiltersCollapsed} collapsedSize="35px">
                        <div className={classes.groupHeader} onClick={() => this.toggleGroup('additionalFiltersCollapsed')}>
                            <Typography variant="body2">{currentReport.additionalFiltersCollapsed ?
                                [<ArrowDropDown key="arrow" style={{ verticalAlign: 'middle' }} />] :
                                [<ArrowDropUp key="arrow" style={{ verticalAlign: 'middle' }} />]}
                                {lang("additionalFilters")}
                            </Typography>
                        </div>
                        <div className={classes.filterSection}>
                            {currentReport.publicationId !== '' &&
                                <React.Fragment>
                                    {currentReport.mediaTypes === null ? <LinearProgress key="mediaTypeLoading" variant="query" /> :
                                        [<div key="mediaTypes">
                                            <Typography variant="subtitle2" className={classes.filterSectionLabel}>
                                                {lang("ticketType")}:
                                            </Typography>
                                            {currentReport.mediaTypes.map(mediaType => (
                                                <FormControlLabel key={mediaType.id} className={classes.filterLabel} value={mediaType.id.toString()} label={<Typography variant="body2">{mediaType.name}</Typography>} control={
                                                    <Checkbox className={classes.filterControl} name="mediaType" value={mediaType.id.toString()} onChange={this.handleChange}
                                                        checked={currentReport.selectedMediaTypes.find(function (element) { return element === mediaType.id; }) !== undefined}
                                                    />}
                                                />
                                            ))}
                                        </div>]
                                    }
                                    <div key="showLateProofSubmissionsOnly">
                                        <Typography variant="subtitle2" className={classes.filterSectionLabel}>
                                            {lang("showLateProofSubmissionsOnly")}:
                                        </Typography>
                                        <Checkbox name="showLateProofSubmissionsOnly" value="showLateProofSubmissionsOnly"
                                            checked={currentReport.showLateProofSubmissionsOnly} onChange={this.handleChange} className={classes.showLateProofCheckbox}
                                        />
                                    </div>
                                    {currentReport.ticketStatuses === null ? <LinearProgress key="ticketStatusLoading" variant="query" /> :
                                        [<div key="ticketStatuses">
                                            <Typography variant="subtitle2" className={classes.filterSectionLabel}>
                                                {lang("ticketStatus")}:
                                            </Typography>
                                            {currentReport.ticketStatuses.map(ticketStatus => (
                                                <FormControlLabel key={ticketStatus.id} value={ticketStatus.id.toString()} label={<Typography variant="body2">{ticketStatus.name}</Typography>} control={
                                                    <Checkbox className={classes.filterControl} name="ticketStatus" value={ticketStatus.id.toString()} onChange={this.handleChange}
                                                        checked={currentReport.selectedTicketStatuses.find(function (element) { return element === ticketStatus.id; }) !== undefined}
                                                    />}
                                                />
                                            ))}
                                        </div>]
                                    }
                                    {currentReport.proofTypes === null ? <LinearProgress key="proofTypeLoading" variant="query" /> :
                                        [<div key="proofTypes">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'top', paddingTop: 8}}>
                                                            <Typography variant="subtitle2" className={classes.filterSectionLabel}>
                                                                {lang("proofType")}:
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            {currentReport.proofTypes.map(proofType => (
                                                                <FormControlLabel key={proofType.id} value={proofType.id.toString()} label={<Typography variant="body2">{lang(proofType.name)}</Typography>} control={
                                                                    <Checkbox className={classes.filterControl} name="proofType" value={proofType.id.toString()} onChange={this.handleChange}
                                                                        checked={currentReport.selectedProofTypes.find(function (element) { return element === proofType.id; }) !== undefined}
                                                                    />}
                                                                />
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>]
                                    }
                                    <div key="colors">
                                        <Typography variant="subtitle2" className={classes.filterSectionLabel}>
                                            {lang("colors")}:
                                        </Typography>
                                    <FormControlLabel label={<Typography variant="body2">{lang("anyColor")}</Typography>} control={
                                            <Checkbox name="ticketColor" value={"anyColor"} onChange={this.handleChange} className={classes.filterControl} checked={currentReport.anyColor} />}
                                        />
                                    <FormControlLabel label={<Typography variant="body2">{lang("C")}</Typography>} control={
                                            <Checkbox name="ticketColor" value={"colorC"} onChange={this.handleChange} className={classes.filterControl} checked={currentReport.colorC} disabled={currentReport.anyColor} />}
                                        />
                                        <FormControlLabel label={<Typography variant="body2">{lang("M")}</Typography>} control={
                                            <Checkbox name="ticketColor" value={"colorM"} onChange={this.handleChange} className={classes.filterControl} checked={currentReport.colorM} disabled={currentReport.anyColor} />}
                                        />
                                        <FormControlLabel label={<Typography variant="body2">{lang("Y")}</Typography>} control={
                                            <Checkbox name="ticketColor" value={"colorY"} onChange={this.handleChange} className={classes.filterControl} checked={currentReport.colorY} disabled={currentReport.anyColor} />}
                                        />
                                        <FormControlLabel label={<Typography variant="body2">{lang("K")}</Typography>} control={
                                            <Checkbox name="ticketColor" value={"colorK"} onChange={this.handleChange} className={classes.filterControl} checked={currentReport.colorK} disabled={currentReport.anyColor} />}
                                        />
                                </div>
                                {this.props.userInfo.isClientAdminAndHasPublishersAssigned ? '' : 
                                        currentReport.billableOptions === null ? <LinearProgress key="billableOptionsLoading" variant="query" /> :
                                            [<div key="billable">
                                                <Typography variant="subtitle2" className={classes.filterSectionLabel}>
                                                    {lang("billable")}:
                                            </Typography>
                                                {currentReport.billableOptions.map(billableOption => (
                                                    <FormControlLabel key={billableOption.id} value={billableOption.id.toString()} label={<Typography variant="body2">{billableOption.name}</Typography>} control=
                                                        {<Radio className={classes.filterControl} name="billable" onChange={this.handleChange} checked={currentReport.selectedBillableOption === billableOption.id.toString()} />}
                                                    />
                                                ))}
                                            </div>]
                                }
                                    {currentReport.proofReceivedOptions === null ? <LinearProgress key="proofReceivedOptionsLoading" variant="query" /> :
                                        [<div key="proofReceived">
                                            <Typography variant="subtitle2" className={classes.filterSectionLabel}>
                                                {lang("proofReceived")}:
                                            </Typography>
                                            {currentReport.proofReceivedOptions.map(proofReceivedOption => (
                                                <FormControlLabel key={proofReceivedOption.id} value={proofReceivedOption.id.toString()} label={<Typography variant="body2">{proofReceivedOption.name}</Typography>} control={
                                                    <Radio className={classes.filterControl} name="proofReceived" onChange={this.handleChange}
                                                        checked={currentReport.selectedProofReceivedOption === proofReceivedOption.id.toString()}
                                                    />}
                                                />
                                            ))}
                                        </div>]
                                    }
                                </React.Fragment>
                            }
                        </div>
                    </Collapse>]
                }
            </React.Fragment>;

        issueSelector =
            <div className={classes.searchTypeSection}>
                <Radio
                    className={classes.searchType}
                    checked={currentReport.searchTypeId === SearchType.PubIssue}
                    onChange={this.handleChange}
                    value={SearchType.PubIssue}
                    name="searchType"
                    aria-label="searchByPubIssue"
                />
                <div>
                    <div className={classes.issueSelectorSection} style={{ display: 'flex' }}>
                        {!currentReport.publishers ? <CircularProgress /> :
                            <React.Fragment>
                                <FormControl key="publisher" margin="dense" fullWidth className={classes.dropDownContainer} variant="standard">
                                    <InputLabel shrink htmlFor="publisherId">{lang("publisher")}</InputLabel>
                                    <NativeSelect id="publisherId" name="publisherId" value={currentReport.publisherId} onChange={this.handleChange} onClick={this.handleClick} >
                                        <option value="">{lang("selectPublisher")}</option>
                                        {currentReport.publishers.map(publisher => (
                                            <option key={publisher.id} value={publisher.id}>{publisher.name}</option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                                {!currentReport.publications || currentReport.publications === null ? <LinearProgress key="publicationLoading" variant="query" /> :
                                    <FormControl key="publication" margin="dense" fullWidth className={classes.dropDownContainer} variant="standard">
                                        <InputLabel shrink htmlFor="publicationId">{lang("publication")}</InputLabel>
                                        <NativeSelect id="publicationId" name="publicationId" value={currentReport.publicationId} onChange={this.handleChange} onClick={this.handleClick} >
                                            <option value="">{lang("selectPublication")}</option>
                                            {currentReport.publications.map(publication => (
                                                <option key={publication.id} value={publication.id}>{publication.name}</option>
                                            ))}
                                        </NativeSelect>
                                    </FormControl>
                                }
                                {!currentReport.issues && currentReport.issues === null ? <LinearProgress key="issueLoading" variant="query" /> :
                                    <FormControl key="issue" margin="dense" fullWidth style={{ width: 274 }} variant="standard">
                                        <InputLabel shrink htmlFor="issueId">{lang("issue")}</InputLabel>
                                        <NativeSelect id="issueId" name="issueId" value={currentReport.issueId} onChange={this.handleChange} onClick={this.handleClick} >
                                            <option value="">{lang("selectIssue")}</option>
                                            {currentReport.issues.map(issue => (
                                                <option key={issue.id} value={issue.id}>{issue.name}</option>
                                            ))}
                                        </NativeSelect>
                                    </FormControl>
                                }
                            </React.Fragment>
                        }
                    </div>
                    {issueSelectorFilters}
                </div>
            </div>;

        multiTypeSelector =
            <div className={classes.searchTypeSection}>
                <Radio
                    className={classes.searchType}
                    checked={currentReport.searchTypeId !== SearchType.PubIssue}
                    onChange={this.handleChange}
                    value={currentReport.multiSearchTypeId}
                    name="multiSearchType"
                    aria-label="idSelector"
                />
                <div style={{ display: "flex", width: "100%" }}>
                    <FormControl key="searchTypeSelector" margin="dense" fullWidth variant="standard" className={classes.dropDownContainer} style={{ flex: "1 0 273px" }}>
                        <InputLabel shrink htmlFor="multiSearchType">{lang("type")}</InputLabel>
                        <NativeSelect id="multiSearchType" name="multiSearchType" value={currentReport.multiSearchTypeId} onChange={this.handleChange} onClick={this.handleClick} >
                            <option value={SearchType.Submission}>{lang("submissionId")}</option>
                            <option value={SearchType.Ticket}>{lang("ticketId")}</option>
                            <option value={SearchType.TicketComponent}>{lang("ticketComponentId")}</option>
                        </NativeSelect>
                    </FormControl>
                    <FormControl key="multiSearchTypeValue" margin="dense" fullWidth variant="standard">
                        <InputLabel htmlFor="multiSearchTypeValue">{this.getSearchTypeDescription(currentReport.searchTypeId)}&thinsp;*</InputLabel>
                        <Input id="multiSearchTypeValue" name="multiSearchTypeValue" value={currentReport.multiSearchTypeValue} autoComplete="off" onChange={this.handleChange} onClick={this.handleClick} inputProps={{ maxLength: "50" }} />
                    </FormControl>
                </div>
            </div>;

        if (currentReport.ticketReportLoaded) {
            report = <TicketReportList userInfo={this.props.userInfo} currentReport={currentReport} updateReport={this.updateReport} />;
        }
        else if (currentReport.ticketDetailsLoaded) {
            report = <TicketReportDetails userInfo={this.props.userInfo} currentReport={currentReport} updateReport={this.updateReport} />;
        }

        return (
            <div>
                <div className={classes.headerSection}>
                    <Typography className={classes.heading} variant='h5'>{lang("ticketReport")}</Typography>
                    <hr className={classes.horizontalBar} />
                </div>
                <div className={classes.searchSection}>
                    <form id="searchForm" className={classes.formFix} >
                        {issueSelector}
                        {multiTypeSelector}
                        <div style={{ display: 'inline-block', position: 'relative' }}>
                            <Button type="submit" variant="contained" color="primary" data-component="ticketReport" data-classname="submit" className={classes.submit} onClick={this.handleSubmit} disabled={this.state.submitting}>
                                {lang("viewReport")}
                            </Button>
                                {this.state.submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </form>
                </div>
                <div className={classes.reportSection}>
                    {report}
                </div>
                <Dialog open={this.state.showError} onClose={this.closeErrorDialog} describedby="alert-dialog-description">
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography variant="body2">{lang(this.state.error)}</Typography>
                            {this.state.errorMessage.length > 0 && <Typography variant="body2">{lang(this.state.errorMessage)}</Typography>}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeErrorDialog} color="secondary" variant="contained" autoFocus>
                            {lang("ok")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>);
    }
}

export default withStyles(TicketReport, styles, { withTheme: true });