import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { lang, langFormat } from '../../Language';
import MediaFileRow from './MediaFileRow';
import { TicketComponentStatus } from '../../TicketComponentStatus';
import { MediaFileType } from './TicketDetailsCustom';

const styles = theme => ({
    heading: {
        fontWeight: 'bold',
        marginTop: theme.spacing(1),
    },
    horizontalBar: {
        borderColor: 'black',
    },
    body: {
        marginTop: theme.spacing(1),
    },
    dialogContent: {
        position: 'relative'
    },
    dialogActions: {
        display: 'block',
        justifyContent: 'none',
        textAlign: 'center'
    },
    iframe: {
        width: '100%',
        height: 'calc(100vh - 240px)',
    },
    spacer: {
        marginBottom: 10,
    },
    mediaFileRows: {
        marginLeft: 50,
        minWidth: 380
    },
    mediaFileRowsHeader: {
        paddingBottom: 30
    },
    mediaFileRowFilenameText: {
        marginLeft: 50
    },
    mediaFileRowPreviewText: {
        marginLeft: 155
    },
    inlineBlock: {
        display: 'inline-block',
        verticalAlign: 'top',
        paddingBottom: 50
    },
    btnNewSubmission: {
        marginLeft: theme.spacing(2),
    },
});

class TicketSubmitted extends Component {
    displayName = TicketSubmitted.name

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false
        };
    }

    returnToTrackDeliveries = () => {
        let { currentSubmission, updateSubmission } = this.props;
        currentSubmission.ticket = null;
        currentSubmission.redirectLocation = '/track';
        updateSubmission(currentSubmission);
    }

    startNewTicket = () => {
        let { currentSubmission, updateSubmission } = this.props;
        currentSubmission.ticket = null;
        currentSubmission.redirectLocation = '/ticket/new';
        updateSubmission(currentSubmission);
    }

    openDialog = () => {
        this.setState({ showDialog: true });
    }

    closeDialog = () => {
        this.setState({ showDialog: false });
    }

    render() {

        const { classes, currentSubmission, updateSubmission } = this.props;

        let mediaFileRows = '';
        if (!currentSubmission.ticket.isPrint && currentSubmission.ticket.components.length > 0 &&
            Number(currentSubmission.ticket.fileTypeId) !== MediaFileType.DETAILS_ONLY) {
            mediaFileRows = (
                <div className={classes.mediaFileRows}>
                    <div className={classes.mediaFileRowsHeader}>
                        <Typography variant="body2">
                            <span className={classes.mediaFileRowFilenameText}>{lang('filenames')}</span>
                            <span className={classes.mediaFileRowPreviewText}>{lang('previews')}</span>
                        </Typography>
                    </div>
                    {currentSubmission.ticket.components.map((component, index) => {
                        if (component.status > TicketComponentStatus.Prechecking) {
                            let filePath = `File/StreamFile?fileType=${component.fileType}&id=${component.id}&hash=${component.fileHash}`;
                            return <MediaFileRow readonly currentSubmission={currentSubmission} updateSubmission={updateSubmission} key={component.id} component={component} filePath={filePath} componentIndex={index} />;
                        }
                        return '';
                    })}
                </div>
            );
        }

        return (
            <div className={classes.body} >
                <Typography variant="h5" className={classes.heading}>{lang('confirmationNumber')}: {currentSubmission.ticket.id}</Typography>
                <hr className={classes.horizontalBar} />
                <div className={classes.inlineBlock}>
                    <div className={classes.inlineBlock}>
                        <div className={classes.spacer}><Typography variant="body2">{langFormat('adSuccessfullyDelivered', currentSubmission.ticket.publicationName, currentSubmission.ticket.issueName)}</Typography></div>
                        <div className={classes.spacer}><Typography variant="body2"><Link to='#' onClick={this.openDialog}>{lang('clickHere')}</Link>{` ${lang('printConfirmation')}`}</Typography></div>
                        <Button onClick={this.returnToTrackDeliveries} variant='contained' color='secondary'>{lang('returnToTrackDeliveries')}</Button>
                        <Button className={classes.btnNewSubmission} onClick={this.startNewTicket} color="secondary" variant="contained" >
                            {lang("startNewTicket")}
                        </Button>
                    </div>
                    <div className={classes.inlineBlock}>
                        {mediaFileRows}
                    </div>
                </div>
                <Dialog open={this.state.showDialog} fullWidth scroll='body' maxWidth='lg' onClose={this.closeDialog} >
                    <DialogTitle id="form-dialog-title">{lang('adTicketConfirmationDialogTitle')}</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <iframe className={classes.iframe} title={this.state.currentId} src={`./File/StreamFile?fileType=Receipt&id=${currentSubmission.ticket.id}`} />
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button name='ok' variant='contained' autoFocus onClick={this.closeDialog} color="secondary">
                            {lang('ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(TicketSubmitted, styles);