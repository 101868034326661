import React from 'react';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { lang } from '../Language';
import { Link } from 'react-router-dom';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import TicketClosedDialog, { TicketState } from './Submission/TicketClosedDialog';
import { getComponentThemeProperty } from '../StyleCustomization';

const styles = theme => ({
    info: {
        paddingBottom: 10
    },
    gridInfo: {
        paddingTop: 6
    },
    radioGroup: {
        marginLeft: 10
    },
    radio: {
        padding: 2
    },
    smallerDialogTitle: {
        fontWeight: 'bold',
        fontSize: '1.25rem'
    },
    bold: {
        fontWeight: 'bold'
    },
    sectionHeader: {
        paddingTop: 20,
        paddingBottom: 5
    },
    textField: {
        width: 250
    },
    policyText: {
        paddingTop: 10
    },
    policyTextTitle: {
        paddingBottom: 20
    },
    padLeft: {
        paddingLeft: 24
    },
    forIE11: {
        display: 'none'
        //IE11 does not support submit buttons outside of a form via the form property.
        //This is needed to use built in HTML5 form validation.
        //The create button in the dialog calls an invisible button inside the form instead.
    }
});

const DialogType = Object.freeze({
    Error: 0,
    RequestPolicy: 1
});

class CyberSourceDialog extends React.Component {
    displayName = CyberSourceDialog.name

    constructor(props) {
        super(props);
        let defaultValue = props.proofPaymentData.shippingConfigs[props.proofPaymentData.shippingConfigs.length - 1].id.toString();
        let defaultTotalCost = `$${this.findShippingCostById(+defaultValue) + props.proofPaymentData.proofPrice}.00`; 
        this.state = {
            showDialog: false,
            radioValue: defaultValue,
            totalCost: defaultTotalCost,
            formToSubmit: undefined,
            dialogType: DialogType.RequestPolicy,
            dialogMessage: '',
            paymentUrl: '',
            ticketState: TicketState.None
        };
    }

    findShippingCostById = (id) => {
        const { proofPaymentData } = this.props;
        for (let i = 0; i < proofPaymentData.shippingConfigs.length; i++) {
            if (proofPaymentData.shippingConfigs[i].id === id) {
                return proofPaymentData.shippingConfigs[i].price;
            }
        }
        return 0;
    }

    proofPaymentChange = (event) => {
        let copies = document.getElementById('copies');
        let value = +copies.value;
        let newCost = '$--.--';
        if (event.target.name.indexOf('radio') !== -1) {
            if (!Number.isNaN(value)) {
                let shippingCostId = +event.target.value;
                value = (value * this.props.proofPaymentData.proofPrice) + this.findShippingCostById(shippingCostId);
                newCost = '$' + value + '.00';
                this.setState({ totalCost: newCost, radioValue: event.target.value });
            }
        }
        else {
            if (!Number.isNaN(value)) {
                let shippingCostId = this.state.radioValue;
                value = (value * this.props.proofPaymentData.proofPrice) + this.findShippingCostById(shippingCostId);
                newCost = '$' + value + '.00';
                this.setState({ totalCost: newCost });
            }
        }
    }

    showDialog = () => {
        this.setState({ showDialog: true, dialogType: DialogType.RequestPolicy });
    }

    closeDialog = () => {
        this.setState({ showDialog: false });
    }

    validateForm = (copies) => {
        let isValid = true;
        let copiesAmount = +copies.value;  //change string value into Number or NaN.
        if (Number.isNaN(copiesAmount) || !Number.isInteger(copiesAmount) || copiesAmount < 1 || copiesAmount > 10) {
            isValid = false;
            copies.setCustomValidity('Requires a number between 1 and 10');
        }
        else {
            copies.setCustomValidity('');
        }

        return isValid;
    }

    submitForm = (e) => {
        const { proofPaymentData } = this.props;
        let form = document.getElementById('CyberSourceShippingForm');
        let copies = document.getElementById('copies');
        let email = document.getElementById('email');
        let phoneNo = document.getElementById('phoneNumber');
        if (!this.validateForm(copies) || !form.checkValidity()) {
            return false;
        }
        e.preventDefault();
        fetch('SvrTicket/SignFormAndSend',
            {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    shippingConfigId: this.state.radioValue,
                    numberOfProofs: copies.value,
                    email: email.value,
                    phoneNumber: phoneNo.value,
                    tid: proofPaymentData.ticketId,
                    returnToTracking: this.props.cancelProofRequest === undefined
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    //Post Data to CyberSource and remove form from webpage
                    this.setState({ formToSubmit: data.request, paymentUrl: data.paymentUrl }, () => {
                        document.getElementById('CyberSourceSubmissionForm').submit();
                        this.setState({ formToSubmit: undefined, paymentUrl: '' });
                    });
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        this.setState({ showDialog: true, dialogMessage: lang(data.error), dialogType: DialogType.Error })
                    }
                }
            })
    }

    clickSubmit = () => {
        document.getElementById('submit').click();
    }

    render() {
        const { classes, proofPaymentData, open, close, cancelProofRequest } = this.props;
        let { formToSubmit, paymentUrl } = this.state;
        let showProofRequestGuideLink = getComponentThemeProperty("help", "showProofRequestGuideLink") === 'true';
        let cancelProofRequestButton = '';
        if (cancelProofRequest) {
            cancelProofRequestButton = (<Button variant='contained' autoFocus onClick={cancelProofRequest} color="primary">{lang('cancelProofRequest')}</Button>);
        }

        let dialogTitle = this.state.dialogType === DialogType.RequestPolicy ? lang('proofRequestPolicyTitle') : lang('error');


        return (
            <Dialog open={open} onClose={() => close(0)}>
                <DialogTitle className={classes.smallerDialogTitle} id="form-dialog-title">
                    {lang('requestedAColorProof')}
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div className={classes.info}><Typography variant="body2">{lang('proofInfoLine1')}</Typography></div>
                        {showProofRequestGuideLink ? (
                            <div className={classes.info}><Typography variant="body2">{`${lang('proofInfoLine2')} `}<a target='_blank' rel='noopener noreferrer' href={'./File/HelpResource?fileType=ProofRequestGuide'}>{lang('proofRequestGuide')}</a></Typography></div>
                        ) : null}
                        <div className={classes.info}><Typography variant="body2"><Link to='#' onClick={this.showDialog}>{lang('refundPolicy')}</Link></Typography></div>
                        <p />
                        <div className={classes.info}><Typography variant="body2" className={classes.bold}>{lang('numberOfCopies')}</Typography></div>
                        <form id='CyberSourceShippingForm'>
                            <div>
                                <FormControl margin='dense' fullWidth required variant="standard">
                                    <InputLabel htmlFor='copies'>{lang('copies')}</InputLabel>
                                    <Input id='copies' name='copies' defaultValue={1} autoComplete='copies' onChange={this.proofPaymentChange} />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl margin='dense' fullWidth required variant="standard">
                                    <InputLabel htmlFor='email'>{lang('email')}</InputLabel>
                                    <Input id='email' type="email" name='email' defaultValue={proofPaymentData.email} autoComplete='email' />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl margin='dense' fullWidth required variant="standard">
                                    <InputLabel htmlFor='phoneNumber'>{lang('phoneNumber')}</InputLabel>
                                    <Input id='phoneNumber' name='phoneNumber' defaultValue={proofPaymentData.phoneNumber} autoComplete='phoneNumber' />
                                </FormControl>
                            </div>
                            <div className={classes.sectionHeader}><Typography variant="body2" className={classes.bold}>{lang('selectShipping')}</Typography></div>
                            <RadioGroup value={this.state.radioValue} id='radioGroup' className={classes.radioGroup} onChange={this.proofPaymentChange}>
                                {proofPaymentData.shippingConfigs !== undefined ? proofPaymentData.shippingConfigs.map((config, index) => {
                                    return <FormControlLabel key={`ship_${index}`} label={`($${config.price}.00) ${config.description}`} value={`${config.id}`} control={<Radio color="primary" name={`radio-${index}`} className={classes.radio} />} />
                                }) : ''}
                            </RadioGroup>
                            <Button id='submit' className={classes.forIE11} type='submit' onClick={this.submitForm}>''</Button>
                        </form>
                        <div className={classes.sectionHeader}><Typography variant="body2" className={classes.bold}>{lang('proofCost')}</Typography></div>
                        <div className={classes.padLeft}><Typography variant="body2">{lang('costPerCopy')}</Typography></div>
                        <div className={classes.sectionHeader}><Typography variant="body2" className={classes.bold}>{`${lang('proofTotal')} ${this.state.totalCost}`}</Typography></div>
                        <Dialog open={this.state.showDialog} onClose={this.closeDialog} maxWidth='md'>
                            <DialogTitle className={classes.bold} id="form-dialog-title">{dialogTitle}</DialogTitle>
                            <DialogContent>
                                {this.state.dialogType === DialogType.RequestPolicy ? [
                                    <React.Fragment key="requestPolicyText">
                                        <div className={classes.policyText}><Typography variant="body2">{`${lang('proofRequestPolicyLine1')} `}<a target='_blank' rel='noopener noreferrer' href={lang('quadARMUrl')}>{lang('quadARMUrl')}</a>.</Typography></div>
                                        <div className={classes.policyText}><Typography variant="body2">{lang('proofRequestPolicyLine2')}</Typography></div>
                                        <div className={classes.policyText}><Typography variant="body2">{lang('proofRequestPolicyLine3')}</Typography></div>
                                    </React.Fragment>
                                ] :  this.state.dialogMessage }
                            </DialogContent>
                            <DialogActions>
                                <Button variant='contained' autoFocus onClick={this.closeDialog} color="secondary">
                                    {lang('ok')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    {this.state.formToSubmit !== undefined ? [
                        <form key="cyberSourceForm" id="CyberSourceSubmissionForm" action={paymentUrl} method="post">
                            <input type="hidden" name="access_key" value={formToSubmit.accessKey} />
                            <input type="hidden" name="amount" value={formToSubmit.amount} />
                            <input type="hidden" name="bill_to_address_city" value={formToSubmit.billToAddressCity} />
                            <input type="hidden" name="bill_to_address_line1" value={formToSubmit.billToAddressLine1} />
                            <input type="hidden" name="bill_to_address_line2" value={formToSubmit.billToAddressLine2} />
                            <input type="hidden" name="bill_to_email" value={formToSubmit.billToEmail} />
                            <input type="hidden" name="bill_to_forename" value={formToSubmit.billToFirstName} />
                            <input type="hidden" name="bill_to_phone" value={formToSubmit.billToPhoneNum} />
                            <input type="hidden" name="bill_to_surname" value={formToSubmit.billToLastName} />
                            <input type="hidden" name="currency" value={formToSubmit.currency} />
                            <input type="hidden" name="locale" value={formToSubmit.locale} />
                            <input type="hidden" name="merchant_defined_data1" value={formToSubmit.ticketId} />
                            <input type="hidden" name="merchant_defined_data2" value={formToSubmit.publisherName} />
                            <input type="hidden" name="merchant_defined_data3" value={formToSubmit.status} />
                            <input type="hidden" name="merchant_defined_data4" value={formToSubmit.publicationName} />
                            <input type="hidden" name="merchant_defined_data5" value={formToSubmit.issueName} />
                            <input type="hidden" name="merchant_defined_data6" value={formToSubmit.mediaType} />
                            <input type="hidden" name="merchant_defined_data7" value={formToSubmit.headline} />
                            <input type="hidden" name="merchant_defined_data8" value={formToSubmit.product} />
                            <input type="hidden" name="merchant_defined_data9" value={formToSubmit.size} />
                            <input type="hidden" name="merchant_defined_data10" value={formToSubmit.pms} />
                            <input type="hidden" name="merchant_defined_data11" value={formToSubmit.color} />
                            <input type="hidden" name="merchant_defined_data12" value={formToSubmit.proofCount} />
                            <input type="hidden" name="merchant_defined_data13" value={formToSubmit.proofShippingOption} />
                            <input type="hidden" name="override_custom_cancel_page" value={formToSubmit.returnURL} />
                            <input type="hidden" name="override_custom_receipt_page" value={formToSubmit.returnURL} />
                            <input type="hidden" name="profile_id" value={formToSubmit.profileId} />
                            <input type="hidden" name="reference_number" value={formToSubmit.referenceNumber} />
                            <input type="hidden" name="signature" value={formToSubmit.signature} />
                            <input type="hidden" name="signed_date_time" value={formToSubmit.signedDateTime} />
                            <input type="hidden" name="signed_field_names" value={formToSubmit.signedFieldNames} />
                            <input type="hidden" name="transaction_type" value={formToSubmit.transactionType} />
                            <input type="hidden" name="transaction_uuid" value={formToSubmit.transactionGuid} />
                            <input type="hidden" name="unsigned_field_names" value={formToSubmit.unsignedFieldNames} />
                        </form>
                    ] : ''}
                </DialogContent>
                <DialogActions>
                    {cancelProofRequestButton}
                    <Button variant='contained' onClick={() => close(0)} color="primary">
                        {lang('cancel')}
                    </Button>
                    <Button variant='contained' autoFocus onClick={this.clickSubmit} color="secondary">
                        {lang('payForProofs')}
                    </Button>
                </DialogActions>
                <TicketClosedDialog open={this.state.ticketState !== TicketState.None} isDeleted={this.state.ticketState === TicketState.Deleted} currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission} />
            </Dialog>
        );
    }
}

export default withStyles(CyberSourceDialog, styles, { withTheme: true });