import React from 'react';
import { lang } from '../../Language';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import green from '@mui/material/colors/green';
import Button from '@mui/material/Button';
import SalesReportList from './SalesReportList';
import NativeSelect from '@mui/material/NativeSelect';
import { overrideComponentThemeStyle } from '../../StyleCustomization';
import { dateParser } from '../../HelperMethods';

const styles = theme => {
    var result = {
        heading: {
            fontWeight: 'bold',
            marginTop: theme.spacing(1)
        },
        horizontalBar: {
            borderColor: 'black'
        },
        formFix: {
            width: '100%', //Only needed for IE11
        },
        searchSection: {
            width: 900,
        },
        searchType: {
            width: 48,
            marginTop: 15,
            alignItems: 'flex-start',
            display: 'inline-table'
        },
        searchTypeSection: {
            display: 'flex',
        },
        dropDownContainer: {
            width: 274,
            marginRight: 15
        },
        submit: {
            margin: '15px 0px 25px 45px',
        },
        filterSection: {
            '& div:not(:last-child)': {
                marginBottom: 2
            },
            marginLeft: 2
        },
        filterSectionLabel: {
            display: 'inline-block',
            marginRight: 25,
            verticalAlign: 'middle',
            whiteSpace: 'nowrap'
        },
        showLateProofCheckbox: {
            marginLeft: -20,
            padding: 5,
        },
        filterControl: {
            padding: 5,
        },
        filterLabel: {
            marginRight: 25,
        },
        groupHeader: {
            marginTop: theme.spacing(1),
            cursor: 'pointer',
            userSelect: 'none',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: '#CCC',
            background: "#EEE",
            '&:hover': {
                background: '#D8D8D8'
            },
            'transition': 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        },
        reportSection: {
            overflow: 'auto'
        },
        headerSection: {
            height: '100%',
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: 'calc(100% - 55px)',
            left: 'calc(100% - 65px)',
            overflow: 'hidden', //Needed for IE11 to stop showing scroll bars
        }
    };
    result = overrideComponentThemeStyle(theme, result, "salesReport");
    return result;
};

const pubIssueSearchTypeId = 1;

class SalesReport extends React.Component {
    displayName = SalesReport.name

    constructor(props) {
        super(props);
        this.state = {
            currentReport: {
                publishers: null,
                publications: null,
                issues: null,
                searchIsReady: false,
                tickets: [],
                publisherId: '',
                publicationId: '',
                issueId: '',
                searchTypeId: pubIssueSearchTypeId,
                ticketReportLoaded: false,
                ticket: null,
            }
        };
    }

    componentDidMount() {
        let { currentReport } = this.state;
        if (!currentReport.publishers) {
            this.loadPublishers();
        }
    }

    updateReport = (state, callback) => {
        if (!callback) {
            this.setState({ currentReport: state });
        }
        else
            this.setState({ currentReport: state }, callback);
    }

    loadPublishers = () => {
        fetch('SvrSales/PublisherList', { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                let { currentReport } = this.state;
                currentReport.publishers = data;
                currentReport.publisherId = currentReport.publishers && currentReport.publishers.length > 0 ? currentReport.publishers[0].id : undefined;
                this.updateReport(currentReport, this.loadPublications);
            });
    }

    loadPublications = () => {
        let { currentReport } = this.state;
        fetch(`SvrSales/PublicationList?publisherId=${currentReport.publisherId}`, { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                let { currentReport } = this.state;
                currentReport.publications = data;
                this.updateReport(currentReport);
            });
    }

    loadIssues = () => {
        let { currentReport } = this.state;
        fetch(`SvrSales/IssueList?publisherId=${currentReport.publisherId}&publicationId=${currentReport.publicationId}`, { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                let { currentReport } = this.state;
                currentReport.issues = data;
                this.updateReport(currentReport);
            });
    }

    handleChange = (event) => {
        let { currentReport } = this.state;
        if (event.target.name === 'publisherId') {
            currentReport.publisherId = event.target.value;
            this.setState({ searchIsReady: false });
            this.loadPublications();
        }
        else if (event.target.name === 'publicationId') {
            currentReport.publicationId = event.target.value;
            currentReport.issueId = '';
            this.setState({ searchIsReady: false });
            this.loadIssues();
        }
        else if (event.target.name === 'issueId') {
            currentReport.issueId = event.target.value;
            this.setState({ searchIsReady: true });
        }
        currentReport.ticketReportLoaded = false; //adjusting anything without clicking the button will hide it.

        this.updateReport(currentReport);
    };

    handleSubmit = (e) => {
        if (!this.state.submitting) {
            let { currentReport } = this.state;
            var form = document.getElementById("searchForm");

            if (!form.checkValidity()) {
                return; //Regular submit will happen, causing built in form validation to work.
            }
            e.preventDefault();

            currentReport.ticketReportLoaded = false;
            this.updateReport(currentReport);

            this.setState({ submitting: true });
            var formData = new FormData();
            formData.append("publisherId", currentReport.publisherId);
            formData.append("publicationId", currentReport.publicationId);
            formData.append("issueId", currentReport.issueId);
            fetch("SvrSales/TicketList", {
                method: "POST",
                body: formData,
                credentials: "same-origin"
            })
            .then(response => response.text())
            .then(data => {
                if (data) {
                    data = JSON.parse(data, dateParser);
                    let { currentReport } = this.state;
                    currentReport.tickets = data;
                    currentReport.ticketReportLoaded = true;
                    this.updateReport(currentReport);
                    this.setState({ submitting: false });
                }
            });
        }
        return false;
    };

    render() {
        const { classes } = this.props;
        const { currentReport } = this.state;
        let issueSelector = "";
        let report = '';

        issueSelector =
            <div className={classes.searchTypeSection}>
                <div>
                    <div className={classes.issueSelectorSection} style={{ display: 'flex' }}>
                        {!currentReport.publishers ? <CircularProgress /> :
                            <React.Fragment>
                                {!currentReport.publications || currentReport.publications === null ? <LinearProgress key="publicationLoading" variant="query" /> :
                                    <FormControl key="publication" margin="dense" fullWidth className={classes.dropDownContainer} variant="standard">
                                        <InputLabel shrink htmlFor="publicationId">{lang("publication")}</InputLabel>
                                        <NativeSelect id="publicationId" name="publicationId" value={currentReport.publicationId} onChange={this.handleChange}>
                                            <option value="">{lang("selectPublication")}</option>
                                            {currentReport.publications.map(publication => (
                                                <option key={publication.id} value={publication.id}>{publication.name}</option>
                                            ))}
                                        </NativeSelect>
                                    </FormControl>
                                }
                                {!currentReport.issues && currentReport.issues === null ? <LinearProgress key="issueLoading" variant="query" /> :
                                    <FormControl key="issue" margin="dense" fullWidth style={{ width: 274 }} variant="standard">
                                        <InputLabel shrink htmlFor="issueId">{lang("issue")}</InputLabel>
                                        <NativeSelect id="issueId" name="issueId" value={currentReport.issueId} onChange={this.handleChange}>
                                            <option value="">{lang("selectIssue")}</option>
                                            {currentReport.issues.map(issue => (
                                                <option key={issue.id} value={issue.id}>{issue.name}</option>
                                            ))}
                                        </NativeSelect>
                                    </FormControl>
                                }
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>;
        if (currentReport.ticketReportLoaded) {
            report = <SalesReportList userInfo={this.props.userInfo} currentReport={currentReport} updateReport={this.updateReport} />;
        }

        let noPublishersAvailable = currentReport.publisherId === undefined;

        return (
            <div>
                <div className={classes.headerSection}>
                    <Typography className={classes.heading} variant='h5'>{lang("sales")}</Typography>
                    <hr className={classes.horizontalBar} />
                </div>
                {!noPublishersAvailable && 
                    <div className={classes.searchSection}>
                        <form id="searchForm" className={classes.formFix} >
                            {issueSelector}
                            <div style={{ display: 'inline-block', position: 'relative' }}>
                                <Button type="submit" variant="contained" color="primary" data-component="ticketReport" data-classname="submit" className={classes.submit} onClick={this.handleSubmit} disabled={this.state.submitting || !this.state.searchIsReady}>
                                    {lang("viewAdList")}
                                </Button>
                                {this.state.submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </form>
                    </div>}
                {noPublishersAvailable && <div>
                    <Typography>{lang('noPubsOrIssues')}</Typography>
                </div>}
                <div className={classes.reportSection}>
                    {report}
                </div>
            </div>);
    }
}

export default withStyles(SalesReport, styles, { withTheme: true });