import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { lang, langFormat } from '../../Language';
import Typography from '@mui/material/Typography';
import { Link, Navigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ImageDisplay from '../Common/ImageDisplay';
import TicketClosedDialog, { TicketState } from '../Submission/TicketClosedDialog';
import GateDialog from '../Gatefold/GateDialog';
import CustomTooltip from '../Common/CustomTooltip';
import ResubmissionNotesDialog from '../Common/ResubmissionNotesDialog';

const styles = theme => ({
    grid: {
        paddingTop: 7,
        paddingBottom: 7,
        borderBottomStyle: 'solid',
        borderBottomWidth: 2,
        borderBottomColor: 'black'
    },
    component: {
        paddingTop: 5,
        paddingBottom: 5,
        width: 354,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            /* The scrollbar CSS entries here force horizontal scrollbars to appear on macOS even if they are disabled */
            webkitAppearance: 'none',
        },
        '&::-webkit-scrollbar:horizontal': {
            height: '11px',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '8px',
            border: '2px solid white',
            backgroundColor: 'rgba(0,0,0,0.5)',
        },
    },
    componentDetails: {
        paddingLeft: 20,
        paddingTop: 5,
        paddingBottom: 5,
        overflow: 'auto'
    },
    mediaPanelContainer: {
        width: 110,
        float: 'left'
    },
    panelLabel: {
        fontWeight: 'bold',
        position: 'absolute',
        top: 3,
        zIndex: 1
    },
    dialogContent: {
        position: 'relative',
    },
    dialogActions: {
        display: 'block',
        justifyContent: 'none',
        textAlign: 'center'
    },
    iframe: {
        width: '100%',
        height: 'calc(100vh - 240px)',
    },
    dialogComponent: {
        maxHeight: 'calc(100% - 20px)',
    },
    disabled: {
        color: theme.palette.text.disabled,
    },
});

const DialogType = Object.freeze({
    None: 0,
    Error: 1,
    DeleteConfirmation: 2,
    AdTicketConfirmation: 3,
    PrecheckReport: 4,
    PreflightReport: 5,
    GatefoldPreview: 6
});

const MILLIMETERS_PER_INCH = 25.4;

class ComponentDetails extends Component {
    displayName = ComponentDetails.name

    constructor(props) {
        super(props);
        this.state = {
            currentId: 0,
            showDialog: false,
            dialogMessage: '',
            dialogTitle: '',
            dialogType: DialogType.None,
            lastTicketToDelete: 0,
            ticketState: TicketState.None,
            redirectTo: null,
            showResubmissionNotesDialog: false
        };
    }

    deleteTicket = (ticketId) => {
        this.setState({ showDialog: true, dialogType: DialogType.DeleteConfirmation, lastTicketToDelete: ticketId });
    }

    confirmTicketDelete = (ticketId) => {
        fetch('SvrTicket/DeleteTicket?tid=' + ticketId, { method: 'POST', credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.props.deleteTicketHandler();
                    this.setState({ lastTicketToDelete: 0, dialogMessage: '' });
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        this.setState({ showDialog: true, dialogType: DialogType.Error, dialogMessage: lang(data.error) });
                    }
                }
            });
    }

    resubmitTicket = (ticketId, isResubmissionNoteRequired) => {
        if (!isResubmissionNoteRequired) {
            fetch('SvrTicket/ResubmitTicket', {
                body: JSON.stringify({ tid: ticketId }),
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
                .then(data => {
                    if (data.success) {
                        this.redirectToTicket(data.tid);
                    }
                    else {
                        this.setState({ showDialog: true, dialogType: DialogType.Error, dialogMessage: lang(data.error) });
                    }
                });
        }
        else {
            this.setState({ showResubmissionNotesDialog: true });
        }
    }

    redirectToTicket = (ticketId) => {
        if (this.props.updateSubmission && this.props.currentSubmission && this.props.currentSubmission.ticket) {
            this.props.currentSubmission.ticket = null;
            this.props.currentSubmission.editorInfo = null;
            this.props.updateSubmission(this.props.currentSubmission);
        }
        this.setState({ redirectTo: /ticket/ + ticketId })
    }

    renderTicketLinks = (data) => {
        var links = [];
        if (!data.isSubmitted && data.isContinueAllowed) {
            links.push(<div key={`${data.id}_continue`}><Typography variant="body2"><Link to={`/ticket/${data.id}`}>{lang('continue')}</Link></Typography></div>);
        }
        if (!data.isSubmitted && data.isDeleteAllowed) {
            links.push(<div key={`${data.id}_delete`}><Typography variant="body2"><Link to='#' onClick={() => this.deleteTicket(data.id)}>{lang('delete')}</Link></Typography></div>);
        }
        if (data.isSubmitted && data.isConfirmationAvailable) {
            links.push(<div key={`${data.id}_confirm`}><Typography variant="body2"><Link to='#' onClick={() => { this.showdialog(DialogType.AdTicketConfirmation, data.id) }}>{lang('adTicketConfirmation')}</Link></Typography></div>);
        }
        if (data.isPrint && data.canRequestProof && data.isSubmitted) {
            links.push(<div key={`${data.id}_buyMore`}><Typography variant="body2"><Link to='#' onClick={() => this.props.onBuyAdditionalProofs(data.id)}>{lang('buyAdditionalProofs')}</Link></Typography></div>);
        }
        if (data.isPrint && data.isPrintAvailable && data.allowTicketResubmission) {
            if (data.ticketResubmissionId) {
                links.push(
                    <CustomTooltip
                        key={`${data.id}_ticketResubmissionTooltip`}
                        interactive
                        placement="top-start"
                        title={
                            <React.Fragment>
                                <Typography variant="body2">
                                    {data.isTicketResubmissionInProgress ? lang('ticketResubmissionPendingResubmission') : lang('ticketResubmissionCompletedResubmission')}
                                </Typography>
                                <Typography variant="body2">
                                    <Link to='#' onClick={() => this.redirectToTicket(data.ticketResubmissionId)}>{lang('ticket')} #{data.ticketResubmissionId}</Link>
                                </Typography>
                            </React.Fragment>
                        }
                    >
                        <div key='5'><Typography variant="body2"><Link className={this.props.classes.disabled} to='#'>{lang('resubmitTicket')}</Link></Typography></div>
                    </CustomTooltip>);
            }
            else {
                links.push(<div key={`${data.id}_resubmit`}><Typography variant="body2"><Link to='#' onClick={() => this.resubmitTicket(data.id, data.isResubmissionNoteRequired)}>{lang('resubmitTicket')}</Link></Typography></div>);
            }
        }
        return links;
    }

    dialog = (classes) => {
        let dialogContents = '';
        let dialogTitle = '';
        let clickAction = this.closeDialog;
        let isReport = this.state.dialogType === DialogType.PrecheckReport || this.state.dialogType === DialogType.PreflightReport
            || this.state.dialogType === DialogType.AdTicketConfirmation;
        if (this.state.dialogType === DialogType.PrecheckReport) {
            dialogTitle = lang('precheckReport');
            dialogContents = <iframe className={this.props.classes.iframe} title={this.state.currentId} src={`./File/StreamFile?fileType=PrecheckReport&id=${this.state.currentId}`} />
        }
        else if (this.state.dialogType === DialogType.PreflightReport) {
            dialogTitle = lang('preflightReport');
            dialogContents = <iframe className={this.props.classes.iframe} title={this.state.currentId} src={`./File/StreamFile?fileType=PreflightReport&id=${this.state.currentId}`} />
        }
        else if (this.state.dialogType === DialogType.AdTicketConfirmation) {
            dialogTitle = lang('adTicketConfirmationDialogTitle');
            dialogContents = <iframe className={this.props.classes.iframe} title={this.state.currentId} src={`./File/StreamFile?fileType=Receipt&id=${this.state.currentId}`} />
        }
        else if (this.state.dialogType === DialogType.DeleteConfirmation) {
            dialogTitle = lang('deleteTicketDialogHeader');
            clickAction = () => this.closeDialog(true);
            dialogContents = <Typography variant="body2">{lang('deleteTicketDialogText')}</Typography>;
        }
        else if (this.state.dialogType === DialogType.Error) {
            dialogTitle = lang('error');
            dialogContents = <Typography variant="body2">{this.state.dialogMessage}</Typography>;
        }
        else if (this.state.dialogType === DialogType.GatefoldPreview) {
            return <GateDialog open={this.state.showDialog} closeDialog={this.closeDialog} ticket={this.props.data} />
        }

        return (
            <Dialog open={this.state.showDialog} classes={isReport ? { paperScrollPaper: classes.dialogComponent } : {}} scroll={isReport ? 'body' : 'paper'} fullWidth={isReport} maxWidth={isReport ? 'lg' : 'sm'} onClose={() => { this.closeDialog(); }} >
                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent className={isReport ? this.props.classes.dialogContent : ''}>
                    {dialogContents}
                </DialogContent>
                <DialogActions className={isReport ? this.props.classes.dialogActions : ''}>
                    {this.state.dialogType !== DialogType.DeleteConfirmation ? [''] :
                        [
                            <Button key={1} name='cancel' variant='contained' onClick={() => { this.closeDialog() }} color="primary">
                                {lang('cancel')}
                            </Button>
                        ]}
                    <Button name='ok' variant='contained' autoFocus onClick={clickAction} color="secondary">
                        {lang('ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    showdialog = (type, id) => {
        this.setState({ showDialog: true, dialogType: type, currentId: id });
    }

    closeDialog = (deleteTicket) => {
        if (deleteTicket !== undefined && deleteTicket && this.state.dialogType === DialogType.DeleteConfirmation) {
            this.confirmTicketDelete(this.state.lastTicketToDelete);
        }
        else {
            this.setState({ showDialog: false, lastTicketToDelete: 0 });
        }
    }

    renderComponentDetails = (ticket, component, index, hideLinks) => {
        var links = [];

        let panelText = '';
        if (component.panelPositions) {
            if (component.panelPositions.length === 1) {
                if (component.panelPositions[0].name === null) {
                    panelText = `Page ${index + 1}`;
                }
                else {
                    panelText = component.panelPositions[0].name;
                }
            }
            else if (component.panelPositions.length === 2) {
                panelText = component.panelPositions[0].name + '/' + component.panelPositions[component.panelPositions.length - 1].name;
            }
            else if (component.panelPositions.length > 2) {
                panelText = component.panelPositions[0].name + '-' + component.panelPositions[component.panelPositions.length - 1].name;
            }
        }

        if (!hideLinks && component.precheckReportExists) {
            links.push(<div key={`${component.id}_precheck`}><Typography variant="body2"><Link to='#' onClick={() => { this.showdialog(DialogType.PrecheckReport, component.id) }}>{langFormat('viewPrecheckReport', panelText)}</Link></Typography></div>);
        }
        if (!hideLinks && component.preflightReportExists) {
            links.push(<div key={`${component.id}_preflight`}><Typography variant="body2"><Link to='#' onClick={() => { this.showdialog(DialogType.PreflightReport, component.id) }}>{langFormat('viewPreflightReport', panelText)}</Link></Typography></div>);
        }
        if (!hideLinks && index === ticket.components.length - 1 && ticket.isPrint && ticket.hasPreview && ticket.isSubmitted) {
            links.push(<div key={`${ticket.id}_preview`}><Typography variant="body2"><Link to='#' onClick={() => this.showdialog(DialogType.GatefoldPreview, ticket.id)}>{lang('gatePreview')}</Link></Typography></div>);
        }
        return links;
    }

    findMinAndMaxValuesForComponent = (minMaxObj, valueIn) => {
        if (valueIn < minMaxObj.min) {
            minMaxObj.min = valueIn;
        }
        if (valueIn > minMaxObj.max) {
            minMaxObj.max = valueIn;
        }
    }

    //Expects an object with a min and max field that will have their values set.  If the componentIndex is 0 then just the value itself is set without any checks.
    findMinAndMaxValuesForComponent2 = (minMaxObj, minValueIn, maxValueIn) => {
        if (minValueIn < minMaxObj.min) {
            minMaxObj.min = minValueIn;
        }
        if (maxValueIn > minMaxObj.max) {
            minMaxObj.max = maxValueIn;
        }
    }

    getFilePath = (component) => {
        if (component.isPublisherSupplied) {
            return `File/StreamPublisherSuppliedImage?tcid=${component.id}`;
        }
        else if (component.fileType != null) {
            return `File/StreamFile?fileType=${component.fileType}&id=${component.componentId}&hash=${component.fileHash}`;
        }
        return '';
    }

    formatRange = (minMaxObj, measurement) => {
        let min = minMaxObj.min;
        let max = minMaxObj.max;
        if (measurement !== '"') {
            min = +((min * MILLIMETERS_PER_INCH).toFixed(4));
            max = +((max * MILLIMETERS_PER_INCH).toFixed(4));
        }
        return min === max ? `${min}${measurement}` : `${min}${measurement} - ${max}${measurement}`;
    }

    truncateFileName = (filename, length) => {
        if (filename) {
            return filename.length <= length ? filename : `${filename.substring(0, length)}...`;
        }
        return '';
    }

    renderTicketDetails = (data, classes, hideLinks) => {
        if (data.isPrint) {

            let imageDisplays = [];
            let colors = [];
            let pmsColors = [];
            let fileNames = '';
            let notes = '';

            //default values are large/small enough that they get overwritten by the min and max value of the first component encountered.
            let minMaxWidthObj = { min: 99999, max: -99999 };
            let minMaxHeightObj = { min: 99999, max: -99999 };
            let minMaxBleedObj = { min: 99999, max: -99999 };
            let minMaxSafetyObj = { min: 99999, max: -99999 };
            let measurement = data.components[0] !== undefined && data.components[0].userMeasurementPreference === '"' ? '"' : ' mm';

            //loop through ticket components and generate delimited lists, and find Min and Max values of widths, heights, bleeds, and safeties.
            data.components.map((component, index) => {
                if (component.filename !== undefined && component.filename !== null) {
                    if (fileNames === '') {
                        fileNames = this.truncateFileName(component.filename, 20);
                    }
                    else {
                        fileNames = `${fileNames}, ${this.truncateFileName(component.filename, 20)}`;
                    }
                }

                notes = notes === '' ? component.notes : `${notes}, ${component.notes}`;

                this.findMinAndMaxValuesForComponent(minMaxWidthObj, component.trimWidth);
                this.findMinAndMaxValuesForComponent(minMaxHeightObj, component.trimHeight);
                this.findMinAndMaxValuesForComponent2(minMaxBleedObj, component.minBleed, component.maxBleed);
                this.findMinAndMaxValuesForComponent2(minMaxSafetyObj, component.minSafety, component.maxSafety);
                if (!component.isPublisherSupplied) {
                    colors.push(component.colors);
                    if (component.pmsColor != null && component.pmsColor.trim() === '') {
                        pmsColors.push(null);
                    }
                    else {
                        pmsColors.push(component.pmsColor);
                    }
                }

                let filePath = this.getFilePath(component);
                imageDisplays.push(<ImageDisplay key={index} component={component} filePath={filePath} componentSize={component.tempSize} sizePanels={component.sizeElements} marginLeft={10} />);
                return '';
            });

            //format the resulting data into the measurement range strings
            let widths = this.formatRange(minMaxWidthObj, measurement);
            let heights = this.formatRange(minMaxHeightObj, measurement);
            let bleeds = this.formatRange(minMaxBleedObj, measurement);
            let safeties = this.formatRange(minMaxSafetyObj, measurement);
            let colorText = '';
            if (colors.length > 1) {
                for (let i = 1; i < colors.length; i++) {
                    if ((colors[i - 1] + pmsColors[i - 1]) !== (colors[i] + pmsColors[i])) {
                        colorText = lang('varied');
                    }
                }
            }
            if (colorText !== lang('varied')) {
                colorText = colors[0] + (pmsColors[0] === null ? '' : `, ${pmsColors[0]}`);
            }

            return (
                <React.Fragment key={data.components[0] !== undefined ? data.components[0].componentId : data.id}>
                    <Grid className={classes.component} item xs={3}>
                        <div style={{ display: 'inline-flex', marginTop: 20 }}>
                            {imageDisplays.map(display => {
                                return display;
                            })}
                        </div>
                    </Grid>
                    <Grid className={classes.componentDetails} item xs={6}>
                        {data.components.length > 0 ? [(
                            <React.Fragment key={1}>
                                <div><Typography variant="body2">{lang('filenames')}: {fileNames}</Typography></div>
                                <div><Typography variant="body2">{lang('width')}: {widths}</Typography></div>
                                <div><Typography variant="body2">{lang('height')}: {heights}</Typography></div>
                                <div><Typography variant="body2">{lang('bleed')}: {bleeds}</Typography></div>
                                <div><Typography variant="body2">{lang('safety')}: {safeties}</Typography></div>
                                <div><Typography variant="body2">{lang('colors')}: {colorText}</Typography></div>
                                <div><Typography variant="body2">{lang('notes')}: {notes}</Typography></div>
                            </React.Fragment>
                        )] : [(
                            <React.Fragment key={2}>
                                <div><Typography variant="body2">{lang('filenames')}:</Typography></div>
                                <div><Typography variant="body2">{lang('width')}:</Typography></div>
                                <div><Typography variant="body2">{lang('height')}:</Typography></div>
                                <div><Typography variant="body2">{lang('bleed')}:</Typography></div>
                                <div><Typography variant="body2">{lang('safety')}:</Typography></div>
                                <div><Typography variant="body2">{lang('colors')}:</Typography></div>
                                <div><Typography variant="body2">{lang('notes')}:</Typography></div>
                            </React.Fragment>
                        )]}
                    </Grid>
                    <Grid style={{ paddingLeft: 90 }} item xs={3}>
                        <div style={{ paddingTop: 10 }}>
                            {data.components.map((component, index) => {
                                return this.renderComponentDetails(data, component, index, hideLinks)
                            })}
                        </div>
                    </Grid>
                </React.Fragment>
            )
        }
        else {
            let filePath = `File/StreamFile?fileType=MediaTypeThumbnail&id=${data.thumbnailFileName}`;
            return (
                <React.Fragment key={data.component}>
                    <Grid className={classes.component} item xs={3}>
                        <div className={classes.mediaPanelContainer}>
                            <img src={filePath} alt={data.thumbnailFileName} />
                        </div>
                    </Grid>
                    <Grid className={classes.component} style={{ paddingLeft: 20 }} item >
                        <Typography variant="body2">{data.isDetailsOnly ? lang('ticketDetailsOnly') : `${lang('filenames')}: ${this.getMediaFilenamesTruncated(data.components)}`}</Typography>
                        <div><Typography variant="body2">{data.advertiserNotes}</Typography></div>
                    </Grid>
                    <Grid item xs={2}>
                        <div>
                        </div>
                    </Grid>
                </React.Fragment>
            );
        }
    }

    getMediaFilenamesTruncated = (components) => {
        let result = '';
        let extraFiles = components.length > 4 ? components.length - 4 : 0;
        components = components.slice(0, 4);
        for (let i = 0; i < components.length; i++) {
            if (result.length > 0) {
                result += ', ';
            }
            result += this.truncateFileName(components[i].filename, 30);
        }
        if (extraFiles > 0) {
            result += `, plus ${extraFiles} file(s)`;
        }
        return result;
    }

    render() {
        const { classes, data, hideLinks, className, clickHandler } = this.props;
        let containerClassName = className === undefined ? classes.grid : `${classes.grid} ${className}`;
        if (this.state.redirectTo !== null) {
            return <Navigate to={this.state.redirectTo} replace />
        }

        let containerClickHandler = clickHandler === undefined ? () => { } : clickHandler;

        return (
            <div className={containerClassName} onClick={containerClickHandler}>
                <Grid container>
                    <Grid item xs={9}>
                        <Typography variant="body2">{lang('ticket')} #{data.id} ({data.status}) {data.publicationName} | {data.issueName} | {data.lastChanged} {lang(data.lastChangedText)} {lang('old')}</Typography>
                    </Grid>
                    <Grid style={{ paddingLeft: 90 }} item xs={3}>
                        {!hideLinks && this.renderTicketLinks(data).map(link => {
                            return link;
                        })}
                    </Grid>
                    {this.renderTicketDetails(data, classes, hideLinks)}
                </Grid>
                {this.dialog(classes)}
                <TicketClosedDialog open={this.state.ticketState !== TicketState.None} isDeleted={this.state.ticketState === TicketState.Deleted} />
                <ResubmissionNotesDialog showDialog={this.state.showResubmissionNotesDialog} onCloseHandler={() => this.setState({ showResubmissionNotesDialog: false })} ticketId={data.id} />
            </div>
        );
    }
}

export default withStyles(ComponentDetails, styles);