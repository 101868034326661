import React, { useRef, useState } from 'react';
import { lang } from '../../Language';
import InputLabel from '@mui/material/InputLabel';
import { Navigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FilledInput from '@mui/material/FilledInput';
import { useEffect } from 'react';


export default function ResubmissionNotesDialog({ ticketId, showDialog, onCloseHandler }) {
    const [notes, setNotes] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [redirectTo, setRedirectTo] = useState(null);
    const defaultText = `${lang('resubmissionNotesDialogFormattedTicketText')} ${ticketId}`;
    const newLineSize = 2; //new line and carriage return together.
    const inputRef = useRef();

    const closeDialog = (isOkAction) => {
        if (isOkAction) {
            resubmitTicket()
        }
        else {
            setNotes('');
            onCloseHandler && onCloseHandler(isOkAction);
        }
    }

    const resubmitTicket = () => {
        fetch('SvrTicket/ResubmitTicket', {
            body: JSON.stringify({ tid: ticketId, notes: defaultText + '\n' + notes }),
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(data => {
                if (data.success) {
                    redirectToTicket(data.tid);
                }
            });
    }

    const redirectToTicket = (ticketId) => {
        setRedirectTo(/ticket/ + ticketId);
    }

    let maxNoteLength = 255;

    const handleChange = (event) =>
    {
        let newNotes = event.target.value;
        let numOfNewLineChars = 0;
        for (let i = 0; i < newNotes.length; i++) {
            if (newNotes[i] === '\n') {
                numOfNewLineChars += 2;
            }
        }
        maxNoteLength = 255 - newLineSize - defaultText.length - numOfNewLineChars;
        newNotes = newNotes.substring(0, maxNoteLength);
        setNotes(newNotes);
        setIsValid(newNotes.length > 0 && newNotes.trim() !== '');
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (showDialog && inputRef.current && inputRef.current !== null) {
                inputRef.current.focus();
            }
        }, 100);

        return () => {
            clearTimeout(timeout);
        }
    }, [showDialog]);

    if (redirectTo !== null) {
        return <Navigate to={redirectTo} replace />
    }

    let dialogControl =
        <Dialog open={showDialog} aria-labelledby='submit-revision-dialog'>
            <DialogTitle id="submit-revision-title">{lang('resubmissionNotesDialogTitle')}</DialogTitle>
            <DialogContent>
                <FormControl kye='notes' fullWidth variant='filled'>
                    <InputLabel htmlFor='detailsNotes' >{defaultText}</InputLabel>
                    <FilledInput
                        id="detailsNotes"
                        name="notes"
                        multiline
                        rows={3}
                        value={notes}
                        inputRef={inputRef}
                        onChange={handleChange}
                        inputProps={{ maxLength: maxNoteLength.toString() }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => { closeDialog(false); }} color="primary">
                    {lang('cancel')}
                </Button>
                <Button variant='contained' disabled={!isValid} onClick={() => { closeDialog(true); }} color="secondary">
                    {lang('ok')}
                </Button>
            </DialogActions>
        </Dialog>;

    return (
        <React.Fragment>
            {dialogControl}
        </React.Fragment>
    );
}