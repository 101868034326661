import React, { Component } from 'react';
import { lang } from '../../Language';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FilledInput from '@mui/material/FilledInput';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TicketDetailsCustom from './TicketDetailsCustom';
import { MediaFileType } from './TicketDetailsCustom';

const styles = theme => ({
    flexibleRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        '& div:not(:first-of-type)': {
            paddingLeft: theme.spacing(1),
        },
        '& div:not(:last-of-type)': {
            paddingRight: theme.spacing(1),
        }
    },
    widthLimited: {
        maxWidth: '700px',
    },
    autoFitColumn: {
        flexShrink: '0',
        marginTop: 'auto',
    },
    fillRemainderColumn: {
        flex: '1 0 auto',
    },
    fakeLabel: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontSize: '0.75rem',
        width: '100%',
        color: 'rgba(0, 0, 0, 0.54)',
        paddingTop: theme.spacing(1),
    },
    fakeInput: {
        fontSize: '1rem',
        width: '100%',
    },
    adSpecsUrlSection: {
        maxWidth: 700
    },
    adSpecs: {
        float: 'right'
    }
});

const NOTES_CHAR_LIMIT = 255;

class TicketDetailsMedia extends Component {
    displayName = TicketDetailsMedia.name

    constructor(props) {
        super(props);
        this.state = {
            showingDialog: false,
            dialogMessage: '',
        };
    }

    getSelectedPublication = () => {
        let { currentSubmission } = this.props;
        for (var pub in currentSubmission.publications) {
            if (currentSubmission.publications[pub].id === Number(currentSubmission.ticket.publicationId)) {
                return currentSubmission.publications[pub];
            }
        }
        return null;
    };

    handleChange = (event) => {
        let { currentSubmission } = this.props;

        if (event.target.type === 'checkbox') {
            currentSubmission.ticket[event.target.value] = event.target.checked;
        }
        else {
            currentSubmission.ticket[event.target.name] = event.target.value;
        }
        if (event.target.name === "fileTypeId") {
            currentSubmission.ticket.resolutionId = ''; //Clear resolution when type is changed.
        }

        this.props.updateSubmission(currentSubmission);
    };

    okDialog = () => {
        this.setState({
            showingDialog: false
        });
    };

    buildDetailsBody = () => {
        let { currentSubmission } = this.props;
        const { classes } = this.props;
        let selectedPublication = this.getSelectedPublication();
        let detailsBody = '';
        if (!currentSubmission.fileTypes || !currentSubmission.fileTypes.map) {
            //AdSizes is loaded in the parent component because it needs to change when you switch issues
            detailsBody = <LinearProgress variant="query" />;
        }
        else {
            detailsBody = [];
            detailsBody.push(
                <FormControl key="fileType" className={classes.widthLimited} margin="dense" required fullWidth variant="standard">
                    <InputLabel shrink htmlFor="fileTypeId">{lang("fileType")}</InputLabel>
                    <Select native id="fileTypeId" name="fileTypeId" displayEmpty value={currentSubmission.ticket.fileTypeId} onChange={this.handleChange} >
                        <option value="">{lang("selectFileType")}</option>
                        {currentSubmission.fileTypes.map(fileType => (
                            <option key={fileType.name} value={fileType.id}>
                                {fileType.name}
                            </option>
                        ))}
                    </Select>
                </FormControl>);
            if (selectedPublication && selectedPublication.mediaAdSpecsUrl && selectedPublication.mediaAdSpecsUrl.trim() !== '') {
                detailsBody.push(<div key='mediaAdSpecsUrl' className={classes.adSpecsUrlSection}><a className={classes.adSpecs} target='_blank' rel='noopener noreferrer' href={selectedPublication.mediaAdSpecsUrl}>{lang('adSpecs')}</a></div>)
            }
            if (currentSubmission.allowsStaticResolution && (Number(currentSubmission.ticket.fileTypeId) === MediaFileType.STATIC || 
                Number(currentSubmission.ticket.fileTypeId) === MediaFileType.STATIC_LINK)) {
                     detailsBody.push(
                        <FormControl key="resolution" className={classes.widthLimited} margin="dense" required fullWidth variant="standard">
                        <InputLabel shrink htmlFor="resolutionId">{lang("resolution")}</InputLabel>
                        <Select native id="resolutionId" name="resolutionId" displayEmpty value={currentSubmission.ticket.resolutionId} onChange={this.handleChange} >
                            <option value="">{lang("selectResolution")}</option>
                            {currentSubmission.staticOptions.map(staticOption => (
                                <option key={staticOption.name} value={staticOption.id}>
                                    {staticOption.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>);
                if (currentSubmission.staticMessage) {
                    const detailsHtml = React.createElement("span", { dangerouslySetInnerHTML: { __html: currentSubmission.staticMessage } });
                    detailsBody.push(<Typography variant="body2" key="staticMessage">{detailsHtml}</Typography>);
                }
            }
            detailsBody.push(<TicketDetailsCustom key="customDetails" currentSubmission={currentSubmission} updateSubmission={this.props.updateSubmission} />);
            if (currentSubmission.canSetBillable) {
                detailsBody.push(<FormControlLabel key="setIsBillable"
                    control={
                        <Checkbox
                            checked={currentSubmission.ticket.isBillable}
                            value="isBillable"
                            onChange={this.handleChange}
                        />
                    }
                    label={lang("isBillable")}
                />);
            }

            let numOfNewLineChars = 0;
            for (let i = 0; i < currentSubmission.ticket.notes.length; i++) {
                if (currentSubmission.ticket.notes[i] === '\n') {
                    numOfNewLineChars += 2;
                }
            }

            detailsBody.push(<FormControl key="notes" className={classes.widthLimited} fullWidth variant="filled">
                <InputLabel htmlFor="detailsNotes">{lang("notes")}</InputLabel>
                <FilledInput
                    id="detailsNotes"
                    name="notes"
                    multiline
                    rows={3}
                    value={currentSubmission.ticket.notes}
                    onChange={this.handleChange}
                    inputProps={{ maxLength: (NOTES_CHAR_LIMIT - numOfNewLineChars).toString() }}
                />
            </FormControl>);
        }
        return detailsBody;
    };
    
    render() {
        let detailsBody = this.buildDetailsBody();
        return (
            <React.Fragment>
                {detailsBody}
                <Dialog
                    open={this.state.showingDialog}
                    aria-describedby="message-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="message-dialog-description">
                            <Typography variant="body2">{lang(this.state.dialogMessage)}</Typography>;
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.okDialog} variant="contained" color="secondary" autoFocus>
                            {lang("ok")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(TicketDetailsMedia, styles);