import React, { Component } from 'react';
import { lang } from '../../Language';
import { langFormat } from '../../Language';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import green from '@mui/material/colors/green';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { overrideComponentThemeStyle } from '../../StyleCustomization';
import 'babel-polyfill';
import Link from '@mui/material/Link';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ReactDOMServer from 'react-dom/server';
import LinearProgress from '@mui/material/LinearProgress';

const styles = theme => {
    var result = {
        formFix: {
            width: '100%', //Only needed for IE11
        },
        forIE11: {
            display: 'none',
            //IE11 does not support submit buttons outside of a form via the form property.
            //This is needed to use built in HTML5 form validation.
            //The create button in the dialog calls an invisible button inside the form instead.
        },
        emailTopSection: {
            '& > div': { marginTop: 2 }
        },
        fakeLabel: {
            fontFamily: theme.typography.subtitle1.fontFamily,
            fontSize: '0.75rem',
            width: '100%',
            color: 'rgba(0, 0, 0, 0.54)',
            paddingTop: theme.spacing(1),
            marginBottom: theme.spacing(0.5)
        },
        checkbox: {
            padding: 0,
            marginLeft: 10
        },
        draftEditorWrapper: {
            position: 'relative',
            marginBottom: 5
        },
        draftEditorToolbar: {
            top: -84,
            position: 'absolute',
            background: '#F3F3F3'
        },
        draftEditor: {
            height: '190px',
            border: '1px solid #919191',
            padding: 5,
            borderRadius: 2,
            fontFamily: theme.typography.subtitle1.fontFamily,
        },
        attachmentSection: {
            display: 'flex'
        },
        ticketAttachments: {
            width: '50%',
            paddingLeft: 5
        },
        userSuppliedAttachments: {
            width: '50%'
        },
        link: {
            textDecoration: 'underline',
            padding: 0,
            marginRight: theme.spacing(1),
            '&:hover': {
                backgroundColor: 'transparent',
            }
        },
        linkButton: {
            textDecoration: 'underline',
            padding: '3px 4px 3px 0px',
            '&:hover': {
                backgroundColor: 'transparent',
            }
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: 'calc(100% - 40px)',
            left: 'calc(100% - 55px)',
            overflow: 'hidden', //Needed for IE11 to stop showing scroll bars
        }
    };
    result = overrideComponentThemeStyle(theme, result, "IsrEmail");
    return result;
};

export const FileStatus = Object.freeze({
    Uploading: 1,
    Uploaded: 2,
    Delete: 3
});

const MAX_ATTACHMENT_SIZE = 100 * 1024 * 1024; //100MiB for attachment

class IsrEmail extends Component {
    displayName = IsrEmail.name

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            loading: false,
            showError: false,
            error: '',
            errorMessage: '',
            showEmailDialog: false,
            showLoginDialog: false,
            submittingEmailDialog: false,
            submittingLoginDialog: false,
            editorState: EditorState.createEmpty(),
            currentUrl: '',
            bodyTemplate: 'isrEmailBody_AdConcern_NeedNewFile',
            userData: {
                username: '',
                password: '',
                email: null,
                officePhone: null,
                title: null
            },
            emailData: {
                from: '',
                to: '',
                cc: '',
                subject: '',
                body: '',
                ticketId: null,
                ticketReceipt: false,
                originalJpeg: [],
                hardCropJpeg: [],
                hardCropTemplateJpeg: [],
                precheckReport: [],
                preflightReport: [],
                emailAttachments: []
            },
            disableAddAttachment: false,
            emailAttachmentsCounter: 0
        };
    }

    isrEmailInit = () => {
        this.setState({
            loading: true
        });

        fetch(`SvrIsr/IsrEmailLogin?ticketId=${this.props.currentReport.ticket.id}`, { method: 'post', credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.isAuthenticated) {
                    this.setState({ currentUrl: data.currentUrl }, () => {
                        this.initEmailFormData(data);
                        this.setState({ loading: false, isAuthenticated: true, showLoginDialog: false, showEmailDialog: true });
                    });
                }
                else {
                    this.initLoginFormData();
                    this.setState({ loading: false, isAuthenticated: false, showLoginDialog: true, showEmailDialog: false, currentUrl: data.currentUrl });
                }
            });
    }

    closeErrorDialog = () => {
        this.setState({ error: '', errorMessage: '', showError: false });
    };

    initLoginFormData = () => {
        let { userData } = this.state;
        userData.username = '';
        userData.password = '';
        userData.email = null;
        userData.officePhone = null;
        userData.title = null;
        this.setState({ userData });
    };

    handleLoginFormChange = event => {
        let currentUserData = this.state.userData;
        currentUserData[event.target.name] = event.target.value;
        this.setState({ userData: currentUserData });
    };

    handleLoginFormSubmit = (e) => {
        if (!this.state.submittingLoginDialog) {
            var form = document.getElementById("loginForm");
            if (!form.checkValidity()) {
                return; //Regular submit will happen, causing built in form validation to work.
            }
            e.preventDefault();
            this.setState(
                {
                    submittingLoginDialog: true
                },
                () => {
                    fetch("SvrIsr/IsrEmailLoginUser", {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/json'
                        },
                        credentials: 'same-origin',
                        body: JSON.stringify({ ...this.state.userData, ticketId: this.props.currentReport.ticket.id })
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.isAuthenticated === true) {
                                this.setState({ currentUrl: data.currentUrl }, () => {
                                    this.initEmailFormData(data);
                                    this.setState({ loading: false, submittingLoginDialog: false, isAuthenticated: true, showLoginDialog: false, showEmailDialog: true });
                                });
                            }
                            else {
                                this.setState({ loading: false, submittingLoginDialog: false, error: data.error, showError: true, currentUrl: data.currentUrl });
                            }
                        });
                },
            );
        }
        return false;
    };

    closeLoginDialog = () => {
        this.setState({ showLoginDialog: false });
    }

    initEmailFormData = (data) => {
        const { currentReport } = this.props;
        let { userData, emailData } = this.state;

        userData.username = data.username;
        userData.email = data.email;
        userData.officePhone = data.officePhone;
        userData.title = data.title;
        emailData.from = userData.email;
        emailData.to = currentReport.ticket.contactEmail;
        emailData.cc = userData.email;
        if (data.contactInfo && data.contactInfo !== '') {
            emailData.cc += ", " + data.contactInfo;
        }
        emailData.ticketId = currentReport.ticket.id;

        this.setEmailSubject(emailData, currentReport);
        this.setEmailBodyContent(emailData);
        this.setEmailAttachments(emailData, currentReport);
        this.setState({ userData, emailData });
    };

    setEmailSubject = (emailData) => {
        const { currentReport } = this.props;
        let materialTrackingNumber = currentReport.ticket.materialTrackingNumber ? currentReport.ticket.materialTrackingNumber : "";
        emailData.subject = langFormat("isrEmailReason_AdConcern_Subject", currentReport.ticket.issueName, currentReport.ticket.publicationName,
            currentReport.ticket.advertiser, currentReport.ticket.id, materialTrackingNumber);
    };

    setEmailBodyContent = (emailData) => {
        let { editorState } = this.state;
        var htmlBlock = ReactDOMServer.renderToStaticMarkup(this.renderEmailBodyContent());
        const blocksFromHTML = htmlToDraft(htmlBlock);
        const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
        editorState = EditorState.createWithContent(state);

        if(emailData){
            emailData.body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        }         

        this.setState({ editorState });
    };

    setEmailBodyContentRefresh = () => {
        let { emailData, editorState } = this.state;
        var htmlBlock = ReactDOMServer.renderToStaticMarkup(this.renderEmailBodyContent());
        const blocksFromHTML = htmlToDraft(htmlBlock);
        const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
        editorState = EditorState.createWithContent(state);
        emailData.body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.setState({ editorState });
    };

    setEmailAttachments = (emailData, currentReport) => {
        emailData.ticketReceipt = false;
        emailData.originalJpeg = [];
        emailData.hardCropJpeg = [];
        emailData.hardCropTemplateJpeg = [];
        emailData.precheckReport = [];
        emailData.preflightReport = [];
        emailData.emailAttachments = [];
        if (currentReport.ticket.isPrint) {
            for (var idx in currentReport.ticket.components) {
                let component = currentReport.ticket.components[idx];
                if (component.hardCropExists && !component.isPublisherSupplied) {
                    emailData.hardCropTemplateJpeg.push(component.id);
                }
            }
        }
    };

    renderEmailBodyContent = () => {
        const { userData } = this.state;
        let { currentReport } = this.props;
        let publisherName = currentReport.ticket.refinedPublisherName;
        let publicationName = currentReport.ticket.publicationName;
        let issueName = currentReport.ticket.issueName;
        let advertiser = currentReport.ticket.advertiser;
        let submissionUrl = this.state.currentUrl;

        return (
            <React.Fragment>
                <Typography variant="body2" paragraph>
                    <span>{lang("isrEmailGreeting")}</span>
                </Typography>
                <Typography variant="body2" paragraph>
                    <span>{langFormat('isrEmailBody_AdConcern_Part1', publisherName, advertiser, issueName, publicationName)}</span>
                </Typography>
                <Typography variant="body2" paragraph>
                    <span>{lang('isrEmailBody_AdConcern_Part2')}</span>
                </Typography>
                <Typography variant="body2" paragraph>
                    <span style={{color: '#E25041'}}>-----------------</span>
                </Typography>
                <Typography variant="body2" paragraph>
                    <span>{lang('isrEmailBody_AdConcern_Part3')}</span>
                </Typography>
                <Typography variant="body2" paragraph>
                    <span>{lang(this.state.bodyTemplate)}<a href={submissionUrl}>{submissionUrl}</a></span>
                </Typography>
                <Typography variant="body2" paragraph>
                    <span style={{ color: '#005A96' }}><strong>{userData.username}</strong></span>
                    <br />
                    <span style={{ color: '#002C5F' }}>{lang("imagingServiceRepresentative")}</span>
                    <br /><br />
                    <span style={{ color: '#002C5F' }}><strong>{lang("quad")}</strong></span>
                    <br />
                    <span style={{ color: '#002C5F' }}>{lang("quadSlogan")}</span>
                    <br />
                    {userData.officePhone !== null && userData.officePhone.length > 0 &&
                        <React.Fragment>
                            <span><a style={{ color: '#002C5F' }} href={"tel:" + userData.officePhone}>{userData.officePhone}</a></span>
                            <br />
                        </React.Fragment>
                    }
                    <br />
                    <a style={{ color: '#005A96' }} href={"mailto:" + userData.email}>{userData.email}</a>
                    <br />
                    <a style={{ color: '#005A96' }} href={lang("quadWebsiteUrl")}>{lang("quadWebsiteName")}</a>
                </Typography>
            </React.Fragment>
        );
    };

    onEditorStateChange = (editorState) => {
        let { emailData } = this.state;
        emailData.body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.setState({ editorState, emailData });
    };

    onTicketAttachmentChange = (e) => {
        let { emailData } = this.state;
        if (e.target.name === "ticketReceipt") {
            emailData.ticketReceipt = !emailData.ticketReceipt;
        }
        else {
            var index = emailData[e.target.name].indexOf(Number(e.target.value));
            if (index > -1) {
                emailData[e.target.name].splice(index, 1);
            } else {
                emailData[e.target.name].push(Number(e.target.value));
            }
        }
        this.setState({ emailData });
    }

    adNewAttachment = () => {
        var button = document.getElementById("attachment" + this.state.emailAttachmentsCounter);
        button.click();
    }

    onAttachmentAdded = (e) => {
        let { emailData } = this.state;

        if (e.target.files.length > 0) {
            if (e.target.files[0].size > MAX_ATTACHMENT_SIZE) {
                this.setState({ error: "fileTooLarge", showError: true });
            }
            else {
                this.setState({ disableAddAttachment: true });
                var newAttachment = {
                    id: 0,
                    file: e.target.files[0],
                    fileName: e.target.files[0].name,
                    fileStatus: FileStatus.Uploading,
                    uploadProgress: 0
                };

                require('formdata-polyfill'); //IE11 needs this for FormData POST to work correctly.
                var formData = new FormData();
                formData.append('fileName', newAttachment.fileName);
                fetch("SvrIsr/BeginEmailAttachmentUpload", {
                    method: "POST",
                    credentials: 'same-origin',
                    body: formData
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success && data.emailAttachmentId !== null) {
                            newAttachment.id = data.emailAttachmentId;
                            let formData = new FormData();
                            let request = new XMLHttpRequest();
                            formData.append('emailAttachmentId', newAttachment.id);
                            formData.append('fileUpload', newAttachment.file);

                            var outsideThis = this;
                            request.addEventListener('load', function (e) {
                                outsideThis.onAttachmentUploadComplete(newAttachment.id, request.response);
                            });

                            request.upload.addEventListener('progress', function (e) {
                                let percent = (e.loaded / e.total) * 100;
                                outsideThis.updateAttachmentUploadProgress(newAttachment.id, percent);
                            });

                            // If server is sending a JSON response then set JSON response type
                            request.responseType = 'json';

                            // Send POST request to the server side script
                            request.open('post', 'SvrIsr/UploadEmailAttachment');
                            request.send(formData);

                            emailData.emailAttachments.push(newAttachment);
                            this.setState({ emailAttachmentsCounter: this.state.emailAttachmentsCounter + 1, emailData });
                        }
                        else if (data.valid === false && data.errors) {
                            this.setState({ disableAddAttachment: false, error: data.error, errorMessage: data.errors, showError: true });
                        }
                        else {
                            this.setState({ disableAddAttachment: false, error: data.error, showError: true });
                        }
                    });
            }
        }
    }

    updateAttachmentUploadProgress = (emailAttachmentId, percent) => {
        let { emailData } = this.state;
        var attachment = emailData.emailAttachments.find(item => item.id === emailAttachmentId);
        if (attachment) {
            attachment.uploadProgress = percent;
            this.setState({ disableAddAttachment: true, emailData });
        }
    }

    onAttachmentUploadComplete = (emailAttachmentId, data) => {
        let { emailData } = this.state;
        if (!data) {
            //HTTP error, usually caused by going over server HTTP upload limit
            data = { success: false, error: 'maxFileSizeError' };
        }
        if (data.success) {
            var attachment = emailData.emailAttachments.find(item => item.id === emailAttachmentId);
            attachment.fileStatus = FileStatus.Uploaded;
            attachment.file = null; //It's uploaded so we don't need it anymore.
            this.setState({ disableAddAttachment: false, emailData });
        }
    }

    deleteAttachment = (emailAttachmentId) => {
        this.setState({ disableAddAttachment: true });
        fetch("SvrIsr/DeleteEmailAttachment", {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin',
            body: JSON.stringify({ emailAttachmentId: emailAttachmentId })
        })
            .then(response => response.json())
            .then(data => {
                let { emailData } = this.state;
                if (data.success) {
                    let attachment = emailData.emailAttachments.find(item => item.id === emailAttachmentId);
                    if (attachment) {
                        let index = emailData.emailAttachments.indexOf(attachment);
                        if (index >= 0) {
                            emailData.emailAttachments.splice(index, 1);
                        }
                    }
                }
                else {
                    this.props.showBackgroundDialog(lang(data.error));
                }
                this.setState({ disableAddAttachment: false, emailData });
            });
    }

    handleEmailFormChange = event => {
        let currentEmailData = this.state.emailData;
        currentEmailData[event.target.name] = event.target.value;
        this.setState({ emailData: currentEmailData });
    };

    handleEmailFormSubmit = (e) => {
        const { editorState } = this.state;

        if (!this.state.submittingEmailDialog) {
            var form = document.getElementById("emailForm");
            if (!form.checkValidity()) {
                return; //Regular submit will happen, causing built in form validation to work.
            }
            e.preventDefault();

            //custom validation for body content
            const regTags = /(<([^>]+)>)/ig;
            const regEmptySpaces = /\r?\n|\r/g;
            var bodyContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            var bodyContentWithoutHtmlTags = bodyContent.replace(regTags, '').replace(regEmptySpaces, '');
            if (bodyContentWithoutHtmlTags.length <= 0) {
                this.setState({ error: "isrEmailBody_Validation", showError: true });
                return false;
            }

            this.setState(
                {
                    submittingEmailDialog: true
                },
                () => {
                    require('formdata-polyfill'); //IE11 needs this for FormData POST to work correctly.
                    fetch("SvrIsr/SendIsrEmail", {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/json'
                        },
                        credentials: 'same-origin',
                        body: JSON.stringify(this.state.emailData)
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success === true) {
                                this.setState({ submittingEmailDialog: false, showEmailDialog: false });
                            }
                            else {
                                this.setState({ submittingEmailDialog: false, error: data.error, errorMessage: data.errors, showError: true });
                            }
                        });
                },
            );
        }
        e.preventDefault();
        return false;
    };

    closeIsrEmailDialog = () => {
        this.setState({
            showEmailDialog: false
        });
    };

    updateBodyText = (event) => {
        let { emailData } = this.state;
        this.setState({
            bodyTemplate: event.target.value
        }, () => this.setEmailBodyContent(emailData));
    };

    render() {
        const { classes } = this.props;
        const { editorState } = this.state;
        let { currentReport } = this.props;
        const { userData, emailData, submittingEmailDialog, submittingLoginDialog } = this.state;

        let emailFormContent = '';
        let loginFormContent = '';
        let activationControl = '';

        activationControl =
            <Link component={Button} onClick={() => this.isrEmailInit()} disabled={this.state.loading} className={classes.linkButton}>{lang("sendIsrEmail")}</Link>;

        emailFormContent =
            <form id="emailForm" className={classes.formFix} >
                <div className={classes.emailTopSection}>
                    <FormControl key="emailFrom" margin="dense" fullWidth required variant="standard">
                        <InputLabel htmlFor="emailFrom">{lang('from')}</InputLabel>
                        <Input id="emailFrom" name="from" value={emailData.from} autoComplete="off" onChange={this.handleEmailFormChange} disabled />
                    </FormControl>
                    <FormControl key="emailTo" margin="dense" fullWidth required variant="standard">
                        <InputLabel htmlFor="emailTo">{lang('to')}</InputLabel>
                        <Input id="emailTo" name="to" value={emailData.to} autoComplete="off" onChange={this.handleEmailFormChange} aria-describedby="email-to-helper-text" />
                        <FormHelperText id="email-to-helper-text">{lang("isrEmailInstruction")}</FormHelperText>
                    </FormControl>
                    <FormControl key="emailCC" margin="dense" fullWidth variant="standard">
                        <InputLabel htmlFor="emailCC">{lang('cc')}</InputLabel>
                        <Input id="emailCC" name="cc" value={emailData.cc} autoComplete="off" onChange={this.handleEmailFormChange} />
                    </FormControl>
                    <FormControl key="emailSubject" margin="dense" fullWidth variant="standard">
                        <InputLabel htmlFor="emailSubject">{lang("subject")}</InputLabel>
                        <Input id="emailSubject" name="subject" value={emailData.subject} autoComplete="off" onChange={this.handleEmailFormChange} />
                    </FormControl>
                    <FormControl key="subjectTemplate" margin="dense" fullWidth variant="standard">
                        <InputLabel htmlFor="subjectTemplate">Subject template</InputLabel>
                        <Select
                            labelId="subject-template-label"
                            id="subject-template-label-id"
                            value={this.state.bodyTemplate}
                            onChange={this.updateBodyText}
                        >
                            <MenuItem value={'isrEmailBody_AdConcern_NeedNewFile'}>{lang("isrEmailBodyOption_NeedNewFile")}</MenuItem>
                            <MenuItem value={'isrEmailBody_AdConcern_Run_As_Is'}>{lang("isrEmailBodyOption_RunAsIs")}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.fakeLabel}>{lang("body")}&thinsp;*</div>
                <Editor
                    editorState={editorState}
                    toolbarClassName={classes.draftEditorToolbar}
                    wrapperClassName={classes.draftEditorWrapper}
                    editorClassName={classes.draftEditor}
                    toolbarOnFocus
                    editorStyle={{
                        greenBackground: {
                            backgroundColor: 'green'
                        }
                    }}
                    className={classes.draftEditorContainer}
                    onEditorStateChange={this.onEditorStateChange}
                />
                <div className={classes.attachmentSection}>
                    <div className={classes.ticketAttachments}>
                        <Typography variant="body2">{lang("attachments")}:</Typography>
                        {currentReport.ticket.isConfirmationAvailable &&
                            [<div key="ticketReceipt">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                                            checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
                                            id="ticketReceipt" name="ticketReceipt" className={classes.checkbox}
                                            checked={emailData.ticketReceipt}
                                            onChange={(e) => this.onTicketAttachmentChange(e)}
                                        />
                                    }
                                    label={lang("ticketReceipt")}
                                />
                            </div>]
                        }
                        {currentReport.ticket.isPrint &&
                            currentReport.ticket.components.map((component) => {
                                return (
                                    <React.Fragment key="ticketComponentFiles">
                                        {component.originalExists && !component.isPublisherSupplied &&
                                            [<div key="original">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                                                            checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
                                                            id="originalJpeg" name="originalJpeg" className={classes.checkbox}
                                                            checked={emailData.originalJpeg.find(function (element) { return element === component.id; }) !== undefined}
                                                            onChange={(e) => this.onTicketAttachmentChange(e)}
                                                            value={component.id.toString()}
                                                        />
                                                    }
                                                    label={lang("originalJpeg") + " - " + component.id}
                                                />
                                            </div>]
                                        }
                                        {component.hardCropExists && !component.isPublisherSupplied &&
                                            [<div key="hardCrop">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                                                            checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
                                                            id="hardCropJpeg" name="hardCropJpeg" className={classes.checkbox}
                                                            checked={emailData.hardCropJpeg.find(function (element) { return element === component.id; }) !== undefined}
                                                            onChange={(e) => this.onTicketAttachmentChange(e)}
                                                            value={component.id.toString()}
                                                        />
                                                    }
                                                    label={lang("hardCropJpeg") + " - " + component.id}
                                                />
                                                <br />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                                                            checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
                                                            id="hardCropTemplateJpeg" name="hardCropTemplateJpeg" className={classes.checkbox}
                                                            checked={emailData.hardCropTemplateJpeg.find(function (element) { return element === component.id; }) !== undefined}
                                                            onChange={(e) => this.onTicketAttachmentChange(e)}
                                                            value={component.id.toString()}
                                                        />
                                                    }
                                                    label={lang("hardCropTemplateJpeg") + " - " + component.id}
                                                />
                                            </div>]
                                        }
                                        {component.precheckReportExistsForSure && !component.isPublisherSupplied &&
                                            [<div key="precheckReport">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                                                            checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
                                                            id="precheckReport" name="precheckReport" className={classes.checkbox}
                                                            checked={emailData.precheckReport.find(function (element) { return element === component.id; }) !== undefined}
                                                            onChange={(e) => this.onTicketAttachmentChange(e)}
                                                            value={component.id.toString()}
                                                        />
                                                    }
                                                    label={lang("emailPrecheckReportInstruction") + " - " + component.id}
                                                />
                                            </div>]
                                        }
                                        {component.preflightReportExistsForSure && !component.isPublisherSupplied &&
                                            [<div key="preflightReport">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                                                            checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
                                                            id="preflightReport" name="preflightReport" className={classes.checkbox}
                                                            checked={emailData.preflightReport.find(function (element) { return element === component.id; }) !== undefined}
                                                            onChange={(e) => this.onTicketAttachmentChange(e)}
                                                            value={component.id.toString()}
                                                        />
                                                    }
                                                    label={lang("emailPreflightReportInstruction") + " - " + component.id}
                                                />
                                            </div>]
                                        }
                                    </React.Fragment>
                                );
                            })
                        }
                    </div>
                    <div className={classes.userSuppliedAttachments}>
                        <Typography variant="body2">{lang("attachDocument")}:</Typography>
                        {emailData.emailAttachments !== null &&
                            emailData.emailAttachments.map(attachment => {
                                return (<React.Fragment key={attachment.key} >
                                    <input type="file" id={attachment.key} style={{ display: 'none' }} onChange={(e) => this.onAttachmentAdded(e)} />
                                    {attachment.fileStatus === FileStatus.Uploading
                                        && [<LinearProgress variant="determinate" value={attachment.uploadProgress} />]
                                    }
                                    {attachment.fileStatus === FileStatus.Uploaded &&
                                        [<Typography variant="body2" style={{ height: 21 } /*temp solution*/}><span>
                                            <Link href={`File/StreamFile?fileType=EmailAttachment&id=${attachment.id}`} className={classes.link} alt={attachment.fileName} target="_blank">{attachment.fileName}</Link>
                                            <Link component={Button} className={classes.link} onClick={() => this.deleteAttachment(attachment.id)}>{lang("delete")}</Link>
                                        </span></Typography>]
                                    }
                                </React.Fragment>);
                            })
                        }
                        <Link component={Button} disabled={this.state.disableAddAttachment} className={classes.linkButton} onClick={() => this.adNewAttachment()}>{lang("addNewAttachment")}</Link>
                        <input type="file" id={"attachment" + this.state.emailAttachmentsCounter} style={{ display: 'none' }} onChange={(e) => this.onAttachmentAdded(e)} />

                    </div>
                </div>
                <div className={classes.forIE11}>
                    <button id="btnSubmitEmail" type="submit" onClick={this.handleEmailFormSubmit} />
                </div>
            </form>;

        loginFormContent =
            <form id="loginForm" className={classes.formFix} >
                <Typography variant="body2">{lang("isrEmailLoginDescription")}</Typography>
                <FormControl margin="dense" required fullWidth variant="standard">
                    <InputLabel htmlFor="username">{lang("username")}</InputLabel>
                    <Input id="username" name="username" autoComplete="username" value={userData.username} onChange={this.handleLoginFormChange} />
                </FormControl>
                <FormControl margin="dense" required fullWidth variant="standard">
                    <InputLabel htmlFor="password">{lang("password")}</InputLabel>
                    <Input name="password" type="password" id="password" autoComplete="current-password" value={userData.password} onChange={this.handleLoginFormChange} />
                </FormControl>
                <div className={classes.forIE11}>
                    <button id="btnSubmitLogin" type="submit" onClick={this.handleLoginFormSubmit} />
                </div>
            </form>;

        return (
            <React.Fragment>
                {activationControl}
                <Dialog open={this.state.showEmailDialog} aria-labelledby='send-isr-email-title' maxWidth="md">
                    <DialogTitle id="support-request-title">{lang("emailTemplateSetup")}</DialogTitle>
                    <DialogContent>
                        {emailFormContent}
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' onClick={() => { this.closeIsrEmailDialog(false); }} color="primary">
                            {lang('cancel')}
                        </Button>
                        <Button variant='contained' onClick={() => document.getElementById("btnSubmitEmail").click()} color="secondary" disabled={submittingEmailDialog}>
                            {lang('send')}
                        </Button>
                        {submittingEmailDialog && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.showLoginDialog} aria-labelledby='isr-email-login'>
                    <DialogTitle id="support-request-title">{lang("login")}</DialogTitle>
                    <DialogContent>
                        {loginFormContent}
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' onClick={() => { this.closeLoginDialog(); }} color="primary">
                            {lang('cancel')}
                        </Button>
                        <Button variant='contained' onClick={() => document.getElementById("btnSubmitLogin").click()} color="secondary" disabled={submittingLoginDialog}>
                            {lang('login')}
                        </Button>
                        {submittingLoginDialog && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.showError} onClose={this.closeErrorDialog} describedby="alert-dialog-description">
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography variant="body2">{lang(this.state.error)}</Typography>
                            {this.state.errorMessage.length > 0 && <Typography variant="body2">{lang(this.state.errorMessage)}</Typography>}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeErrorDialog} color="secondary" variant="contained" autoFocus>
                            {lang("ok")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(IsrEmail, styles);