import React, { Component } from 'react';
import { lang } from '../../Language';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import ImageDisplay from '../Common/ImageDisplay';

const styles = theme => ({
    header: {
        paddingLeft: 3,
        fontWeight: 'bold'
    },
    table: {
        borderCollapse: 'separate',
        borderSpacing: '3px 0px'
    },
    head: {
        fontSize: 14,
        height: 40,
        paddingLeft: 3,
        paddingRight: 3,
        whiteSpace: 'nowrap',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        }
    },
    sortLabel: {
        color: `${theme.palette.primary.contrastText} !important`,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold'
        },
        '&:focus': {
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold'
        }
    },
    row: {
        height: 25,
        paddingLeft: 3,
        paddingRight: 3,
        whiteSpace: 'nowrap'
    },
    rowDetails: {
        height: 100,
        paddingLeft: 3,
        paddingRight: 3,
        whiteSpace: 'nowrap',
        backgroundColor: '#DDD',
        borderColor: 'black'
    },
    body: {
        padding: '0px 3px 0px 3px'
    },
    bodyDetails: {
        padding: '0px 3px 0px 3px',
        borderBottom: '1px solid black',
        height: '100%'
    },
    component: {
        width: 200,
        marginLeft: 3
    },
    detailsContainer: {
        display: 'flex'
    },
    detailSectionLabel: {
        display: 'inline-block',
        marginRight: 25,
        verticalAlign: 'middle',
        margin: '12px 25px 0px 15px'
    },
    detailsRow: {
        height: 25
    },
    detailsBody: {
        padding: '4px 20px 4px 4px',
        border: 'none'
    },
    ticketComponentDetails: {
        marginRight: 35
    },
    currentComponentDetailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    currentComponentReports: {
        marginTop: 10
    },
    imageDisplay: {
        display: 'inline-flex',
        overflow: 'auto',
        maxWidth: 350
    }
});

class SalesReportList extends Component {
    displayName = SalesReportList.name

    constructor(props) {
        super(props);
        this.state = {
            order: 'desc',
            orderBy: 'ticketId',
            isNumber: true
        };
    }

    desc = (a, b, orderBy, isNumber) => {

        var valueA = undefined;
        var valueB = undefined;

        if (!isNumber) {
            if (orderBy === 'urls') {
                valueA = '';
                valueB = '';
                for (let i = 0; i < a[orderBy].length; i++) {
                    valueA += a[orderBy] ? a[orderBy].toString().toLowerCase() : '';
                }
                for (let i = 0; i < b[orderBy].length; i++) {
                    valueB += b[orderBy] ? b[orderBy].toString().toLowerCase() : '';
                }
            }
            else {
                valueA = a[orderBy] ? a[orderBy].toString().toLowerCase() : '';
                valueB = b[orderBy] ? b[orderBy].toString().toLowerCase() : '';
            }
            return -valueA.localeCompare(valueB);
        }
        else {
            if (orderBy === 'proofReceived') {
                //special case; need to sort additionally by the date/time
                return this.sortByProofReceived(a, b, orderBy);
            }
            valueA = a[orderBy] ? a[orderBy] : 0;
            valueB = b[orderBy] ? b[orderBy] : 0;
            if (valueB < valueA) {
                return -1;
            }
            if (valueB > valueA) {
                return 1;
            }
            return 0;
        }
    }

    hasAtLeastOneHardCropFile = (ticket) => {
        let result = false;
        if (ticket.components) {
            for (let i = 0; i < ticket.components.length; i++) {
                if (ticket.components[i].hardCropExists) {
                    result = true;
                    break;
                }
            }
        }
        return result;
    }

    sortByProofReceived = (a, b, orderBy) => {

        let valueA = a[orderBy] ? a[orderBy] : 0;
        let valueB = b[orderBy] ? b[orderBy] : 0;

        if (valueB < valueA) {
            return -1;
        }
        if (valueB > valueA) {
            return 1;
        }
        if (valueA && valueB) {
            var receivedOnA = a['proofReceivedOn'];
            var receivedOnB = b['proofReceivedOn'];
            var dateA = Date.parse(receivedOnA.toLocaleDateString() + ' ' + receivedOnA.toLocaleTimeString());
            var dateB = Date.parse(receivedOnB.toLocaleDateString() + ' ' + receivedOnB.toLocaleTimeString());
            if (dateB < dateA) {
                return -1;
            }
            if (dateB > dateA) {
                return 1;
            }
        }
        return 0;
    }

    stableSort = (array, cmp) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    getSorting = (order, orderBy, isNumber) => {
        return order === 'desc' ? (a, b) => this.desc(a, b, orderBy, isNumber) : (a, b) => -this.desc(a, b, orderBy, isNumber);
    }

    onSortChange = (property, isNumber) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy, isNumber });
    };

    onComponentChange = (event) => {
        let { currentReport } = this.props;
        var ticket = currentReport.tickets.find(t => t.ticketId === Number(event.target.id));
        if (ticket) {
            var component = ticket.components.find(c => c.id === Number(event.target.value));
            if (component) {
                ticket.selectedComponent = component;
            }
        }
        this.props.updateReport(currentReport);
    }

    getFilePath = (component) => {
        if (component.isPublisherSupplied) {
            return `File/StreamPublisherSuppliedImage?tcid=${component.id}`;
        }
        else if (component.fileType != null && component.id) {
            return `File/StreamFile?fileType=${component.fileType}&id=${component.id}&hash=${component.fileHash}`;
        }
        return '';
    }

    render() {
        const { classes } = this.props;
        const { order, orderBy, isNumber } = this.state;
        let { currentReport } = this.props;
        var header = "";
        if (currentReport.publications !== null) {
            var publication = currentReport.publications.find(p => p.id === currentReport.publicationId);
            if (publication) {
                header = publication.name;
            }
        }
        if (currentReport.issues !== null) {
            var issue = currentReport.issues.find(i => i.id === currentReport.issueId);
            if (issue) {
                header += ", " + issue.name;
            }
        }
        if (header.length > 0) {
            header += " " + lang('tickets');
        }

        let tableColumns = [
            { id: 'ticketDetails', label: '', sortable: false },
            { id: 'advertiser', label: lang('advertiser'), sortable: true },
            { id: 'downloadSubmissionReceipt', label: lang('adSubmissionReceipt'), sortable: false },
            { id: 'downloadFiles', label: lang('finalPdfs'), sortable: false },
            { id: 'headline', label: lang('headline'), sortable: true },
            { id: 'product', label: lang('product'), sortable: true },
            { id: 'size', label: lang('size'), sortable: true },
            { id: 'status', label: lang('status'), sortable: true },
            { id: 'materialTrackingNumber', label: lang('materialTrackingNumber'), sortable: true },
            { id: 'createdBy', label: lang('createdBy'), sortable: true },
            { id: 'submittedOn', label: lang('submittedOn'), sortable: true, isNumber: true },
        ];

        return (
            <div>
                <Typography className={classes.header} variant='h6'>{header}</Typography>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className={classes.row}>
                            {tableColumns.map(column => (
                                <TableCell className={classes.head} key={column.id} sortDirection={orderBy === column.id ? order : false}>
                                    {
                                        column.sortable
                                            ? [<TableSortLabel key={column.id} classes={{ root: classes.sortLabel, active: classes.sortLabel, icon: classes.sortLabel }} active={orderBy === column.id} direction={order} onClick={() => this.onSortChange(column.id, column.isNumber)}>
                                                {column.label}
                                            </TableSortLabel>]
                                            : column.label
                                    }
                                    
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.stableSort(currentReport.tickets, this.getSorting(order, orderBy, isNumber))
                            .map(ticket =>
                            {
                                var rows = [];
                                rows.push(
                                    <TableRow key={ticket.ticketId} className={classes.row}>
                                        <TableCell className={classes.body}>
                                            <div className={classes.imageDisplay}>
                                                {ticket.components.map((component, index) => {
                                                    return <ImageDisplay key={index} component={component} filePath={this.getFilePath(component)} componentSize={component.tempSize} sizePanels={component.sizeElements} marginLeft={10} />
                                                })}
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.body}>{ticket.advertiser}</TableCell>
                                        <TableCell className={classes.body}>{ticket.isConfirmationAvailable &&
                                            [<div key="receipt">
                                                <a href={`file/StreamFile?fileType=Receipt&id=${ticket.ticketId}&noinline=true`} download={ticket.receiptPdfFileName} rel="noopener">{lang("downloadReceipt")}</a>
                                            </div>]
                                        }</TableCell>
                                        <TableCell className={classes.body}>{ticket.isConfirmationAvailable && this.hasAtLeastOneHardCropFile(ticket) &&
                                            [<div key="downloadHardCropFiles">
                                                <form id={'downloadFiles-' + ticket.ticketId} method='post' action='SvrSales/DownloadHardCropFiles'>
                                                    <input type='hidden' name='ticketId' value={ticket.ticketId} />
                                                </form>
                                                <a href='#' rel='noopener' onClick={(e) => {
                                                    e.preventDefault();
                                                    document.getElementById('downloadFiles-' + ticket.ticketId).submit();
                                                }}>{lang("downloadPdfs")}</a>
                                            </div>]}
                                        </TableCell>
                                        <TableCell className={classes.body}>{ticket.headline}</TableCell>
                                        <TableCell className={classes.body}>{ticket.product}</TableCell>
                                        <TableCell className={classes.body}>{ticket.size}</TableCell>
                                        <TableCell className={classes.body}>{ticket.statusDesc}</TableCell>
                                        <TableCell className={classes.body}>{ticket.materialTrackingNumber}</TableCell>
                                        <TableCell className={classes.body}>{ticket.createdBy}</TableCell>
                                        <TableCell className={classes.body}>
                                            {ticket.submittedOn !== null ? `${ticket.submittedOn.toLocaleDateString()} ${ticket.submittedOn.toLocaleTimeString()}` : ""}
                                        </TableCell>
                                    </TableRow>);
                                return rows;
                            })
                        }
                    </TableBody>
                </Table>
            </div>
        );
    }
}

export default withStyles(SalesReportList, styles, { withTheme: true });