import React from 'react';
import Cookies from 'js-cookie';
import { lang } from '../../Language';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { withStyles } from 'tss-react/mui';
import CircularProgress from '@mui/material/CircularProgress';
import { List, ListItem, ListItemText, Checkbox } from '@mui/material';

const styles = theme => ({
    heading: {
        fontWeight: 'bold',
        marginTop: theme.spacing(1)
    },
    horizontalBar: {
        borderColor: 'black'
    },
    widthLimited: {
        maxWidth: '700px',
    },
    heightLimited: {
        maxWidth: '700px',
        maxHeight: '450px',
        overflow: 'auto',
    },
    listItemRow: {
        paddingTop: '4px',
        paddingBottom: '4px',
    },
    noPadding: {
        padding: 0,
    },
    buttonProgress: {
        position: 'relative',
        top: '8px',
        left: '-60px',
    },
});

var DOWNLOAD_COOKIE = "adShuttlePP_DownloadStarted";
var downloadCheckInterval;
var downloadGiveUp;

class SpreadPuller extends React.Component {
    displayName = SpreadPuller.name

    constructor(props) {
        super(props);
        this.state = {
            publishers: null,
            publisherId: '',
            publications: null,
            publicationIds: [],
            issues: null,
            issueIds: '',
            issueName: '',
            startingDownload: false,
        };
    }

    componentDidMount() {
        if (!this.state.publishers) {
            fetch('SvrISR/FullPublisherList', { credentials: 'same-origin' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ publishers: data });
                });
        }
    };

    loadPublications = (publisherId) => {
        if (Number(publisherId) > 0) {
            fetch(`SvrISR/PublicationList?publisherId=${publisherId}`, { credentials: 'same-origin' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ publications: data });
                });
        }
    };

    loadIssues = () => {
        this.setState(
            {
                issues: null,
                issueIds: '',
            },
            () => {
                if (this.state.publicationIds.length > 0) {
                    require('formdata-polyfill'); //IE11 needs this for FormData POST to work correctly.
                    var formData = new FormData();
                    formData.append("publisherId", this.state.publisherId);
                    this.state.publicationIds.map(pid => formData.append("publicationIds", pid.id));
                    fetch("SvrISR/GroupedIssues", {
                        method: "POST",
                        body: formData,
                        credentials: "same-origin"
                    })
                        .then(response => response.json())
                        .then(data => {
                            this.setState({ issues: data });
                        });
                }
            });
    };

    handlePublicationSelection = value => () => {
        let checked = this.state.publicationIds;
        const rowIdx = checked.indexOf(value);
        if (rowIdx === -1) {
            checked.push(value);
        }
        else {
            checked.splice(rowIdx, 1);
        }
        this.setState({ publicationIds: checked }, () => { this.loadIssues(); });
    };

    handleChange = event => {
        let poke = {};
        poke[event.target.name] = event.target.value;
        if (poke.issueIds && event.target.selectedOptions.length > 0) {
            poke.issueName = event.target.selectedOptions[0].innerText;
        }
        this.setState(poke);
        if (poke.publisherId) {
            this.loadPublications(poke.publisherId);
        }
    };
    
    startDownload = () => {
        if (Cookies.get(DOWNLOAD_COOKIE)) {
            Cookies.remove(DOWNLOAD_COOKIE);
        }
        //Remove the cookie first so we don't assume the download started.
        this.setState({ startingDownload: true }, () => {   
            downloadCheckInterval = window.setInterval(this.checkRestoreDownload, 1000); //Call every 1 second
            downloadGiveUp = 120; //Give up after 2 minutes
            document.getElementById('spreadPullerForm').submit();
        });
        return true;
    };

    checkRestoreDownload = () => {
        if (downloadGiveUp <= 0 || Cookies.get(DOWNLOAD_COOKIE)) {
            this.setState({ startingDownload: false });
            Cookies.remove(DOWNLOAD_COOKIE);
            window.clearInterval(downloadCheckInterval);
        }
        downloadGiveUp--;
    };

    render() {
        const { classes } = this.props;
        let publishers = <CircularProgress />;
        let publications = <React.Fragment />;
        let issues = <React.Fragment />;
        let download = <React.Fragment />;
        if (this.state.publishers) {
            publishers =
                <FormControl className={classes.widthLimited} margin="dense" required fullWidth variant="standard">
                    <InputLabel shrink htmlFor="publisherId">{lang("publisher")}</InputLabel>
                    <Select native name="publisherId" displayEmpty value={this.state.publisherId} onChange={this.handleChange} >
                        <option value="">{lang("selectPublisher")}</option>
                        {this.state.publishers.map(publisher => (
                            <option key={publisher.name} value={publisher.id}>
                                {publisher.name}
                            </option>
                        ))}
                    </Select>
                </FormControl>;
        }
        if (this.state.publisherId && Number(this.state.publisherId) > 0 && this.state.publications) {
            publications = <FormControl className={classes.heightLimited} margin="dense" required fullWidth variant="standard">
                <InputLabel shrink htmlFor="publications">{lang("publication")}</InputLabel>
                <List id="publicationIdList" dense style={{ paddingTop: '12px' }}>
                    {this.state.publications.map(publication => (
                        <ListItem key={publication.id} className={classes.listItemRow} button onClick={this.handlePublicationSelection(publication)} >
                            <Checkbox className={classes.noPadding} color="primary" disableRipple checked={this.state.publicationIds.indexOf(publication) !== -1} />
                            <ListItemText primary={publication.name} />
                        </ListItem>
                        ))}
                </List>
            </FormControl>;
        }
        if (this.state.issues) {
            issues = <FormControl className={classes.widthLimited} margin="dense" required fullWidth variant="standard">
                <InputLabel shrink htmlFor="issueIds">{lang("issue")}</InputLabel>
                <Select native name="issueIds" displayEmpty value={this.state.issueIds} onChange={this.handleChange} >
                    <option value="">{lang("selectIssue")}</option>
                    {this.state.issues.map(issue => (
                        <option key={issue.name} value={issue.ids.join()}>
                            {issue.name}
                        </option>
                    ))}
                </Select>
            </FormControl>;
        }
        if (this.state.issueIds && this.state.issueIds.length > 0) {
            let starting = '';
            if (this.state.startingDownload) {
                starting = <CircularProgress size={24} className={classes.buttonProgress} />;
            }
            download = <form id="spreadPullerForm" action="SvrISR/PullSpreads" method="post">
                <input type="hidden" name="publisherId" value={this.state.publisherId} />
                <input type="hidden" name="issueIds" value={this.state.issueIds} />
                <input type="hidden" name="issueName" value={this.state.issueName} />
                <Button disabled={this.state.startingDownload} type="button" variant="contained" color="primary" onClick={() => this.startDownload()}>
                    {lang("download")}
                </Button>
                {starting}
            </form>;
        }
        
        return (
            <div>
                <Typography className={classes.heading} variant='h5'>{lang("spreadPuller")}</Typography>
                <hr className={classes.horizontalBar} />
                {publishers}
                <br />
                {publications}
                <br />
                {issues}
                <p />
                {download}
            </div>
        );
    }
}

export default withStyles(SpreadPuller, styles);