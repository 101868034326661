import { useLocation } from "react-router-dom";

export function withLocation(Component) {
    function ComponentWithLocationProp(props) {
        let location = useLocation();

        return (
            <Component
                {...props}
                location={ location }
            />
        );
    }
    return ComponentWithLocationProp;
}