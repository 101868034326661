import React, { Component } from 'react';
import { lang, langFormat } from '../../Language';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import MediaPickerButton from './MediaPickerButton';
import RadioGroup from '@mui/material/RadioGroup';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TicketDetailsPrint from './TicketDetailsPrint';
import TicketDetailsMedia from './TicketDetailsMedia';
import TicketClosedDialog, { TicketState } from './TicketClosedDialog';

const styles = theme => ({
    mainForm: {
        minWidth: '380px',
        minHeight: '480px',
        display: 'flex',
        flexDirection: 'column',
    },
    widthLimited: {
        maxWidth: '700px',
    },
    publisherSelect: {
        fontStyle: 'italic',
        fontSize: 'smaller',
        color: '#888',
        paddingLeft: theme.spacing(2),
    },
    mediaPicker: {
        flexDirection: 'row',
    },
    spacer: {
        paddingTop: theme.spacing(2),
    },
    actionButtons: {
        width: '100%',
        textAlign: 'right',
        '&> :not(:nth-of-type(even))': {
            marginRight: theme.spacing(1),
        },
    },
});

const DialogMode = Object.freeze({
    Message: 1,
    Confirmation: 2,
});

class TicketDetails extends Component {
    displayName = TicketDetails.name

    constructor(props) {
        super(props);
        this.state = {
            showingDialog: false,
            dialogMessage: '',
            dialogMode: DialogMode.Message,
            ticketState: TicketState.None
        };
    }
    
    componentDidMount() {
        let { currentSubmission } = this.props;
        if (currentSubmission.ticket.id) {
            //Working on existing ticket, reload all lists
            this.loadMissingLists();
        }
        else if (!currentSubmission.publications) {
            this.loadPublications();
        }
    };

    getMediaType = (mediaTypeId) => {
        let { currentSubmission } = this.props;
        for (var mediaType in currentSubmission.mediaTypes) {
            if (currentSubmission.mediaTypes[mediaType].id === mediaTypeId) {
                return currentSubmission.mediaTypes[mediaType];
            }
        }
        return null;
    };

    getSelectedPublication = () => {
        let { currentSubmission } = this.props;
        if (currentSubmission.ticket.publicationId > 0) {
            for (var publication in currentSubmission.publications) {
                if (currentSubmission.publications[publication].id.toString() === currentSubmission.ticket.publicationId.toString() &&
                    currentSubmission.publications[publication].publisherId.toString() === currentSubmission.ticket.publisherId.toString()) {
                    return currentSubmission.publications[publication];
                }
            }
        }
        return null;
    };

    getSelectedIssue = () => {
        let { currentSubmission } = this.props;
        if (currentSubmission.ticket.issueId > 0) {
            for (var issue in currentSubmission.issues) {
                if (currentSubmission.issues[issue].id.toString() === currentSubmission.ticket.issueId.toString()) {
                    return currentSubmission.issues[issue];
                }
            }
        }
        return null;
    };

    handleChange = (event) => {
        let { currentSubmission } = this.props;

        if (event.target.name === 'publicationId') {
            var results = event.target.value.split('_');
            currentSubmission.ticket.publisherId = results[0];
            currentSubmission.ticket.publicationId = results[1];
            currentSubmission.selectedPublication = this.getSelectedPublication();
            this.loadIssues(currentSubmission);
        }
        else if (event.target.name === 'mediaTypeId') {
            currentSubmission.mediaTypeId = event.target.value;
            currentSubmission.ticket.mediaTypeId = Number(currentSubmission.mediaTypeId);
            let wasPrint = currentSubmission.ticket.isPrint;
            let mediaType = this.getMediaType(currentSubmission.ticket.mediaTypeId);
            currentSubmission.ticket.isPrint = (mediaType) ? mediaType.isPrint : null;
            if (wasPrint !== currentSubmission.ticket.isPrint) {
                currentSubmission.ticket.fileTypeId = '';
                currentSubmission.ticket.resolutionId = '';
                currentSubmission.ticket.sizeId = '';
                currentSubmission.ticket.sizeGroupId = '';
                currentSubmission.sizeGroup = '';
                currentSubmission.ticket.isrUploadPageCount = null;
                currentSubmission.ticket.isInsertWorkflow = false;
                currentSubmission.ticket.isCreateAnAd = false;
                if (currentSubmission.ticket.isPrint) {
                    this.loadDefaultAdSize(currentSubmission);
                }
                if (!wasPrint) {
                    currentSubmission.ticket.proofIncluded = currentSubmission.proofDefault;
                }
            }
            this.loadMediaTypeFields(currentSubmission);
        }
        else {
            currentSubmission.ticket[event.target.name] = event.target.value;
        }
        var selectDefaultMedia = false;
        if (event.target.name === 'issueId') {
            currentSubmission.selectedIssue = this.getSelectedIssue(); //Set so we don't have to find it again on every render.
            currentSubmission.ticket.sizeId = ''; //Clear size when switching issues
            currentSubmission.ticket.sizeGroupId = '';
            currentSubmission.sizeGroup = '';
            currentSubmission.adSizes = null; //Clear size list too
            currentSubmission.defaultAdSize = null;
            this.loadAdSizes(currentSubmission);
            if (currentSubmission.selectDefaultMedia && !currentSubmission.mediaTypeId) {
                selectDefaultMedia = true;
            }
            else if (currentSubmission.ticket.mediaTypeId > 0 && currentSubmission.selectedIssue) {
                if (currentSubmission.ticket.isPrint ? !currentSubmission.selectedIssue.isPrintOpen : !currentSubmission.selectedIssue.isMediaOpen) {
                    //Current selected media type cannot be used anymore. If we're defaulting, do that, otherwise clear the selection.
                    if (currentSubmission.selectDefaultMedia) {
                        selectDefaultMedia = true;
                    }
                    else {
                        currentSubmission.mediaTypeId = '';
                        currentSubmission.ticket.mediaTypeId = null;
                        currentSubmission.ticket.isPrint = null;
                    }
                }
            }
        }
        this.props.updateSubmission(currentSubmission);
        if (selectDefaultMedia && currentSubmission.selectedIssue) {
            let mediaType = null;
            for (var mtIdx in currentSubmission.mediaTypes) {
                let issue = currentSubmission.selectedIssue;
                if (currentSubmission.mediaTypes[mtIdx].isPrint ? issue.isPrintOpen : issue.isMediaOpen) {
                    mediaType = currentSubmission.mediaTypes[mtIdx];
                    break;
                }
            }
            if (mediaType) {
                this.handleChange({ target: { name: 'mediaTypeId', value: mediaType.id.toString() } });
            }
        }
    };

    loadMissingLists = () => {
        let { currentSubmission } = this.props;
        if (!currentSubmission.publications ||
            !currentSubmission.issues ||
            !currentSubmission.adSizes ||
            !currentSubmission.mediaTypeFields) {
            fetch(`SvrTicket/DetailsLists?ticketId=${currentSubmission.ticket.id}`, { credentials: 'same-origin' })
                .then(response => response.json())
                .then(data => {
                    let { currentSubmission } = this.props;
                    currentSubmission.publications = data.publications;
                    currentSubmission.selectedPublication = this.getSelectedPublication();
                    currentSubmission.hidePublisherName = data.hidePublisherName;
                    currentSubmission.canSetBillable = data.canSetBillable;
                    currentSubmission.issues = data.issues;
                    currentSubmission.selectedIssue = this.getSelectedIssue();
                    currentSubmission.mediaTypes = data.mediaTypes;
                    currentSubmission.mediaTypeId = currentSubmission.ticket.mediaTypeId.toString();
                    currentSubmission.skipMediaDialog = data.skipMediaDialog;
                    currentSubmission.canOrderProofs = data.canOrderProofs;
                    currentSubmission.proofCertType = data.proofCertType;
                    currentSubmission.proofCertUrl = data.proofCertUrl;
                    currentSubmission.sendProofAddress = data.sendProofAddress;
                    currentSubmission.adSizes = data.adSizes;
                    currentSubmission.hasCreateSizes = data.hasCreateSizes;
                    currentSubmission.allowIsrUpload = data.allowIsrUpload;
                    currentSubmission.allowInsertsWorkflow = data.allowInsertsWorkflow;
                    currentSubmission.ticket.isInsertWorkflow = currentSubmission.allowInsertsWorkflow && currentSubmission.selectedPublication.hasAssociations;
                    currentSubmission.proofDefault = data.proofDefault;
                    currentSubmission.proofOptions = data.proofOptions;
                    currentSubmission.inlineProofMessage = data.inlineProofMessage;
                    currentSubmission.groupSizes = data.groupSizes;
                    currentSubmission.showProofOptions = data.showProofOptions;
                    currentSubmission.multipleSizes = currentSubmission.groupSizes && currentSubmission.ticket.sizeGroupId > 0;
                    currentSubmission.sameFoldingDummy = data.sameFoldingDummy;
                    currentSubmission.showDeliveryConfirmationDialog = data.showDeliveryConfirmationDialog;
                    currentSubmission.wasDeliveryConfirmationDialogShown = false;
                    this.props.updateSubmission(currentSubmission);
                });
        }
    }

    loadPublications = () => {
        fetch('SvrTicket/PublicationList', { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                let { currentSubmission } = this.props;
                currentSubmission.publications = data.publications;
                currentSubmission.selectedPublication = null;
                currentSubmission.allowInsertsWorkflow = data.allowInsertsWorkflow;
                currentSubmission.hidePublisherName = data.hidePublisherName;
                currentSubmission.canSetBillable = data.canSetBillable;
                currentSubmission.ticket.publicationId = '';
                this.props.updateSubmission(currentSubmission);
            });
    }

    loadIssues = (currentSubmission) => {
        currentSubmission.issues = null;
        if (currentSubmission.selectedPublication.isRedirect) {
            currentSubmission.ticket.publicationId = -1; //Prevents loading indicator from showing.
            this.setState({
                showingDialog: true,
                dialogMode: DialogMode.Confirmation,
                dialogMessage: langFormat('redirectConfirmation', currentSubmission.selectedPublication.name, currentSubmission.selectedPublication.redirectUrl)
            });
            return;
        }
        let publicationId = currentSubmission.ticket.publicationId;
        let publisherId = currentSubmission.ticket.publisherId;
        if (!(publicationId > 0 && publisherId > 0)) {
            if (currentSubmission.ticket.issueId > 0) {
                //If they picked a publication then cleared it out, clear issue.
                let { currentSubmission } = this.props;
                currentSubmission.issues = null;
                currentSubmission.ticket.issueId = null;
                this.props.updateSubmission(currentSubmission);
            }
            return; //Nothing to load yet, or they
        }
        fetch(`SvrTicket/IssueList?publisherId=${publisherId}&publicationId=${publicationId}`, { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                let { currentSubmission } = this.props;
                currentSubmission.issues = data.issues;
                currentSubmission.mediaTypes = data.mediaTypes;
                currentSubmission.skipMediaDialog = data.skipMediaDialog;
                currentSubmission.canOrderProofs = data.canOrderProofs;
                currentSubmission.proofCertType = data.proofCertType;
                currentSubmission.proofCertUrl = data.proofCertUrl;
                currentSubmission.sendProofAddress = data.sendProofAddress;
                currentSubmission.selectDefaultMedia = data.selectDefaultMedia;
                currentSubmission.ticket.issueId = '';
                currentSubmission.selectedIssue = null;
                currentSubmission.ticket.isPrint = data.isPrint;
                currentSubmission.mediaTypeFields = null;
                currentSubmission.fileTypes = null;
                currentSubmission.allowsStaticResolution = false;
                currentSubmission.allowsUrls = false;
                currentSubmission.requiresUrls = false;
                currentSubmission.ticket.urls = [];
                currentSubmission.staticOptions = null;
                currentSubmission.staticMessage = null;
                currentSubmission.loadedMediaTypeId = null;
                currentSubmission.ticket.mediaTypeId = data.mediaTypeId;
                //Radio buttons need to bind to a string, so we maintain an extra copy of mediaTypeId.
                if (data.mediaTypeId) {
                    currentSubmission.mediaTypeId = data.mediaTypeId.toString();
                }
                else {
                    currentSubmission.mediaTypeId = null;
                }
                currentSubmission.ticket.isCreateAnAd = false;
                currentSubmission.ticket.isrUploadPageCount = null;
                currentSubmission.ticket.isInsertWorkflow = false;
                this.props.updateSubmission(currentSubmission);
                if (currentSubmission.ticket.mediaTypeId) {
                    this.loadMediaTypeFields(currentSubmission);
                }
            });
    }

    loadAdSizes = (currentSubmission) => {
        let issueId = currentSubmission.ticket.issueId;
        if ((currentSubmission.allowInsertsWorkflow && currentSubmission.ticket.isInsertWorkflow && !currentSubmission.selectedPublication.hasAssociations) ||
            (currentSubmission.allowInsertsWorkflow && !currentSubmission.ticket.isInsertWorkflow && currentSubmission.selectedPublication.hasAssociations)) {
            issueId = currentSubmission.selectedIssue.associatedIssueId;
        }
        let publisherId = currentSubmission.ticket.publisherId;
        if (!(issueId > 0 && publisherId > 0)) {
            return; //Nothing to load yet
        }
        fetch(`SvrTicket/AdSizeList?publisherId=${publisherId}&issueId=${issueId}`, { credentials: 'same-origin' } )
            .then(response => response.json())
            .then(data => {
                let { currentSubmission } = this.props;
                currentSubmission.adSizes = data.adSizes;
                currentSubmission.hasCreateSizes = data.hasCreateSizes;
                currentSubmission.allowIsrUpload = data.allowIsrUpload;
                currentSubmission.proofDefault = data.proofDefault;
                currentSubmission.showProofOptions = data.showProofOptions;
                if (currentSubmission.proofDefault > 0) {
                    currentSubmission.ticket.proofIncluded = currentSubmission.proofDefault;
                }
                else {
                    currentSubmission.ticket.proofIncluded = '';
                }
                currentSubmission.proofOptions = data.proofOptions;
                currentSubmission.inlineProofMessage = data.inlineProofMessage;
                currentSubmission.defaultAdSize = data.defaultAdSize;
                this.loadDefaultAdSize(currentSubmission);
                this.props.updateSubmission(currentSubmission);
            });
    };

    loadDefaultAdSize = (currentSubmission) => {
        if (currentSubmission.defaultAdSize && currentSubmission.defaultAdSize.defaultAdSizeId) {
            currentSubmission.sizeGroup = `${currentSubmission.defaultAdSize.adSizeGroup.id}_${currentSubmission.defaultAdSize.adSizeGroup.sizeId}`;
            var results = currentSubmission.sizeGroup.split('_');
            currentSubmission.ticket.sizeGroupId = results[0];
            currentSubmission.ticket.sizeId = results[1];
            if (currentSubmission.ticket.sizeId === 'null') {
                currentSubmission.ticket.sizeId = null;
            }
            currentSubmission.ticket.isrUploadPageCount = null;
            currentSubmission.editorInfo = null;
            currentSubmission.groupSizes = null;
            if (currentSubmission.ticket.sizeId > 0 || currentSubmission.ticket.isrUploadPageCount > 0) {
                currentSubmission.multipleSizes = false;
            }
            else {
                currentSubmission.multipleSizes = true;
                currentSubmission.groupSizes = currentSubmission.defaultAdSize.adSizeGroups;
                currentSubmission.sameFoldingDummy = currentSubmission.defaultAdSize.sameFoldingDummy;
                currentSubmission.ticket.sizeId = currentSubmission.defaultAdSize.defaultAdSizeId;
            }
        }
    };

    loadMediaTypeFields = (currentSubmission) => {
        let publisherId = currentSubmission.ticket.publisherId;
        let publicationId = currentSubmission.ticket.publicationId;
        let mediaTypeId = currentSubmission.ticket.mediaTypeId;
        if (currentSubmission.loadedMediaTypeId === mediaTypeId && currentSubmission.mediaTypeFields) {
            return; //Already have it, don't ask again.
        }
        else if (!(publisherId > 0 && publicationId > 0 && mediaTypeId > 0)) {
            return;
        }
        fetch(`SvrTicket/MediaTypeFields?publisherId=${publisherId}&publicationId=${publicationId}&mediaTypeId=${mediaTypeId}`, { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                let { currentSubmission } = this.props;
                currentSubmission.mediaTypeFields = data.mediaTypeFields;
                currentSubmission.fileTypes = data.fileTypes;
                currentSubmission.allowsStaticResolution = data.allowsStaticResolution;
                currentSubmission.allowsUrls = data.allowsUrls;
                currentSubmission.requiresUrls = data.requiresUrls;
                currentSubmission.staticOptions = data.staticOptions;
                currentSubmission.staticMessage = data.staticMessage;
                currentSubmission.loadedMediaTypeId = mediaTypeId;
                this.props.updateSubmission(currentSubmission);
            });
    };

    okDialog = () => {
        this.setState({
            showingDialog: false
        });
    };

    proceedDialog = () => {
        this.setState({
            showingDialog: false
        }, () => {
            window.location.href = this.props.currentSubmission.selectedPublication.redirectUrl;
        });
    };

    onCancel = () => {
        let { currentSubmission } = this.props;
        if (currentSubmission.ticket.id > 0 && !currentSubmission.ticket.isNew) {
            //Existing ticket - Force a ticket reload because they could have had components processing when they went into edit.
            currentSubmission.ticket = null;
            currentSubmission.showEditDetails = false;
            currentSubmission.publications = null; //In case they tampered with the pub/issue list, this will force a refresh next time.
            this.props.updateSubmission(currentSubmission);
        }
        else {
            //New ticket, go back
            currentSubmission.ticket = null;
            currentSubmission.redirectLocation = '/ticket';
            this.props.updateSubmission(currentSubmission);
        }
    };

    submitTicket = (e) => {
        let { currentSubmission } = this.props;
        var form = document.getElementById("ticketDetailsForm");
        if (!form.checkValidity()) {
            return; //Regular submit will happen, causing built in form validation to work.
        }
        e.preventDefault();

        if ((currentSubmission.allowInsertsWorkflow && currentSubmission.ticket.isInsertWorkflow && !currentSubmission.selectedPublication.hasAssociations) ||
            (currentSubmission.allowInsertsWorkflow && !currentSubmission.ticket.isInsertWorkflow && currentSubmission.selectedPublication.hasAssociations)) {
            currentSubmission.ticket.publicationId = currentSubmission.selectedPublication.associatedPublicationId;
            currentSubmission.ticket.issueId = currentSubmission.selectedIssue.associatedIssueId;
            currentSubmission.publications = null; //this will force a refresh next time.
        }
        let submissionUrl = "SvrTicket/New";
        if (currentSubmission.ticket.id > 0) {
            //Edit ticket
            submissionUrl = "SvrTicket/EditTicketDetails";
        }
        //Create new ticket
        fetch(submissionUrl, {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin',
            body: JSON.stringify(currentSubmission.ticket)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success === true) {
                    currentSubmission.ticket = data.ticket;
                    currentSubmission.showEditDetails = false;
                    currentSubmission.expandedPanel = 0;
                    currentSubmission.acceptableMediaFileTypes = data.acceptableMediaFileTypes
                    currentSubmission.reusableComponents = data.reusableComponents;
                    currentSubmission.fixMyFileEnabled = data.fixMyFileEnabled;
                    currentSubmission.useCMYKOption = data.useCMYKOption;
                    currentSubmission.showCMYKCheckboxes = data.showCMYKCheckboxes;
                    currentSubmission.isCyberSourceEnabled = data.isCyberSourceEnabled;
                    currentSubmission.followCursor = data.followCursor;
                    currentSubmission.showRuler = data.showRuler;
                    currentSubmission.transparencyMode = data.transparencyMode;
                    currentSubmission.showCrosshairs = data.showCrosshairs;
                    currentSubmission.showSubInchMarks = data.showSubInchMarks;
                    currentSubmission.showAdSizeOutline = data.showAdSizeOutline;
                    currentSubmission.showDeliveryConfirmationDialog = data.showDeliveryConfirmationDialog;
                    currentSubmission.wasDeliveryConfirmationDialogShown = false;
                    currentSubmission.fileUploads = [];
                    currentSubmission.showAgreementConfirmation = data.showAgreementConfirmation;
                    this.props.updateSubmission(currentSubmission);
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        this.setState({
                            showingDialog: true,
                            dialogMessage: lang(data.error)
                        });
                    }
                }
            });
    };

    buildDetailsBody = () => {
        let { currentSubmission } = this.props;
        const { classes } = this.props;
        let detailsBody = '';
        if (!currentSubmission.publications) {
            detailsBody = <CircularProgress />;
        }
        else {
            detailsBody = [];
            let localSelect = [];
            if (currentSubmission.hidePublisherName) {
                localSelect = currentSubmission.publications.map(pub => {
                    return (
                        /*If inserts workflow is disabled, show all publications,
                          if inserts workflow is enabled, do not show the inserts pubs, with the exception of the publication that is already selected*/
                        (!currentSubmission.allowInsertsWorkflow || !pub.hasAssociations || pub.id === currentSubmission.ticket.publicationId) &&
                        [<option key={pub.name} value={pub.publisherId + '_' + pub.id}>
                            {pub.name}
                        </option>]
                    );
                });
            }
            else {
                let groupBy = function (pubs) {
                    return pubs.reduce(function (rv, x) {
                        rv[x.publisherName] = rv[x.publisherName] || [];
                        rv[x.publisherName].push(x);
                        return rv;
                    }, []);
                };
                let groupedPubs = groupBy(currentSubmission.publications);
                for (var grp in groupedPubs) {
                    localSelect.push(
                        <optgroup key={grp} label={grp}>
                            {groupedPubs[grp].map(pub => {
                                return (
                                    /*If inserts workflow is disabled, show all publications,
                                      if inserts workflow is enabled, do not show the inserts pubs, with the exception of the publication that is already selected*/
                                    (!currentSubmission.allowInsertsWorkflow || !pub.hasAssociations || pub.id === currentSubmission.ticket.publicationId) &&
                                    [<option key={pub.name} value={pub.publisherId + '_' + pub.id}>
                                        {pub.name}
                                    </option>]
                                );
                            })}
                        </optgroup>
                    );
                }
            }
            let currentPub = (currentSubmission.ticket && currentSubmission.ticket.publisherId) ?
                currentSubmission.ticket.publisherId + '_' + currentSubmission.ticket.publicationId :
                '';
            detailsBody.push(
                <FormControl key="publication" className={classes.widthLimited} margin="dense" required fullWidth variant="standard">
                    <InputLabel shrink htmlFor="publicationId">{lang("publication")}</InputLabel>
                    <Select native id="publicationId" name="publicationId" displayEmpty value={currentPub} onChange={this.handleChange} >
                        <option value="">{lang("selectPublication")}</option>
                        {localSelect}
                    </Select>
                </FormControl>);
            if (currentSubmission.ticket && currentSubmission.ticket.publicationId > 0) {
                if (currentSubmission.issues === null) {
                    detailsBody.push(<LinearProgress key="issueLoading" variant="query" />);
                }
                else {
                    detailsBody.push(
                        <FormControl key="issue" className={classes.widthLimited} margin="dense" required fullWidth variant="standard">
                            <InputLabel shrink htmlFor="issueId">{lang("issue")}</InputLabel>
                            <Select native id="issueId" name="issueId" displayEmpty value={currentSubmission.ticket.issueId} onChange={this.handleChange} >
                                <option value="">{lang("selectIssue")}</option>
                                {currentSubmission.issues.map(issue => {
                                    return (
                                        /*If inserts workflow is disabled, show all issues,
                                          if inserts workflow is enabled, do not show the inserts issues, with the exception of the issue that is already selected*/
                                        (!currentSubmission.allowInsertsWorkflow || !currentSubmission.selectedPublication.hasAssociations || issue.id === currentSubmission.ticket.issueId) &&
                                        [<option key={issue.name} value={issue.id}>
                                            {issue.name}
                                        </option>]
                                    );
                                })}
                            </Select>
                        </FormControl>);
                }
                if (currentSubmission.ticket.issueId && currentSubmission.ticket.issueId !== '') {
                    detailsBody.push(
                        <FormControl key="advertiser" className={classes.widthLimited} margin="dense" required fullWidth variant="standard">
                            <InputLabel htmlFor="advertiser">{lang("advertiser")}</InputLabel>
                            <Input id="advertiser" name="advertiser" value={currentSubmission.ticket.advertiser} onChange={this.handleChange} inputProps={{ maxLength: "50" }} />
                        </FormControl>
                    );
                    if (!currentSubmission.skipMediaDialog) {
                        if (currentSubmission.mediaTypes) {
                            detailsBody.push(<div key={`mediaPicker_${currentSubmission.ticket.issueId}`}>
                                <FormControl component="fieldset" variant="standard">
                                    <RadioGroup name="mediaTypeId" value={currentSubmission.mediaTypeId} onChange={this.handleChange} className={classes.mediaPicker}>
                                        {currentSubmission.mediaTypes.map(mediaType => (
                                            <MediaPickerButton
                                                key={"mt_" + mediaType.id.toString()}
                                                value={mediaType.id.toString()}
                                                label={mediaType.name}
                                                canSelect={mediaType.isPrint ? currentSubmission.selectedIssue.isPrintOpen : currentSubmission.selectedIssue.isMediaOpen}
                                                publisherId={currentSubmission.ticket.publisherId}
                                                mediaTypeId={mediaType.id} />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </div>);
                        }
                        else {
                            detailsBody.push(<LinearProgress key="mediaPickerLoading" variant="query" />);
                        }
                    }
                    if (currentSubmission.ticket.mediaTypeId) {
                        var mediaType = this.getMediaType(currentSubmission.ticket.mediaTypeId);
                        if (mediaType && mediaType.detailsMessage && mediaType.detailsMessage.length > 0) {
                            const detailsHtml = React.createElement("span", { dangerouslySetInnerHTML: { __html: mediaType.detailsMessage } });
                            detailsBody.push(<Typography variant="body2" key="mediaMessage">{detailsHtml}</Typography>);
                        }
                        if (currentSubmission.ticket.isPrint) {
                            detailsBody.push(<TicketDetailsPrint key="printDetails" currentSubmission={currentSubmission} updateSubmission={this.props.updateSubmission} loadAdSizes={this.loadAdSizes} />);
                        }
                        else {
                            detailsBody.push(<TicketDetailsMedia key="mediaDetails" currentSubmission={currentSubmission} updateSubmission={this.props.updateSubmission} />);
                        }
                    }
                }
            }
            var canSubmit = false;
            if (currentSubmission.ticket.isPrint && (currentSubmission.ticket.sizeId || currentSubmission.ticket.isrUploadPageCount > 0)) {
                canSubmit = true;
            }
            if (!currentSubmission.ticket.isPrint && (currentSubmission.ticket.resolutionId ||
                (currentSubmission.ticket.fileTypeId && (!currentSubmission.allowsStaticResolution || currentSubmission.ticket.fileTypeId !== "2")))) {
                canSubmit = true;
            }
            var buttonText = (currentSubmission.ticket.isCreateAnAd) ? lang("proceedToFileCreation") : lang("proceedToFileSelection");
            if (currentSubmission.ticket.id > 0) {
                buttonText = lang("applyChanges");
            }
            detailsBody.push(<React.Fragment key="btnProceed">
                <div className={classes.spacer} />
                <div className={`${classes.actionButtons} ${classes.widthLimited}`}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.onCancel}
                    >
                        {lang("Cancel")}
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={this.submitTicket}
                        disabled={!canSubmit}
                    >
                        {buttonText}
                    </Button>
                </div></React.Fragment>);
        }
        return detailsBody;
    }

    buildMessageDialog = () => {
        var dialogButtons = '';
        if (this.state.dialogMode === DialogMode.Confirmation) {
            dialogButtons = <React.Fragment>
                <Button onClick={this.okDialog} variant="contained" color="primary" autoFocus>
                    {lang("cancel")}
                </Button>
                <Button onClick={this.proceedDialog} variant="contained" color="secondary" autoFocus>
                    {lang("proceed")}
                </Button>
            </React.Fragment>;
        }
        else {
            dialogButtons = <Button onClick={this.okDialog} variant="contained" color="primary" autoFocus>
                {lang("ok")}
            </Button>;
        }

        return (
            <Dialog
                open={this.state.showingDialog}
                aria-describedby="message-dialog-description"
                maxWidth="md"
            >
                <DialogContent>
                    <DialogContentText id="message-dialog-description">
                        {React.createElement("span", { dangerouslySetInnerHTML: { __html: this.state.dialogMessage } })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {dialogButtons}
                </DialogActions>
            </Dialog>);
    };

    render() {
        let detailsBody = this.buildDetailsBody();
        const { classes } = this.props;

        return (
            <form id="ticketDetailsForm" onSubmit={(e) => { e.preventDefault(); return false; }}>
                <div className={classes.mainForm}>
                    {detailsBody}
                </div>
                {this.buildMessageDialog()}
                <TicketClosedDialog open={this.state.ticketState !== TicketState.None} isDeleted={this.state.ticketState === TicketState.Deleted} currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission} />
            </form>
        );
    }
}

export default withStyles(TicketDetails, styles);