import React, { Component } from 'react';
import { lang } from '../../Language';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import TicketDetails from './TicketDetails';

const styles = theme => ({
    heading: {
        fontWeight: 'bold',
        paddingTop: theme.spacing(1),
    },
    horizontalBar: {
        borderColor: 'black'
    },
});

class NewTicket extends Component {
    displayName = NewTicket.name

    constructor(props) {
        super(props);

        let { currentSubmission } = this.props;
        if (!currentSubmission["ticket"]) {
            //User navigated via URL, so create an empty ticket to start
            currentSubmission.ticket = {
                status: 0,
                publicationId: '',
                id: null,
                advertiser: '',
                notes: '',
                adId: '',
                headline: '',
                description: '',
                product: '',
                companyName: '',
                urls: null,
                title: '',
                tags: '',
                isBillable: true,
            };
            this.props.updateSubmission(currentSubmission);
            window.scroll(0, 0); //New ticket, scroll to top of page
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Typography variant="h5" className={classes.heading}>{lang("startNewTicket")}</Typography>
                <hr className={classes.horizontalBar} />
                <TicketDetails currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission} />
            </div>
        );
    }
}

export default withStyles(NewTicket, styles);