import React, { useState, useEffect } from 'react';
import { lang } from '../../Language';
import Button from '@mui/material/Button';
import { createMakeStyles } from "tss-react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Navigate } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import { overrideComponentThemeStyle } from '../../StyleCustomization';
import 'babel-polyfill';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ComponentDetails from '../Tracking/ComponentDetails';
import CircularProgress from '@mui/material/CircularProgress';
const { makeStyles } = createMakeStyles({ useTheme });

const useStyles = makeStyles()(theme => {  
    let result = {
        submitRevisionButton: {
            marginTop: theme.spacing(1),
            marginLeft: 15
        },
        ticketRow: {
            display: 'block',
            cursor: 'pointer',
            padding: theme.spacing(1),
            userSelect: 'none',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'rgba(255,255,255,0)',
            '&:hover': {
                background: '#DDD',
                boxShadow: '5px 5px 5px #777',
                borderRadius: '8px',
                borderWidth: '1px',
                borderStyle: 'dashed',
                borderColor: '#AAA',
            },
            '&:active': {
                background: '#CCC',
            },
        },
        ticketRowSelected: {
            display: 'block',
            cursor: 'pointer',
            padding: theme.spacing(1),
            userSelect: 'none',
            background: '#BBB',
            boxShadow: '5px 5px 5px #777',
            borderRadius: '8px',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: '#888',
        },
        progressContainer: {
            textAlign: 'center'
        }
    };
    result = overrideComponentThemeStyle(theme, result, "ResubmissionDialog");
    return result;
});

export default function ResubmissionDialog({ currentSubmission, updateSubmission, onticketSelectedHandler }) {
    const { classes } = useStyles();
    const [showDialog, setShowDialog] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [selectedTicketId, setSelectedTicketId] = useState(-1);
    const [redirectTo, setRedirectTo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getResubmissionReadyTicketsForUser();
    }, []);

    const initDialog = () => {
        setShowDialog(true);
    }

    const closeDialog = (doResubmission) => {
        setShowDialog(false);
        let selectedTicket = tickets?.find(x => x.id === selectedTicketId);
        if (onticketSelectedHandler && selectedTicket && selectedTicket.isResubmissionNoteRequired) {
            onticketSelectedHandler(selectedTicketId);
        }
        else if (doResubmission) {
            resubmitTicket(selectedTicketId);
        }
    }

    const resubmitTicket = (ticketId) => {
        fetch('SvrTicket/ResubmitTicket', {
            body: JSON.stringify({ tid: ticketId }),
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(data => {
                if (data.success) {
                    redirectToTicket(data.tid);
                }
            });
    }

    const redirectToTicket = (ticketId) => {
        if (updateSubmission && currentSubmission && currentSubmission.ticket) {
            currentSubmission.ticket = null;
            currentSubmission.editorInfo = null;
            updateSubmission(currentSubmission);
        }
        setRedirectTo(/ticket/ + ticketId);
    }

    const getResubmissionReadyTicketsForUser = () => {
        fetch(`SvrTracking/GetResubmissionReadyTicketsForUser`, { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                setTickets(data.tickets);
                setIsLoading(false);
            });
    }

    const onTicketSelected = (ticketId) => {
        setSelectedTicketId(ticketId);
    }

    if (redirectTo !== null) {
        return <Navigate to={redirectTo} replace />
    }

    let buttonControl =
        <Button style={{minWidth: 160}} size='small' variant='contained' onClick={() => initDialog()} disabled={showDialog} className={classes.submitRevisionButton}>{lang('submitRevision')}</Button>;

    let dialogContent = !isLoading ?
        <React.Fragment>
            {
                tickets?.map(ticket => {
                    return <ComponentDetails clickHandler={() => onTicketSelected(ticket.id)} className={(ticket.id === selectedTicketId ? classes.ticketRowSelected : classes.ticketRow)} key={ticket.id} data={ticket} hideLinks />;
                })
            }
        </React.Fragment> : <div className={classes.progressContainer}><CircularProgress color='primary' size={100} /></div>;

    let dialogControl =
        <Dialog open={showDialog} aria-labelledby='submit-revision-dialog'>
            <DialogTitle id="submit-revision-title">{lang('resubmissionDialogTitle')}</DialogTitle>
            <DialogContent>
                {dialogContent}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => { closeDialog(false); }} color="primary">
                    {lang('cancel')}
                </Button>
                <Button variant='contained' onClick={() => { closeDialog(true); }} color="secondary" disabled={selectedTicketId === -1}>
                    {lang('ok')}
                </Button>
            </DialogActions>
        </Dialog>;

    return (
        <React.Fragment>
            {buttonControl}
            {dialogControl}
        </React.Fragment>
    );
}