import React from 'react';
import Button from '@mui/material/Button';
import { lang } from '../../Language';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import Input from '@mui/material/Input';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
    filterType: {
        background: 'rgba(0,0,0,0.09)',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        marginRight: theme.spacing(1),
    },
    filterInput: {
        marginRight: theme.spacing(1),
    },
    userDropdown: {
        minWidth: '400px',
    },
});

const UserSearchCriteria = Object.freeze({
    ByName: 0,
    ByEmail: 1,
    ByTicketId: 2,
    ByComponentId: 3,
    BySubmissionId: 4
});

class UserPicker extends React.Component {
    displayName = UserPicker.name

    constructor(props) {
        super(props);
        this.state = {
            searchResults: [],
            hasSelectedUser: false,
            loading: true,
            lastError: '',
            showingError: false,
        };
    }

    componentDidMount() {
        fetch("SvrISR/RecentUsers", { credentials: "same-origin" })
            .then(response => response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        searchResults: data.users,
                        loading: false
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        lastError: data.error,
                        showingError: true
                    });
                }
            });
    }

    onFilter = (e) => {
        if (!this.state.loading) {
            var form = document.getElementById("userPickerForm");
            var userPickerInput = document.getElementById("userPickerInput");
            if (userPickerInput.value.length < 3) {
                userPickerInput.setCustomValidity(lang('badRequest')); //For internal use, so I didn't waste a language table key on it.
            }
            else {
                userPickerInput.setCustomValidity('');
            }
            if (!form.checkValidity()) {
                return; //Regular submit will happen, causing built in form validation to work.
            }
            e.preventDefault();
            this.setState(
                {
                    loading: true
                },
                () => {
                    require('formdata-polyfill'); //IE11 needs this for FormData POST to work correctly.
                    var formData = new FormData(document.getElementById("userPickerForm"));
                    fetch("SvrISR/UserSearch", {
                        method: "POST",
                        body: formData,
                        credentials: "same-origin"
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success === true) {
                                this.setState({
                                    searchResults: data.users,
                                    loading: false
                                });
                            }
                            else {
                                this.setState({
                                    loading: false,
                                    lastError: data.error,
                                    showingError: true
                                });
                            }
                        });
                },
            );
        }
    }

    handleUserChanged = (event) => {
        var hasSelection = event.target.value && event.target.value.length > 0;
        this.setState({ hasSelectedUser: hasSelection });
    };

    onUserSelected = () => {
        let selectedUserId = document.getElementById("selectedUserId");
        if (selectedUserId && selectedUserId.value && this.props.onUserSelected) {
            this.props.onUserSelected(selectedUserId.value);
        }
    };

    render() {
        const { classes } = this.props;
        let { selectButtonText } = this.props;
        if (!selectButtonText) {
            selectButtonText = lang('ok');
        }
        let userDropDown = <CircularProgress />;
        if (!this.state.loading) {
            userDropDown = <FormControl className={classes.userDropdown} variant="standard">
                <InputLabel htmlFor="selectedUserId">{lang("selectUser")}</InputLabel>
                <NativeSelect id="selectedUserId" onChange={this.handleUserChanged}>
                    <option value="" />
                    {this.state.searchResults.map(user => (
                        <option key={`user${user.id}`} value={user.id}>
                            {user.value}
                        </option>
                    ))}
                </NativeSelect>
            </FormControl>;
        }

        return (
            <div>
                <form id="userPickerForm">
                    <NativeSelect name="searchType" className={classes.filterType} >
                        <option value={UserSearchCriteria.ByName}>{lang("name")}</option>
                        <option value={UserSearchCriteria.ByEmail}>{lang("email")}</option>
                        <option value={UserSearchCriteria.ByTicketId}>{lang("ticketId")}</option>
                        <option value={UserSearchCriteria.ByComponentId}>{lang("componentId")}</option>
                        <option value={UserSearchCriteria.BySubmissionId}>{lang("submissionId")}</option>
                    </NativeSelect>
                    <Input id="userPickerInput" required name="findText" className={classes.filterInput} />
                    <Button type="submit" variant="contained" color="primary" onClick={this.onFilter} disabled={this.state.loading} >
                        {lang("filter")}
                    </Button>
                </form>
                <p />
                {userDropDown}
                <p />
                <Button disabled={!this.state.hasSelectedUser} variant="contained" color="secondary" onClick={() => this.onUserSelected()}>{selectButtonText}</Button>
                <Dialog
                    open={this.state.showingError}
                    onClose={() => { this.setState({ showingError: false }); }}
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {lang(this.state.lastError)}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ showingError: false }); }} color="secondary" variant="contained" autoFocus>
                            {lang("ok")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(UserPicker, styles);