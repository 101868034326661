import './index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const root = createRoot(document.getElementById('root'));

if (baseUrl && document.location.pathname.length < baseUrl.length) {
    //Browser URL is missing the trailing slash. We should navigate to the actual base URL.
    //When the react-router v6 BrowserRouter sees a URL smaller than base URL it won't render child components.
    window.history.replaceState({}, 'AdShuttle', baseUrl);
}

root.render(<React.StrictMode>
    <BrowserRouter basename={baseUrl} >
        <App />
    </BrowserRouter>
</React.StrictMode>);