import React, { Component } from 'react';
import { lang } from '../../Language';
import Input from '@mui/material/Input';
import LinearProgress from '@mui/material/LinearProgress';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

const styles = theme => ({
    spacer: {
        paddingTop: theme.spacing(2),
    },
    fakeContainer: {
        maxWidth: '700px',
        background: 'rgba(0,0,0,0.09)',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        padding: '12px',
        paddingTop: '0',
        marginTop: '4px', //The fakeInput should actually have a marginBottom, but things look too spaced if I do that.
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        '&:hover': {
            background: 'rgba(0,0,0,0.13)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
        },
        'transition': 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    },
    fakeLabel: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontSize: '0.75rem',
        width: '100%',
        color: 'rgba(0, 0, 0, 0.54)',
        paddingTop: theme.spacing(1),
    },
    fakeInput: {
        fontSize: '1rem',
        width: '100%',
    },
    fakeDisabled: {
        color: '#bbb',
    },
    linkButton: {
        textDecoration: 'underline',
        padding: '3px 4px',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    flexibleRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        '& div:not(:first-of-type)': {
            paddingLeft: theme.spacing(1),
        },
        '& div:not(:last-of-type)': {
            paddingRight: theme.spacing(1),
        }
    },
    autoFitColumn: {
        flexShrink: '0',
        marginTop: 'auto',
    },
    fillRemainderColumn: {
        flex: '1 0 auto',
    },
});

class TicketDetailsUrls extends Component {
    displayName = TicketDetailsUrls.name
    
    componentDidMount() {
        let { currentSubmission } = this.props;
        if (currentSubmission.requiresUrls && currentSubmission.ticket.urls.length === 0) {
            if (this.props.updateSubmission) {
                //Only add missing URL if we're not in the read only editor.
                //This would happen if someone enabled the URL requirement after a ticket was created, or URLs did not load.
                this.addUrlClicked();
            }
        }
    };

    handleUrlChange = (event) => {
        let { currentSubmission } = this.props;
        let urlId = Number(event.target.name.substring(3));

        for (var index in currentSubmission.ticket.urls) {
            if (currentSubmission.ticket.urls[index].id === urlId) {
                currentSubmission.ticket.urls[index].url = event.target.value;
                this.props.updateSubmission(currentSubmission);
                break;
            }
        }
    };

    deleteUrlClicked = (id) => {
        let { currentSubmission } = this.props;

        let deleteIndex = -1;
        for (var idx in currentSubmission.ticket.urls) {
            if (currentSubmission.ticket.urls[idx].id === id) {
                deleteIndex = idx;
                break;
            }
        }
        if (deleteIndex >= 0) {
            currentSubmission.ticket.urls.splice(deleteIndex, 1);
            this.props.updateSubmission(currentSubmission);
        }
    };

    addUrlClicked = () => {
        let { currentSubmission } = this.props;

        let nextPlaceholderId = -1;
        for (var idx in currentSubmission.ticket.urls) {
            let cid = currentSubmission.ticket.urls[idx].id;
            if (cid <= nextPlaceholderId) {
                nextPlaceholderId = cid - 1;
            }
        }
        currentSubmission.ticket.urls.push({ id: nextPlaceholderId, url: '' });
        this.props.updateSubmission(currentSubmission);
    };

    render() {
        let { currentSubmission } = this.props;
        const { classes } = this.props;
        let detailsBody = '';
        let urlTitle = lang("urls");
        if (currentSubmission.requiresUrls) {
            urlTitle = urlTitle + " *";
        }
        if (!currentSubmission.mediaTypeFields) {
            //Note: Media Type custom fields are loaded by TicketDetails to reduce complexity and bundle requests.
            detailsBody = <LinearProgress variant="query" />;
        }
        else if (this.props.readOnly) {
            detailsBody = <div className={classes.fakeContainer}>
                <div className={classes.fakeLabel}>{urlTitle}</div>
                {currentSubmission.ticket.urls.map(url => {
                    return (<Typography variant="body2" key={url.id}>
                        <a href={url.url} alt="Custom Ticket URL" target="_blank" rel='noopener noreferrer'>{url.url}</a>
                    </Typography>);
                })}
            </div>;
        }
        else {
            let firstRow = true;
            detailsBody = <div className={classes.fakeContainer}>
                <div className={classes.fakeLabel}>{urlTitle}</div>
                {currentSubmission.ticket.urls.map(url => {
                    let deleteButton = <div className={classes.autoFitColumn} >
                        <Link component={Button} className={classes.linkButton} onClick={() => this.deleteUrlClicked(url.id)}>{lang("delete")}</Link>
                    </div>;
                    if (currentSubmission.requiresUrls && firstRow) {
                        deleteButton = '';
                        firstRow = false;
                    }
                    return (<div key={url.id} className={classes.flexibleRow} >
                        <div className={classes.fillRemainderColumn} >
                            <Input type="url" name={`url${url.id}`} margin="dense" required fullWidth value={url.url} onChange={this.handleUrlChange} />
                        </div>
                        {deleteButton}
                    </div>);
                })}
                <Link component={Button} className={classes.linkButton} onClick={() => this.addUrlClicked()}>{lang("addUrl")}</Link>
            </div>;
        }

        return (
            <React.Fragment>
                {detailsBody}
            </React.Fragment>
        );
    }
}

export default withStyles(TicketDetailsUrls, styles);