import React from 'react';
import { withStyles } from 'tss-react/mui';
import { lang, langFormat } from '../../Language';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const styles = theme => ({
    heading: {
        fontWeight: 'bold',
        marginTop: theme.spacing(1)
    },
    horizontalBar: {
        borderColor: 'black'
    },
    mainSection: {
        width: 700,
    },
    loadButton: {
        marginTop: theme.spacing(1)
    },
    ticketList: {
        maxHeight: '400px',
        overflow: 'auto'
    },
    draftEditorWrapper: {
        position: 'relative',
        marginBottom: 5
    },
    draftEditorToolbar: {
        top: -84,
        position: 'absolute',
        background: '#F3F3F3'
    },
    draftEditor: {
        height: '250px',
        border: '1px solid #919191',
        padding: 5,
        borderRadius: 2,
        fontFamily: theme.typography.subtitle1.fontFamily,
    },
    fakeLabel: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontSize: '0.75rem',
        width: '100%',
        color: 'rgba(0, 0, 0, 0.54)',
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5)
    },
    disabledTicket: {
        color: 'red'
    },
    sentTicket: {
        color: 'green'
    }
});

class TicketSubmissionEmail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            saveEnabled: true,
            lastMessage: '',
            loading: false,
            showError: false,
            error: '',
            tickets: {
                ticketIds: [],
                validTicketIds: [],
                ticketsInProgressIds: [],
                selectedTicketIds: [],
                sentTicketIds: []
            },
            showEmailDialog: false,
            submittingEmailDialog: false,
            editorState: EditorState.createEmpty(),
            emailPreviewData: {
                ticketId: null,
                to: null,
                subject: null,
                body: null
            }
        }
    }

    setEmailBodyContent = (emailData) => {
        let { editorState } = this.state;
        const blocksFromHTML = htmlToDraft(emailData.body);
        const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
        editorState = EditorState.createWithContent(state);
        if (emailData) {
            emailData.body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        }
        this.setState({ editorState });
    };

    handleLoad = (e) => {
        let { tickets } = this.state;
        tickets.ticketIds = [];
        tickets.validTicketIds = [];
        tickets.ticketsInProgressIds = [];
        tickets.selectedTicketIds = [];
        tickets.sentTicketIds = [];
        if (!this.state.loading) {
            this.setState({ loading: true, tickets: tickets });
            var ticketIdsString = document.getElementById('ticketIds').value;
            var ticketIdsArray = ticketIdsString.split(/[, ]+/).filter(function (el) {
                return el.length && el == +el;
            });

            require('formdata-polyfill'); //IE11 needs this for FormData POST to work correctly.
            var formData = new FormData();
            ticketIdsArray.map(tid => formData.append("ticketIds", tid));
            fetch("SvrAdmin/ValidateTicketIds", {
                method: "POST",
                body: formData,
                credentials: "same-origin"
            })
                .then(response => response.json())
                .then(data => {
                    tickets.ticketIds = ticketIdsArray;
                    tickets.selectedTicketIds = ticketIdsArray.slice();
                    tickets.validTicketIds = data.validTicketIds;
                    tickets.ticketsInProgressIds = data.ticketsInProgressIds;
                    for (var i = 0; i < tickets.ticketIds.length; i++) {
                        let ticketId = tickets.ticketIds[i];
                        let isValid = tickets.validTicketIds.indexOf(+ticketId) !== -1;
                        let inProgress = tickets.ticketsInProgressIds.indexOf(+ticketId) !== -1;
                        if (!isValid || inProgress) {
                            let index = tickets.selectedTicketIds.indexOf(ticketId);
                            if (index > -1) {
                                tickets.selectedTicketIds.splice(index, 1);
                            }
                        }
                    }
                    this.setState({ loading: false, tickets: tickets });
                });
        }
    };

    handleToggleSelection = (value) => () => {
        let { tickets } = this.state;
        let index = tickets.selectedTicketIds.indexOf(value);
        if (index > -1) {
            tickets.selectedTicketIds.splice(index, 1);
        }
        else {
            tickets.selectedTicketIds.push(value);
        }
        this.setState({ tickets: tickets });
    };

    handlePreview = (value) => () => {
        let { emailPreviewData } = this.state;
        fetch(`SvrAdmin/GetSubmissionEmailText?ticketId=${value}`, { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    emailPreviewData.ticketId = value;
                    emailPreviewData.to = data.to;
                    emailPreviewData.subject = data.subject;
                    emailPreviewData.body = data.body;
                    this.setEmailBodyContent(emailPreviewData);
                    this.setState({
                        emailPreviewData: emailPreviewData,
                        showEmailDialog: true
                    });
                }
            });
    };

    closeEmailDialog = () => {
        this.setState({
            showEmailDialog: false
        });
    };

    closeErrorDialog = () => {
        this.setState({ error: '', showError: false });
    };

    sendEmail = (ticketId) => () => {
        let { tickets } = this.state;
        fetch(`SvrAdmin/SendSubmissionEmail?ticketId=${ticketId}`, { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    let index = tickets.selectedTicketIds.indexOf(ticketId);
                    if (index > -1) {
                        tickets.selectedTicketIds.splice(index, 1);
                    }
                    tickets.sentTicketIds.push(ticketId);
                    this.setState({
                        showEmailDialog: false,
                        tickets: tickets
                    });
                }
                else {
                    this.setState({ showError: true, error: data.error });
                }
            });
    };

    sendAllEmails = () => () => {
        let { tickets } = this.state;
        require('formdata-polyfill'); //IE11 needs this for FormData POST to work correctly.
        var formData = new FormData();
        tickets.selectedTicketIds.map(tid => formData.append("ticketIds", tid));
        fetch("SvrAdmin/SendSubmissionEmails", {
            method: "POST",
            body: formData,
            credentials: "same-origin"
        })
            .then(response => response.json())
            .then(data => {
                for (var i = 0; i < data.sentTicketIds.length; i++) {
                    let ticketId = '' + data.sentTicketIds[i];
                    let index = tickets.selectedTicketIds.indexOf(ticketId);
                    if (index > -1) {
                        tickets.selectedTicketIds.splice(index, 1);
                    }
                    let index2 = tickets.sentTicketIds.indexOf(ticketId);
                    if (index2 < 0) {
                        tickets.sentTicketIds.push(ticketId);
                    }
                }
                this.setState({ loading: false, tickets: tickets });
                if (!data.success) {
                    this.setState({ showError: true, error: data.error });
                }
            });
    };

    render() {
        const { classes } = this.props;
        let { tickets } = this.state;
        let { editorState } = this.state;
        let { emailPreviewData } = this.state;
        let { submittingEmailDialog } = this.state;
        let ticketIdsSelection =
            <div id="ticketIdSelection">
                <FormControl key="ticketIds" margin="dense" fullWidth variant="standard">
                    <InputLabel htmlFor="ticketIds">{lang("ticketIDs")}:&thinsp;*</InputLabel>
                    <Input id="ticketIds" name="ticketIds" autoComplete="off" />
                </FormControl>
                <Button type="submit" variant="contained" color="primary" className={classes.loadButton} onClick={this.handleLoad} disabled={this.state.loading}>
                    {lang("load")}
                </Button>
            </div>;
        let ticketList = <React.Fragment />;
        if (tickets.ticketIds.length > 0) {
            ticketList =
                <React.Fragment>
                    <List className={classes.ticketList}>
                        {tickets.ticketIds.map((value) => {
                            let labelId = `checkbox-list-label-${value}`;
                            let isSent = tickets.sentTicketIds.indexOf(value) !== -1;
                            let isValid = tickets.validTicketIds.indexOf(+value) !== -1;
                            let inProgress = tickets.ticketsInProgressIds.indexOf(+value) !== -1;
                            let isDisabled = isSent || !isValid || inProgress;
                            let ticketLink = 
                                <React.Fragment>
                                    {isSent
                                        ? <Link className={classes.sentTicket} id={value} target="_blank" to={`/isr/ticketDetails/${value}`}>{langFormat('disabledTicketIdEmailSent', value)}</Link>
                                        : isValid
                                            ? inProgress
                                                ? <Link className={classes.disabledTicket} id={value} target="_blank" to={`/isr/ticketDetails/${value}`}>{langFormat('disabledTicketIdTicketInProgress', value)}</Link>
                                                : <Link id={value} target="_blank" to={`/isr/ticketDetails/${value}`}>{lang('ticket')} {value}</Link>
                                            : <Link className={classes.disabledTicket} id={value} target="_blank" to={`/isr/ticketDetails/${value}`}>{langFormat('disabledTicketIdInvalidTicketId', value)}</Link>
                                    }
                                </React.Fragment>;
 
                            return (
                                <ListItem key={value} role={undefined} dense button disabled={isDisabled}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            onClick={this.handleToggleSelection(value)}
                                            checked={tickets.selectedTicketIds.indexOf(value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    {ticketLink}
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="comments" onClick={this.handlePreview(value)} disabled={isDisabled}>
                                            <MailOutlineIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                    <Button type="submit" variant="contained" color="primary" className={classes.loadButton} onClick={this.sendAllEmails()} disabled={this.state.loading || tickets.selectedTicketIds.length == 0}>
                        {lang('sendEmails')}
                    </Button>
                </React.Fragment>
        }

        let emailContent =
            <React.Fragment>
                <div className={classes.emailTopSection}>
                    <FormControl key="emailTo" margin="dense" fullWidth variant="standard">
                        <InputLabel htmlFor="emailTo">{lang('to')}</InputLabel>
                        <Input id="emailTo" name="to" value={emailPreviewData.to} autoComplete="off" onChange={this.handleEmailFormChange} disabled />
                    </FormControl>
                    <FormControl key="emailSubject" margin="dense" fullWidth variant="standard">
                        <InputLabel htmlFor="emailSubject">{lang("subject")}</InputLabel>
                        <Input id="emailSubject" name="subject" value={emailPreviewData.subject} autoComplete="off" onChange={this.handleEmailFormChange} disabled />
                    </FormControl>
                </div>
                <div className={classes.fakeLabel}>{lang("body")}</div>
                <Editor
                    disabled
                    editorState={editorState}
                    wrapperClassName={classes.draftEditorWrapper}
                    toolbarHidden
                    editorClassName={classes.draftEditor}
                    editorStyle={{
                        greenBackground: {
                            backgroundColor: 'green'
                        }
                    }}
                    className={classes.draftEditorContainer}
                />
        </React.Fragment>
        
        return (
            <div>
                <div id="page-header">
                    <Typography className={classes.heading} variant='h5'>{lang('resendTicketSubmissionEmail')}</Typography>
                    <hr className={classes.horizontalBar} />
                </div>
                <div className={classes.mainSection}>
                    {ticketIdsSelection}
                    {ticketList}
                </div>
                <Dialog open={this.state.showEmailDialog} aria-labelledby='send-isr-email-title' maxWidth="md">
                    <DialogTitle id="support-request-title">{lang('ticket')} {emailPreviewData.ticketId}</DialogTitle>
                    <DialogContent>
                        {emailContent}
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' onClick={() => { this.closeEmailDialog(false); }} color="primary">
                            {lang('cancel')}
                        </Button>
                        <Button variant='contained' onClick={this.sendEmail(emailPreviewData.ticketId)} color="secondary" disabled={submittingEmailDialog}>
                            {lang('send')}
                        </Button>
                        {submittingEmailDialog && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.showError} onClose={this.closeErrorDialog} describedby="alert-dialog-description">
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography variant="body2">{lang(this.state.error)}</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeErrorDialog} color="secondary" variant="contained" autoFocus>
                            {lang("ok")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(TicketSubmissionEmail, styles);