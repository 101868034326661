//All Ticket statuses and values

export const TicketStatus = Object.freeze({
    InProgress: 2000,
    Submitted: 2010,
    SubmitFailed: 2015,
    Recieved: 2020,
    Rejected: 2030,
    Accepted: 2040,
    SubmitPending: 2060
});


