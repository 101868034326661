import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate } from 'react-router';

const styles = theme => ({
    centeredSpinner: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '50px',
    },
});

class Logout extends Component {
    displayName = Logout.name

    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    componentDidMount() {
        fetch('SvrUser/LogOut', { credentials: 'same-origin' }).then(response => response.json()).then(data => {
            this.props.onLogoutCompleted(data.loggedIn, null);
            this.setState({ loading: false });
        });
    }

    render() {
        const { classes } = this.props;

        return this.state.loading ? (
            <div className={classes.centeredSpinner}>
                <CircularProgress size={50} />
            </div>
        ) : (
                <Navigate to="/" replace />
            );
    }
}
export default withStyles(Logout, styles);