import React from 'react';
import { lang } from '../Language';
import { Link, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from 'tss-react/mui';
import { withLocation } from '../withLocation';

const styles = theme => ({
    layout: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(1)}`,
    },
    topSpace: {
        marginTop: theme.spacing(4),
    },
});

export class Activation extends React.Component {
    displayName = Activation.name

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            lastMessage: 'activatingAccount',
            redirectToLogin: false
        };
    }

    componentDidMount() {
        fetch("SvrUser/ActivateUser" + this.props.location.search, { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success === true) {
                    if (data.alreadyActive) {
                        this.setState({
                            loading: false,
                            redirectToLogin: true
                        });
                    }
                    else {
                        this.setState({
                            loading: false,
                            lastMessage: 'activationComplete'
                        });
                    }
                }
                else {
                    this.setState({
                        loading: false,
                        lastMessage: data.error
                    });
                }
            });
    }

    render() {
        const { classes } = this.props;
        const { loading } = this.state;

        let spinny = '';
        let okButton = '';
        if (loading) {
            spinny = <CircularProgress size={60} />;
        }
        else {
            okButton = (
                <div className={classes.topSpace}>
                    <Link to='/' replace>
                        <Button color="primary" variant="contained">
                            {lang("ok")}
                        </Button>
                    </Link>
                </div>
            );
        }

        return (
            <div className={classes.layout} >
                {this.state.redirectToLogin ? (
                    <Navigate to={{ pathname: '/', state: { showError: true, lastError: 'accountAlreadyActivated'} }} replace />
                ) : ''}
                <Paper className={classes.paper} >
                    {spinny}
                    <div className={classes.topSpace}>
                        <Typography variant="body2">
                            {lang(this.state.lastMessage)}
                        </Typography>
                    </div>
                    {okButton}
                </Paper>
            </div>
        );
    }
}
export default withLocation(withStyles(Activation, styles));