import React, { Component } from 'react';
import { lang } from '../../Language';
import ComponentDetails from './ComponentDetails';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import CyberSourceDialog from '../CyberSourceDialog';
import { fetchValueFromQueryString } from '../../HelperMethods';

const style = theme => ({
    heading: {
        fontWeight: 'bold',
        marginTop: theme.spacing(1)
    },
    searchMargin: {
        marginRight: 5,
        marginLeft: 5
    },
    searchField: {
        width: 180
    },
    searchAlign: {
        paddingTop: theme.spacing(1),
        textAlign: 'right'
    },
    searchError: {
        color: 'red'
    },
    horizontalBar: {
        borderColor: 'black'
    },
    nextButton: {
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center'
    },
    neutralColor: {
        color: theme.palette.neutral.contrastText,
        backgroundColor: theme.palette.neutral.main,
        '&:hover': {
            backgroundColor: theme.palette.neutral.dark,
        },
    },
    bold: {
        fontWeight: 'bold'
    }
});

const DialogType = Object.freeze({
    None: 0,
    BuyProofs: 1,
    Error: 2,
    ProofPaymentResult: 3
});

class TrackDeliveries extends Component {
    displayName = TrackDeliveries.name

    constructor(props) {
        super(props);

        let defaultDialogType = DialogType.None;
        let defaultShowDialog = false;
        let defaultDialogMessage = '';

        //Check for CyberSource info to show to user
        let decision = fetchValueFromQueryString(props.location.search, 'decision').toLowerCase();
        let reason = fetchValueFromQueryString(props.location.search, 'reason');
        if (decision !== '') {
            let addAdditionalInfoMessage = false;
            if (decision === 'decline') {
                decision = 'reject';
                addAdditionalInfoMessage = true;
            }
            decision = lang(`cyberSource_${decision}Message`);
            if (reason.length > 0) {
                reason = `${lang('reason')}: ${lang(`cyberSource_${reason}`)}`;
            }
            defaultShowDialog = true;
            defaultDialogType = DialogType.ProofPaymentResult;
            defaultDialogMessage = <div><p>{decision}</p><p>{reason}</p>{addAdditionalInfoMessage ? [<p>{lang('cyberSource_rejectMessageDetails')}</p>] : ''}</div>;
        }

        this.state = {
            isSearch: false,
            tickets: [],
            searchText: '',
            totalTickets: 0,
            proofPaymentData: undefined,
            showDialog: defaultShowDialog,
            dialogType: defaultDialogType,
            message: defaultDialogMessage
        };
    }

    componentDidMount() {
        this.getTickets();
    }

    getTickets = (searchText, numberOfTickets) => {
        fetch('SvrTracking/GetTicketsForUser',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ searchText, numberOfTickets, completed: true }),
                credentials: "same-origin"
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.setState({
                        tickets: data.tickets, totalTickets: data.totalTickets, isSearch: searchText && searchText.length > 0 
                    });
                }
                else {
                    this.setState({ showDialog: true, message: lang(data.error), dialogType: DialogType.Error });
                }
            });
    }

    buyAdditionalProofs = (ticketId) => {
        fetch(`SvrTicket/ProofRequestData?tid=${ticketId}&allowProofs=true`,
            {
                method: 'POST',
                credentials: "same-origin"
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.setState({ showDialog: true, dialogType: DialogType.BuyProofs, proofPaymentData: { ticketId, shippingConfigs: data.shippingConfigs, email: data.email, phoneNumber: data.phoneNumber, proofPrice: data.proofPrice } });
                }
            });
    }

    showDialog = () => {
        let dialogTitle = '';
        let dialogContent = '';
        if (this.state.dialogType === DialogType.Error) {
            dialogTitle = lang('error');
            dialogContent = <Typography variant="body2">{this.state.message}</Typography>;
        }
        else if (this.state.dialogType === DialogType.ProofPaymentResult) {
            dialogTitle = lang('proofPaymentResult');
            dialogContent = <Typography variant="body2">{this.state.message}</Typography>;
        }

        if (this.state.dialogType === DialogType.BuyProofs) {
            return <CyberSourceDialog open={this.state.showDialog} close={this.closeDialog} proofPaymentData={this.state.proofPaymentData} />;
        }
        else {
            return (

                <Dialog open={this.state.showDialog} onClose={this.closeDialog} >
                    <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent>
                        {dialogContent}
                    </DialogContent>
                    <DialogActions>
                        <Button key={1} variant='contained' autoFocus onClick={this.closeDialog} color="secondary">
                            {lang('ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }
    }

    submitSearch = (event) => {
        let form = document.getElementById('searchForm');
        let searchText = document.getElementById('searchText');
        if (searchText.value.length < 3) {
            searchText.setCustomValidity(lang('ticketSearchError'));
        }
        else {
            searchText.setCustomValidity('');
        }
        if (!form.checkValidity()) {
            return;
        }
        event.preventDefault();
        this.getTickets(searchText.value);
    }

    closeDialog = () => {
        this.setState({ showDialog: false });
    }

    onTextChange = (event) => {
        this.setState({ searchText: event.target.value });
    }

    render() {
        const { classes } = this.props;
        let showSearchReset = this.state.isSearch ? <Button size='small' onClick={() => { this.getTickets() }} className={classes.searchMargin} variant='contained' color='secondary' >{lang('reset')}</Button> : '';

        return (
            <div>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography className={classes.heading} variant='h5'>{lang("trackDeliveries")}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.searchAlign}>
                            <form id='searchForm'>
                                <FormControl style={{ display: 'inline' }} variant="standard">
                                    <Input name='searchText' id='searchText' type='text' onChange={this.onTextChange} className={classes.searchField} />
                                </FormControl>
                                <Button size='small' name='search' type='submit' onClick={this.submitSearch} className={`${classes.searchMargin} ${classes.neutralColor}`} variant='contained' color='primary'>{lang('search')}</Button>
                                {this.state.isSearch ? '|' : ''}{showSearchReset}
                            </form>
                        </div>
                    </Grid>
                </Grid>
                <hr className={classes.horizontalBar} />
                {this.showDialog()}
                <InfiniteScroll
                    dataLength={this.state.tickets.length}
                    next={() => { this.getTickets(this.state.searchText, this.state.tickets.length + 5) }}
                    hasMore={this.state.tickets.length < this.state.totalTickets}
                    loading={<div>{lang('ticketListLoading')}</div>}
                    size={50}>
                {
                    this.state.tickets.map(ticket => {
                        return <ComponentDetails key={ticket.id} data={ticket} onBuyAdditionalProofs={this.buyAdditionalProofs} currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission}/>;
                    })
                }
                </InfiniteScroll>
            </div>
        );
    }
}

export default withStyles(TrackDeliveries, style, { withTheme: true });