import React, { Component } from 'react';
import { lang, langFormat } from '../../Language';
import { withStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import ProgressBar from '../Common/ProgressBar';
import Typography from '@mui/material/Typography';
import { endsWithIgnoreCase } from '../../HelperMethods';
import TicketClosedDialog, { TicketState } from './TicketClosedDialog';
import 'babel-polyfill';
import 'formdata-polyfill';

const styles = theme => ({
    neutralColor: {
        color: theme.palette.neutral.contrastText,
            backgroundColor: theme.palette.neutral.main,
                '&:hover': {
            backgroundColor: theme.palette.neutral.dark,
        },
    },
    repurposeDropdown: {
        minWidth: 200
    },
    repurposeDropdownInDialog: {
        minWidth: 200,
        maxWidth: 300
    },
    fileUploadButton: {
        cursor: 'pointer'
    },
    fileUpload: {
        cursor: 'pointer',
        position: 'absolute',
        top: '0',
        bottom: '0',
        right: '0',
        left: '0',
        width: '100%',
        opacity: '0'
    },
    fileUploadContainer: {
        marginLeft: 10
    },
    fileUploadLabel: {
        marginLeft: 10
    },
    precheckButton: {
        float: 'right'
    },
    wrapText: {
        overflowWrap: 'break-word'
    }
});

const MAX_FILE_SIZE = 1024 * 1024 * 100; //100MB max upload file size

const DialogType = Object.freeze({
    None: 0,
    Error: 1,
});

class MediaUpload extends Component {

    constructor(props) {
        super(props);
        let comp = props.currentSubmission.ticket.components[props.componentIndex];
        this.state = {
            radioValue: comp.radioValue,
            filenames: '',
            previousUploadComponentId: comp.previousUploadComponentId,
            isUploading: false,
            showDialog: false,
            dialogType: DialogType.None,
            message: '',
            isButtonEnabled: true,
            dialogSubmitFiles: false,
            ticketState: TicketState.None
        };
    }

    static getDerivedStateFromProps = (props, state) => {
        //Check to see if the media upload button was clicked on the parent component, if so, then submit the files on this component.
        if (props.mediaUploadButtonClicked) {
            state.dialogSubmitFiles = true;
            return state;
        }
        return null;
    }

    uploadTypeChange = (event) => {
        let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
        component.radioValue = event.target.value;
        this.setState({ radioValue: event.target.value });
        if (event.target.value === 'repurpose') {
            this.isValidUpload('', this.state.previousUploadComponentId, event.target.value);
        }
        else if (event.target.value === 'advertiser') {
            this.isValidUpload(this.state.filenames, 0, event.target.value);
        }
        this.props.currentSubmission.ticket.components[this.props.componentIndex] = component;
        this.props.updateSubmission(this.props.currentSubmission);

    }
    
    truncateFileName = (filename) => {
        const maxLength = 30;
        return filename.length <= maxLength ? filename : `${filename.substring(0, maxLength)}...`;
    }

    displayRepurposeDropdown = (classes, reusableComponents) => {
        if (this.state.radioValue === 'repurpose') {
            let repurposeDropdownClassName = this.props.inDialog ? classes.repurposeDropdownInDialog : classes.repurposeDropdown;
            return (
                <Select native name='repurposeDropdown' value={this.state.previousUploadComponentId} onChange={this.changeHandler} className={repurposeDropdownClassName}>
                    <option key={0} value={0}>{lang('reuploadText')}</option>
                    {reusableComponents && reusableComponents.map(file => {
                        return <option key={file.ticketComponentId} value={file.ticketComponentId}>{this.truncateFileName(file.filename)}</option>;
                    })}
                </Select>
            );
        }
        else {
            return '';
        }
    }

    fileChange = (event) => {
        let filenames = this.getFilenamesFromFiles(event.target.files);
        this.isValidUpload(filenames, 0, 'advertiser');
        this.setState({ filenames: filenames });
    }

    getFilenamesFromFiles = (files) => {
        let concatenatedFilenames = ''
        if (files !== undefined) {
            for (let i = 0; i < files.length; i++) {
                if (files[i] !== undefined) {
                    if (i === 0) {
                        concatenatedFilenames = files[i].name
                    }
                    else {
                        concatenatedFilenames = concatenatedFilenames + ', ' + files[i].name;
                    }
                }
            }
        }
        return concatenatedFilenames;
    }

    isValidUpload = (filenames, previousUploadComponentId, radioValue) => {
        if ((radioValue === 'advertiser' && filenames !== null && filenames.length > 0) || (radioValue === 'repurpose' && previousUploadComponentId !== '0' && previousUploadComponentId !== 0)) {
            if (this.props.updateMediaButtonState) {
                this.props.updateMediaButtonState(true, true);
            }
            return true;
        }
        if (this.props.updateMediaButtonState) {
            this.props.updateMediaButtonState(false, true);
        }
        return false;
    }

    changeHandler = (event) => {
        let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
        if (event.target.name.includes('repurposeDropdown')) {
            component.previousUploadComponentId = event.target.value;
            this.isValidUpload('', event.target.value, 'repurpose');
            this.setState({ previousUploadComponentId: event.target.value });
        }
        else if (event.target.name.includes('Upload') && event.target.files && event.target.files[0]) {
            let filenames = this.getFilenamesFromFiles(event.target.files);
            this.isValidUpload(filenames, this.state.previousUploadComponentId, 'advertiser');
            this.setState({ filenames: filenames });
        }
        this.props.currentSubmission.ticket.components[this.props.componentIndex] = component;
        this.props.updateSubmission(this.props.currentSubmission);
    }

    validateFileType = (filename) => {
        let acceptableTypes = this.props.currentSubmission.acceptableMediaFileTypes;
        acceptableTypes = acceptableTypes.replace(/\*/g, '');
        let splitArray = acceptableTypes.split(';');
        for (let y = 0; y < splitArray.length; y++) {
            if (endsWithIgnoreCase(filename, splitArray[y])) {
                return true;
            }
        }
        return false;
    }

    submitFiles = () => {
        //If repurpose was selected handle the repurposing of the old file, otherwise drop through and do the file upload.
        if (this.props.updateMediaButtonState) {
            this.props.updateMediaButtonState(false, false);
        }
        if (this.state.radioValue === 'repurpose') {
            this.setState({ isButtonEnabled: false, dialogSubmitFiles: false });
            fetch(`SvrTicket/RepurposeMediaFile?ticketId=${this.props.currentSubmission.ticket.id}&previousComponentId=${this.state.previousUploadComponentId}`,
                { method: 'POST', credentials: 'same-origin' })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        //refetch ticket.
                        fetch(`SvrTicket/GetTicket?ticketId=${this.props.currentSubmission.ticket.id}`, { method: 'POST', credentials: "same-origin" })
                            .then(response => response.json())
                            .then(data => {
                                //reload ticket
                                this.props.currentSubmission.ticket = data.ticket;
                                this.props.updateSubmission(this.props.currentSubmission);
                            });
                    }
                    else {
                        if (data.error === 'isDeleted') {
                            this.setState({ ticketState: TicketState.Deleted });
                        }
                        else if (data.error === 'isSubmitted') {
                            this.setState({ ticketState: TicketState.Submitted });
                        }
                        else {
                            this.setState({ message: lang(data.error), showDialog: true, dialogType: DialogType.Error, isButtonEnabled: true });
                        }
                    }
                });
            return;
        }

        let form = document.getElementById('fileUpload');
        let tid = this.props.currentSubmission.ticket.id;
        let fileInput = undefined;
        for (let i = 0; i < form.length; i++) {
            if (form[i].type === 'file') {
                fileInput = form[i];
                break;
            }
        }

        //Pre-upload file checks
        for (let i = 0; i < fileInput.files.length; i++) {
            let file = fileInput.files[i];

            //Check if file is an acceptable file type
            if (!this.validateFileType(file.name)) {
                this.setState({ dialogType: DialogType.Error, message: langFormat('invalidFileType', this.props.currentSubmission.acceptableMediaFileTypes), showDialog: true });
                return;
            }
            //Check if file size is too large
            if (file.size > MAX_FILE_SIZE) {
                this.setState({ dialogType: DialogType.Error, message: lang('maxFileSizeError'), showDialog: true })
                return;
            }
            if (file.name.length > 100) {
                this.setState({ message: lang('filenameTooLong'), dialogType: DialogType.Error, showDialog: true });
                return;
            }
        }

        for (let i = 0; i < fileInput.files.length; i++) {
            this.props.currentSubmission.fileUploads.push({ file: fileInput.files[i], percent: 0, isDone: false });
        }

        this.uploadNextFile(tid);
        this.setState({ isUploading: true });
    }

    uploadNextFile = (tid) => {
        let request = new XMLHttpRequest();
        let fileUpload = new FormData();
        let savedFile = this.props.currentSubmission.fileUploads.find(file => !file.isDone);
        if (this.props.currentSubmission.fileUploads && savedFile) {
            let file = savedFile.file;
            require('formdata-polyfill');
            fileUpload.set('fileUpload', file);
            request.upload.addEventListener('progress', this.updateProgress)
            request.onreadystatechange = () => {
                if (request.readyState !== 4) return;
                if (request.status >= 200 && request.status <= 300) {
                    let response = JSON.parse(request.response);
                    if (response.success) {
                        this.props.currentSubmission.fileUploads.find(file => !file.isDone).isDone = true;  //set finished
                        //if a file still needs to be uploaded, start uploading the next file
                        if (this.props.currentSubmission.fileUploads && this.props.currentSubmission.fileUploads.find(file => !file.isDone)) {
                            this.uploadNextFile(tid);
                        }
                        else {
                            fetch(`SvrTicket/SetMediaFilesToPrecheck?ticketId=${tid}`, { method: 'POST', credentials: "same-origin" })
                                .then(response => response.json())
                                .then(data => {
                                    this.props.currentSubmission.ticket = data.ticket;
                                    this.props.currentSubmission.fileUploads = [];
                                    this.props.updateSubmission(this.props.currentSubmission);
                                });
                        }
                    }
                    else {
                        if (response.error === 'isSubmitted') {
                            this.setState({ ticketState: TicketState.Submitted });
                        }
                        else if (response.error === 'isDeleted') {
                            this.setState({ ticketState: TicketState.Deleted })
                        }
                    }
                }
            }
            request.open('POST', `SvrTicket/UploadMediaFile?tid=${tid}`);
            request.send(fileUpload);
        }
    }

    updateProgress = (e) => {
        let { updateSubmission } = this.props;
        let total = e.total;
        let uploaded = e.loaded;
        let percent = parseInt(uploaded * 100 / total, 10);
        let upload = this.props.currentSubmission.fileUploads.find(file => !file.isDone);
        if (upload) {
            upload.percent = percent;
            updateSubmission(this.props.currentSubmission)
        }
    }

    closeDialog = () => {
        this.setState({ showDialog: false });
    }

    fileButtonClick = (event) => {
        if (event.target && event.target.children.length > 0 && event.target.children[0].children.length > 0) {
            event.target.children[0].children[0].click();
        }
    }

    render() {
        const { classes, reusableComponents } = this.props;
        if (this.state.dialogSubmitFiles && this.props.mediaUploadButtonClicked) {
            this.props.disableMediaUploadButtonClicked();
            this.submitFiles();
        }
        let truncatedFilename = this.truncateFileName(this.state.filenames);
        let fileUploads = this.props.currentSubmission.fileUploads;
        let isUploading = fileUploads !== undefined && fileUploads.length > 0;
        let uploadPercent = 0;
        if (isUploading) {
            let runningTotal = 0;
            for (let i = 0; i < fileUploads.length; i++) {
                runningTotal += fileUploads[i].percent;
            }
            uploadPercent = parseInt(runningTotal / fileUploads.length, 10);
        }
        require('babel-polyfill');
        let acceptableMediaFileTypes = this.props.currentSubmission.acceptableMediaFileTypes.replace(/;/g, ',').replace(/\*/g, '');
        return (
            <div>
                {!isUploading ? (
                    <form id='fileUpload'>
                        <div>
                            <FormControlLabel label={lang('advertiserSuppliedMedia')} value='advertiser' control={<Radio color='primary' name=' advertiserRadio' onChange={this.uploadTypeChange} checked={this.state.radioValue === 'advertiser'} />} />
                            <FormControlLabel style={{ display: this.state.radioValue === 'advertiser' ? '' : 'none' }} disabled={this.state.radioValue !== 'advertiser'} className={classes.fileUploadContainer} label={<div className={classes.fileUploadLabel}>{truncatedFilename}</div>} control={<Button color='primary' onClick={this.fileButtonClick} className={`${classes.fileUploadButton} ${classes.neutralColor}`} variant='contained'>{lang('browse') + '...'}<input name='fileUpload' accept={acceptableMediaFileTypes} multiple onChange={this.fileChange} className={classes.fileUpload} type='file' /></Button>} />
                        </div>
                        <div>
                            <FormControlLabel label={lang('repurposePreviousUpload')} value='repurpose' control={<Radio name='repurposeRadio' color='primary' onChange={this.uploadTypeChange} checked={this.state.radioValue === 'repurpose'} />} />
                            {this.displayRepurposeDropdown(classes, reusableComponents)}
                        </div>
                        <div className={classes.precheckButton}>
                            {this.props.inDialog ? '' : (
                                <Button style={{ display: !this.isValidUpload(this.state.filenames, this.state.previousUploadComponentId, this.state.radioValue) || isUploading || !this.state.isButtonEnabled ? 'none' : 'inline' }} color='secondary' variant="contained" disabled={!this.isValidUpload(this.state.filenames, this.state.previousUploadComponentId, this.state.radioValue) || isUploading || !this.state.isButtonEnabled} onClick={this.submitFiles} >{lang('proceedToPrecheck')}</Button>
                                )}
                        </div>
                    </form>) :
                    <ProgressBar variant='determinate' value={uploadPercent} />}
                <Dialog open={this.state.showDialog} onClose={this.closeDialog} >
                    <DialogTitle id="form-dialog-title">{lang('error')}</DialogTitle>
                    <DialogContent className={classes.wrapText}>
                        <Typography variant="body2">{this.state.message}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' autoFocus onClick={this.closeDialog} color="primary">
                            {lang('ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <TicketClosedDialog open={this.state.ticketState !== TicketState.None} isDeleted={this.state.ticketState === TicketState.Deleted} currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission} />
            </div>
            );
    }
}

export default withStyles(MediaUpload, styles);