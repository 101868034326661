import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { lang } from '../../Language';
import { withStyles } from 'tss-react/mui';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ProgressBar from '../Common/ProgressBar';
import { TicketComponentStatus } from '../../TicketComponentStatus';
import 'ie-array-find-polyfill';
import RadioGroup from '@mui/material/RadioGroup';
import 'formdata-polyfill';
import TicketClosedDialog, { TicketState } from './TicketClosedDialog';

const styles = theme => ({
    fileUploadContainer: {
        marginLeft: 10
    },
    fileUploadLabel: {
        marginLeft: 10
    },
    fileUpload: {
        cursor: 'pointer',
        position: 'absolute',
        top: '0',
        bottom: '0',
        right: '0',
        left: '0',
        width: '100%',
        opacity: '0'
    },
    fileUploadButton: {
        cursor: 'pointer'
    },
    colors: {
        marginLeft: 60
    },
    pantoneColors: {
        paddingLeft: 10
    },
    repurposeDropdown: {
        minWidth: 200
    },
    panelDetails: {
        position: 'relative'
    },
    precheckButton: {
        float: 'right'
    },
    neutralColor: {
        color: theme.palette.neutral.contrastText,
        backgroundColor: theme.palette.neutral.main,
        '&:hover': {
            backgroundColor: theme.palette.neutral.dark,
        },
    },
    inlineColors: {
        display: 'inline'
    },
    publisherSuppliedText: {
        paddingTop: 13,
        paddingLeft: 150 
    }
});

const MAX_FILE_SIZE = 1024 * 1024 * 100; //100MB max upload file size

const DialogType = Object.freeze({
    None: 0,
    DigitalReplica: 1,
    Error: 2,
    RequiredFields: 3
});

class PrintUpload extends Component {
    displayName = PrintUpload.name

    constructor(props) {
        super(props);
        this.state = {
            filename: '',
            message: '',
            showDialog: false,
            dialogType: DialogType.None,
            isUploading: false,
            ticketState: TicketState.None
        };
    }

    closeDialog = (result) => {
        if (result === true) {
            this.setState({ showDialog: false });
        }
        else {
            let replicaCheckbox = document.getElementById(`digitalReplica-${this.props.currentSubmission.ticket.components[this.props.componentIndex].id}`);
            replicaCheckbox.click();
            this.setState({ showDialog: false });
        }
    }

    findNextUnfinishedComponentAndFlashPanel(index) {
        let components = this.props.currentSubmission.ticket.components;
        if (components) {
            for (let i = 0; i < components.length; i++) {
                if (components[i].status !== TicketComponentStatus.PublisherSupplied &&
                    components[i].status !== TicketComponentStatus.ReadyToTransmit &&
                    i !== index) {
                    return i;
                }
            }
        }
        return -1;
    }

    isValidComponent = () => {
        let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
        const { radioValue, previousUploadComponentId, colorBlack, colorCyan, colorMagenta, colorYellow } = component;
        if ((colorCyan || colorMagenta || colorYellow || colorBlack) && ((radioValue === 'advertiser' && this.state.filename !== null && this.state.filename.length > 0) || (radioValue === 'repurpose' && +previousUploadComponentId !== 0))) {
            return true;
        }
        this.setState({ showDialog: true, dialogType: DialogType.RequiredFields, message: lang('pleaseEnsure') });
        return false;
    }

    fileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({ filename: event.target.files[0].name });
        }
    }

    fileButtonClick = (event) => {
        this.setState({ filename: '' });
        let form = event.target.form;
        for (let i = 0; i < form.length; i++) {
            if (form[i].type === 'file') {
                form[i].value = null;
                break;
            }
        }
        if (event.target && event.target.children.length > 0 && event.target.children[0].children.length > 0) {
            event.target.children[0].children[0].click();
        }
    }

    colorChange = (event, checked) => {
        let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
        let name = event.target.name;
        if (name === 'fourColor' && checked === 'fourColor') {
            //happens if the 4C radio button is selected
            component.colorCyan = true;
            component.colorMagenta = true;
            component.colorYellow = true;
            component.colorBlack = true;
        }
        else if (name === 'fourColor') {
            component.colorCyan = checked;
            component.colorMagenta = checked;
            component.colorYellow = checked;
            component.colorBlack = checked;
        }
        else if (name === 'pantoneColorText') {
            component.pantoneColor = event.target.value;
        }
        else if (name === 'usePantoneColor' && !checked) {
            component.usePantoneColor = checked;
            component.pantoneColor = undefined;
        }
        else if (name === 'colorBlack' && checked === 'colorBlack') {
            //happens if the K radio button is selected
            component.colorBlack = true;
            component.colorCyan = false;
            component.colorMagenta = false;
            component.colorYellow = false;
        }
        else {
            component[event.target.name] = checked;
        }
        this.props.currentSubmission.ticket.components[this.props.componentIndex] = component;
        this.props.updateSubmission(this.props.currentSubmission);
    }

    uploadTypeChange = (event) => {
        let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
        let radioValue = event.target.value;
        let isPublisherSupplied = event.target.name === 'suppliedRadio';
        fetch(`SvrTicket/UpdatePublisherSupplied?tcid=${component.id}&isPublisherSupplied=${isPublisherSupplied}`, { method: "POST", credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.props.currentSubmission.ticket.components[this.props.componentIndex] = data.ticketComponent;
                    this.props.currentSubmission.flashPanel = data.ticketComponent.isPublisherSupplied ? this.findNextUnfinishedComponentAndFlashPanel(this.props.componentIndex) : -1;
                    this.props.currentSubmission.ticket.components[this.props.componentIndex].radioValue = radioValue;
                    this.props.updateSubmission(this.props.currentSubmission);
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(data.error) });
                    }
                }
            });
    }

    //All changes to checkboxes, radio buttons, and text fields are updated on the ticket component and then the updateSubmission method saves these changes
    // in case the user wants to jump to another window in the app.
    // Note: A chosen file in the file input will NOT be saved for security reasons.
    changeHandler = (event, checked) => {
        let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
        if (event.target.name === 'digitalReplica') {
            component.useDigitalReplica = checked;
            if (checked) {
                this.setState({ showDialog: true, dialogType: DialogType.DigitalReplica });
            }
        }
        else if (event.target.name.includes('repurposeDropdown')) {
            component.previousUploadComponentId = event.target.value;
        }
        else if (event.target.name.includes('Upload') && event.target.files && event.target.files[0]) {
            this.setState({ filename: event.target.files[0].name });
        }
        this.props.currentSubmission.ticket.components[this.props.componentIndex] = component;
        this.props.updateSubmission(this.props.currentSubmission);
    }

    updateProgress = (e) => {
        let { componentIndex, updateSubmission } = this.props;
        let total = e.total;
        let uploaded = e.loaded;
        let percent = parseInt(uploaded * 100 / total, 10);
        let upload = this.props.currentSubmission.fileUploads.find(x => x.componentIndex === componentIndex);
        if (upload) {
            upload.progress = percent;
            updateSubmission(this.props.currentSubmission)
        }
    }

    checkFilenameForIllegalCharacters = (filename) => {
        return filename.indexOf('|') >= 0 || filename.indexOf('/') >= 0 || filename.indexOf('\\') >= 0 || filename.indexOf(':') >= 0 ||
            filename.indexOf('?') >= 0 || filename.indexOf('"') >= 0 || filename.indexOf('<') >= 0 || filename.indexOf('>') >= 0 ||
            filename.indexOf('*') >= 0; 
    }

    //Upload file to server using an XmlHttpRequest here instead of the Fetch API so that we can make use 
    // of the upload.onprogress method to update a progress bar.  
    submitFile = (componentId) => {
        let { updateSubmission, componentIndex } = this.props;
        let form = document.getElementById(`fileUpload-${componentId}`);
        let tcid = componentId;
        require('formdata-polyfill');
        let fileUpload = new FormData(form);
        let filename = '';
        for (let i = 0; i < form.length; i++) {
            if (form[i].type === 'file') {
                filename = form[i].files[0].name;
                if (form[i].files[0].size > MAX_FILE_SIZE) {
                    this.setState({ message: lang('maxFileSizeError'), dialogType: DialogType.Error, showDialog: true });
                    return;  //file upload failed with size error, don't continue upload.
                }
                if (filename.length > 100) {
                    this.setState({ message: lang('filenameTooLong'), dialogType: DialogType.Error, showDialog: true });
                    return;
                }
                if (!filename.toLowerCase().endsWith('.pdf')) {
                    this.setState({ message: lang('fileMustBePDF'), dialogType: DialogType.Error, showDialog: true });
                    return;
                }
                if (this.checkFilenameForIllegalCharacters(filename)) {
                    this.setState({ message: lang('containsIllegalCharacters'), dialogType: DialogType.Error, showDialog: true });
                    return;
                }
            }
        }

        let findIndexOfComponent = this.findIndexOfComponentId;
        let request = new XMLHttpRequest();
        request.upload.addEventListener('progress', this.updateProgress)
        request.onreadystatechange = () => {

            //if the request isn't ready to send, do nothing.
            if (request.readyState !== 4) return;

            //If the request was completed successfully then fetch the updated ticket component from the server and update the currentSubmission.
            if (request.status >= 200 && request.status <= 300) {
                let response = JSON.parse(request.response);
                if (response.success) {
                    fetch('SvrTicket/GetTicketComponent?tcid=' + tcid, { method: 'POST', credentials: 'same-origin' })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success) {
                                //find upload in fileUploads array on current submission object
                                let upload = this.props.currentSubmission.fileUploads.find(x => x.componentIndex === componentIndex);
                                if (upload) {
                                    let index = this.props.currentSubmission.fileUploads.indexOf(upload);
                                    this.props.currentSubmission.fileUploads.splice(index, 1);  //remove fileUpload from fileUploads, it has finished uploading.
                                }
                                let components = this.props.currentSubmission.ticket.components;
                                components[findIndexOfComponent(components, tcid)] = data.ticketComponent;
                                updateSubmission(this.props.currentSubmission);
                            }
                            else {
                                if (this.state === undefined) {
                                    this.clearUploads();
                                    this.props.showBackgroundDialog(lang(data.error));
                                }
                                else {
                                    this.clearUploads();
                                    this.setState({ showDialog: true, message: lang(data.error), dialogType: DialogType.Error, isUploading: false });
                                }
                            }
                        });
                }
                else if (this.state !== undefined) {
                    this.clearUploads();
                    this.setState({ showDialog: true, message: lang(response.error), dialogType: DialogType.Error, isUploading: false });
                }
            }
        }
        request.open('POST', 'SvrTicket/UploadAdFile?tcid=' + tcid);
        request.send(fileUpload);
        this.props.currentSubmission.fileUploads.push({ componentIndex: componentIndex, progress: 0, filename: filename });
        updateSubmission(this.props.currentSubmission);
    }

    findIndexOfComponentId = (components, tcid) => {
        let i;
        for (i = 0; i < components.length; i++) {
            if (components[i].id === tcid) {
                return i;
            }
        }
        return -1;
    }

    submitEditComponent = (event, component, index) => {
        if (!this.isValidComponent()) {
            event.preventDefault();
            return;
        }
        let form = document.getElementById(`fileUpload-${component.id}`);
        if (!form.checkValidity()) {
            return;
        }
        event.preventDefault();
        let uploadDetails = component;
        let props = this.props;  //'this' is not accessible inside of method in fetch below.
        fetch('SvrTicket/SubmitEditComponent', {
            method: 'POST',
            credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(uploadDetails)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    if (component.radioValue === 'advertiser') {
                        this.submitFile(component.id);
                    }
                    else {
                        fetch('SvrTicket/GetTicketComponent?tcid=' + component.id, { method: 'POST', credentials: 'same-origin' })
                            .then(response => response.json())
                            .then(data => {
                                if (data.success) {
                                    let components = props.currentSubmission.ticket.components;
                                    components[index] = data.ticketComponent;
                                    props.updateSubmission(props.currentSubmission);
                                }
                            });
                    }
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(data.error) });
                    }
                }
            });
    }

    clearUploads = () => {
        this.props.currentSubmission.fileUploads = [];
    }

    truncateFileName = (filename) => {
        const maxLength = 20;
        return filename.length <= maxLength ? filename : `${filename.substring(0, maxLength)}...`;
    }
    
    render() {
        const { classes, reusableComponents, currentSubmission, componentIndex } = this.props;
        let reusablePDFComponents = reusableComponents.filter(file => file.filename.toLowerCase().endsWith('.pdf'));
        let component = currentSubmission.ticket.components[componentIndex];
        let uploadData = currentSubmission.fileUploads ? currentSubmission.fileUploads.find(x => x.componentIndex === componentIndex) : undefined;
        let isUploading = uploadData !== undefined;
        let truncatedFilename = this.truncateFileName(this.state.filename);
        let fourColorChecked = component.colorBlack && component.colorMagenta && component.colorCyan && component.colorYellow;

        let dialogTitle = '';
        let dialogContent = '';
        let cancelButton = '';
        if (this.state.dialogType === DialogType.DigitalReplica) {
            dialogTitle = lang('digitalReplicaDialogTitle');
            dialogContent = <div><div style={{ fontWeight: 'bold' }}>{lang('digitalReplicaDialogBoldText')}</div><p></p><div>{lang('digitalReplicaDialogText')}</div></div>;
            cancelButton = <Button onClick={() => this.closeDialog(false)} variant='contained' color='primary'>{lang('cancel')}</Button>;
        }
        else if (this.state.dialogType === DialogType.Error) {
            dialogTitle = lang('error');
            dialogContent = lang(this.state.message);
        }
        else if (this.state.dialogType === DialogType.RequiredFields) {
            dialogTitle = lang('requiredFieldsMissing');
            dialogContent = lang(this.state.message);  
        }

        let defaultRadioColor = currentSubmission.useCMYKOption ? '' : component.colorCyan && component.colorMagenta && component.colorYellow && component.colorBlack
            ? 'fourColor' : 'colorBlack';

        let colorOption = currentSubmission.useCMYKOption ? (
            <React.Fragment>
                <FormControlLabel label={<Typography variant="body2">{lang('4C')}</Typography>} control={<Checkbox color='primary' name='fourColor' checked={fourColorChecked} onChange={this.colorChange} />} />
                <FormControlLabel style={{ visibility: currentSubmission.showCMYKCheckboxes ? 'visible' : 'hidden' }} label={<Typography variant="body2">{lang('C')}</Typography>} control={<Checkbox color='primary' name='colorCyan' checked={component.colorCyan} onChange={this.colorChange} />} />
                <FormControlLabel style={{ visibility: currentSubmission.showCMYKCheckboxes ? 'visible' : 'hidden' }} label={<Typography variant="body2">{lang('M')}</Typography>} control={<Checkbox color='primary' name='colorMagenta' checked={component.colorMagenta} onChange={this.colorChange} />} />
                <FormControlLabel style={{ visibility: currentSubmission.showCMYKCheckboxes ? 'visible' : 'hidden' }} label={<Typography variant="body2">{lang('Y')}</Typography>} control={<Checkbox color='primary' name='colorYellow' checked={component.colorYellow} onChange={this.colorChange} />} />
                <FormControlLabel style={{ visibility: currentSubmission.showCMYKCheckboxes ? 'visible' : 'hidden' }} label={<Typography variant="body2">{lang('K')}</Typography>} control={<Checkbox color='primary' name='colorBlack' checked={component.colorBlack} onChange={this.colorChange} />} />
            </React.Fragment>
        ) : (
                <RadioGroup defaultValue={defaultRadioColor} className={classes.inlineColors} name='colorOption' onChange={this.colorChange}>
                    <FormControlLabel label={<Typography variant="body2">{lang('4C')}</Typography>} value='fourColor' control={<Radio color='primary' name='fourColor' />} />
                    <FormControlLabel label={<Typography variant="body2">{lang('K')}</Typography>} value='colorBlack' control={<Radio color='primary' name='colorBlack' />} />
                </RadioGroup>
            );

        return (
            <div className={classes.panelDetails} style={{width: '100%'}}>
                {!isUploading
                    ? (<form key='1' id={`fileUpload-${component.id}`}>
                        <div>
                            <FormControlLabel label={<Typography variant="body2">{lang('advertiserSupplied')}</Typography>} value='advertiser' control={<Radio color='primary' name=' advertiserRadio' onChange={this.uploadTypeChange} checked={component.radioValue === 'advertiser'} />} />
                            <FormControlLabel style={{ display: component.radioValue === 'advertiser' ? '' : 'none' }} disabled={component.radioValue !== 'advertiser'} className={classes.fileUploadContainer} label={<div className={classes.fileUploadLabel}>{truncatedFilename}</div>} control={<Button color='primary' onClick={this.fileButtonClick} className={`${classes.fileUploadButton} ${classes.neutralColor}`} variant='contained'>{lang('browse') + '...'}<input name='fileUpload' accept='.pdf' onChange={this.fileChange} className={classes.fileUpload} type='file' /></Button>} />
                        </div>
                        <div style={{ display: component.radioValue === 'advertiser' ? '' : 'none' }} className={classes.colors}>
                            {colorOption}
                            {currentSubmission.ticket.allowPantone ? (
                                <div>
                                    <FormControlLabel label={<Typography variant="body2">{lang('usePantone')}</Typography>} control={<Checkbox name='usePantoneColor' color='primary' checked={component.usePantoneColor} onChange={this.colorChange} />} />
                                    {component.usePantoneColor ? (
                                        <FormControlLabel label={<Typography variant="body2">{lang('pantoneColor') + ':'}</Typography>} labelPlacement='start' control={<TextField name='pantoneColorText' required value={component.pantoneColor} onChange={this.colorChange} className={classes.pantoneColors} inputProps={{ maxLength: '20' }} />} />
                                    ) : ''}
                                    <div>{lang('pantoneWarning')}</div>
                                </div>
                            ): ''}
                        </div>
                        <div>
                            {component.isPublisherSuppliedEnabled ? (
                                <div style={{ display: 'flex' }}>
                                    <FormControlLabel label={<Typography variant="body2">{lang('publisherSupplied')}</Typography>} value='supplied' control={<Radio name='suppliedRadio' color='primary' onChange={this.uploadTypeChange} checked={component.radioValue === 'supplied'} />} />
                                    {component.isPublisherSupplied ? <div className={classes.publisherSuppliedText}><Typography variant="body2">{lang('publisherSuppliedText')}</Typography></div> : ''}
                                </div>
                            ): ''}
                        </div>
                        <div>
                            <FormControlLabel label={<Typography variant="body2">{lang('repurposePreviousUpload')}</Typography>} value='repurpose' control={<Radio name='repurposeRadio' color='primary' onChange={this.uploadTypeChange} checked={component.radioValue === 'repurpose'} />} />
                            {component.radioValue === 'repurpose' ? (
                                <Select native name='repurposeDropdown' value={component.previousUploadComponentId} onChange={this.changeHandler} className={classes.repurposeDropdown} variant="standard">
                                    <option key={0} value={0}>{lang('reuploadText')}</option>
                                    {reusablePDFComponents && reusablePDFComponents.map(file => {
                                        return <option key={file.ticketComponentId} value={file.ticketComponentId}>{this.truncateFileName(file.filename)}</option>;
                                    })}
                                </Select>
                            ) : ''}
                        </div>
                        <div style={{ display: component.radioValue === 'repurpose' ? '' : 'none' }} className={classes.colors}>
                            {colorOption}
                            {currentSubmission.ticket.allowPantone ? (
                                <div>
                                    <FormControlLabel label={<Typography variant="body2">{lang('usePantone')}</Typography>} control={<Checkbox name='usePantoneColor' color='primary' checked={component.usePantoneColor} onChange={this.colorChange} />} />
                                    {component.usePantoneColor ? (
                                        <FormControlLabel label={<Typography variant="body2">{lang('pantoneColor') + ':'}</Typography>} labelPlacement='start' control={<TextField name='pantoneColorText' required value={component.pantoneColor} onChange={this.colorChange} className={classes.pantoneColors} inputProps={{ maxLength: '20' }} variant="standard" />} />
                                    ) : ''}
                                    <div>{lang('pantoneWarning')}</div>
                                </div>
                            ) : ''}
                        </div>
                        <div>
                            {currentSubmission.ticket.isReplicaAllowed ? (
                                <div>
                                    <FormControlLabel label={<Typography variant="body2">{lang('digitalReplica')}</Typography>} control={<Checkbox id={`digitalReplica-${component.id}`} name='digitalReplica' color='primary' checked={component.useDigitalReplica} onChange={this.changeHandler} />} />
                                </div>
                            ): ''}
                        </div>
                        <div className={classes.precheckButton}>
                            <Button color='secondary' type='submit' disabled={component.isPublisherSupplied} style={{ display: component.isPublisherSupplied ? 'none' : 'initial' }} onClick={(e) => this.submitEditComponent(e, component, componentIndex)} variant='contained'>{lang('proceedToPrecheck')}</Button>
                        </div>
                    </form>) :
                    (<ProgressBar variant='determinate' text='Uploading...' value={uploadData.progress} />)}
                <Dialog open={this.state.showDialog} onClose={() => this.closeDialog(this.state.dialogType !== DialogType.DigitalReplica)} >
                    <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body2">{dialogContent}</Typography>
                    </DialogContent>
                    <DialogActions>
                        {cancelButton}
                        <Button variant='contained' autoFocus onClick={() => this.closeDialog(true)} color="secondary">
                            {lang('ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <TicketClosedDialog open={this.state.ticketState !== TicketState.None} isDeleted={this.state.ticketState === TicketState.Deleted} currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission} />
            </div>
        );
    }
}

PrintUpload.propTypes = {
    updateSubmission: PropTypes.any.isRequired,
    currentSubmission: PropTypes.any.isRequired,
    componentIndex: PropTypes.any.isRequired
};

export default withStyles(PrintUpload, styles);