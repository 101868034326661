import React, { Component } from 'react';
import { lang } from '../Language';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { TextField } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import green from '@mui/material/colors/green';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { isValidPasswordComplexity } from '../HelperMethods';

const styles = theme => ({
    mainForm: {
        minWidth: '300px',
        maxWidth: '750px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    heading: {
        fontWeight: 'bold',
        marginTop: theme.spacing(1),
    },
    horizontalBar: {
        borderColor: 'black'
    },
    formFix: {
        width: '100%', //Only needed for IE11
    },
    submit: {
        marginTop: theme.spacing(3),
    },
    submitWrapper: {
        textAlign: 'right',
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '100%',
        marginTop: 0,
        marginLeft: -50,
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
        },
    },
};

class EditProfile extends Component {
    displayName = EditProfile.name

    constructor(props) {
        super(props);
        this.state = {
            profile: [],
            loading: true,
            submitting: false,
            showingMessage: false,
            lastMessage: '',
        };
    }

    componentDidMount() {
        fetch('SvrUser/GetProfile', { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                this.setState({ profile: data, loading: false });
            });
    }

    handleSubmit = (e) => {
        if (!this.state.submitting) {
            var form = document.getElementById("editProfileForm");
            var password1 = document.getElementById("password1");
            password1.setCustomValidity('');
            var password2 = document.getElementById("password2");
            password2.setCustomValidity('');

            if (password1.value !== '' || password2.value !== '') {
                if (!isValidPasswordComplexity(password1)) {
                    return;
                }
                if (password1.value !== password2.value) {
                    password2.setCustomValidity(lang('passwordMismatch') + '\n');;
                }
                else {
                    password2.setCustomValidity('');
                }
            }
            if (!form.checkValidity()) {
                return; //Regular submit will happen, causing built in form validation to work.
            }
            e.preventDefault();
            const { updateUserInfo } = this.props;
            this.setState(
                {
                    submitting: true
                },
                () => {
                    require('formdata-polyfill'); //IE11 needs this for FormData POST to work correctly.
                    fetch(`SvrUser/SubmitEditProfile`, {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/json'
                        },
                        credentials: 'same-origin',
                        body: JSON.stringify(this.state.profile.user)
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success === true) {
                                updateUserInfo(data.userInfo);
                                if (data.emailUpdated) {
                                    this.setState({ submitting: false, lastMessage: lang('changeEmailDispatchMessage'), showingMessage: true });
                                }
                                else {
                                    this.setState({ submitting: false, lastMessage: lang('profileUpdated'), showingMessage: true });
                                }
                            }
                            else {
                                this.setState({
                                    submitting: false,
                                    lastMessage: lang(data.error),
                                    showingMessage: true
                                });
                            }
                        });
                },
            );
        }
        return false;
    };

    handleChange = event => {
        var currentProfile = this.state.profile;
        currentProfile.user[event.target.name] = event.target.value;
        this.setState({ profile: currentProfile });
    };

    handlePasswordChange = event => {
        this.handleChange(event)
        this.validatePassword(event);
      }

      validatePassword = (event) => {
        if(event.target == document.getElementById('password2'))  
        {
            this.validatePasswordMatch(event);
            return;
        }

        if (isValidPasswordComplexity(event.target)) {
            event.target.setCustomValidity('');
        }
    };

    validatePasswordMatch = (event) => {
        var password = document.getElementById('password1');
        if (password.value !== event.target.value) {
            event.target.setCustomValidity(lang('passwordMismatch') + '\n'); ;
        }
        else {
            event.target.setCustomValidity('');
        }      
    };

    closeMessage = () => {
        this.setState({ showingMessage: false });
    };

    renderProfileGrid = () => {
        const { classes } = this.props;
        const { submitting } = this.state;
        const { profile } = this.state;
        return (
            <div>
                <div className={classes.mainForm}>
                    <form id="editProfileForm" className={classes.formFix} >
                        <TextField margin="dense" required fullWidth variant="standard" label={lang("emailAddress")}
                            id="email" name="email" type="email" autoComplete="email"
                            value={profile.user.email} onChange={this.handleChange} inputProps={{ maxLength: "100" }} />
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <TextField id="firstName" name="firstName" autoComplete="firstName" 
                                    margin="dense" required fullWidth variant="standard"
                                    label={lang("firstName")} value={profile.user.firstName}
                                    onChange={this.handleChange} inputProps={{ maxLength: "30" }} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField margin="dense" required fullWidth label={lang("lastName")} variant="standard"
                                    id="lastName" name="lastName" autoComplete="lastName"
                                    value={profile.user.lastName} onChange={this.handleChange} inputProps={{ maxLength: "30" }} />
                            </Grid>
                        </Grid>
                        <TextField id="companyName" name="companyName" autoComplete="companyName"
                            margin="dense" required fullWidth variant="standard"
                            label={lang("company")} value={profile.user.companyName}
                            onChange={this.handleChange} inputProps={{ maxLength: "30" }} />
                        <TextField margin="dense" fullWidth variant="standard" label={lang("phone")}
                            id="phone" name="phone" autoComplete="phone" value={profile.user.phone}
                            onChange={this.handleChange} inputProps={{ maxLength: "15" }} />
                        <TextField margin="dense" fullWidth variant="standard" label={lang("address1")}
                            id="address1" name="address1" autoComplete="address1" value={profile.user.address1}
                            onChange={this.handleChange} inputProps={{ maxLength: "40" }} />
                        <TextField margin="dense" fullWidth variant="standard" label={lang("address2")}
                            id="address2" name="address2" autoComplete="address2" value={profile.user.address2}
                            onChange={this.handleChange} inputProps={{ maxLength: "40" }} />
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <TextField margin="dense" fullWidth variant="standard" label={lang("city")}
                                    id="city" name="city" autoComplete="city" value={profile.user.city} 
                                    onChange={this.handleChange} inputProps={{ maxLength: "30" }} />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl margin="dense" required fullWidth variant="standard">
                                    <InputLabel htmlFor="state">{lang("state")}</InputLabel>
                                    <Select native id="state" name="state" MenuProps={MenuProps} value={profile.user.state} onChange={this.handleChange}>
                                        {profile.states.map(state => (
                                            <option key={state.value} value={state.id}>
                                                {state.value}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl margin="dense" fullWidth variant="standard">
                                    <InputLabel htmlFor="postalCode">{lang("postalCode")}</InputLabel>
                                    <Input id="postalCode" name="postalCode" autoComplete="postalCode" value={profile.user.postalCode} onChange={this.handleChange} inputProps={{ maxLength: "10" }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl margin="dense" fullWidth variant="standard">
                                    <InputLabel htmlFor="country">{lang("country")}</InputLabel>
                                    <Input id="country" name="country" autoComplete="country" value={profile.user.country} onChange={this.handleChange} inputProps={{ maxLength: "30" }} />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <FormControl margin="dense" fullWidth variant="standard">
                            <InputLabel htmlFor="password1">{lang("newPassword")}</InputLabel>
                            <Input id="password1" name="password1" type="password" onChange={this.handlePasswordChange} />
                        </FormControl>
                        <FormControl margin="dense" fullWidth variant="standard">
                            <InputLabel htmlFor="password2">{lang("confirmPassword")}</InputLabel>
                            <Input id="password2" name="password2" type="password" onChange={this.handlePasswordChange} />
                        </FormControl>
                        <FormControl margin="dense" required fullWidth variant="standard">
                            <InputLabel htmlFor="units">{lang("unitPreference")}</InputLabel>
                            <Select native id="units" name="units" MenuProps={MenuProps} value={profile.user.units} onChange={this.handleChange}>
                                {profile.unitsOfMeasure.map(uom => (
                                    <option key={uom.value} value={uom.id}>
                                        {uom.value}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        <div className={classes.submitWrapper}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                disabled={submitting}
                                className={classes.submit}
                                onClick={this.handleSubmit}
                            >
                                {lang("apply")}
                            </Button>
                            {submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </form>
                </div>
                <div>
                    <Dialog
                        open={this.state.showingMessage}
                        onClose={this.closeMessage}
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {this.state.lastMessage}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeMessage} variant="contained" color="secondary" autoFocus>
                                {lang("ok")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }

    render() {
        const { classes } = this.props;
        let contents = this.state.loading
            ? <p><em>{lang("loading")}</em></p>
            : this.renderProfileGrid();

        return (
            <div>
                <Typography variant="h5" className={classes.heading}>{lang('editProfile')}</Typography>
                <hr className={classes.horizontalBar} />
                {contents}
            </div>
        );
    }
}

export default withStyles(EditProfile, styles);