import React, { Component, Fragment } from 'react';
import { lang } from '../Language';
import classNames from 'classnames';
import { withStyles } from 'tss-react/mui';
import { NavLink } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BuildIcon from '@mui/icons-material/Build';
import HelpIcon from '@mui/icons-material/Help';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ExtensionIcon from '@mui/icons-material/Extension';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { overrideComponentThemeStyle } from '../StyleCustomization';
import RequestQuote from '@mui/icons-material/RequestQuote';
const drawerWidth = 200;

const styles = theme => {
    var result = {
        hide: {
            display: 'none',
        },
        drawerPaper: {
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px',
            clear: 'left',
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerPaperClose: {
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px',
            clear: 'left',
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(6),
        },
        toolbar: {
            display: 'flex',
            userSelect: 'none',
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0',
            paddingRight: '5px',
            height: '35px',
            '&:hover': {
                background: 'rgba(0, 0, 0, 0.08)',
            },
            'transition': 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        },
        regularNav: {
            paddingLeft: '3px',
            paddingRight: '3px',
            marginRight: '0px',
            color: 'inherit',
            minWidth: '42px',
            '&.active': {
                backgroundColor: theme.palette.primary.main,
                '&:focus': {
                    backgroundColor: theme.palette.primary.main,
                },
                '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                },
                color: theme.palette.primary.contrastText,
                '& $regularNav': {
                    color: theme.palette.primary.contrastText,
                },
            }
        },
        navIcon: {
            width: '1.5em',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    };

    return overrideComponentThemeStyle(theme, result, "navMenu");
};

class NavMenu extends Component {
    displayName = NavMenu.name
    state = {
        open: true,
    };

    toggleDrawerClose = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        const { classes } = this.props;
        const NavLinkRef = React.forwardRef((props, ref) => <div ref={ref}><NavLink {...props} /></div>);

        return (
            <Drawer
                variant="permanent"
                classes={{
                    paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                }}
                open={this.state.open}
            >
                <div className={classes.toolbar} onClick={this.toggleDrawerClose}>
                    <Icon style={{ display: 'inherit' }}>
                        {this.state.open ? <ChevronLeftIcon /> : <ChevronRightIcon/>}
                    </Icon>
                </div>
                <Divider />
                <List>
                    {
                        (!this.props.userInfo.cannotSubmit) && 
                        <>
                                <ListItem button component={NavLinkRef} to='/ticket' className={classes.regularNav}>
                                    <ListItemIcon className={classes.regularNav}><CloudUploadIcon className={classes.navIcon} /></ListItemIcon>
                                    <ListItemText primary={lang("newContinue")} className={classes.regularNav} classes={{ primary: classes.regularNav }} />
                                </ListItem>
                                <ListItem button component={NavLinkRef} to='/track' className={classes.regularNav}>
                                    <ListItemIcon className={classes.regularNav}><LocalShippingIcon className={classes.navIcon} /></ListItemIcon>
                                    <ListItemText primary={lang("trackDeliveries")} className={classes.regularNav} classes={{ primary: classes.regularNav }} />
                                </ListItem>
                        </>
                    }
                    {
                    (this.props.userInfo.cannotSubmit && this.props.userInfo.isSales) &&
                        <ListItem button component={NavLinkRef} to='/sales' className={classes.regularNav}>
                            <ListItemIcon className={classes.regularNav}><RequestQuote className={classes.navIcon} /></ListItemIcon>
                            <ListItemText primary={lang("sales")} className={classes.regularNav} classes={{ primary: classes.regularNav }} />
                        </ListItem>
                    }
                    <ListItem button component={NavLinkRef} to='/profile' className={classes.regularNav}>
                        <ListItemIcon className={classes.regularNav}><BuildIcon className={classes.navIcon}/></ListItemIcon>
                        <ListItemText primary={lang("editProfile")} className={classes.regularNav} classes={{ primary: classes.regularNav }} />
                    </ListItem>
                    <ListItem button component={NavLinkRef} to='/help' className={classes.regularNav}>
                        <ListItemIcon className={classes.regularNav}><HelpIcon className={classes.navIcon}/></ListItemIcon>
                        <ListItemText primary={lang("help")} className={classes.regularNav} classes={{ primary: classes.regularNav }} />
                    </ListItem>
                    {
                        (!this.props.userInfo.cannotSubmit && this.props.userInfo.isSales) &&
                        <ListItem button component={NavLinkRef} to='/sales' className={classes.regularNav}>
                            <ListItemIcon className={classes.regularNav}><RequestQuote className={classes.navIcon} /></ListItemIcon>
                            <ListItemText primary={lang("sales")} className={classes.regularNav} classes={{ primary: classes.regularNav }} />
                        </ListItem>
                    }
                    {
                        (this.props.userInfo.isIsr || this.props.userInfo.isClientAdminAndHasPublishersAssigned) &&
                        <ListItem button component={NavLinkRef} to='/isr' className={classes.regularNav}>
                            <ListItemIcon className={classes.regularNav}><AssignmentIcon className={classes.navIcon} /></ListItemIcon>
                            <ListItemText primary={lang("isr")} className={classes.regularNav} classes={{ primary: classes.regularNav }} />
                        </ListItem>
                    }
                    { this.props.userInfo.isAdmin &&
                        <ListItem button component={NavLinkRef} to='/admin' className={classes.regularNav}>
                            <ListItemIcon className={classes.regularNav}><ExtensionIcon className={classes.navIcon} /></ListItemIcon>
                            <ListItemText primary={lang("admin")} className={classes.regularNav} classes={{ primary: classes.regularNav }} />
                        </ListItem>
                    }
                </List>
            </Drawer>
        );
    }
}
export default withStyles(NavMenu, styles);