import React, { Component } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { withStyles } from 'tss-react/mui';
import SizePreviewRow from './SizePreviewRow';

const styles = theme => ({
    spacer: {
        paddingTop: theme.spacing(2),
    },
    sizeGroups: {
        display: 'table',
    },
    foldingDummyExpanded: {
        display: 'table-cell',
        width: '150px',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    foldingDummyCollapsed: {
        display: 'table-cell',
        width: theme.spacing(1),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    sizeGroupRow: {
        display: 'block',
        cursor: 'pointer',
        padding: theme.spacing(1),
        userSelect: 'none',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'rgba(255,255,255,0)',
        '&:hover': {
            background: '#DDD',
            boxShadow: '5px 5px 5px #777',
            borderRadius: '8px',
            borderWidth: '1px',
            borderStyle: 'dashed',
            borderColor: '#AAA',
        },
        '&:active': {
            background: '#CCC',
        },
    },
    sizeGroupSelected: {
        display: 'block',
        cursor: 'pointer',
        padding: theme.spacing(1),
        userSelect: 'none',
        background: '#BBB',
        boxShadow: '5px 5px 5px #777',
        borderRadius: '8px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#888',
    },
    sizeGroupCell: {
        display: 'table-cell',
        verticalAlign: 'top',
    },
    sizeGroupInfoCell: {
        display: 'table-cell',
        verticalAlign: 'top',
        paddingLeft: '1em',
        minWidth: '120px',
    },
    sizeGroupPages: {
        display: 'flex',
    },
    panels: {
        textAlign: 'center',
    },
});

class SizeGroupPicker extends Component {
    displayName = SizeGroupPicker.name

    sizeGroupClicked = (sizeId) => {
        let { currentSubmission } = this.props;
        currentSubmission.ticket.sizeId = sizeId;
        this.props.updateSubmission(currentSubmission);
    };

    getFoldingDummy = (currentSubmission) => {
        const { classes } = this.props;
        if (currentSubmission.sameFoldingDummy) {
            if (currentSubmission.groupSizes.length > 0 && currentSubmission.groupSizes[0].foldingDummyExists) {
                return <div className={classes.foldingDummyExpanded}><img src={`File/StreamFile?fileType=AdSizePreview&id=${currentSubmission.ticket.publisherId},${currentSubmission.groupSizes[0].id}`} alt="Size Sample" /></div>;
            }
        }
        else if (currentSubmission.ticket.sizeId) {
            for (var sizeIdx in currentSubmission.groupSizes) {
                if (currentSubmission.groupSizes[sizeIdx].id === currentSubmission.ticket.sizeId) {
                    return <div className={classes.foldingDummyExpanded}><img src={`File/StreamFile?fileType=AdSizePreview&id=${currentSubmission.ticket.publisherId},${currentSubmission.groupSizes[sizeIdx].id}`} alt="Size Sample" /></div>;
                }
            }
        }
        return <div className={classes.foldingDummyCollapsed}>&nbsp;</div>;
    };

    render() {
        const { classes } = this.props;
        let { currentSubmission } = this.props;

        let detailsBody = '';
        if (!currentSubmission.groupSizes) {
            //TicketDetailsPrint loads this data, so it should be populated by the time this renders.
            detailsBody = <LinearProgress variant="query" />;
        }
        else {
            detailsBody = <div className={classes.sizeGroups}>
                {this.getFoldingDummy(currentSubmission)}
                <div className={classes.sizeGroupCell}>
                    <div className={classes.sizeGroups}>
                        {currentSubmission.groupSizes.map(groupSize => {
                            if (!currentSubmission.ticket.isCreateAnAd) {
                                var rowClass = classes.sizeGroupRow;
                                if (currentSubmission.ticket.sizeId === groupSize.id) {
                                    rowClass = classes.sizeGroupSelected;
                                }
                                return <SizePreviewRow key={groupSize.id} size={groupSize} className={rowClass} onClick={() => this.sizeGroupClicked(groupSize.id)} />;
                            }
                            return '';
                        })}
                    </div>
                </div>
            </div>;
        }

        return (
            <React.Fragment>
                {detailsBody}
            </React.Fragment>
        );
    }
}

export default withStyles(SizeGroupPicker, styles);