import React, { Component } from 'react';
import { lang } from '../Language';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Divider from '@mui/material/Divider';

export class PdfPresets extends Component {
    displayName = PdfPresets.name

    slideUp = (props) => {
        return <Slide direction="up" {...props} />;
    };

    render() {
        return (
            <div>
                <Dialog open
                    TransitionComponent={this.slideUp}
                    aria-labelledby='pdf-presets-title'
                >
                    <DialogTitle id="pdf-presets-title">{lang("pdfPresetFiles")}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body2"><a href="File/HelpResource?fileType=AdobePdfPresets">{lang("adobePdfPresets")}</a></Typography>
                        <Typography variant="body2"><a href="File/HelpResource?fileType=QuarkXPressExport">{lang("quarkXpressExport")}</a></Typography>
                        <Typography variant="body2"><a href="File/HelpResource?fileType=QuarkXPressPrintStyle">{lang("quarkXpressPrintStyle")}</a></Typography>
                        <br/>
                        <Divider />
                        <br/>
                        <Typography variant="body2"><a href="File/HelpResource?fileType=Prepress">{lang("adobeInDesignPreflightSettings")}</a></Typography>
                        <Typography variant="body2"><a href="https://helpx.adobe.com/indesign/using/preflighting-files-handoff.html" target="_blank" rel="noopener noreferrer">{lang("inDesignDocumentation")}</a></Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => this.props.closeDialog()}
                        >{lang("ok")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
