import React from 'react';
import { lang } from '../../Language';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { withStyles } from 'tss-react/mui';
import TableSortLabel from '@mui/material/TableSortLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';

const styles = theme => ({
    table: {
        borderCollapse: 'separate',
        borderSpacing: '3px'
    },
    headerCell: {
        fontSize: 14,
        height: 40,
        paddingLeft: 3,
        paddingRight: 3,
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    sortLabel: {
        color: `${theme.palette.primary.contrastText} !important`,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold'
        },
        '&:focus': {
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold'
        }
    },
    titleDropdown: {
        marginRight: '16px'
    },
    tableRow: {
        minHeight: '24px',
        height: 'inherit',
    },
    tableCell: {
        padding: 0,
    },
    linkButton: {
        verticalAlign: 'baseline',
        marginLeft: '3px',
        padding: '1px 8px',
        textDecoration: 'underline',
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: 'inherit',
            textDecoration: 'underline',
        },
        '&:active': {
            color: theme.palette.primary.light,
        },
    },
});

class JobPicker extends React.Component {
    displayName = JobPicker.name

    constructor(props) {
        super(props);
        this.state = {
            isLinked: false,
            includeExtra: false,
            jobList: null,
            yearList: null,
            selectedYear: null,
            titleList: null,
            selectedTitle: null,
            sortCol: 'jobNumber',
            sortDesc: true,
            showingError: false,
            errorMessage: ''
        };
        this.columns = [
            { id: 'jobNumber', label: lang('jobNumber') },
            { id: 'issueName', label: lang('issueName') },
            { id: 'issueYear', label: lang('issueYear') },
            { id: 'jobSelection', label: lang('jobSelection') },
        ];
    }

    componentDidUpdate(prevProps) {
        if (this.props.job !== prevProps.job && this.props.job !== null) {
            const { publisherId, publicationId, job } = this.props;
            const isLinked = (this.props.job.insightJobNumber && this.props.job.insightJobNumber.length > 0);
            fetch(`SvrISR/IssueJobsList?publisherId=${publisherId}&publicationId=${publicationId}&issueId=${job.psIssueId}`, { credentials: 'same-origin' })
                .then(response => response.json())
                .then(data => {
                    if (data.success && data.jobs) {
                        let newYears = this.getYears(data.currentTitleCode, data.jobs, this.state.includeExtra);
                        let titleCodes = this.getTitleCodes(data.jobs);
                        this.setState({ jobList: data.jobs, isLinked: isLinked, yearList: newYears, selectedYear: newYears[0].toString(), titleList: titleCodes , selectedTitle: data.currentTitleCode });
                    }
                });
        }
    }

    contains = (a, obj) => {
    for (var i = 0; i < a.length; i++) {
        if (a[i] === obj) {
            return true;
        }
    }
    return false;
}

    getTitleCodes = (jobs) => {
        let titles = [];
        for (let job in jobs) {
            let currentJob = jobs[job];
            if (!this.contains(titles, currentJob.titleCode)) {
                titles.push(currentJob.titleCode);
            }
        }
        return titles.sort();
    }

    getYears = (selectedTitle, jobs, includeExtra) => {
        let newYears = [];
        jobs = jobs.filter(job => job.titleCode === selectedTitle)
        for (let row in jobs) {
            let job = jobs[row];
            let endsWithZero = job.jobNumber && job.jobNumber.length > 0 && job.jobNumber[job.jobNumber.length - 1] === '0';
            if (newYears.indexOf(job.issueYear) < 0 && (includeExtra || endsWithZero)) {
                newYears.push(job.issueYear);
            }
        }
        if (newYears.length === 0) {
            //Add this year so we don't have an empty drop down.
            newYears.push(new Date().getFullYear().toString());
        }
        newYears.sort(function (a, b) { return b - a; });
        return newYears;
    }

    closeDialog = () => {
        this.props.onJobUpdated();
    };

    saveLink = (j) => {
        const { publisherId, job } = this.props;
        let setJob = j.jobNumber;
        if (j.isLinked) {
            setJob = ''; //Clear Link
        }
        fetch(`SvrISR/LinkIssueToJob?publisherId=${publisherId}&issueId=${job.psIssueId}&jobNumber=${setJob}`, { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    let { job } = this.props;
                    job.insightJobNumber = setJob;
                    if (setJob.length > 0) {
                        job.insightTitleName = j.titleName;
                        job.insightTitleCode = j.titleCode;
                        job.insightJobName = j.issueName;
                        job.insightJobNumber = j.jobNumber;
                    }
                    else {
                        job.insightTitleName = '';
                        job.insightTitleCode = '';
                        job.insightJobName = '';
                        job.insightJobNumber = '';
                    }
                    this.props.onJobUpdated();
                }
                else {
                    let errorMsg = data.error;
                    if (!errorMsg) {
                        errorMsg = 'unexpectedError';
                    }
                    this.setState({ showingError: true, errorMessage: lang(errorMsg) });
                }
            });
    }

    getSortedJobs = () => {
        const { sortCol, sortDesc, jobList, includeExtra, selectedYear, selectedTitle } = this.state;
        var compare = function (a, b) {
            if (b < a) return -1;
            if (b > a) return 1;
            return 0;
        };


        let filteredList = jobList.filter(job => job.titleCode === selectedTitle && job.issueYear.toString() === selectedYear &&
            (includeExtra || (job.jobNumber && job.jobNumber.length > 0 && job.jobNumber[job.jobNumber.length - 1] === '0')));
        const sortedIndexes = filteredList.map((el, index) => [el, index]);
        sortedIndexes.sort((a, b) => {
            const order = compare(a[0][sortCol], b[0][sortCol]);
            if (order !== 0) return (sortDesc) ? order : -order;
            return (sortDesc) ? a[1] - b[1] : b[1] - a[1];
        });
        return sortedIndexes.map(el => el[0]);
    };

    changeSort = (colName) => {
        if (this.state.sortCol === colName) {
            const { sortDesc } = this.state;
            this.setState({ sortDesc: !sortDesc });
        }
        else {
            this.setState({ sortCol: colName });
        }
    };

    getTableHeader = () => {
        const { classes } = this.props;
        return (
            <TableHead>
                <TableRow style={{ height: '40px' }}>
                    {this.columns.map(
                        col => (
                            <TableCell key={col.id} className={classes.headerCell}
                                onClick={() => this.changeSort(col.id)}
                                sortDirection={this.state.sortCol === col.id ? (this.state.sortDesc ? 'desc' : 'asc') : false}
                            >
                                <TableSortLabel
                                    classes={{ root: classes.sortLabel, active: classes.sortLabel, icon: classes.sortLabel }}
                                    active={this.state.sortCol === col.id}
                                    direction={this.state.sortDesc ? 'desc' : 'asc'}
                                >
                                    {col.label}
                                </TableSortLabel>
                            </TableCell>
                        ),
                        this,
                    )}
                </TableRow>
            </TableHead>
        );
    };

    onTitleChange = (event) => {
        let years = this.getYears(event.target.value, this.state.jobList, this.state.includeExtra);
        this.setState({ selectedTitle: event.target.value, yearList: years, selectedYear: years[0].toString() });
    }

    render() {
        const { classes } = this.props;
        let body = <CircularProgress />;
        if (this.state.jobList) {
            body = <div>
                <Typography variant="body2" component="div">
                    {lang("titleCode")}:
                    <Select className={classes.titleDropdown} native value={this.state.selectedTitle} onChange={this.onTitleChange} variant="standard">
                        {this.state.titleList.map(title => <option key={title} value={title}>{title}</option>)}
                    </Select>
                    {lang("year")}:
                    <Select native value={this.state.selectedYear} onChange={(e) => this.setState({ selectedYear: e.target.value })} variant="standard">
                        {this.state.yearList.map(year => <option key={year} value={year}>{year}</option>)}
                    </Select>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.includeExtra}
                                onChange={(e) => this.setState({ includeExtra: e.target.checked, yearList: this.getYears(this.state.selectedTitle, this.state.jobList, e.target.checked) })}
                            />
                        }
                        label={lang("includeExtraJobs")}
                        labelPlacement="start"
                    />
                </Typography>
                <br />
                <Table className={classes.table} aria-labelledby="tableTitle">
                    {this.getTableHeader()}
                    <TableBody>
                        {this.getSortedJobs().map(j =>
                            <TableRow key={j.jobNumber} className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>{j.jobNumber}</TableCell>
                                <TableCell className={classes.tableCell}>{j.issueName}</TableCell>
                                <TableCell className={classes.tableCell}>{j.issueYear}</TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Button className={classes.linkButton} variant="text" size="small" disableRipple onClick={()=>this.saveLink(j)}>
                                        {j.isLinked ? lang('unlinkJob') : (this.state.isLinked) ? lang('changeJob') : lang('selectJob')}
                                    </Button>
                                </TableCell>
                            </TableRow>)}
                    </TableBody>
                </Table>
            </div>;
        }

        return (
            <React.Fragment>
                <Dialog open={this.props.job !== null} maxWidth="md"
                    aria-labelledby='job-picker-title' TransitionProps={{onExited: () => this.setState({ jobList: null })}}
                >
                    <DialogTitle id="job-picker-title">{lang("jobSelection")}</DialogTitle>
                    <DialogContent>
                        {body}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => this.closeDialog()}
                        >{lang("close")}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.showingError}>
                    <DialogContentText>
                        {this.state.errorMessage}
                    </DialogContentText>
                    <DialogActions>
                        <Button color="primary" variant="contained" onClick={() => this.setState({ showingError: false})}>{lang("ok")}</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(JobPicker, styles);