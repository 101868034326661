import React from 'react';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import green from '@mui/material/colors/green';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import { lang } from '../Language';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import NewAccount from './NewAccount';
import ForgotPassword from './ForgotPassword';
import SupportRequest from './SupportRequest';
import Cookies from 'js-cookie';
import { overrideComponentThemeStyle } from '../StyleCustomization';
import { getComponentThemeProperty } from '../StyleCustomization';

const styles = theme => {
    var result = {
        contentWrapper: {
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
        },
        header: {
            background: '#FFF',
        },
        textAboveLogin: {
            textAlign: 'center',
        },
        loginBody: {
            flex: '1 0 auto',
            background: '#ABC',
        },
        logo: {
            textAlign: 'center',
            marginTop: theme.spacing(3),
            '& img': { height: 'inherit' },
        },
        covers: {
            textAlign: 'center',
            '& img': { width: 'inherit', height: 'inherit' },
        },
        layout: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
        paper: {
            minWidth: '350px',
            maxWidth: '400px',
            marginTop: theme.spacing(4),
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(1)}`,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            paddingTop: '6px',
        },
        forgotPassword: {
            display: 'inline',
            float: 'right',
            padding: '4px', //To match the CheckBox to the left.
            marginTop: '2px',
        },
        checkboxPadding: {
            padding: '4px',
            marginLeft: '8px',
        },
        loginBoxBottomContent: {
            alignSelf: 'center',
        },
        submitWrapper: {
            margin: theme.spacing(1),
            textAlign: 'center',
            display: 'block',
        },
        submit: {
            paddingLeft: '64px',
            paddingRight: '64px',
        },
        buttonProgress: {
            color: green[500],
            position: 'relative',
            top: '8px',
            left: '-95px',
            marginTop: 0,
            marginRight: 0,
        },
        helpBelowLogin: {
            textAlign: 'center',
            paddingTop: theme.spacing(6),
        },
        bottomBar: {
            flexShrink: '0',
            textAlign: 'center',
            padding: '1em',
            '& p': {
                color: getComponentThemeProperty("login", "footerForegroundColor"),
            },
            '& p a': {
                color: getComponentThemeProperty("login", "footerLinkColor"),
            },
            '& p a:visited': {
                color: getComponentThemeProperty("login", "footerLinkColor"),
            },
        },
        bottomSegment: {
            paddingLeft: '0.5em',
            paddingRight: '0.5em',
        },
        bottomIcon: {
            verticalAlign: 'middle',
            marginRight: '4px',
        },
    };
    
    result = overrideComponentThemeStyle(theme, result, "login");
    let loginBg = getComponentThemeProperty("login", "background");
    if (loginBg && loginBg.length > 0) {
        document.body.style.background = loginBg;
    }
    else {
        document.body.style.background = result.loginBody.background;
    }
    return result;
};

const LoginDialog = Object.freeze({
    NONE: 0,
    FORGOTPASSWORD: 1,
    CREATEACCOUNT: 2,
    DOWNLOADPRESETS: 3,
});
const REMEMBER_ME = "rememberMe";

class Login extends React.Component {
    constructor(props) {
        super(props);
        let rememberMe = Cookies.get(REMEMBER_ME);
        let lastError = this.props?.location?.state ? this.props.location.state.lastError : '';
        let showError = this.props?.location?.state ? this.props.location.state.showError : false;
        if (props.autoLogoutFrom) {
            lastError = 'sessionTimeout';
            showError = true;
        }
        if (showError === undefined) {
            showError = false; //Occurs after a redirect caused by not being logged in
        }

        this.state = {
            loading: false,
            showingError: showError,
            lastError: lastError,
            showDialog: LoginDialog.NONE,
            initialUser: rememberMe
        };
    }

    handleSubmit = (e) => {
        if (!this.state.loading) {
            var username = document.getElementById("username");
            var password = document.getElementById("password");
            if (username.value === '' || password.value === '') {
                return; //Built in validation will fire for this
            }
            e.preventDefault();
            var rememberYou = document.getElementById('chkRememberMe');
            if (rememberYou.checked) {
                //Remember me is valid for 1 year after the last time you logged in.
                Cookies.set(REMEMBER_ME, username.value, { expires: 365 });
            }
            else if (Cookies.get(REMEMBER_ME)) {
                Cookies.remove(REMEMBER_ME);
            }
            this.setState(
                {
                    loading: true
                },
                () => {
                    require('formdata-polyfill'); //IE11 needs this for FormData POST to work correctly.
                    var formData = new FormData(document.getElementById("loginForm"));
                    fetch("SvrUser/SubmitLogin", {
                        method: "POST",
                        body: formData,
                        credentials: "same-origin"
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success === true) {
                                this.props.onLoginCompleted(true, data.userInfo);
                                //Setting login completed instantly redirects to the destination page.
                            }
                            else {
                                this.setState({
                                    loading: false,
                                    lastError: data.error,
                                    showingError: true
                                });
                            }
                        });
                },
            );
        }
        return false;
    };

    closeError = () => {
        this.setState({ showingError: false });
    };

    showDialog = (dialog) => {
        this.setState({ showDialog: dialog });
    };

    closeDialog = () => {
        this.setState({ showDialog: LoginDialog.NONE });
    };

    buildLoginBox = () => {
        const { classes } = this.props;
        const { loading, initialUser } = this.state;

        return <Paper className={classes.paper}>
            <Typography variant="subtitle2">
                <strong>{lang("signInWithAdShuttle")}</strong>
            </Typography>
            <form id="loginForm" className={classes.form}>
                <FormControl margin="dense" required fullWidth variant="standard">
                    <InputLabel htmlFor="username">{lang("emailAddress")}</InputLabel>
                    <Input id="username" name="username" autoComplete="email" defaultValue={initialUser} autoFocus={initialUser === undefined} />
                </FormControl>
                <FormControl margin="dense" required fullWidth variant="standard">
                    <InputLabel htmlFor="password">{lang("password")}</InputLabel>
                    <Input
                        name="password"
                        type="password"
                        id="password"
                        autoFocus={initialUser !== undefined}
                        autoComplete="current-password"
                    />
                </FormControl>
                <FormControlLabel
                    control={<Checkbox id="chkRememberMe" value="remember" color="primary" className={classes.checkboxPadding} defaultChecked={initialUser !== undefined} />}
                    label={<Typography variant="body2">{lang("rememberMe")}</Typography>}
                />
                <Typography variant="body2" component={'span'} className={classes.forgotPassword}><Link to="#" onClick={() => this.showDialog(LoginDialog.FORGOTPASSWORD)}>{lang("forgotPassword")}</Link></Typography>
                <span className={classes.submitWrapper}>
                    {loading && <CircularProgress size={24} style={{ visibility: 'hidden' }} /> /* Extra spinner keeps submit button centered. */}
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                        data-component="login"
                        data-classname="submit"
                        className={classes.submit}
                        onClick={this.handleSubmit}
                    >
                        {lang("signIn")}
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </span>
            </form>
            <p />
            <Typography variant="subtitle2"><strong>{lang("notRegistered")}</strong></Typography>
            <Typography variant="body2"><Link to="#" onClick={() => this.showDialog(LoginDialog.CREATEACCOUNT)}>{lang("createFreeAccount")}</Link></Typography>
        </Paper>;
    };

    render() {
        const { cannotSubmit, isSales } = this.props;
        const { destination } = this.props?.location?.state
            || this.state.autoLogoutFrom
            || (this.props.needsToChangePassword && { destination: { pathname: '/passreset', state: { message: lang('passwordExpired') } } })
            || (!cannotSubmit ? { destination: { pathname: '/ticket' } } : isSales ? { destination: { pathname: '/sales' } } : { destination: { pathname: '/help' } });

        const currentUsername = document.getElementById("username");
        const { classes } = this.props;

        let currentDialog = '';
        if (this.state.showDialog === LoginDialog.CREATEACCOUNT) {
            currentDialog = <NewAccount closeDialog={this.closeDialog} />;
        }
        else if (this.state.showDialog === LoginDialog.FORGOTPASSWORD) {
            currentDialog = <ForgotPassword closeDialog={this.closeDialog} defaultEmail={currentUsername} />;
        }
        let header = '';
        let logo = '';
        let loginBox = '';
        if (getComponentThemeProperty("login", "hasHeader") === "True") {
            header = <div className={classes.header}>
                <img className={classes.headerLogo} data-component="login" data-classname="headerLogo" src="branding/GetImage?fileType=loginLogo" alt="AdShuttle" />
            </div>;
        }
        else {
            logo = <div className={classes.logo} data-component="login" data-classname="logo">
                <img data-component="login" data-classname="logo" src="branding/GetImage?fileType=loginLogo" alt="AdShuttle" />
            </div>;
        }
        let coverLocation = getComponentThemeProperty("login", "showCovers");
        let covers = <div className={classes.covers}><img src="branding/GetImage?fileType=covers" alt="Previous Issue Covers" /></div>;
        if (coverLocation === "Right") {
            loginBox = <React.Fragment>
                <div className={classes.layout}>{this.buildLoginBox()} {covers}</div>
                <div className={classes.helpBelowLogin}>
                    <SupportRequest showButton showDialog={this.props.showSupport} />
                </div>
            </React.Fragment>;
        }
        else if (coverLocation === "Left") {
            loginBox = <React.Fragment>
                <div className={classes.layout}>{covers} {this.buildLoginBox()}</div>
                <div className={classes.helpBelowLogin}>
                    <SupportRequest showButton showDialog={this.props.showSupport}/>
                </div>
            </React.Fragment>;
        }
        else if (coverLocation === "Top") {
            loginBox = <React.Fragment>{covers}<div className={classes.layout}>{this.buildLoginBox()}</div>
                <div className={classes.helpBelowLogin}>
                    <SupportRequest showButton showDialog={this.props.showSupport}/>
                </div></React.Fragment>;
        }
        else if (coverLocation === "Bottom") {
            loginBox = <React.Fragment><div className={classes.layout}>{this.buildLoginBox()}</div>{covers}
                <div className={classes.helpBelowLogin}>
                    <SupportRequest showButton showDialog={this.props.showSupport}/>
                </div></React.Fragment>;
        }
        else if (coverLocation === "BelowHelp") {
            loginBox = <React.Fragment><div className={classes.layout}>{this.buildLoginBox()}</div>
                <div className={classes.helpBelowLogin}>
                    <SupportRequest showButton showDialog={this.props.showSupport}/>
                </div>{covers}</React.Fragment>;
        }
        else {
            loginBox = <React.Fragment>
                <div className={classes.layout}>{this.buildLoginBox()}</div>
                <div className={classes.helpBelowLogin}><SupportRequest showButton showDialog={this.props.showSupport}/></div>
            </React.Fragment>;
        }

        //don't redirect to the support page, go to '/'.
        if (destination && destination.pathname === '/help/support') {
            destination.pathname = '/';
        }

        return (this.props.isAuthenticated) ? (<Navigate to={destination} replace />) : (
            <div className={classes.contentWrapper}>
                {header}
                <div className={classes.loginBody}>
                    <div className={classes.spaceAboveLogo} />
                    {logo}
                    <div className={classes.textAboveLogin}>
                        {React.createElement("span", { dangerouslySetInnerHTML: { __html: lang("textAboveLogin") } })}
                    </div>
                    {loginBox}
                </div>
                <Dialog
                    open={this.state.showingError}
                    onClose={this.closeError}
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {lang(this.state.lastError)}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeError} color="secondary" variant="contained" autoFocus>
                            {lang("ok")}
                        </Button>
                    </DialogActions>
                </Dialog>
                <div className={classes.bottomBar}>
                    <Typography variant="body2">
                        <span className={classes.bottomSegment}>{lang("copyright")} {lang("workflowSolutions")}</span>
                        <span className={classes.bottomSegment}><Link to="/terms" target="_blank">{lang("terms")}</Link></span>
                        <span className={classes.bottomSegment}><Link to="/privacy" target="_blank">{lang("privacyPolicy")}</Link></span>
                    </Typography>
                </div>
                {currentDialog}
            </div>
        );
    }
}

export default withStyles(Login, styles, { withTheme: true });