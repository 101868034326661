import React, { Component } from 'react';
import { Route, Routes } from 'react-router';
import NotFound from '../Common/NotFound';
import AdminIndex from './AdminIndex';
import CustomPaginationActionsTable from './AdminUsers';
import TicketSubmissionEmail from './AdminTicketSubmissionEmail';

export class AdminMain extends Component {
    displayName = AdminMain.name

    render() {
        return (
            <Routes>
                <Route path="/users" element={<CustomPaginationActionsTable />}></Route>
                <Route path="/TicketSubmissionEmail" element={<TicketSubmissionEmail/>}></Route>
                <Route path="/" element={<AdminIndex/>}></Route>
                <Route element={<NotFound/>} />
            </Routes>
        );
    }
}
