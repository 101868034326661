import * as THREE from 'three';
import React, { createRef } from 'react';
import { Canvas, useFrame } from 'react-three-fiber';
import { withStyles } from 'tss-react/mui';
import { lang } from '../../Language';
import { Slider } from '@mui/material';
import { BackSide, DoubleSide, FrontSide, LinearFilter } from 'three';
import IconButton from '@mui/material/IconButton';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Pause from '@mui/icons-material/Pause';
import CircularProgress from '@mui/material/CircularProgress';

const styles = theme => ({
    canvas: {
        height: '450px'
    },
    controlBar: {
        width: '100%',
        display: 'table'
    },
    playButton: {
        display: 'table-cell',
        marginRight: '12px',
        padding: '8px'
    },
    slider: {
        display: 'table-cell',
        verticalAlign: 'middle',
    },
});

//Playback is managed from the ThreeJS side because that is always rendering frames at the screen
//refresh rate on its own. Playing the animation there is "free", while a full react render needs to
//figure out what changes are made to the DOM and update more stuff.
//While playback is active the react code will refresh the slider position using setTimeout.
var _currentTime = 0;
var _lastTime = -1;
var _totalTime = 0;
let STEPS_PER_SECOND = 15; //Number of steps on the slider UI for each second
let SECONDS_PER_KEYFRAME = 2.0;
var _intervalId = null;
var _playing = false;
var _animation = null;
var _gatePages = null;
var _texturesLoading = 0;

function processAnimation(data, ticket, onTexturesLoaded) {
    _animation = data;
    _totalTime = (_animation.frames.length - 1) * SECONDS_PER_KEYFRAME;
    if (_totalTime < 0) _totalTime = 0; //Animations should have more than 1 frame.
    _gatePages = [];
    
    for (var i = 0; i < _animation.frames.length; i++) {
        var actualPageLeft = 0, actualPageRight = 0; //The actual left & right of the FRONT of the page, even when flipped.
        var target = 0;
        for (var j = 0; j < _animation.frames[i].pages.length; j++) {
            let pageWidth = data.panels[_animation.pages[j].front - 1].width;
            if (j === 0) {
                if (_animation.frames[i].pages[j].sideVisible === 'Back') {
                    actualPageLeft = pageWidth;
                    actualPageRight = 0;
                }
                else {
                    actualPageLeft = 0;
                    actualPageRight = pageWidth;
                }
            }
            else {
                actualPageLeft = actualPageRight; //Left side of next page is right side of previous page
                if (_animation.frames[i].pages[j].sideVisible === 'Back') {
                    actualPageRight -= pageWidth;
                }
                else {
                    actualPageRight += pageWidth;
                }
            }
            let x = (actualPageLeft < actualPageRight) ? actualPageLeft : actualPageRight;
            _animation.frames[i].pages[j].location = x;
            if (_animation.pages[j].front === _animation.frames[i].targetPage ||
                _animation.pages[j].back === _animation.frames[i].targetPage) {
                target = x; //Location of target page, for centering
            }
        }
        for (j = 0; j < _animation.frames[i].pages.length; j++) {
            _animation.frames[i].pages[j].location -= target;
        }
    }
    for (i = 0; i < _animation.pages.length; i++) {
        var gatePage = {
            front: null,
            back: null,
            leftFold: _animation.pages[i].leftFold,
            rightFold: _animation.pages[i].rightFold,
            rotation: 0,
            rotationSide: null,
            location: null,
            frontDisplayIndex: _animation.pages[i].front,
            backDisplayIndex: _animation.pages[i].back,
            visible: true,
            panelFront: data.panels[_animation.pages[i].front - 1],
            panelBack: data.panels[_animation.pages[i].back - 1]
        };
        gatePage.front = generatePageThumbnail(ticket, gatePage, true, onTexturesLoaded);
        gatePage.back = generatePageThumbnail(ticket, gatePage, false, onTexturesLoaded);
        _gatePages.push(gatePage);
    }
}

function calculateFrame(time) {
    let kf1 = ~~(time / SECONDS_PER_KEYFRAME);
    let kf2 = (kf1 < _animation.frames.length - 1) ? kf1 + 1 : kf1;
    var weight = (time - (kf1 * SECONDS_PER_KEYFRAME)) / SECONDS_PER_KEYFRAME;

    let firstKf = _animation.frames[kf1];
    let secondKf = _animation.frames[kf2];
    let compRot = isCompleteRotation(firstKf, secondKf);
    for (var i = 0; i < firstKf.pages.length; i++)
    {
        _gatePages[i].visible = true;
        let firstRot = (firstKf.pages[i].sideVisible === 'Back') ? 180 : 0;
        let secondRot = (secondKf.pages[i].sideVisible === 'Back') ? 180 : 0;
        _gatePages[i].rotation = applyWeight(firstRot, secondRot, weight);
        if (firstRot !== secondRot) {
            if (firstKf.pages[i].foldHint !== 'None') {
                _gatePages[i].rotationSide = firstKf.pages[i].foldHint;
            }
            else {
                if (compRot) {
                    if (firstKf.pages[i].sideVisible === 'Front')
                        _gatePages[i].rotationSide = 'Left';
                    else
                        _gatePages[i].rotationSide = 'Right';
                }
                else {
                    _gatePages[i].rotationSide = getRotationSide(firstKf, secondKf, i);
                }
            }
        }
        if (firstKf.pages[i].sideVisible === 'None') {
            _gatePages[i].visible = false;
        }
        _gatePages[i].location = applyWeight(firstKf.pages[i].location, secondKf.pages[i].location, weight);
        if (weight < 0.5) //After we're half way through turning the page, swap zOrder so the correct page appears on top of stacks
            _gatePages[i].zOrder = firstKf.pages[i].zOrder;
        else
            _gatePages[i].zOrder = secondKf.pages[i].zOrder;
        if (firstRot !== secondRot) { _gatePages[i].zOrder++; }
    }
}

function getRotationSide(kfStart, kfEnd, idx)
{
    let pageBelow = getPageBelowIndex(kfStart, idx);
    if (_gatePages[idx].leftFold != null && _gatePages[idx].leftFold !== 'Flat') {
        for (var i = idx; i < _gatePages.length; i++)
        {
            if (pageBelow == null || _gatePages[i].frontDisplayIndex === kfEnd.targetPage ||
                _gatePages[i].backDisplayIndex === kfEnd.targetPage) {
                if (kfStart.pages[i].sideVisible === 'Front')
                    return 'Left';
                else
                    return 'Right';
            }
        }
    }
    if (_gatePages[idx].rightFold != null && _gatePages[idx].rightFold !== 'Flat') {
        for (i = idx; i >= 0; i--)
        {
            if (pageBelow == null || _gatePages[i].frontDisplayIndex === kfEnd.targetPage ||
                _gatePages[i].backDisplayIndex === kfEnd.targetPage) {
                return 'Right';
                //We should have a front/back case here, but that doesn't produce desired results. May wish to revisit this.
            }
        }
    }
    if (pageBelow != null) {
        //May be flipping multiple pages at once, look down
        var lowerSide = getRotationSide(kfStart, kfEnd, pageBelow);
        if (kfStart.pages[idx].sideVisible !== kfStart.pages[pageBelow].sideVisible) {
            //Swap side
            switch (lowerSide) {
                case 'Left':
                    lowerSide = 'Right';
                    break;
                case 'Right':
                    lowerSide = 'Left';
                    break;
                default: break;
            }
        }
        return lowerSide;
    }
    return 'None';
}

function isCompleteRotation(kfStart, kfEnd)
{
    //Returns true if the entire gatefold is flipping over.
    for (var i = 0; i < kfStart.pages.length; i++)
    {
        if (kfStart.pages[i].sideVisible === kfEnd.pages[i].sideVisible) {
            return false;
        }
    }
    return true;
}

function getPageBelowIndex(kf, index)
{
    var current = kf.pages[index];
    var bestMatch = current.zOrder;
    var matchingIndex = null;
    for (var i = 0; i < kf.pages.length; i++)
    {
        var page = kf.pages[i];
        if (page.location === current.location && page.zOrder < current.zOrder &&
            (page.zOrder > bestMatch || current.zOrder === bestMatch)) {
            bestMatch = page.ZOrder;
            matchingIndex = i;
        }
    }
    return matchingIndex;
}

function applyWeight(num1, num2, weight)
{
    return (num1 * (1 - weight)) + (num2 * weight);
}

function generatePageThumbnail(ticket, gatePage, isFront, onTexturesLoaded) {
    let panel = isFront ? gatePage.panelFront : gatePage.panelBack;
    var component = null;
    var compPageIndex = 0;
    for (let i = 0; i < ticket.components.length; i++) {
        for (let j = 0; j < ticket.components[i].panelPositions.length; j++) {
            if (panel.id === ticket.components[i].panelPositions[j].id) {
                component = ticket.components[i];
                compPageIndex = j;
                break;
            }
            if (component !== null) {
                break;
            }
        }
    }
    let imageUrl = null;
    if (component != null && component.isPublisherSupplied) {
        imageUrl = `File/StreamPublisherSuppliedImage?tcid=${component.id}`;
    }
    else if (component != null && component.fileType != null) {
        if (component.fileType === 'OriginalThumbnail') {
            imageUrl = `File/StreamFile?fileType=OriginalFull&id=${component.id}`;
        }
        else if (component.fileType === 'HardCropThumbnail') {
            imageUrl = `File/StreamFile?fileType=HardCropFull&id=${component.id}`;
        }
    }
    if (imageUrl) {
        _texturesLoading++;
        const loader = new THREE.TextureLoader();
        loader.load(
            imageUrl,
            function (texture) {
                if (component.panelPositions.length > 1) {
                    //Texture needs to be cropped. We don't have split spreads for originals and hard crops don't get split until delivery.
                    var totalWidth = 0;
                    var cropStart = 0.0, cropEnd = 1.0;
                    for (var i = 0; i < component.panelPositions.length; i++) {
                        if (i === compPageIndex) {
                            cropStart = totalWidth;
                        }
                        totalWidth += Number(component.panelPositions[i].width);
                        if (i === compPageIndex) {
                            cropEnd = totalWidth;
                        }
                    }
                    texture.repeat.x = (cropEnd - cropStart) / totalWidth;
                    texture.offset.x = cropStart / totalWidth;
                }
                if (isFront) {
                    gatePage.front = texture;
                }
                else {
                    gatePage.back = texture;
                }
                _texturesLoading--;
                if (_texturesLoading < 0) {
                    _texturesLoading = 0;
                }
                if (_texturesLoading === 0) {
                    onTexturesLoaded();
                }
            },
            undefined,
            function (err) {
                _texturesLoading = 0; //An image failed to load, so let's stop trying.
                onTexturesLoaded();
            }
        );
        return null;
    }
    //No page image. Generate one.
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    ctx.canvas.width = 425;
    ctx.canvas.height = 550;
    ctx.fillStyle = "#AAA";
    ctx.fillRect(0, 0, 425, 550);
    ctx.strokeStyle = "#444";
    ctx.lineWidth = 2;
    ctx.strokeRect(0, 0, 425, 550);
    ctx.font = "40px Arial";
    ctx.fillStyle = "#000";
    let caption = panel.name;
    let width = ctx.measureText(caption).width;
    ctx.fillText(caption, (425 / 2) - (width / 2), 60);

    // canvas contents will be used for a texture
    var texture = new THREE.Texture(canvas)
    texture.needsUpdate = true;
    texture.magFilter = LinearFilter;
    texture.minFilter = LinearFilter;
    return texture;
}

function Pages(props) {
    const meshFront = [];
    const meshBack = [];
    const meshOutline = [];
    const allRefs = React.useRef([]);
    if (allRefs.current.length !== _gatePages.length * 3) {
        allRefs.current = Array(_gatePages.length * 3).fill().map((_, i) => allRefs.current[i] || createRef());

        for (var i = 0; i < _gatePages.length; i++) {
            meshFront.push(allRefs.current[(i * 3)]);
            meshBack.push(allRefs.current[(i * 3) + 1]);
            meshOutline.push(allRefs.current[(i * 3) + 2]);
        }
    }
    else {
        for (i = 0; i < _gatePages.length; i++) {
            meshFront.push(allRefs.current[(i * 3)]);
            meshBack.push(allRefs.current[(i * 3) + 1]);
            meshOutline.push(allRefs.current[(i * 3) + 2]);
        }
    }

    useFrame((state, delta) => {
        if (_playing) {
            _currentTime = _lastTime + delta;
            if (_currentTime >= _totalTime) {
                _playing = false;
                _currentTime = _totalTime;
            }
        }
        if (_lastTime !== _currentTime) {
            calculateFrame(_currentTime);
            _lastTime = _currentTime;
        }
        for (var p = 0; p < _gatePages.length; p++) {
            //We store rotation in degrees but we need to convert to Radians for calculations and ThreeJS.
            var rotation = _gatePages[p].rotation / -180.0 * Math.PI;
            if (_gatePages[p].rotationSide === 'Right') {
                rotation = -rotation;
            }

            if (p > 0) {
                var prevRot = _gatePages[p - 1].rotation / -180.0 * Math.PI;
                if (_gatePages[p - 1].rotationSide === 'Right') {
                    prevRot = -prevRot;
                }
                var nextX = Math.cos(prevRot) * (_gatePages[p - 1].panelFront.width / 2.0);
                nextX += Math.cos(rotation) * (_gatePages[p].panelFront.width / 2.0);
                var nextZ = Math.sin(-prevRot) * (_gatePages[p - 1].panelFront.width / 2.0);
                nextZ += Math.sin(-rotation) * (_gatePages[p].panelFront.width / 2.0);

                meshFront[p].current.position.x = meshFront[p - 1].current.position.x + nextX;
                meshFront[p].current.position.z = meshFront[p - 1].current.position.z + nextZ;
                meshFront[p].current.renderOrder = _gatePages[p].zOrder;
                meshBack[p].current.position.x = meshBack[p - 1].current.position.x + nextX;
                meshBack[p].current.position.z = meshBack[p - 1].current.position.z + nextZ;
                meshBack[p].current.renderOrder = _gatePages[p].zOrder;
                meshOutline[p].current.position.x = meshOutline[p - 1].current.position.x + nextX;
                meshOutline[p].current.position.z = meshOutline[p - 1].current.position.z + nextZ;
                meshOutline[p].current.renderOrder = _gatePages[p].zOrder;
            }
            else {
                meshFront[p].current.position.x = _gatePages[p].location;
                meshFront[p].current.renderOrder = _gatePages[p].zOrder;
                meshBack[p].current.position.x = _gatePages[p].location;
                meshBack[p].current.renderOrder = _gatePages[p].zOrder;
                meshOutline[p].current.position.x = _gatePages[p].location;
                meshOutline[p].current.renderOrder = _gatePages[p].zOrder;
            }
            meshFront[p].current.rotation.y = rotation;
            meshBack[p].current.rotation.y = rotation;
            meshOutline[p].current.rotation.y = rotation;
        }
    })

    let pageMeshes = [];
    for (var p = 0; p < _gatePages.length; p++) {
        pageMeshes.push(<React.Fragment key={`page3d_${p}`}>
            <mesh
                {...props}
                ref={meshOutline[p]}
                scale={[1.005, 1.005, 1.005]}>
                <planeBufferGeometry args={[_gatePages[p].panelFront.width, _gatePages[p].panelFront.height]} />
                <meshBasicMaterial side={DoubleSide} color="#444" depthTest={false} toneMapped={false} />
            </mesh>
            <mesh
                {...props}
                ref={meshFront[p]}
                scale={[1.0, 1.0, 1.0]}>
                <planeBufferGeometry args={[_gatePages[p].panelFront.width, _gatePages[p].panelFront.height]} />
                <meshStandardMaterial map={_gatePages[p].front} side={FrontSide} depthTest={false} toneMapped={false} />
            </mesh>
            <mesh
                {...props}
                ref={meshBack[p]}
                scale={[-1.0, 1.0, 1.0]}>
                <planeBufferGeometry args={[_gatePages[p].panelFront.width, _gatePages[p].panelFront.height]} />
                <meshStandardMaterial map={_gatePages[p].back} side={BackSide} depthTest={false} toneMapped={false} />
            </mesh>
        </React.Fragment>);
    }

    return pageMeshes;
}


class GateDisplay extends React.Component {
    displayName = GateDisplay.name

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            playing: false,
            frame: 0,
            name: '',
        };
        _lastTime = -1;
        _currentTime = 0;
        _playing = false;
    }

    componentDidMount() {
        const { loading } = this.state;
        const { ticket } = this.props;
        if (loading) {
            let publisherId = ticket.publisherId;
            fetch(`SvrGate/GetAnimation?publisherId=${publisherId}&sizeId=${ticket.sizeId}`, { credentials: 'same-origin' })
                .then(response => response.json())
                .then(data => {
                    processAnimation(data, ticket, this.onTexturesLoaded);
                    this.setState({ loading: false, name: data.name });
                }, (error) => {
                    this.setState({ loading: false, name: lang('error') });
                });
        }
    }

    componentWillUnmount() {
        if (_intervalId != null) {
            clearInterval(_intervalId);
            _intervalId = null;
        }
    }

    sliderChanged = (value) => {
        _currentTime = (value / STEPS_PER_SECOND / SECONDS_PER_KEYFRAME);
        this.setState({ frame: value });
    }

    playFrame = () => {
        let { frame, playing } = this.state;
        let actualFrame = ~~(_currentTime * SECONDS_PER_KEYFRAME * STEPS_PER_SECOND);
        if (frame !== actualFrame || _playing !== playing) {
            this.setState({ frame: actualFrame, playing: _playing });
        }
        if (!_playing && _intervalId != null) {
            clearInterval(_intervalId);
            _intervalId = null;
        }
    }

    togglePlay = () => {
        let { playing, frame } = this.state;
        let totalFrames = _totalTime * SECONDS_PER_KEYFRAME * STEPS_PER_SECOND;
        let actualFrame = ~~(_currentTime * SECONDS_PER_KEYFRAME * STEPS_PER_SECOND);
        playing = !playing;
        if (actualFrame >= totalFrames) {
            _currentTime = 0;
            _lastTime = 0;
            frame = 0;
        }
        this.setState({ playing: playing, frame: frame });
        _playing = playing;
        if (_intervalId != null) {
            clearInterval(_intervalId);
            _intervalId = null;
        }
        if (playing) {
            _intervalId = setInterval(this.playFrame, 50); //Every 50ms, which is roughly 20 times per second
        }
    }

    onTexturesLoaded = () => {
        this.forceUpdate();
    }

    render() {
        const { loading, playing, frame } = this.state;
        let totalFrames = _totalTime * SECONDS_PER_KEYFRAME * STEPS_PER_SECOND;

        let playPause = <PlayArrow />;
        if (playing) {
            playPause = <Pause />;
        }
        if (loading || _texturesLoading > 0) {
            return <CircularProgress />;
        }
        return (
            <React.Fragment><div className={this.props.classes.canvas}>
                <Canvas camera={{ zoom: 10, position: [0, 0, 100] }}>
                    <React.Suspense fallback={null}>
                        <ambientLight intensity={1} />
                        <Pages />
                    </React.Suspense>
                </Canvas>
            </div>
                <div className={this.props.classes.controlBar}>
                    <IconButton onClick={this.togglePlay} className={this.props.classes.playButton} aria-label="Play / Pause">
                        {playPause}
                    </IconButton>
                    <Slider className={this.props.classes.slider} onChange={(obj, val) => this.sliderChanged(val)} value={frame} max={totalFrames} aria-labelledby="continuous-slider" />
                </div>
            </React.Fragment>
        );
    }
}
export default withStyles(GateDisplay, styles);