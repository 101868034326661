import React, { Component } from 'react';
import { lang, langFormat } from '../../Language';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { overrideComponentThemeStyle } from '../../StyleCustomization';
import { TicketComponentStatus } from '../../TicketComponentStatus';

const styles = theme => {
    var result = {
        imagePreview: {
            '& img': { width: '100%', height: '100%' },
        },
        linkButton: {
            verticalAlign: 'baseline',
            padding: 0,
            textDecoration: 'underline',
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: 'inherit',
                textDecoration: 'underline',
            },
            '&:active': {
                color: theme.palette.primary.light,
            },
        }
    };
    result = overrideComponentThemeStyle(theme, result, "ImagePreviewDialog");
    return result;
};

export const ImageType = Object.freeze({
    Empty: 0,
    Original: 1,
    HardCropped: 2,
    PublisherSupplied: 3
});

class ImagePreviewDialog extends Component {
    displayName = ImagePreviewDialog.name

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            imageSource: null,
            componentId: null
        };
    }

    getLatestImageType = (status) => {
        switch (TicketComponentStatus[status]) {
            case TicketComponentStatus.ReadyToApprovePrecheck:
            case TicketComponentStatus.ReadyToPosition:
            case TicketComponentStatus.ReadyToSoftCrop:
            case TicketComponentStatus.SoftCropping:
            case TicketComponentStatus.SoftCropFailed:
            case TicketComponentStatus.ReadyToHardCrop:
            case TicketComponentStatus.HardCropping:
            case TicketComponentStatus.HardCropFailed:
            case TicketComponentStatus.HardCropCrashed:
            case TicketComponentStatus.ReadyToGenerateTemplate:
            case TicketComponentStatus.GeneratingTemplates:
            case TicketComponentStatus.GenerateTemplatesFailed:
                return ImageType.Original;

            case TicketComponentStatus.ReadyToApprovePosition:
            case TicketComponentStatus.ReadyToPreflight:
            case TicketComponentStatus.Preflighting:
            case TicketComponentStatus.PreflightPassed:
            case TicketComponentStatus.PreflightFailed:
            case TicketComponentStatus.PreflightCrashed:
            case TicketComponentStatus.ReadyToTransmit:
            case TicketComponentStatus.ReadyToAddCropMarks:
            case TicketComponentStatus.AddingCropMarks:
            case TicketComponentStatus.AddCropMarksFailed:
            case TicketComponentStatus.AddCropMarksCrashed:
            case TicketComponentStatus.ReadyToCreatePlateReadyFile:
            case TicketComponentStatus.CreatingPlateReadyFile:
            case TicketComponentStatus.CreatingPlateReadyFileFailed:
            case TicketComponentStatus.CreatingPlateReadyFileCrashed:
            case TicketComponentStatus.ReadyToAlterGeometry:
            case TicketComponentStatus.AlteringGeometry:
            case TicketComponentStatus.AlteringGeometryCrashed:
            case TicketComponentStatus.PostProcessingComplete:
                return ImageType.HardCropped;

            case TicketComponentStatus.PublisherSupplied:
                return ImageType.PublisherSupplied;

            default:
                return ImageType.Empty;
        }
    }

    getImageSrc = (component, type, status) => {
        let hash = component.hash ? `&hash=${component.hash}` : '';

        if (type) {
            return `file/StreamFile?fileType=${type}&id=${component.id}${hash}`;
        }
        else {
            if (!status) {
                status = component.status;
            }
            switch (this.getLatestImageType(status)) {
                case ImageType.HardCropped:
                    return `file/StreamFile?fileType=HardCropFull&id=${component.id}${hash}`;
                case ImageType.Original:
                    return `file/StreamFile?fileType=OriginalFull&id=${component.id}${hash}`;
                case ImageType.PublisherSupplied:
                    return `file/StreamPublisherSuppliedImage?tcid=${component.ticketId}`;
                default:
                    return ""; // use empty image so IE doesn't render the missing image "X"
            }
        }
    }

    showDialog = () => {
        let src = this.state.imageSource;
        let componentId = this.state.componentId;
        if (!src || componentId !== this.props.component.id) {
            src = this.getImageSrc(this.props.component, this.props.type, this.props.status);
            componentId = this.props.component.id;
        }
        this.setState({
            showDialog: true, imageSource: src, componentId: componentId
        });
    }

    closeDialog = () => {
        this.setState({
            showDialog: false
        });
    }
    
    render() {
        const { classes } = this.props;
        let activationControl = '';
        let dialogTitle = '';

        if (this.props.showLink) {
            activationControl =    
                <Button className={classes.linkButton} variant="text" size="small" disableRipple onClick={this.showDialog}>
                    {this.props.linkText}
                </Button>;
        } else if (this.props.showImageThumbnail) {
            activationControl =
                <img id="PreviewImage" src={this.props.imageThumbnailSource} onClick={this.showDialog} style={{ cursor: 'pointer' }} alt={langFormat("noImageFound", this.props.component.id)} />;
        }

        if (this.props.component) {
            dialogTitle = `${lang("ticket")} ${this.props.component.ticketId} ${lang("filePreview")}`;
        } else {
            dialogTitle = `${lang("filePreview")}`;
        }

        return (
            <React.Fragment>
                {activationControl}
                <Dialog open={this.state.showDialog} aria-labelledby='image-preview-title'>
                    <DialogTitle id="image-preview-title">{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <div className={classes.imagePreview}>
                            <img src={this.state.imageSource} alt={langFormat("noImageFound", this.props.component.id)} />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="contained" onClick={this.closeDialog}>{lang("ok")}</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(ImagePreviewDialog, styles);