import React, { Component } from 'react';
import { lang } from '../../Language';
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
    heading: {
        fontWeight: 'bold',
        marginTop: theme.spacing(1),
    },
    horizontalBar: {
        borderColor: 'black'
    },
    adminButton: {
        marginRight: '10px'
    }
});

class AdminIndex extends Component {
    displayName = AdminIndex.name

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Typography className={classes.heading} variant='h5'>{lang("Admin")}</Typography>
                <hr className={classes.horizontalBar} />
                <p>
                    <Button color="primary" variant="contained" component={Link} to="/admin/users" className={classes.adminButton}>{lang("Users")}</Button>
                    <Button color="primary" variant="contained" component={Link} to="/admin/ticketSubmissionEmail" className={classes.adminButton}>{lang("resendTicketSubmissionEmail")}</Button>
                </p>
            </div>
        );
    }
}

export default withStyles(AdminIndex, styles);