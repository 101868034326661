import React, { useState, useEffect, useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import { Route, Routes, Navigate } from 'react-router';
import NotFound from './components/Common/NotFound';
import Login from './components/Login';
import Logout from './components/Logout';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import Activation from './components/Activation';
import { setLanguageLookup } from './Language';
import MainLayout from './components/MainLayout';
import fetchIntercept from 'fetch-intercept';
import ResetPassword from './components/ResetPassword';
import ConfirmEmailChange from './components/ConfirmEmailChange';
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { createMakeStyles } from "tss-react";
import { useTheme } from "@mui/material/styles";
import GlobalStyles from '@mui/material/GlobalStyles';
const { makeStyles } = createMakeStyles({ useTheme });

const useStyles = makeStyles()({
    centeredSpinner: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '50px',
    },
});

function AdShuttleGlobal() { }
var _unregisterLogout = null;
var _logoutTimeout = null;
const SESSION_TIMEOUT = 1000 * 60 * 119; //1 minute less than 120 to make sure we're out before the server-side timeout of 120.

export default function App(props) {
    const { classes } = useStyles();
    const themeData = useRef('');
    const [loading, setLoading] = useState(true);
    const [userInfo, setUserInfo] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [autoLogoutFrom, setAutoLogoutFrom] = useState(null);
    const location = useLocation();

    useEffect(() => {
        //Allow us to call pushTheme externally to push a new theme at runtime.
        window.AdShuttleGlobal = AdShuttleGlobal;
        AdShuttleGlobal.pushTheme = pushNewTheme;
        AdShuttleGlobal.getTheme = getTheme;

        //In addition to the material theme colors, we can still use CSS at the page level for non-material components.
        //Just give them a className as usual. As long as className is a string, it won't be lost at compile time.
        fetch('Branding/GetTheme').then(response => response.json())
            .then(data => {
                var theme = createTheme(data);
                theme.palette.neutral = theme.palette.augmentColor({ color: theme.palette.neutral });

                fetch('Branding/GetLanguageTable').then(response => response.json())
                    .then(data => {
                        setLanguageLookup(data);
                        fetch('SvrUser/IsLoggedIn', { credentials: 'same-origin' }).then(response => response.json()).then(data => {
                            if (data.server) {
                                document.title = `AdShuttle - ${data.server}`;
                            }
                            themeData.current  = theme;
                            setLoading(false);
                            setIsAuthenticated(data.loggedIn);
                            setUserInfo(data.userInfo);
                            installAutoLogout(data.loggedIn);
                        });
                    });
            });
    }, []);

    const getTheme = () => {
        return themeData.current;
    }

    const pushNewTheme = (data) => {
        var theme = createTheme(data);
        theme.palette.neutral = theme.palette.augmentColor({ color: theme.palette.neutral });
        themeData.current = theme;
    }

    const autoLogout = () => {
        fetch('SvrUser/LogOut', { credentials: 'same-origin' }).then(response => response.json()).then(data => {
            if (_logoutTimeout) {
                clearTimeout(_logoutTimeout);
            }
            _logoutTimeout = null;
            _unregisterLogout();
            _unregisterLogout = null;
            setIsAuthenticated(false);
            setUserInfo(null);
            setAutoLogoutFrom({ pathname: window.location.pathname });
        });
    };

    const installAutoLogout = (isAuthenticated) => {
        if (isAuthenticated && _unregisterLogout === null) {
            _unregisterLogout = fetchIntercept.register({
                request: function (url, config) {
                    //Reset the timeout, the server knows we are alive.
                    if (_logoutTimeout) {
                        clearTimeout(_logoutTimeout);
                    }
                    _logoutTimeout = setTimeout(() => autoLogout(), SESSION_TIMEOUT);

                    return [url, config];
                }
            });
            //Set initial timeout
            if (_logoutTimeout) {
                clearTimeout(_logoutTimeout);
            }
            _logoutTimeout = setTimeout(() => autoLogout(), SESSION_TIMEOUT);
        }
        else if (!isAuthenticated && _unregisterLogout) {
            //Logging out, turn off fetch interception
            _unregisterLogout();
            _unregisterLogout = null;
            if (_logoutTimeout) {
                clearTimeout(_logoutTimeout);
            }
        }
    };

    const handleLogin = (isAuthenticated, userInfo) => {
        installAutoLogout(isAuthenticated);
        setIsAuthenticated(isAuthenticated);
        setUserInfo(userInfo);
        setAutoLogoutFrom(null);
    }

    const updateUserInfo = (userInfo) => {
        setUserInfo(userInfo);
    }

    const inside = (isAuthenticated) ?
        <MainLayout allowAdResubmission={userInfo.allowTicketResubmission} userInfo={userInfo} updateUserInfo={updateUserInfo} /> :
        <Navigate to={{ pathname: '/', state: { destination: location } }} replace />;

    //On component mount we start loading the theme colors. Until that's populated, we render the progress spinner.
    return loading ? (
        <div className={classes.centeredSpinner}>
            <CircularProgress size={50} />
        </div>
    ) : (
        <MuiThemeProvider theme={themeData.current}>
            <CssBaseline />
            <GlobalStyles styles={{ body: { backgroundColor: '#fafafa', fontSize: '0.875rem', lineHeight: '1.43', letterSpacing: '0.01071em' } }} />
            <Routes>
                <Route path='/' element={<Login {...props} location={location} autoLogoutFrom={autoLogoutFrom} isAuthenticated={isAuthenticated}
                    onLoginCompleted={handleLogin} needsToChangePassword={userInfo && userInfo.tempPasswordExpiration} cannotSubmit={userInfo && userInfo.cannotSubmit} isSales={userInfo && userInfo.isSales}
                    showSupport={location.state && location?.state?.destination && location.state.destination.pathname === '/help/support'} />} />
                <Route path='/logout' element={<Logout {...props} onLogoutCompleted={handleLogin} />} />
                <Route path='/ticket/*' element={inside} />
                <Route path='/track/*' element={inside} />
                <Route path='/profile/*' element={inside} />
                <Route path='/help/*' element={inside} />
                <Route path='/isr/*' element={inside} />
                <Route path='/sales/*' element={inside} />
                <Route path='/admin/*' element={inside} />
                <Route path='/terms' element={<Terms />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/activate/*' element={<Activation />} />
                <Route path='/confirmemailchange/*' element={<ConfirmEmailChange />} />
                <Route path='/user/*' element={<Navigate to={{ pathname: '/' }} replace />} />
                <Route path='/passreset/*' element={<ResetPassword />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </MuiThemeProvider>
    );
}
