import React from 'react';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const styles = theme => ({
    component: {
        paddingTop: 5,
        paddingBottom: 5
    },
    panelContainer: {
        position: 'relative',
        backgroundColor: '#888',
        height: 100
    },
    panelLabel: {
        fontWeight: 'bold',
        position: 'absolute',
        top: 3,
        zIndex: 1
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
});

class ImageDisplay extends React.Component {
    displayName = ImageDisplay.name

    //If an image fails to load, remove it from view and remove the circular progress.
    onImageError = (event, componentId) => {
        event.target.style.display = 'none';
        this.removeCircularProgressAndShowLabel(componentId);
    }

    //If an image loads, remove the circular progress
    onImageLoaded = (componentId) => {
        this.removeCircularProgressAndShowLabel(componentId);
    }

    removeCircularProgressAndShowLabel = (componentId) => {
        var progress = document.getElementById('component-progress-' + componentId);
        if (progress) {
            progress.style.display = 'none';
        }
        let index = 0;
        let label = document.getElementById(`Component-Label-${componentId}-${index}`);
        while (label !== undefined && label !== null) {
            label.style.display = 'initial';
            index = index + 1;
            label = document.getElementById(`Component-Label-${componentId}-${index}`);
        }
    }

    getTextWidth = (text, font) => {
        // re-use canvas object for better performance
        var canvas = this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement("canvas"));
        var context = canvas.getContext("2d");
        context.font = font;
        var metrics = context.measureText(text);
        return metrics.width;
    }

    //Add the labels for the panels (e.g. "panel 1")
    addPanelLabels = (panelPositions, classes, component, labelsAlwaysVisible, panelWidth) => {
        let x = 0;
        let currentWidth = 0;
        let labelVisibility = labelsAlwaysVisible ? 'initial' : 'none';
        return (
            panelPositions && panelPositions.map((position, index) => {
                x += currentWidth;
                if (panelPositions.length === 1 && panelWidth) {
                    //Split panels (spreads) always use the x9 calculation for lines, which we need to match.
                    //Otherwise we can just use the panel width which is more accurate for smaller sizes.
                    currentWidth = panelWidth;
                }
                else {
                    currentWidth = parseFloat(position.width);
                }

                if (currentWidth < 50) {
                    if (component !== undefined && component.id > 0 && component.filename !== null) {
                        return <div id={`Component-Label-${component.id}-${index}`} key={index} style={{ textShadow: '0 0 2px #FFF', left: x, width: currentWidth, overflow: 'hidden', textAlign: 'center', fontSize: '7pt', top: 5, display: labelVisibility }} className={classes.panelLabel}>{position.name}</div>;
                    }
                    else {
                        return <div key={index} style={{ textShadow: '0 0 2px #FFF', left: x, width: currentWidth, overflow: 'hidden', textAlign: 'center', fontSize: '7pt', top: 5 }} className={classes.panelLabel}>{position.name}</div>;
                    }
                }
                if (component !== undefined && component.id > 0 && component.filename !== null) {
                    return <div id={`Component-Label-${component.id}-${index}`} key={index} style={{ textShadow: '0 0 2px #FFF', left: x, width: currentWidth, overflow: 'hidden', textAlign: 'center', display: labelVisibility }} className={classes.panelLabel}><Typography variant="body2">{position.name}</Typography></div>;
                }
                else {
                    return <div key={index} style={{ textShadow: '0 0 2px #FFF', left: x, width: currentWidth, overflow: 'hidden', textAlign: 'center' }} className={classes.panelLabel}><Typography variant="body2">{position.name}</Typography></div>;
                }
            })
        );
    }


    //Add the dashed separator lines to the panels
    addPanelLines = (panelPositions) => {
        let x = 0;
        let count = 1;
        return (
            panelPositions && panelPositions.map(position => {
                let length = panelPositions.length;
                if (count < length) {
                    x = parseFloat(position.width) + x;
                    count = count + 1;
                    return <div key={position.index} style={{ position: 'absolute', height: '100%', left: x, borderLeftWidth: 1, borderLeftStyle: 'dashed', zIndex: '1' }}></div>;
                }
                return '';
            })
        );
    }

    //Fetch the image for the panel(s) and provide a progress spinny while it loads the image.  When the image is loaded (or fails to load) the spinny's div container is hidden.
    displayImage = (classes, filePath, component) => {
        if (filePath && filePath !== '') {
            return (
                <React.Fragment>
                    <div id={'component-progress-' + component.id} className={classes.progress}><CircularProgress /></div>
                    <img src={filePath} onError={(e) => this.onImageError(e, component.id)} onLoad={() => this.onImageLoaded(component.id)} alt={component.fileName} style={{ position: 'absolute', height: parseFloat(component.imageHeight), width: '100%' }} />
                </React.Fragment>
            );
        }
        else {
            return '';
        }
    }

    //Add the width of the panel below the panel (e.g. 8.5" )
    displayPanelSize = (component, templateSize, showPanelSizes, marginLeft) => {
        if (showPanelSizes) {
            return <div style={{ position: 'relative', width: parseFloat(templateSize.width), textAlign: 'center', display: 'block', marginLeft: marginLeft }}><Typography variant="body2">{component.sizeComponent.width}</Typography></div>;
        }
        else {
            return '';
        }
    }

    renderImage = (classes, component, filePath, componentSize, sizePanels, marginLeft) => {
        if (component) {
            return (
                <div>
                    <div className={classes.panelContainer} style={{ marginLeft: marginLeft, width: parseFloat(component.imageWidth), height: parseFloat(component.imageHeight) }}>
                        {this.addPanelLabels(component.panelPositions, classes, component, false, parseFloat(component.imageWidth))}
                        {this.addPanelLines(component.panelPositions)}
                        {this.displayImage(classes, filePath, component)}
                    </div>
                    {this.displayPanelSize(component, componentSize, this.props.showPanelWidths, marginLeft)}
                </div>
             );
        }
        else {
            return (
                <div>
                    <div className={classes.panelContainer} style={{ marginLeft: marginLeft, width: parseFloat(componentSize.width), height: parseFloat(componentSize.height) }}>
                        {this.addPanelLabels(sizePanels, classes, undefined, true, parseFloat(componentSize.width))}
                        {this.addPanelLines(sizePanels)}
                    </div>
                </div>
                );
        }
    }


    render() {
        const { classes, component, filePath, componentSize, sizePanels, marginLeft } = this.props;
        return (
            <React.Fragment>
                {this.renderImage(classes, component, filePath, componentSize, sizePanels, marginLeft)}
            </React.Fragment>
        );
    }
}

//component (Optional, but suggested): A TicketComponent, fields that are required include: id, filename, imageWidth, imageHeight, panelPositions, and template (for template.width).
//filePath (Required if providing a component): filePath to the component's image to load it.
//ComponentSize (Required): A ComponentSize object with width and height properties.
//sizePanels (Required if NOT providing a component): Used in the same manner as a component's panelPositions.
//marginLeft (Required): Used to inject a margin-left to the ImageDisplay.

export default withStyles(ImageDisplay, styles);