import React, { Component } from 'react';
import { lang } from '../../Language';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import ArrowRight from '@mui/icons-material/ArrowRight';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Checkbox from '@mui/material/Checkbox';
import ImagePreviewDialog from './ImagePreviewDialog';
import { Link } from 'react-router-dom';
import { dateParser } from '../../HelperMethods';

const styles = theme => ({
    header: {
        paddingLeft: 3,
        fontWeight: 'bold'
    },
    table: {
        borderCollapse: 'separate',
        borderSpacing: '3px 0px'
    },
    head: {
        fontSize: 14,
        height: 40,
        paddingLeft: 3,
        paddingRight: 3,
        whiteSpace: 'nowrap',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        }
    },
    sortLabel: {
        color: `${theme.palette.primary.contrastText} !important`,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold'
        },
        '&:focus': {
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold'
        }
    },
    row: {
        height: 25,
        paddingLeft: 3,
        paddingRight: 3,
        whiteSpace: 'nowrap'
    },
    rowDetails: {
        height: 100,
        paddingLeft: 3,
        paddingRight: 3,
        whiteSpace: 'nowrap',
        backgroundColor: '#DDD',
        borderColor: 'black'
    },
    body: {
        padding: '0px 3px 0px 3px'
    },
    bodyDetails: {
        padding: '0px 3px 0px 3px',
        borderBottom: '1px solid black',
        height: '100%'
    },
    component: {
        width: 200,
        marginLeft: 3
    },
    detailsContainer: {
        display: 'flex'
    },
    detailSectionLabel: {
        display: 'inline-block',
        marginRight: 25,
        verticalAlign: 'middle',
        margin: '12px 25px 0px 15px'
    },
    detailsRow: {
        height: 25
    },
    detailsBody: {
        padding: '4px 20px 4px 4px',
        border: 'none'
    },
    ticketComponentDetails: {
        marginRight: 35
    },
    currentComponentDetailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    currentComponentReports: {
        marginTop: 10
    }
});

class TicketReportList extends Component {
    displayName = TicketReportList.name

    constructor(props) {
        super(props);
        this.state = {
            order: 'desc',
            orderBy: 'ticketId',
            isNumber: true
        };
    }

    desc = (a, b, orderBy, isNumber) => {

        var valueA = undefined;
        var valueB = undefined;

        if (!isNumber) {
            if (orderBy === 'urls') {
                valueA = '';
                valueB = '';
                for (let i = 0; i < a[orderBy].length; i++) {
                    valueA += a[orderBy] ? a[orderBy].toString().toLowerCase() : '';
                }
                for (let i = 0; i < b[orderBy].length; i++) {
                    valueB += b[orderBy] ? b[orderBy].toString().toLowerCase() : '';
                }
            }
            else {
                valueA = a[orderBy] ? a[orderBy].toString().toLowerCase() : '';
                valueB = b[orderBy] ? b[orderBy].toString().toLowerCase() : '';
            }
            return -valueA.localeCompare(valueB);
        }
        else {
            if (orderBy === 'proofReceived') {
                //special case; need to sort additionally by the date/time
                return this.sortByProofReceived(a, b, orderBy);
            }
            valueA = a[orderBy] ? a[orderBy] : 0;
            valueB = b[orderBy] ? b[orderBy] : 0;
            if (valueB < valueA) {
                return -1;
            }
            if (valueB > valueA) {
                return 1;
            }
            return 0;
        }
    }

    sortByProofReceived = (a, b, orderBy) => {

        let valueA = a[orderBy] ? a[orderBy] : 0;
        let valueB = b[orderBy] ? b[orderBy] : 0;

        if (valueB < valueA) {
            return -1;
        }
        if (valueB > valueA) {
            return 1;
        }
        if (valueA && valueB) {
            var receivedOnA = a['proofReceivedOn'];
            var receivedOnB = b['proofReceivedOn'];
            var dateA = Date.parse(receivedOnA.toLocaleDateString() + ' ' + receivedOnA.toLocaleTimeString());
            var dateB = Date.parse(receivedOnB.toLocaleDateString() + ' ' + receivedOnB.toLocaleTimeString());
            if (dateB < dateA) {
                return -1;
            }
            if (dateB > dateA) {
                return 1;
            }
        }
        return 0;
    }

    stableSort = (array, cmp) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    getSorting = (order, orderBy, isNumber) => {
        return order === 'desc' ? (a, b) => this.desc(a, b, orderBy, isNumber) : (a, b) => -this.desc(a, b, orderBy, isNumber);
    }

    onSortChange = (property, isNumber) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy, isNumber });
    };

    toggleDetails = (ticket) => {
        let { currentReport } = this.props;
        ticket.ticketDetailsExpanded = !ticket.ticketDetailsExpanded;
        this.props.updateReport(currentReport);
    }

    setProofReceived = (event) => {
        let { currentReport, updateReport } = this.props;
        var ticket = currentReport.tickets.find(t => t.ticketId === Number(event.target.id));
        if (ticket) {
            ticket.proofReceived = event.target.checked;
            fetch(`SvrIsr/SetProofReceived?tid=${ticket.ticketId}&isReceived=${event.target.checked}`, { method: 'post', credentials: 'same-origin' })
                .then(response => response.text())
                .then(data => {
                    if (data) {
                        data = JSON.parse(data, dateParser);
                        ticket.proofReceivedOn = data.proofReceivedOn;
                        updateReport(currentReport);
                    }
                });
        }        
    }

    onComponentChange = (event) => {
        let { currentReport } = this.props;
        var ticket = currentReport.tickets.find(t => t.ticketId === Number(event.target.id));
        if (ticket) {
            var component = ticket.components.find(c => c.id === Number(event.target.value));
            if (component) {
                ticket.selectedComponent = component;
            }
        }
        this.props.updateReport(currentReport);
    }

    componentDetails(classes, ticket) {
        return (
            <TableRow key={`ticketDetails${ticket.ticketId}`} className={classes.rowDetails}>
                <TableCell className={classes.bodyDetails} colSpan={23}>
                    <div className={classes.detailsContainer}>
                        <div className={classes.ticketComponentDetails}>
                            <Typography variant="subtitle2" className={classes.detailSectionLabel}>
                                {lang("componentId")}:
                            </Typography>
                            <FormControl key="componentId" margin="dense" required fullWidth className={classes.component} variant="standard">
                                <NativeSelect id={ticket.ticketId.toString()} name="componentId" value={ticket.selectedComponent !== null ? ticket.selectedComponent.id : ""} onChange={this.onComponentChange}>
                                    <option value="" />
                                    {ticket.components.map(component => (
                                        <option key={component.id} value={component.id}>{component.id}</option>
                                    ))}
                                </NativeSelect>
                            </FormControl>
                        </div>
                        <div className={classes.currentComponentDetailsContainer}>
                            <div className={classes.currentComponentDetails}>
                                <Table>
                                    <TableBody>
                                        <TableRow className={classes.detailsRow}>
                                            <TableCell className={classes.detailsBody}>{lang('filename')}</TableCell>
                                            <TableCell className={classes.detailsBody}>
                                                {ticket.isPrint
                                                    ? ticket.selectedComponent.filename
                                                    : [<a id="ticketComponentFilename" href={"/file/streamfile?fileType=MediaFile&id=" + ticket.selectedComponent.Id}>{ticket.selectedComponent.filename}</a>]
                                                }
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className={classes.detailsRow}>
                                            <TableCell className={classes.detailsBody}>{lang('componentStatus')}</TableCell>
                                            <TableCell className={classes.detailsBody}>{ticket.selectedComponent.status}</TableCell>
                                        </TableRow>
                                        <TableRow className={classes.detailsRow}>
                                            <TableCell className={classes.detailsBody}>{lang('publisherSupplied')}</TableCell>
                                            <TableCell className={classes.detailsBody}>{ticket.selectedComponent.isPublisherSupplied.toString()}</TableCell>
                                        </TableRow>
                                        <TableRow className={classes.detailsRow}>
                                            <TableCell className={classes.detailsBody}>{lang('color')}</TableCell>
                                            <TableCell className={classes.detailsBody}>{ticket.selectedComponent.color}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                            <div className={classes.currentComponentReports}>
                                <Table>
                                    <TableBody>
                                        {ticket.selectedComponent.precheckReportExists &&
                                            <TableRow className={classes.detailsRow}>
                                                <TableCell className={classes.detailsBody}>{lang('precheckReport')}</TableCell>
                                                <TableCell className={classes.detailsBody}>
                                                <a id="originalPdf" href={"file/StreamFile?fileType=PrecheckReport&id=" + ticket.selectedComponent.id} target="_blank" rel="noopener noreferrer">{lang('precheckReport')}</a>
                                                </TableCell>
                                            </TableRow>
                                        }
                                        {ticket.selectedComponent.preflightReportExists &&
                                            <TableRow className={classes.detailsRow}>
                                                <TableCell className={classes.detailsBody}>{lang('preflightReport')}</TableCell>
                                                <TableCell className={classes.detailsBody}>
                                                <a id="originalPdf" href={"file/StreamFile?fileType=PreflightReport&id=" + ticket.selectedComponent.id} target="_blank" rel="noopener noreferrer">{lang('preflightReport')}</a>
                                                </TableCell>
                                            </TableRow>
                                        }
                                        {ticket.isConfirmationAvailable &&
                                            <TableRow className={classes.detailsRow}>
                                                <TableCell className={classes.detailsBody}>{lang("confirmationReceipt")}</TableCell>
                                                <TableCell className={classes.detailsBody}>
                                                <a id="originalPdf" href={"file/StreamFile?fileType=Receipt&id=" + ticket.ticketId} target="_blank" rel="noopener noreferrer">{lang("confirmationReceipt")}</a>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                        <div className={classes.currentComponentFiles}>
                            {!ticket.selectedComponent.isPublisherSupplied && ticket.isPrint &&
                                [<Table key="componentFiles">
                                    <TableBody>
                                        {ticket.selectedComponent.originalExists &&
                                            [<TableRow key="original" className={classes.detailsRow}>
                                                <TableCell className={classes.detailsBody}>{lang("original")}</TableCell>
                                                <TableCell className={classes.detailsBody}>
                                                    <Typography variant="subtitle2">
                                                        <ImagePreviewDialog showLink linkText={lang("originalFull")} component={ticket.selectedComponent} status="ReadyToPosition" />
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                        <a id="originalPdf" href={"file/StreamFile?fileType=OriginalPdf&id=" + ticket.selectedComponent.id} target="_blank" rel="noopener noreferrer">{lang("originalPdf")}</a>
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>]
                                        }
                                        {ticket.selectedComponent.softCropExists &&
                                            [<TableRow key="softCropo" className={classes.detailsRow}>
                                                <TableCell className={classes.detailsBody}>{lang("softCrop")}</TableCell>
                                                <TableCell className={classes.detailsBody}>
                                                    <a id="softCropPdf" href={"file/StreamFile?fileType=SoftCropPdf&id=" + ticket.selectedComponent.id} target="_blank" rel="noopener noreferrer">{lang("softCropPdf")}</a>
                                                </TableCell>
                                            </TableRow>]
                                        }
                                        {ticket.selectedComponent.hardCropExists &&
                                            [<TableRow key="hardCrop" className={classes.detailsRow}>
                                                <TableCell className={classes.detailsBody}>{lang("hardCrop")}</TableCell>
                                                <TableCell className={classes.detailsBody}>
                                                    <Typography variant="subtitle2">
                                                        <ImagePreviewDialog showLink linkText={lang("hardCropFull")} component={ticket.selectedComponent} />
                                                        {ticket.selectedComponent.isSplittingSpreadComplete &&
                                                            ticket.selectedComponent.pageSuffixes.map((page, index) => {
                                                                return (
                                                                    <a id="hardCropPage" href={"file/StreamFile?fileType=HardCropPage&id=" + ticket.selectedComponent.id + "&index=" + index} target="_blank" rel="noopener noreferrer"> {page}</a>
                                                                );
                                                            })
                                                        }
                                                    </Typography>
                                                    {ticket.selectedComponent.hardCropWithmarksExists &&
                                                        [<Typography variant="subtitle2">
                                                            <a id="hardCropMarksFull" href={"file/StreamFile?fileType=HardCropMarksFull&id=" + ticket.selectedComponent.id} target="_blank" rel="noopener noreferrer">{lang("hardCropFullWithCropMarks")}</a>
                                                            {ticket.selectedComponent.isSplittingSpreadComplete &&
                                                                ticket.selectedComponent.pageSuffixes.map((page, index) => {
                                                                    return (
                                                                        <a id="hardCropMarksPage" href={"file/StreamFile?fileType=HardCropMarksPage&id=" + ticket.selectedComponent.id + "&index=" + index} target="_blank" rel="noopener noreferrer"> {page}</a>
                                                                    );
                                                                })
                                                            }
                                                        </Typography>]
                                                    }
                                                    <Typography variant="subtitle2">
                                                        <ImagePreviewDialog showLink linkText={lang("hardCropFullWithTemplate")} component={ticket.selectedComponent} type="HardCropFullWithTemplate" />
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                        <a id="HardCropPdf" href={"file/StreamFile?fileType=HardCropPdf&id=" + ticket.selectedComponent.id} target="_blank" rel="noopener noreferrer">{lang("hardCropPdf")}</a>
                                                        {ticket.selectedComponent.isPdfSplit && ticket.selectedComponent.isSplittingSpreadComplete &&
                                                            ticket.selectedComponent.pageSuffixes.map((page, index) => {
                                                                return (
                                                                    <a id="hardCropFullWithTemplatePage" href={"file/StreamFile?fileType=HardCropPagePdf&id=" + ticket.selectedComponent.id + "&index=" + index} target="_blank" rel="noopener noreferrer"> {page}</a>
                                                                );
                                                            })
                                                        }
                                                    </Typography>
                                                    {ticket.selectedComponent.hardCropWithmarksExists &&
                                                        [<Typography variant="subtitle2">
                                                            <a id="hardCropMarksPdf" href={"file/StreamFile?fileType=HardCropMarksPdf&id=" + ticket.selectedComponent.id} target="_blank" rel="noopener noreferrer">{lang("hardCropPdfWithCropMarks")}</a>
                                                            {ticket.selectedComponent.isPdfSplit && ticket.selectedComponent.isSplittingSpreadComplete &&
                                                                ticket.selectedComponent.pageSuffixes.map((page, index) => {
                                                                    return (
                                                                        <a id="hardCropMarksPagePdf" href={"file/StreamFile?fileType=HardCropMarksPagePdf&id=" + ticket.selectedComponent.id + "&index=" + index} target="_blank" rel="noopener noreferrer"> {page}</a>
                                                                    );
                                                                })
                                                            }
                                                        </Typography>]
                                                    }
                                                </TableCell>
                                            </TableRow>]
                                        }
                                    </TableBody>
                                </Table>]
                            }
                        </div>
                    </div>
                </TableCell>
            </TableRow>);
    }

    render() {
        const { classes } = this.props;
        const { order, orderBy, isNumber } = this.state;
        let { currentReport } = this.props;
        var header = "";
        if (currentReport.publications !== null) {
            var publication = currentReport.publications.find(p => p.id === currentReport.publicationId);
            if (publication) {
                header = publication.name;
            }
        }
        if (currentReport.issues !== null) {
            var issue = currentReport.issues.find(i => i.id === currentReport.issueId);
            if (issue) {
                header += ", " + issue.name;
            }
        }
        if (header.length > 0) {
            header += " " + lang('tickets');
        }

        let tableColumns = [
            { id: 'ticketDetails', label: '', sortable: false },
            { id: 'ticketId', label: lang('ticketId'), sortable: true, isNumber: true },
            { id: 'submissionId', label: lang('submissionId'), sortable: true, isNumber: true },
            { id: 'ticketType', label: lang('ticketType'), sortable: true },
            { id: 'materialTrackingNumber', label: lang('materialTrackingNumber'), sortable: true },
            { id: 'colors', label: lang('colors'), sortable: true },
            { id: 'details', label: lang('details'), sortable: false },
            { id: 'adSpecs', label: lang('adSpecs'), sortable: false },
            { id: 'status', label: lang('status'), sortable: true },
            { id: 'proofIncluded', label: lang('proof'), sortable: true },
            { id: 'proofPaid', label: lang('proofPaid'), sortable: true },
            { id: 'proofReceived', label: lang('proofReceived'), sortable: true, isNumber: true },
            { id: 'advertiser', label: lang('advertiser'), sortable: true },
            { id: 'headline', label: lang('headline'), sortable: true },
            { id: 'product', label: lang('product'), sortable: true },
            { id: 'billable', label: lang('billable'), sortable: true },
            { id: 'createdBy', label: lang('createdBy'), sortable: true },
            { id: 'firstName', label: lang('firstName'), sortable: true },
            { id: 'lastName', label: lang('lastName'), sortable: true },
            { id: 'createdOn', label: lang('createdOn'), sortable: true, isNumber: true },
            { id: 'submittedOn', label: lang('submittedOn'), sortable: true, isNumber: true },
            { id: 'size', label: lang('size'), sortable: true },
            { id: 'urls', label: lang('url'), sortable: true }
        ];

        let removeBillableColumn = this.props.userInfo.isClientAdminAndHasPublishersAssigned;

        if (removeBillableColumn) {
            tableColumns.splice(tableColumns.findIndex(c => c.id == 'billable'), 1);  //remove billable column
        }

        return (
            <div>
                <Typography className={classes.header} variant='h6'>{header}</Typography>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className={classes.row}>
                            {tableColumns.map(column => (
                                <TableCell className={classes.head} key={column.id} sortDirection={orderBy === column.id ? order : false}>
                                    {
                                        column.sortable
                                            ? [<TableSortLabel key={column.id} classes={{ root: classes.sortLabel, active: classes.sortLabel, icon: classes.sortLabel }} active={orderBy === column.id} direction={order} onClick={() => this.onSortChange(column.id, column.isNumber)}>
                                                {column.label}
                                            </TableSortLabel>]
                                            : column.label
                                    }
                                    
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.stableSort(currentReport.tickets, this.getSorting(order, orderBy, isNumber))
                            .map(ticket =>
                            {
                                var rows = [];
                                rows.push(
                                    <TableRow key={ticket.ticketId} className={classes.row}>
                                        <TableCell className={classes.body} onClick={() => this.toggleDetails(ticket)}>
                                            <span style={{cursor: 'pointer'}}>
                                                {ticket.ticketDetailsExpanded
                                                    ? [<ArrowDropDown key="arrow" style={{ verticalAlign: 'middle' }} />]
                                                    : [<ArrowRight key="arrow" style={{ verticalAlign: 'middle' }} />]
                                                }
                                            </span>
                                        </TableCell>
                                        <TableCell className={classes.body}>{ticket.ticketId}</TableCell>
                                        <TableCell className={classes.body}>{ticket.submissionId}</TableCell>
                                        <TableCell className={classes.body}>{ticket.ticketType}</TableCell>
                                        <TableCell className={classes.body}>{ticket.materialTrackingNumber}</TableCell>
                                        <TableCell className={classes.body}>{ticket.colors}</TableCell>
                                        <TableCell className={classes.body}>
                                            <Link id={ticket.ticketId} target="_blank" to={`/isr/ticketDetails/${ticket.ticketId}`}>{lang('details')}</Link>
                                        </TableCell>
                                        <TableCell className={classes.body}><a id="adSpecs" href={ticket.adSpecs} target='_AdSpecs'>{lang('adSpecs')}</a></TableCell>
                                        <TableCell className={classes.body}>{ticket.statusDesc}</TableCell>
                                        <TableCell className={classes.body}>{ticket.proofIncluded}</TableCell>
                                        <TableCell className={classes.body}><Checkbox checked={ticket.proofPaid} /></TableCell>
                                        <TableCell className={classes.body}>
                                            <Checkbox
                                                name="proofReceived" id={`chkProof_${ticket.ticketId}`} value={ticket.proofReceived.toString()}
                                                checked={ticket.proofReceived} onChange={this.setProofReceived} className={classes.detailsAreaCheckbox}
                                            />
                                            {ticket.proofReceivedOn !== null ? `${ticket.proofReceivedOn.toLocaleDateString()} ${ticket.proofReceivedOn.toLocaleTimeString()}` : ""}
                                        </TableCell>
                                        <TableCell className={classes.body}>{ticket.advertiser}</TableCell>
                                        <TableCell className={classes.body}>{ticket.headline}</TableCell>
                                        <TableCell className={classes.body}>{ticket.product}</TableCell>
                                        {
                                            !removeBillableColumn && <TableCell className={classes.body}><Checkbox checked={ticket.billable} /></TableCell>   
                                        }
                                        <TableCell className={classes.body}>{ticket.createdBy}</TableCell>
                                        <TableCell className={classes.body}>{ticket.firstName}</TableCell>
                                        <TableCell className={classes.body}>{ticket.lastName}</TableCell>
                                        <TableCell className={classes.body}>
                                            {ticket.createdOn.toLocaleDateString()} {ticket.createdOn.toLocaleTimeString()}
                                        </TableCell>
                                        <TableCell className={classes.body}>
                                            {ticket.submittedOn !== null ? `${ticket.submittedOn.toLocaleDateString()} ${ticket.submittedOn.toLocaleTimeString()}` : ""}
                                        </TableCell>
                                        <TableCell className={classes.body}>{ticket.size}</TableCell>
                                        <TableCell className={classes.body}>{ticket.urls}</TableCell>
                                    </TableRow>);
                                if (ticket.ticketDetailsExpanded) {
                                    if (ticket.selectedComponent === null) {
                                        rows.push(
                                            <TableRow key={`ticketDetails${ticket.ticketId}`} className={classes.rowDetails}>
                                                <TableCell className={classes.bodyDetails} colSpan={22}>
                                                    <Typography variant="subtitle2" className={classes.detailSectionLabel}>
                                                        {lang("noComponents")}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                    else {
                                        rows.push(this.componentDetails(classes, ticket));
                                    }
                                }
                                return rows;
                            })
                        }
                    </TableBody>
                </Table>
            </div>
        );
    }
}

export default withStyles(TicketReportList, styles, { withTheme: true });