import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Error from '@mui/icons-material/Error';
import Warning from '@mui/icons-material/Warning';
import DeleteForever from '@mui/icons-material/DeleteForever';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { lang } from '../../Language';
import { TicketComponentStatus } from '../../TicketComponentStatus';
import TicketClosedDialog, { TicketState } from './TicketClosedDialog';

const styles = theme => ({
    deleteIcon: {
        '&:hover': {
            cursor: 'pointer'
        },
        marginLeft: 'auto'
    },
    image: {
        '&:hover': {
            cursor: 'pointer'
        },
        marginLeft: 30
    },
    inlineText: {
        position: 'relative',
        display: 'inline-block',
        overflow: 'hidden',
        marginLeft: 5,
        maxWidth: 200,
        width: 200,
        textOverflow: 'ellipsis'
    },
    icon: {
    },
    fileDisplay: {
        padding: 10,
        display: 'flex',
        alignItems: 'center'
    },
    dialogContent: {
        position: 'relative',
    },
    dialogActions: {
        display: 'block',
        justifyContent: 'none',
        textAlign: 'center'
    },
    iframe: {
        width: '100%',
        height: 'calc(100vh - 240px)',
    },
    warningIcon: {
        color: 'yellow'
    },
    errorIcon: {
        color: 'red'
    },
    successIcon: {
        color: 'green'
    }
});

const DialogType = Object.freeze({
    None: 0,
    Error: 1,
    DeleteConfirmation: 2,
    FilePreview: 3
});

class MediaFileRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogType: DialogType.None,
            showDialog: false,
            message: '',
            ticketState: TicketState.None
        };
    }

    delete = (event) => {
        this.setState({ showDialog: true, dialogType: DialogType.DeleteConfirmation })
    }

    view = (event) => {
        this.setState({ showDialog: true, dialogType: DialogType.FilePreview });
    }

    closeDialog = (deleteTicket) => {
        //check if true to avoid any false positives
        if (deleteTicket === true) {
            fetch(`SvrTicket/RemoveFileFromTicket?tcid=${this.props.component.id}`, { method: 'POST', credentials: "same-origin" })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        this.props.currentSubmission.ticket = data.ticket;
                        this.props.currentSubmission.reloadPrecheckPreflightResults = true;
                        this.props.updateSubmission(this.props.currentSubmission);
                    }
                    else {
                        if (data.error === 'isDeleted') {
                            this.setState({ ticketState: TicketState.Deleted });
                        }
                        else if (data.error === 'isSubmitted') {
                            this.setState({ ticketState: TicketState.Submitted });
                        }
                        else {
                            this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(data.error) });
                        }
                    }
                });
        }
        this.setState({ showDialog: false });
    }

    render() {
        const { classes, component, readonly } = this.props;
        let statusIcon = '';
        let dialogTitle = '';
        let dialogMessage = '';
        let isReport = false;
        if (component.status === TicketComponentStatus.PrecheckFailed || component.isMediaVerificationCrashed) {
            statusIcon = <Error className={`${classes.icon} ${classes.errorIcon}`} />;
        }
        else if (component.hasPrecheckWarnings) {
            statusIcon = <Warning className={`${classes.icon} ${classes.warningIcon}`} />;
        }
        else {
            statusIcon = <CheckCircle className={`${classes.icon} ${classes.successIcon}`} />;
        }

        if (this.state.dialogType === DialogType.DeleteConfirmation) {
            dialogTitle = lang('removeFile');
            dialogMessage = lang('removeFileMessage');
        }
        else if (this.state.dialogType === DialogType.Error) {
            dialogTitle = lang('error');
            dialogMessage = lang(this.state.message);
        }
        else if (this.state.dialogType === DialogType.FilePreview) {
            dialogTitle = lang('filePreview');
            let fileType = component.isFilePDF ? 'MediaFull' : 'MediaFile';
            dialogMessage = <iframe className={this.props.classes.iframe} title={component.id} src={`./File/StreamFile?fileType=${fileType}&id=${component.id}`} />
            isReport = true;
        }
        return (
            <div className={classes.fileDisplay}>
                {statusIcon}
                <Typography variant="body2" className={classes.inlineText} component='div'>{component.filename}</Typography>
                <img onClick={this.view} className={classes.image} src={`./File/StreamMediaThumbnail?id=${component.id}&maxWidth=100&maxHeight=100&fileHash=${component.fileHash}`} alt={component.filename} />
                {readonly ? '' : (<DeleteForever onClick={this.delete} className={classes.deleteIcon} />)}
                <Dialog scroll={isReport ? 'body' : 'paper'} open={this.state.showDialog} onClose={() => this.closeDialog()}>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent className={isReport ? classes.dialogContent : ''}><Typography variant="body2">{dialogMessage}</Typography></DialogContent>
                    <DialogActions className={isReport ? classes.dialogActions : ''}>
                        {this.state.dialogType === DialogType.DeleteConfirmation ? (
                            <Button onClick={() => this.closeDialog()} color='primary' variant='contained'>{lang('cancel')}</Button>
                        ) : ''}
                        <Button onClick={() => this.closeDialog(this.state.dialogType === DialogType.DeleteConfirmation)} autoFocus color='secondary' variant='contained'>{lang('ok')}</Button>
                    </DialogActions>
                </Dialog>
                <TicketClosedDialog open={this.state.ticketState !== TicketState.None} isDeleted={this.state.ticketState === TicketState.Deleted} currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission} />
            </div>
        )
    }
}

export default withStyles(MediaFileRow, styles);